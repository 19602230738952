import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import {
  TCheckboxeOption,
  TCheckboxesValue,
  TValues,
} from "newApp/kit/components/Checkboxes/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";

import formValidationSchema from "./formValidationSchema";
import { NewsFormFilesStore } from "./NewsFormFilesStore";
import { NewsFormMainPhotoStore } from "./NewsFormMainPhotoStore";
import { NewsFormPhotosStore } from "./NewsFormPhotosStore";

export class NewsFormStore {
  isLoadings = {
    getRubricsOptions: false,
  };

  form = new FormStore(
    {
      content: null,
      croppedImageId: null,
      date: null,
      isBanner: null,
      isMain: null,
      previewImageId: null,
      state: null,
      title: null,
      type: null,
    },
    formValidationSchema
  );

  arrays: { rubrics: TCheckboxesValue[] } = {
    rubrics: [],
  };

  mainPhoto = new NewsFormMainPhotoStore();
  files = new NewsFormFilesStore();
  photos = new NewsFormPhotosStore();

  options: { rubrics: TCheckboxeOption[] } = {
    rubrics: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getRubricsOptions();
    this.form.changeValueByKey(new Date(), "date");
    this.mainPhoto.init();
    this.files.init();
    this.photos.init();
  };

  reset = () => {
    this.form.reset();
    this.resetRubrics();
    this.resetArrays();
    this.mainPhoto.reset();
    this.files.reset();
    this.photos.reset();
  };

  resetRubrics = () => {
    this.options.rubrics = [];
  };

  resetArrays = () => {
    Object.keys(this.arrays).forEach((key) => (this.arrays[key] = []));
  };

  getRubricsOptions = () => {
    this.isLoadings.getRubricsOptions = true;
    return API.administration.NewsRubrics.newsRubricListGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.rubrics = data
            .filter((i) => i.isActive)
            .map((i) => ({
              text: i.title || "Неизвестно",
              value: i.id || null,
            }));
        });
      })
      .catch(this.resetRubrics)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubricsOptions = false;
        });
      });
  };

  onChangeCheckboxes = (_: any, __: any, checkedValues?: TValues[]) => {
    this.arrays.rubrics = checkedValues;
  };

  validate = () => {
    this.form.validate();
  };

  get isValid() {
    return this.form.isValid;
  }
}
