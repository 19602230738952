import { AxiosRequestConfig } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { IFileItem } from "../../../components/FilesList/index.types";
import { getDataURL } from "../../../utils/getDataURL";
import { TFileUploader } from "../index.types";

class FileItemStore implements IFileItem {
  file: File;
  status: IFileItem["status"] = "idle";
  errorMessage: IFileItem["errorMessage"];
  loadingProgress: IFileItem["loadingProgress"] = 0;
  preview: IFileItem["preview"];
  /** received after upload */
  id: string | null = null;

  private uploadFile: TFileUploader;

  constructor(
    file: File,
    uploadFile: (
      file: File,
      config?: AxiosRequestConfig
    ) => Promise<{ id: string }>
  ) {
    this.file = file;
    this.uploadFile = uploadFile;

    if (file.type.includes("image/")) {
      this.createPreview();
    }
    makeAutoObservable(this);
  }

  upload = async () => {
    try {
      this.status = "loading";
      this.errorMessage = null;

      const { id } = await this.uploadFile(this.file, {
        onUploadProgress: (progressEvent) => {
          if (progressEvent && progressEvent.loaded && progressEvent.total) {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            runInAction(() => (this.loadingProgress = percent));
          }
        },
      });

      runInAction(() => {
        this.id = id;
        this.status = "success";
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
        this.errorMessage = "Не удалось загрузить файл на сервер";
      });
    }
  };

  private createPreview = async () => {
    try {
      const result = await getDataURL(this.file);

      if (typeof result === "string") {
        runInAction(() => {
          this.preview = result;
        });
      }
    } catch (error) {}
  };
}

export default FileItemStore;
