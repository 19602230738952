import { makeAutoObservable } from "mobx";
import { VoteDetailsFormStore } from "./VoteDetailsFormStore";
import { VoteQuestionsFormStore } from "./VoteQuestionsFormStore";

export class VoteFormStore {
  details = new VoteDetailsFormStore();
  questions = new VoteQuestionsFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.details.init();
    this.questions.init();
  };

  reset = () => {
    this.details.reset();
    this.questions.reset();
  };

  validate = () => {
    this.details.validate();
    this.questions.validate();
  };

  get isValid() {
    return this.details.isValid && this.questions.isValid;
  }
}
