import SitePage from "../../pages/site";
import FaqListPage from "../../pages/site/faq";
import FaqCreatePage from "../../pages/site/faq/create";
import FaqItemPage from "../../pages/site/faq/id";
import FaqEditPage from "../../pages/site/faq/id/edit";
import FaqRubricsListPage from "../../pages/site/faq/rubrics";
import FaqRubricsCreatePage from "../../pages/site/faq/rubrics/create";
import FaqRubricsEditPage from "../../pages/site/faq/rubrics/id/edit";
import MobilePage from "../../pages/site/mobile";
import MobileEditPage from "../../pages/site/mobile/edit";
import NewsPage from "../../pages/site/news";
import NewsCreatePage from "../../pages/site/news/create";
import NewsItemPage from "../../pages/site/news/id";
import NewsEditPage from "../../pages/site/news/id/edit";
import RubricsListPage from "../../pages/site/news/rubrics";
import RubricsCreatePage from "../../pages/site/news/rubrics/create";
import RubricEditPage from "../../pages/site/news/rubrics/id/edit";
import ProjectDescriptionPage from "../../pages/site/projectdescription";
import ProjectDescriptionEditPage from "../../pages/site/projectdescription/edit";
import TechSupportPage from "../../pages/site/techsupport";
import TechSupportAnsweredPage from "../../pages/site/techsupport/answered";
import TechSupportAnsweredItemPage from "../../pages/site/techsupport/answered/id";
import TechSupportNewPage from "../../pages/site/techsupport/new";
import TechSupportNewItemPage from "../../pages/site/techsupport/new/id";
import DocsPage from "../../pages/site/docs";
import DocsTermsPage from "../../pages/site/docs/terms";
import DocsTermsEditPage from "../../pages/site/docs/terms/edit";
import DocsAgreementPage from "../../pages/site/docs/agreement";
import DocsAgreementEditPage from "../../pages/site/docs/agreement/edit";
import DocsPolicyPage from "../../pages/site/docs/policy";
import DocsPolicyEditPage from "../../pages/site/docs/policy/edit";
import EmailTemplates from "newApp/pages/site/emailTemplates";
import EmailTemplatesCreate from "newApp/pages/site/emailTemplates/create";
import EmailTemplatesEditPage from "newApp/pages/site/emailTemplates/id";
import {
  URL_SITE,
  URL_SITE_DESCRIPTION,
  URL_SITE_DESCRIPTION_EDIT,
  URL_SITE_FAQ,
  URL_SITE_FAQ_CREATE,
  URL_SITE_FAQ_ID,
  URL_SITE_FAQ_ID_EDIT,
  URL_SITE_FAQ_RUBRICS,
  URL_SITE_FAQ_RUBRICS_CREATE,
  URL_SITE_FAQ_RUBRICS_ID_EDIT,
  URL_ADMINISTRATION_MOBILE,
  URL_ADMINISTRATION_MOBILE_EDIT,
  URL_SITE_NEWS,
  URL_SITE_NEWS_CREATE,
  URL_SITE_NEWS_ID,
  URL_SITE_NEWS_ID_EDIT,
  URL_SITE_NEWS_RUBRICS,
  URL_SITE_NEWS_RUBRICS_ID_EDIT,
  URL_SITE_NEWS_RUBRICS_CREATE,
  URL_SITE_TECHSUPPORT,
  URL_SITE_TECHSUPPORT_ANSWERED,
  URL_SITE_TECHSUPPORT_ANSWERED_ID,
  URL_SITE_TECHSUPPORT_NEW,
  URL_SITE_TECHSUPPORT_NEW_ID,
  URL_SITE_DOCS,
  URL_SITE_DOCS_TERMS,
  URL_SITE_DOCS_TERMS_EDIT,
  URL_SITE_DOCS_AGREEMENTS,
  URL_SITE_DOCS_AGREEMENTS_EDIT,
  URL_SITE_DOCS_POLICY,
  URL_SITE_DOCS_POLICY_EDIT,
  URL_SITE_EMAILTEMPLATES,
  URL_SITE_EMAILTEMPLATES_CREATE,
  URL_SITE_EMAILTEMPLATES_EDIT,
} from "../urls";

const siteRouting: { path: string; component: React.ReactNode }[] = [
  {
    path: URL_SITE,
    component: <SitePage />,
  },
  {
    path: URL_SITE_NEWS,
    component: <NewsPage />,
  },
  {
    path: URL_SITE_NEWS_ID_EDIT,
    component: <NewsEditPage />,
  },
  {
    path: URL_SITE_NEWS_CREATE,
    component: <NewsCreatePage />,
  },
  {
    path: URL_SITE_NEWS_RUBRICS,
    component: <RubricsListPage />,
  },
  {
    path: URL_SITE_NEWS_RUBRICS_ID_EDIT,
    component: <RubricEditPage />,
  },
  {
    path: URL_SITE_NEWS_RUBRICS_CREATE,
    component: <RubricsCreatePage />,
  },
  //   {
  //     path: URL_SITE_NEWS_RUBRICS_ID,
  //     component: <RubricEditPage />,
  //   },
  {
    path: URL_SITE_NEWS_ID,
    component: <NewsItemPage />,
  },
  {
    path: URL_SITE_FAQ,
    component: <FaqListPage />,
  },
  {
    path: URL_SITE_FAQ_CREATE,
    component: <FaqCreatePage />,
  },
  {
    path: URL_SITE_FAQ_RUBRICS,
    component: <FaqRubricsListPage />,
  },
  {
    path: URL_SITE_FAQ_RUBRICS_CREATE,
    component: <FaqRubricsCreatePage />,
  },
  {
    path: URL_SITE_FAQ_RUBRICS_ID_EDIT,
    component: <FaqRubricsEditPage />,
  },
  {
    path: URL_SITE_FAQ_ID,
    component: <FaqItemPage />,
  },
  {
    path: URL_SITE_FAQ_ID_EDIT,
    component: <FaqEditPage />,
  },
  {
    path: URL_ADMINISTRATION_MOBILE,
    component: <MobilePage />,
  },
  {
    path: URL_ADMINISTRATION_MOBILE_EDIT,
    component: <MobileEditPage />,
  },
  {
    path: URL_SITE_DESCRIPTION,
    component: <ProjectDescriptionPage />,
  },
  {
    path: URL_SITE_DESCRIPTION_EDIT,
    component: <ProjectDescriptionEditPage />,
  },
  {
    path: URL_SITE_TECHSUPPORT,
    component: <TechSupportPage />,
  },
  {
    path: URL_SITE_TECHSUPPORT_NEW,
    component: <TechSupportNewPage />,
  },
  {
    path: URL_SITE_TECHSUPPORT_NEW_ID,
    component: <TechSupportNewItemPage />,
  },
  {
    path: URL_SITE_TECHSUPPORT_ANSWERED,
    component: <TechSupportAnsweredPage />,
  },
  {
    path: URL_SITE_TECHSUPPORT_ANSWERED_ID,
    component: <TechSupportAnsweredItemPage />,
  },
  {
    path: URL_SITE_DOCS,
    component: <DocsPage />,
  },
  {
    path: URL_SITE_DOCS_TERMS,
    component: <DocsTermsPage />,
  },
  {
    path: URL_SITE_DOCS_TERMS,
    component: <DocsTermsPage />,
  },
  {
    path: URL_SITE_DOCS_TERMS_EDIT,
    component: <DocsTermsEditPage />,
  },
  {
    path: URL_SITE_DOCS_AGREEMENTS,
    component: <DocsAgreementPage />,
  },
  {
    path: URL_SITE_DOCS_AGREEMENTS_EDIT,
    component: <DocsAgreementEditPage />,
  },
  {
    path: URL_SITE_DOCS_POLICY,
    component: <DocsPolicyPage />,
  },
  {
    path: URL_SITE_DOCS_POLICY_EDIT,
    component: <DocsPolicyEditPage />,
  },
  {
    path: URL_SITE_EMAILTEMPLATES,
    component: <EmailTemplates />,
  },
  {
    path: URL_SITE_EMAILTEMPLATES_CREATE,
    component: <EmailTemplatesCreate />,
  },
  { path: URL_SITE_EMAILTEMPLATES_EDIT, component: <EmailTemplatesEditPage /> },
];

export default siteRouting;
