import { observer } from "mobx-react";
import React from "react";
import IconClose from "../IconClose";
import { IPopupProps } from "./index.types";

import "./styles.scss";

const Popup: React.FC<IPopupProps> = (props) => {
  if (!props.isOpen) return null;

  return (
    <div className="popup">
      <div className="popup__background" onClick={props.onClose}></div>
      <div className="popup__window">
        <button
          onClick={props.onClose}
          className="popup__close-button"
          aria-label="Закрыть окно"
        >
          <IconClose />
        </button>
        {props.title && (
          <div className="popup__window-title">{props.title}</div>
        )}
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default observer(Popup);
