import { FC } from "react";
import IconClose from "./IconClose";
import "./styles.scss";
import { FileStatus } from "./FileStatus";
import { getFileSizeMB } from "../../utils/getFileSizeMB";
import classNames from "classnames";
import Button from "../Button";
import { IFilesListProps } from "./index.types";
import { observer } from "mobx-react";

const FilesList: FC<IFilesListProps> = ({
  files,
  onRemoveFile,
  onRemoveFileAll,
  className,
  errorTopMessage,
}) => {
  return (
    <div
      className={classNames("files-list", className)}
      data-testid="FilesList"
    >
      {errorTopMessage && (
        <p className="files-list__error-top">
          {errorTopMessage}{" "}
          {onRemoveFileAll && files.length > 0 && (
            <Button
              variant="link"
              size="small"
              onClick={() => onRemoveFileAll()}
            >
              Удалить все
            </Button>
          )}
          {undefined}
        </p>
      )}
      <ul className={"files-list__list"}>
        {files.map((fileItem, idx) => {
          const fileStatus = fileItem.status || "success";
          return (
            <li key={idx} className="files-list__item">
              <FileStatus
                status={fileStatus}
                loadingProgress={fileItem.loadingProgress}
                preview={fileItem.preview}
                name={fileItem.file?.name}
              />
              <div className="files-list__item-body">
                {fileItem.file && (
                  <p
                    className={classNames(
                      "files-list__item-title",
                      fileStatus === "success" &&
                        "files-list__item-title--success",
                      fileStatus === "error" && "files-list__item-title--error"
                    )}
                  >
                    {fileItem.file.name}
                  </p>
                )}
                {fileItem.errorMessage && (
                  <p className="files-list__item-error">
                    {fileItem.errorMessage}
                  </p>
                )}
                {fileItem.file && (
                  <p className="files-list__item-file-size">{`${getFileSizeMB(
                    fileItem.file.size
                  )}  Мб`}</p>
                )}
              </div>
              {onRemoveFile && (
                <button
                  className="files-list__button-remove"
                  aria-label="удалить файл"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveFile(idx);
                  }}
                >
                  <IconClose />
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default observer(FilesList);
