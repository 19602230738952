import "./styles.scss";

import { observer } from "mobx-react";
import FlexColumn from "newApp/kit/components/FlexColumn";
import { DeclarationChatStore } from "newApp/stores/common/DeclarationChatStore";
import { DeclarationHistoryStore } from "newApp/stores/common/DeclarationHistoryStore";
import { ExecutionStepStore } from "newApp/stores/pages/execution/ExecutionItemPageStore/ExecutionStepStore";
import { TExecutionDeclarationInfo } from "newApp/stores/pages/execution/ExecutionItemPageStore/index.types";
import React from "react";
import { useLocation } from "react-router";

import DeclarationItemBodyModeSwitcher, {
  DECLARATION_BODY_MODE,
  DECLARATION_BODY_MODE_CHAT,
  DECLARATION_BODY_MODE_HISTORY,
  DECLARATION_BODY_MODE_INFO,
} from "../DeclarationItemBodyModeSwitcher";
import DeclarationItemChat from "../DeclarationItemChat";
import DeclarationItemExecution from "../DeclarationItemExecution";
import DeclarationItemHistory from "../DeclarationItemHistory";
import { IFilesPreviewFile } from "../FilesPreview";

export interface IDeclarationExecutionItemBodyProps {
  info: TExecutionDeclarationInfo;
  history: DeclarationHistoryStore;
  chat: DeclarationChatStore;
  onClickPublish: () => void;
  onClickAdditionalControl: () => void;
  files: IFilesPreviewFile[];
  steps: ExecutionStepStore[];
}

const DeclarationExecutionItemBody: React.FC<IDeclarationExecutionItemBodyProps> =
  (props) => {
    const { search } = useLocation();
    const bodyMode = new URLSearchParams(search).get(DECLARATION_BODY_MODE);

    console.log({ bodyMode });

    return (
      <div>
        <FlexColumn>
          <DeclarationItemBodyModeSwitcher />

          {(!bodyMode || bodyMode === DECLARATION_BODY_MODE_INFO) && (
            <DeclarationItemExecution {...props} />
          )}
          {bodyMode === DECLARATION_BODY_MODE_CHAT && (
            <DeclarationItemChat store={props.chat} />
          )}
          {bodyMode === DECLARATION_BODY_MODE_HISTORY && (
            <DeclarationItemHistory store={props.history} />
          )}
        </FlexColumn>
      </div>
    );
  };

export default observer(DeclarationExecutionItemBody);
