import AnalyticsPage from "../../pages/analytics";
import AnalyticsInWorkPage from "../../pages/analytics/inwork";
import AnalyticsAcceptedPage from "../../pages/analytics/accepted";
import {
  URL_ANALYTICS,
  URL_ANALYTICS_ACCEPTED,
  URL_ANALYTICS_INWORK,
} from "../urls";

const analyticsRouting: { path: string; component: React.ReactNode }[] = [
  {
    path: URL_ANALYTICS,
    component: <AnalyticsPage />,
  },
  {
    path: URL_ANALYTICS_INWORK,
    component: <AnalyticsInWorkPage />,
  },
  {
    path: URL_ANALYTICS_ACCEPTED,
    component: <AnalyticsAcceptedPage />,
  },
];

export default analyticsRouting;
