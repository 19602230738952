import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { NewsFormStore } from "../../../../common/NewsFormStore";

export class NewsEditPageStore {
  isLoadings = {
    getNewsItem: false,
    saveNews: false,
  };
  id: string = "";
  form = new NewsFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getNewsItem();
    this.form.init();
  };

  reset = () => {
    this.form.reset();
  };

  getNewsItem = () => {
    this.isLoadings.getNewsItem = true;
    return API.administration.News.newsIdGet(this.id)
      .then(({ data }) => {
        try {
          runInAction(() => {
            this.form.form.changeValueByKey(data.title || null, "title");
            this.form.form.changeValueByKey(data.content || null, "content");
            this.form.form.changeValueByKey(data.state || null, "state");
            this.form.form.changeValueByKey(data.type || null, "type");
            this.form.form.changeValueByKey(
              !!(String(data.type) === String(1)),
              "isBanner"
            );
            this.form.form.changeValueByKey(
              !!(String(data.type) === String(2)),
              "isMain"
            );
            this.form.form.changeValueByKey(
              data.date ? new Date(data.date) : new Date(),
              "date"
            );
            this.form.arrays.rubrics = data.rubricsIds;
            this.form.photos.setPhotos(data.photos);
            this.form.mainPhoto.setMainPhoto(
              data.croppedImageId || "",
              data.previewImageId || "",
              this.id
            );
            this.form.files.setFiles(data.files);
            this.form.photos.setPhotos(data.photos);
          });
        } catch (error: any) {
          console.error(error);
        }
      })
      .catch(() => {
        this.form.reset();
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getNewsItem = false;
        });
      });
  };

  saveNews = async () => {
    try {
      runInAction(() => {
        this.isLoadings.saveNews = true;
      });
      await API.administration.News.newsIdPost(this.id, {
        content: this.form.form.values.content,
        date: this.form.form.values.date,
        state: this.form.form.values.state,
        type: this.form.form.values.type,
        title: this.form.form.values.title,
        rubricsIds: this.form.arrays.rubrics.map((i) => String(i)),
        imageId: this.form.mainPhoto.mainImageId,
      });
      await this.form.mainPhoto.saveCroppedImage(this.id);
      await this.form.mainPhoto.savePreviewImage(this.id);
      await this.form.files.changeFilesOnServer(this.id);
      await this.form.photos.changePhotosOnServer(this.id);
    } catch (error: any) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.isLoadings.saveNews = false;
      });
    }
  };
}
