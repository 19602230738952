import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import {
  TCheckboxeOption,
  TValues,
} from "newApp/kit/components/Checkboxes/index.types";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";
import formValidationSchema from "./formValidationSchema";

export class StepItemStore {
  isLoadings = {
    getStepTypes: false,
    getStepFields: false,
  };

  form = new FormStore(
    {
      stepType: null,
    },
    formValidationSchema
  );

  options: {
    stepType: ISelectOption[];
    fields: TCheckboxeOption[];
  } = {
    stepType: [],
    fields: [],
  };

  fields: string[] = [];

  constructor(form?: IStepItemStoreForm) {
    if (form) {
      try {
        this.form.changeValueByKey(form.stepType, "stepType");
        this.fields = form.fields;
      } catch (error: any) {
        console.error(error);
      }
      this.getStepFields();
    }
    makeAutoObservable(this);
    this.getStepTypes();
  }

  reset = () => {
    this.form.reset();
  };

  getStepTypes = () => {
    this.isLoadings.getStepTypes = true;
    return API.admin.Steps.apiStepsGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.stepType = data.map((i) => ({
            text: i["name"],
            value: i["id"],
          }));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getStepTypes = false;
        });
      });
  };

  get stepName() {
    if (this.form.values.stepType) {
      return this.options.stepType.find(
        (i) => i.value === this.form.values.stepType
      )?.text;
    }
    return "Не выбран тип шага";
  }

  onChangeStepTypes = (value: any, name?: string) => {
    this.form.changeValue(value, name);
    this.fields = [];
    if (value) this.getStepFields();
  };

  getStepFields = () => {
    this.isLoadings.getStepFields = true;
    return API.admin.Steps.apiStepsIdFieldtypesGet(this.form.values.stepType)
      .then(({ data }) => {
        runInAction(() => {
          this.options.fields = data.map((i) => ({
            text: i["name"],
            value: i["id"],
          }));
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .then(() => {
        runInAction(() => {
          this.isLoadings.getStepFields = false;
        });
      });
  };

  onChangeFields = (_: any, __?: any, checkedValues?: TValues[]) => {
    this.fields = checkedValues as any;
  };

  validate = () => {
    this.form.validate();
  };

  get isValid() {
    return this.form.isValid;
  }
}

export interface IStepItemStoreForm {
  stepType: string;
  fields: string[];
}
