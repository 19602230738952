import React, { useState } from "react";
import ShowHideIcon from "./ShowHideIcon";
import "./styles.scss";

export interface IAccordionProps {
  headerText: React.ReactNode;
  subHeaderText?: string | React.ReactNode;
  defaultIshowBody?: boolean;
  className?: string;
  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
}

const Accordion: React.FC<IAccordionProps> = ({
  defaultIshowBody = false,
  ...props
}) => {
  const [isShowBody, setIsShowBody] = useState(defaultIshowBody);

  const toggleIsShowBody = () => {
    if (isShowBody) {
      if (props.onCloseFunction) props.onCloseFunction();
    } else {
      if (props.onOpenFunction) props.onOpenFunction();
    }
    setIsShowBody(!isShowBody);
  };

  return (
    <div className={`accordion ${props.className ? props.className : ""}`}>
      <div className="accordion__header" onClick={toggleIsShowBody}>
        <div className="accordion__header-row">
          <div className="accordion__header-text">{props.headerText}</div>
          <ShowHideIcon reverse={isShowBody} />
        </div>
        <div className="accordion__header-sub-text">{props.subHeaderText}</div>
      </div>
      <div
        className={[
          "accordion__body",
          !isShowBody ? "accordion__body--hide" : "",
        ].join(" ")}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
