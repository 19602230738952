import React from "react";
import "./styles.scss";

interface IButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "danger" | "link";
  size?: "small" | "medium" | "large";
}

const Button: React.FC<React.ComponentProps<"button"> & IButtonProps> = ({
  onClick,
  variant = "primary",
  size = "medium",
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={[
        "button",
        `button--${variant}`,
        `button--${size}`,
        props.className,
      ].join(" ")}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
