import { apiAccess } from "./access";
import { apiAdmin } from "./admin";
import { apiAdministration } from "./administration";
import { apiAuth } from "./auth";
import { apiShop } from "./shop";
import { apiGeo } from "./geo";

export const API = {
  auth: apiAuth,
  access: apiAccess,
  admin: apiAdmin,
  administration: apiAdministration,
  shop: apiShop,
  geo: apiGeo,
};
