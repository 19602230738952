import { makeAutoObservable } from "mobx";
import { FormStore } from "newApp/kit/stores/FormStore";

export class AnalyticsFilterStore {
  form = new FormStore({
    period: null,
    beginDate: null,
    endDate: null,
  });

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.form.reset();
  };
}
