import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import { ICheckboxProps } from "newApp/kit/components/CheckBox/index.types";
import React from "react";
import "./styles.scss";

export interface ICheckboxHideBlockProps extends ICheckboxProps {
  checkboxTitle?: React.ReactNode;
}

const CheckboxHideBlock: React.FC<ICheckboxHideBlockProps> = (props) => {
  return (
    <div className="checkbox-hide-block">
      <Checkbox {...props}>{props.checkboxTitle}</Checkbox>
      {!!props.value && (
        <div className="checkbox-hide-block__content">{props.children}</div>
      )}
    </div>
  );
};

export default observer(CheckboxHideBlock);
