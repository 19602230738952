import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import SiteNavigator from "newApp/components/SiteNavigator";
import H1 from "newApp/kit/components/H1";
import React from "react";

const SitePage: React.FC = () => {
  return (
    <MainLayout>
      <H1>Сайт</H1>
      <SiteNavigator />
    </MainLayout>
  );
};

export default observer(SitePage);
