import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import TextField from "newApp/kit/components/TextField";
import React from "react";
import IconClose from "../icons/IconClose";
import { CategoryTagsStore } from "./CategoryTagsStore";
import "./styles.scss";

export interface ICategoryTagsProps extends CategoryTagsStore {}

const CategoryTags: React.FC<ICategoryTagsProps> = (props) => {
  return (
    <div className="category-tags">
      <form
        className="category-tags__new-tag"
        onSubmit={(e) => {
          e.preventDefault();
          props.createTag();
        }}
      >
        <TextField
          placeholder="Название нового тега"
          {...props.form.adapters.newTag}
        />
        <Button onClick={() => props.createTag()}>Добавить</Button>
      </form>
      <div className="category-tags__list">
        {props.tags.map((tag, idx) => (
          <div className="category-tags__list-item" key={idx}>
            <div>{tag}</div>
            <div
              className="category-tags__list-item-delete"
              onClick={() => props.deleteTag(idx)}
            >
              <IconClose />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(CategoryTags);
