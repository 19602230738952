import { observer } from "mobx-react";
import Table from "newApp/kit/components/Table";
import React from "react";
import "./styles.scss";

export interface IFreeAnswersListProps {
  freeAnswers: string[];
}

const FreeAnswersList: React.FC<IFreeAnswersListProps> = (props) => {
  return (
    <div className="free-answers-list">
      {!props?.freeAnswers?.length && <div>Ответов пока нет</div>}
      <Table content={props.freeAnswers.map((i) => [String(i)])} />
    </div>
  );
};

export default observer(FreeAnswersList);
