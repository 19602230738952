import React, { useEffect } from "react";
import { observer } from "mobx-react";

import H1 from "newApp/kit/components/H1";
import H3 from "newApp/kit/components/H3";
import Table from "newApp/kit/components/Table";
import MainLayout from "newApp/components/layouts/MainLayout";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";

import shopAnalyticsStore from "newApp/stores/pages/shop/analytics";
import ShopNavigator from "newApp/components/ShopNavigator";
import "./styles.scss";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import Button from "newApp/kit/components/Button";
import AnalyticsFilter from "./AnalyticsFilter";

const AnalyticsPage: React.FC = () => {
  useEffect(() => {
    shopAnalyticsStore.getShopAnalytics();

    return () => {
      shopAnalyticsStore.filter.reset();
    };
  }, []);

  return (
    <MainLayout>
      <div className="shop-analytics">
        <H1>Аналитика</H1>
        <ShopNavigator />
        <ButtonsContainer>
          <AnalyticsFilter
            filter={shopAnalyticsStore.filter}
            getList={shopAnalyticsStore.getShopFilteredAnalytics}
          />

          <Button
            onClick={shopAnalyticsStore.downloadFile}
            variant="secondary"
            size="small"
          >
            Скачать реестр приобретенных товаров XLS
          </Button>
        </ButtonsContainer>
        <H3>Статистика по приобретенным товарам</H3>
        <p className="shop-analytics__count">
          Всего товаров - <span>{shopAnalyticsStore.products.length}</span>
        </p>

        {shopAnalyticsStore.isLoading ? (
          <LoaderSpinner />
        ) : (
          <Table
            className="analytic"
            headers={["№", "Наименование товара", "Количество покупок"]}
            content={shopAnalyticsStore.productInfo}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default observer(AnalyticsPage);
