import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";

export class NewsItemPageStore {
  isLoadings = {
    getNewsItem: false,
    publish: false,
  };
  id: string = "";

  info = {
    title: null,
    date: null,
    isBanner: null,
    isMain: null,
    isActive: null,
    content: null,
    state: null,
  };

  mainImages: IFilesPreviewFile[] = [];
  images: IFilesPreviewFile[] = [];
  files: IFilesPreviewFile[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getNewsItem();
  };

  reset = () => {
    this.resetInfo();
    this.resetFiles();
  };

  resetInfo = () => {
    Object.keys(this.info).forEach((key) => {
      this.info = { ...this.info, [key]: null };
    });
  };

  resetFiles = () => {
    this.mainImages = [];
    this.images = [];
    this.files = [];
  };

  getNewsItem = () => {
    this.isLoadings.getNewsItem = true;
    this.resetInfo();
    this.resetFiles();

    return API.administration.News.newsIdGet(this.id)
      .then(({ data }) => {
        runInAction(() => {
          Object.entries(data).forEach(([key, value]) => {
            this.info[key] = value;
          });
          this.info.date = `${new Date(
            data.date
          ).toLocaleDateString()} ${new Date(data.date).toLocaleTimeString()}`;
          this.info.isBanner = data.type === 1;
          this.info.isMain = data.type === 2;
          this.info.isActive = !!data.state;

          if (data.croppedImageId) {
            this.mainImages.push({
              type: "Image",
              reference:
                URL_API_ADMINISTRATION + "/news/" + this.id + "/croppedimage",
            });
          }
          if (data.previewImageId) {
            this.mainImages.push({
              type: "Image",
              reference:
                URL_API_ADMINISTRATION + "/news/" + this.id + "/previewimage",
            });
          }
          if (data?.photos?.length) {
            this.images = data.photos.map((i) => ({
              type: "Image",
              reference: URL_API_ADMINISTRATION + "/news/photo/" + i.id,
            }));
          }
          if (data?.files?.length) {
            this.files = data.files.map((i) => ({
              reference: URL_API_ADMINISTRATION + "/news/file/" + i.id,
            }));
          }
        });
      })
      .catch(this.reset)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getNewsItem = false;
        });
      });
  };

  onClickPublish = () => {
    this.isLoadings.publish = true;
    return API.administration.News.newsIdPost(this.id, {
      state: this.info.state === 0 ? 1 : 0,
    })
      .then(this.getNewsItem)
      .catch(this.reset)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.publish = false;
        });
      });
  };
}
