import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { OrganizationFormStore } from "newApp/stores/common/OrganizationFormStore";

export class OrganizationEditPageStore {
  isLoadings = {
    getOrganization: false,
    saveOrganization: false,
  };
  id = "";

  organizationForm = new OrganizationFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.organizationForm.init();
    this.getOrganization();
  };

  reset = () => {
    this.organizationForm.reset();
  };

  getOrganization = () => {
    this.isLoadings.getOrganization = true;
    return API.admin.Organization.apiOrganizationsIdGet(this.id)
      .then(({ data }) => {
        runInAction(() => {
          this.organizationForm.form.changeValueByKey(
            data["parentId"],
            "parentId"
          );
          this.organizationForm.form.changeValueByKey(data["title"], "title");
          this.organizationForm.form.changeValueByKey(
            data["titleShort"],
            "titleShort"
          );
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getOrganization = false;
        });
      });
  };

  onSave = async () => {
    await this.organizationForm.form.validate();
    if (this.organizationForm.form.isValid) {
      return await this.saveOrganization();
    }
    return false;
  };

  saveOrganization = () => {
    this.isLoadings.saveOrganization = true;
    return API.admin.Organization.apiOrganizationsSetIdPost(this.id, {
      Active: true,
      ParentId: this.organizationForm.form.values.parentId,
      Title: this.organizationForm.form.values.title,
      TitleShort: this.organizationForm.form.values.titleShort,
    })
      .then(() => true)
      .catch((e) => {
        console.error(e);
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveOrganization = false;
        });
      });
  };
}
