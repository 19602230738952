import React from "react";

const IconExpand: React.FC = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99793 0.375548C9.99732 0.369376 9.99617 0.363399 9.99529 0.357325C9.99424 0.349962 9.99338 0.342579 9.99193 0.335255C9.99055 0.328263 9.98863 0.321485 9.9869 0.31463C9.98531 0.308321 9.98393 0.301974 9.98203 0.295723C9.98 0.288985 9.97746 0.282481 9.9751 0.275899C9.97285 0.269669 9.9708 0.26338 9.96826 0.257227C9.96572 0.251075 9.96271 0.245196 9.9599 0.2392C9.95691 0.232872 9.9541 0.226485 9.95078 0.220274C9.9477 0.214532 9.9442 0.209063 9.94086 0.203477C9.93723 0.197403 9.93379 0.191251 9.92982 0.185313C9.9257 0.179141 9.92109 0.17334 9.91666 0.167403C9.91295 0.162461 9.90951 0.157383 9.90557 0.152559C9.89691 0.142032 9.88781 0.131895 9.87818 0.122266C9.87811 0.122188 9.87805 0.12211 9.87797 0.122032C9.87789 0.121953 9.87781 0.121914 9.87775 0.121836C9.86811 0.112207 9.85799 0.103086 9.84744 0.0944339C9.84262 0.090469 9.83754 0.0870315 9.83258 0.0833205C9.82666 0.0788869 9.82086 0.0742971 9.81469 0.070176C9.80875 0.0662111 9.8026 0.0627736 9.7965 0.0591408C9.79092 0.0558009 9.78547 0.0523048 9.77973 0.0492189C9.77352 0.0458986 9.76715 0.0430861 9.7608 0.0400978C9.7548 0.0372657 9.74891 0.0342774 9.74277 0.0317188C9.73662 0.0291798 9.73033 0.0271095 9.72408 0.0248829C9.7175 0.0225196 9.71102 0.0200001 9.7043 0.0179688C9.69805 0.0160743 9.69168 0.0146875 9.68537 0.0131055C9.67852 0.0113672 9.67174 0.00945315 9.66477 0.00806643C9.65744 0.00662111 9.65006 0.0057422 9.6427 0.00468751C9.63662 0.00382814 9.63064 0.00265626 9.62447 0.00205079C9.61078 0.000722658 9.59707 0 9.58334 0H6.66666C6.43654 0 6.25 0.186544 6.25 0.416661C6.25 0.646779 6.43654 0.833323 6.66666 0.833323H8.5774L5.53871 3.87204C5.37599 4.03476 5.37599 4.29859 5.53871 4.4613C5.70142 4.62402 5.96525 4.62402 6.12797 4.4613L9.16666 1.4226V3.33335C9.16666 3.56347 9.3532 3.75001 9.58332 3.75001C9.81344 3.75001 10 3.56347 10 3.33335V0.4167C10 0.40295 9.99928 0.38922 9.99793 0.375548Z"
        fill="#4C4C4C"
      />
      <path
        d="M3.87203 5.53874L0.83334 8.57744V6.66669C0.83334 6.43657 0.646797 6.25003 0.41668 6.25003C0.186543 6.25003 0 6.43657 0 6.66669V9.58336C0 9.59709 0.000722657 9.6108 0.00207031 9.62447C0.00267578 9.63062 0.00382813 9.6366 0.00470703 9.64269C0.00576172 9.65006 0.0066211 9.65746 0.00808594 9.66476C0.00947266 9.67176 0.0113867 9.67851 0.013125 9.68537C0.014707 9.6917 0.0160938 9.69805 0.0179883 9.7043C0.0200195 9.71101 0.0225391 9.71752 0.0249024 9.72408C0.0271484 9.73033 0.0291992 9.73662 0.0317383 9.74277C0.0342774 9.74892 0.0372852 9.7548 0.0401172 9.7608C0.0431055 9.76713 0.045918 9.77351 0.0492383 9.77973C0.0523242 9.78547 0.0558203 9.79092 0.0591602 9.7965C0.062793 9.80258 0.0662305 9.80875 0.0701953 9.81469C0.0743164 9.82086 0.0789063 9.82664 0.0833399 9.83258C0.0870508 9.83754 0.0904883 9.84262 0.0944532 9.84744C0.103106 9.85799 0.112227 9.86812 0.121856 9.87775C0.121934 9.87783 0.121973 9.87791 0.122051 9.87797C0.122129 9.87805 0.122207 9.8781 0.122285 9.87818C0.131914 9.88779 0.142051 9.89691 0.152578 9.90557C0.157402 9.90953 0.162481 9.91297 0.167422 9.91666C0.173359 9.92109 0.17916 9.9257 0.185332 9.92982C0.19125 9.93379 0.197402 9.93723 0.203496 9.94086C0.209082 9.9442 0.214531 9.9477 0.220293 9.95078C0.226504 9.9541 0.232891 9.95691 0.239219 9.9599C0.245215 9.96273 0.251113 9.96572 0.257246 9.96826C0.263399 9.9708 0.269668 9.97285 0.275918 9.9751C0.2825 9.97746 0.289004 9.97998 0.295742 9.98203C0.301992 9.98392 0.30834 9.98531 0.314649 9.98689C0.321504 9.98863 0.328281 9.99055 0.335274 9.99193C0.342598 9.99338 0.349981 9.99424 0.357344 9.99529C0.363418 9.99615 0.369414 9.99732 0.375567 9.99793C0.389238 9.99928 0.402949 10 0.41666 10H3.33334C3.56346 10 3.75 9.81346 3.75 9.58334C3.75 9.35322 3.56346 9.16668 3.33334 9.16668H1.4226L4.46131 6.12796C4.62403 5.96524 4.62403 5.70141 4.46131 5.5387C4.2986 5.37598 4.03477 5.37602 3.87203 5.53874Z"
        fill="#4C4C4C"
      />
    </svg>
  );
};

export default IconExpand;
