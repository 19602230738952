/* tslint:disable */
/* eslint-disable */
/**
 * Authorization API
 * Модуль авторизации
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface LoginForm
 */
export interface LoginForm {
    /**
     * 
     * @type {string}
     * @memberof LoginForm
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginForm
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginForm
     */
    'device'?: string | null;
}
/**
 * 
 * @export
 * @interface SetOrganizationForm
 */
export interface SetOrganizationForm {
    /**
     * 
     * @type {string}
     * @memberof SetOrganizationForm
     */
    'organizationId'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginForm} [loginForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAisaeLoginPost: async (loginForm?: LoginForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/aisae/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [refreshToken] 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAisaeRefreshPost: async (refreshToken?: string, organizationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/aisae/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginForm} [loginForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAisaeLoginPost(loginForm?: LoginForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAisaeLoginPost(loginForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [refreshToken] 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAisaeRefreshPost(refreshToken?: string, organizationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAisaeRefreshPost(refreshToken, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginForm} [loginForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAisaeLoginPost(loginForm?: LoginForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiAisaeLoginPost(loginForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [refreshToken] 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAisaeRefreshPost(refreshToken?: string, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAisaeRefreshPost(refreshToken, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * 
     * @param {LoginForm} [loginForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAisaeLoginPost(loginForm?: LoginForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [refreshToken] 
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    apiAisaeRefreshPost(refreshToken?: string, organizationId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * 
     * @param {LoginForm} [loginForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAisaeLoginPost(loginForm?: LoginForm, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAisaeLoginPost(loginForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [refreshToken] 
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAisaeRefreshPost(refreshToken?: string, organizationId?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAisaeRefreshPost(refreshToken, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModuleApi - axios parameter creator
 * @export
 */
export const ModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModulesGet: async (organizationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetOrganizationForm} [setOrganizationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModulesSetOrganizationPost: async (setOrganizationForm?: SetOrganizationForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/modules/setOrganization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrganizationForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleApi - functional programming interface
 * @export
 */
export const ModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModulesGet(organizationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModulesGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetOrganizationForm} [setOrganizationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModulesSetOrganizationPost(setOrganizationForm?: SetOrganizationForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModulesSetOrganizationPost(setOrganizationForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModuleApi - factory interface
 * @export
 */
export const ModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModulesGet(organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiModulesGet(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetOrganizationForm} [setOrganizationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModulesSetOrganizationPost(setOrganizationForm?: SetOrganizationForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiModulesSetOrganizationPost(setOrganizationForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleApi - interface
 * @export
 * @interface ModuleApi
 */
export interface ModuleApiInterface {
    /**
     * 
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApiInterface
     */
    apiModulesGet(organizationId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {SetOrganizationForm} [setOrganizationForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApiInterface
     */
    apiModulesSetOrganizationPost(setOrganizationForm?: SetOrganizationForm, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ModuleApi - object-oriented interface
 * @export
 * @class ModuleApi
 * @extends {BaseAPI}
 */
export class ModuleApi extends BaseAPI implements ModuleApiInterface {
    /**
     * 
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModulesGet(organizationId?: string, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).apiModulesGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetOrganizationForm} [setOrganizationForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiModulesSetOrganizationPost(setOrganizationForm?: SetOrganizationForm, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).apiModulesSetOrganizationPost(setOrganizationForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - interface
 * @export
 * @interface OrganizationApi
 */
export interface OrganizationApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    apiOrganizationsGet(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI implements OrganizationApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationsGet(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


