import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";

export class TechSupportPageStore {
  isLoadings = { getQuestions: false };

  form = {
    isAnswered: false,
  };

  pagination = new PaginationStore(undefined, () => this.getQuestions());

  questions: IQuestion[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (isAnswered: boolean) => {
    this.form.isAnswered = isAnswered;
    this.getQuestions();
  };

  reset = () => {
    this.pagination.reset();
    this.form.isAnswered = false;
    this.questions = [];
  };

  getQuestions = () => {
    this.isLoadings.getQuestions = true;
    const pageSize = this.pagination.pageSize;
    const pageNumber = this.pagination.currentPage;

    return API.admin.Question.apiQuestionsGet(
      pageSize,
      pageNumber,
      this.form.isAnswered
    )
      .then(({ data }) => {
        runInAction(() => {
          this.questions = data["questions"].map((question) => ({
            answerText: question["answerText"] || null,
            answeredDate: question["answeredDate"]
              ? new Date(question["answeredDate"]).toLocaleDateString()
              : null,
            answeredUserId: question["answeredUserId"] || null,
            createdDate: question["createdDate"]
              ? new Date(question["createdDate"]).toLocaleDateString()
              : null,
            id: question["id"] || null,
            isAnswered: question["isAnswered"] || null,
            keycloakUserId: question["keycloakUserId"] || null,
            questionText: question["questionText"] || null,
          }));

          const pageCount = data["pageCount"];
          if (pageCount) this.pagination.pages = Number(pageCount);
        });
      })
      .catch(() => {
        runInAction(() => {});
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getQuestions = false;
        });
      });
  };
}

interface IQuestion {
  answerText: null | string;
  answeredDate: null | string;
  answeredUserId: null | string;
  createdDate: null | string;
  id: null | string;
  isAnswered: null | boolean;
  keycloakUserId: null | string;
  questionText: null | string;
}
