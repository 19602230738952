import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { CategoryFormStore } from "newApp/stores/common/CategoryFormStore";

export class CategoryCreatePageStore {
  isLoadings = { createCategory: false };

  categoryForm = new CategoryFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.categoryForm.init();
  };

  reset = () => {
    this.categoryForm.reset();
  };

  onCreate = async () => {
    await this.categoryForm.form.validate();
    if (this.categoryForm.form.isValid) {
      return this.createCategory();
    }
    return false;
  };

  createCategory = () => {
    this.isLoadings.createCategory = true;

    return API.admin.Category.apiObjectsCreatePost({
      Name: this.categoryForm.form.values.name,
      DaysLimit: this.categoryForm.form.values.daysLimit,
      OrganizationId: this.categoryForm.form.values.organizationId,
      ParentId:
        this.categoryForm.form.values.parentId ??
        "00000000-0000-0000-0000-000000000000",
      State: this.categoryForm.form.values.isActive ? 1 : 0,
      Tags: this.categoryForm.categoryTags.tags,
    })
      .then(() => true)
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createCategory = false;
        });
      });
  };
}
