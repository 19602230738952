import { observer } from "mobx-react";
import FlexColumn from "newApp/kit/components/FlexColumn";
import Select from "newApp/kit/components/Select";
import TextField from "newApp/kit/components/TextField";
import { OrganizationFormStore } from "newApp/stores/common/OrganizationFormStore";
import React from "react";

export interface IOrganizationFormProps extends OrganizationFormStore {}

const OrganizationForm: React.FC<IOrganizationFormProps> = (props) => {
  return (
    <FlexColumn>
      <TextField placeholder="Наименование" {...props.form.adapters.title} />
      <TextField
        placeholder="Краткое наименование"
        {...props.form.adapters.titleShort}
      />
      <Select
        placeholder="Родительская организация"
        {...props.form.adapters.parentId}
        options={props.options.organizations}
      />
    </FlexColumn>
  );
};

export default observer(OrganizationForm);
