import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

export class VoteItemResultItemStore implements IVoteItemResultsQuestion {
  fileId: null | string;
  hasOtherOption: false;
  id: null | string;
  isRequired: boolean;
  text: null | string;
  type: null | string;
  votersNumber: number | null;
  votersPercentage: number | null;

  answers: IVoteItemResultsQustionAnswer[];

  freeAnswers: string[] = [];

  isLoadings = { getFreeAnswers: false };

  constructor(form: IVoteItemResultsQuestion) {
    Object.entries(form).forEach(([key, value]) => {
      this[key] = value;
    });
    makeAutoObservable(this);
  }

  resetFreeAnswers = () => {
    this.freeAnswers = [];
  };

  getFreeAnswers = () => {
    this.resetFreeAnswers();
    this.isLoadings.getFreeAnswers = true;
    return API.admin.Analytics.apiAnalyticsFreeanswersGet(String(this.id))
      .then(({ data }) => {
        runInAction(() => {
          this.freeAnswers = data as any;
        });
      })
      .catch(this.resetFreeAnswers)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getFreeAnswers = false;
        });
      });
  };
}

export interface IVoteItemResultsQuestion {
  answers: IVoteItemResultsQustionAnswer[];
  fileId: null | string;
  hasOtherOption: false;
  id: null | string;
  isRequired: boolean;
  text: null | string;
  type: null | string;
  votersNumber: number | null;
  votersPercentage: number | null;
}

export interface IVoteItemResultsQustionAnswer {
  fileId: null | string;
  id: string;
  isOtherOption: boolean;
  text: null | string;
  votersNumber: number;
  votersPercentage: number;
  maleVotersNumber: number | null;
  femaleVotersNumber: number | null;
}
