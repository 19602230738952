import { observer } from "mobx-react";
import AnalyticsNavigator from "newApp/components/AnalyticsNavigator";
import MainLayout from "newApp/components/layouts/MainLayout";
import H1 from "newApp/kit/components/H1";
import React from "react";

const AnalyticsPage: React.FC = () => {
  return (
    <MainLayout>
      <H1>Аналитика</H1>
      <AnalyticsNavigator />
    </MainLayout>
  );
};

export default observer(AnalyticsPage);
