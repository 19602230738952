import classNames from "classnames";
import { FC, useCallback } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import IconClip from "../IconClip";
import { getFileSizeMB } from "../../utils/getFileSizeMB";
import { IDropzoneProps } from "./index.types";
import "./styles.scss";

const Dropzone: FC<IDropzoneProps> = ({
  onAddFiles,
  name,
  maxFiles = 0,
  multiple = true,
  maxFileSize,
  maxFileSizeAll,
  acceptFileTypes,
  acceptFileTypesText,
  customHeaderContent,
  customBodyContent,
}) => {
  const onDrop = useCallback<Required<DropzoneOptions>["onDrop"]>(
    (acceptedFiles, rejectedFiles) => {
      onAddFiles(acceptedFiles, name, rejectedFiles);
    },
    [onAddFiles, name]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      maxFiles,
      multiple,
      maxSize: maxFileSize,
      accept: acceptFileTypes?.join(", "),
    });

  const maxFileSizeAllText = maxFileSizeAll
    ? ` (суммарно не больше ${getFileSizeMB(maxFileSizeAll)} Мб)`
    : "";

  const fileTypesText =
    acceptFileTypesText &&
    `Допустимые форматы для загрузки файлов:
  ${acceptFileTypesText}.`;

  const fileSizeText =
    maxFileSize &&
    ` Размер файла не должен превышать ${getFileSizeMB(maxFileSize)} Мб.`;

  const maxFilesText =
    maxFiles > 0 && ` Максимальное колличество файлов: ${maxFiles}.`;

  return (
    <div
      className={classNames(
        "dropzone",
        isDragActive && "dropzone--drag-active",
        isDragReject && "dropzone--drag-rejected"
      )}
      data-testid='Dropzone'
    >
      <div className='dropzone__header'>
        {customHeaderContent !== undefined ? (
          customHeaderContent
        ) : (
          <p className='dropzone__header-text'>
            {fileTypesText}
            {fileSizeText}
            {maxFilesText}
          </p>
        )}
      </div>
      <div {...getRootProps()} className={"dropzone__body"} role='button'>
        <input {...getInputProps()} name={name} />
        {customBodyContent !== undefined ? (
          customBodyContent
        ) : (
          <div className='dropzone__body-content'>
            {<IconClip className='dropzone__icon' />}
            <p className='dropzone__body-text'>
              Перетащите файлы сюда
              {!isDragActive && (
                <>
                  {" "}
                  или{" "}
                  <span className='dropzone__body-text--blue'>
                    загрузите вручную
                  </span>
                </>
              )}
              {maxFileSizeAllText}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
