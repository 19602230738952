import {
  URL_ADMINISTRATION_CATEGORIES,
  URL_ADMINISTRATION_ORGANIZATIONS,
  URL_ADMINISTRATION_STEPS,
  URL_ADMINISTRATION_MOBILE,
} from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";
import "./styles.scss";

const AdministrationNavigator: React.FC = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const links: { paths: string[]; text: string }[] = [
    { paths: [URL_ADMINISTRATION_ORGANIZATIONS], text: "Организации" },
    { paths: [URL_ADMINISTRATION_CATEGORIES], text: "Классификатор" },
    { paths: [URL_ADMINISTRATION_STEPS], text: "Конструктор шагов" },
    { paths: [URL_ADMINISTRATION_MOBILE], text: "Мобильное приложение" },
  ];

  return (
    <div className="votings-mode-switcher">
      {links.map((link, indx) => (
        <div
          className={[
            "votings-mode-switcher__item",
            link.paths.includes(pathname)
              ? "votings-mode-switcher__item--active"
              : "",
          ].join(" ")}
          key={indx}
          onClick={() => push(link.paths[0])}
        >
          {link.text}
        </div>
      ))}
    </div>
  );
};

export default AdministrationNavigator;
