import "./styles.scss";

import React from "react";
import { useHistory, useLocation } from "react-router";

export const VOTE_ITEM_MODE = "mode";
export const VOTE_ITEM_MODE_INFO = "info";
export const VOTE_ITEM_MODE_RESULTS = "results";

const VoteItemModeSwitcher: React.FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const mode = new URLSearchParams(search).get(VOTE_ITEM_MODE);

  const setQuery = (mode: string) => {
    push({ search: `${VOTE_ITEM_MODE}=${mode}` });
  };

  const links: { onClick: () => void; text: string; isActive: boolean }[] = [
    {
      text: "Описание",
      onClick: () => setQuery(VOTE_ITEM_MODE_INFO),
      isActive: !mode || mode === VOTE_ITEM_MODE_INFO,
    },
    {
      text: "Результаты",
      onClick: () => setQuery(VOTE_ITEM_MODE_RESULTS),
      isActive: mode === VOTE_ITEM_MODE_RESULTS,
    },
  ];

  return (
    <div className="vote-item-mode-switcher">
      {links.map((i, indx) => (
        <div
          className={[
            "vote-item-mode-switcher__item",
            i.isActive ? "vote-item-mode-switcher__item--active" : "",
          ].join(" ")}
          key={indx}
          onClick={i.onClick}
        >
          {i.text}
        </div>
      ))}
    </div>
  );
};

export default VoteItemModeSwitcher;
