import React from "react";
import "./styles.scss";

interface IProps {
  children: React.ReactNode;
}

const H3: React.FC<IProps> = ({ children }) => {
  return <h3 className="h3">{children}</h3>;
};

export default H3;
