import { makeAutoObservable } from "mobx";
import { FormStore } from "newApp/kit/stores/FormStore";

import { formValidationSchema } from "./formValidatationSchema";

export class RubricFormStore {
  form = new FormStore(
    {
      title: null,
      isActive: null,
    },
    formValidationSchema
  );

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {
    this.form.reset();
  };

  validate = () => {
    this.form.validate();
  };
}
