import React from "react";

export const Loader: React.FC = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="92px"
    height="100px"
    viewBox="0 0 92 100"
    enableBackground="new 0 0 92 100"
  >
    <path
      fill="#00aadd"
      className="throbber-path"
      d="M81.765,82.649L56.607,97.19c-6.479,3.744-14.462,3.746-20.942,0.01L10.493,82.682
          C4.012,78.944,0.017,72.032,0.014,64.549L0,35.493c-0.003-7.482,3.985-14.398,10.463-18.141L35.621,2.811
          c6.478-3.744,14.46-3.748,20.943-0.011l25.171,14.519c6.481,3.738,10.476,10.648,10.479,18.131l0.014,29.059
          C92.231,71.989,88.244,78.905,81.765,82.649"
    ></path>
  </svg>
);

export default Loader;
