import "leaflet/dist/leaflet.css";
import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import FlexRow from "newApp/kit/components/FlexRow";
import { compareCoords } from "newApp/utils/compareCoords";
import React, { useState } from "react";
import { CircleMarker } from "react-leaflet";
import Map from "..";

interface ICoords {
  latitude: number;
  longitude: number;
}

interface IVoteMapProps {
  coordinates?: {
    latitude: number;
    longitude: number;
  }[];
  onChangeCoords: any;
  resetCoordinates: any;
  errors?: React.ReactNode;
  addCoords?: (coords: ICoords) => void;
  removeCoords?: (coords: ICoords) => void;
}

const VoteMap: React.FC<IVoteMapProps> = (props) => {
  const [isCreateMode, setIsCreateMode] = useState(false);

  return (
    <div className="vote__map-container">
      <FlexColumn>
        <Map
          polygons={props.coordinates ? [props.coordinates] : []}
          onClick={(coords) => {
            const isExist = props.coordinates?.some((i) =>
              compareCoords(i, coords)
            );
            if (isCreateMode && isExist) {
              props.removeCoords(coords);
            } else {
              props.addCoords(coords);
            }
          }}
        >
          {isCreateMode &&
            props?.coordinates?.map((c, idx) => (
              <CircleMarker center={[c.latitude, c.longitude]} key={idx} />
            ))}
        </Map>

        {props.errors && (
          <p className="warning-text margin-bottom-12">{props.errors}</p>
        )}

        <FlexRow>
          <Button
            className="outlined"
            onClick={() => {
              setIsCreateMode(!isCreateMode);
            }}
          >
            {isCreateMode ? "Завершить создание" : "Создать область"}
          </Button>
          <Button
            variant="secondary"
            className="outlined"
            onClick={() => props.resetCoordinates()}
          >
            Удалить область
          </Button>
        </FlexRow>
      </FlexColumn>
    </div>
  );
};

export default observer(VoteMap);
