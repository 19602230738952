import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FormStore } from "newApp/kit/stores/FormStore";
import {
  IModerationListFilterForm,
  IModerationListFilterOptions,
} from "./index.types";

export class DeclarationListFilterStore {
  form = new FormStore<IModerationListFilterForm>({
    number: null,
    violationStatus: null,
    isPublished: null,
    district: null,
    categoryId: null,
    organizationId: null,
    orderByDescending: null,
  });

  options: IModerationListFilterOptions = {
    isPublished: [
      {
        text: "Неважно",
        value: null,
      },
      {
        text: "Да",
        value: true,
      },
      {
        text: "Нет",
        value: false,
      },
    ],
    districts: [],
    organization: [],
    category: [],
    orderByDescending: [
      {
        text: "От новых к старым (по умолчанию)",
        value: null,
      },
      {
        text: "От старых к новым",
        value: false,
      },
    ],
  };

  isShowModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getOptions();
    this.getCategoryOptions();
  };

  reset = () => {
    this.form.reset();
  };

  resetOptions = () => {
    Object.keys(this.options).forEach((key) => {
      this.options = { ...this.options, [key]: [] };
    });
  };

  openModal = () => {
    this.isShowModal = true;
  };

  closeModal = () => {
    this.isShowModal = false;
  };

  getOptions = () => {
    return API.admin.Violations.apiViolationsFilterdataGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.districts = [
            { text: "Неважно", value: null },
            ...(data["districts"]?.map((i) => ({
              text: String(i),
              value: String(i),
            })) || []),
          ];
          this.options.organization = [
            { text: "Неважно", value: null },
            ...data["organizations"]?.map((i) => ({
              text: String(i.title),
              value: String(i.id),
            })),
          ];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.options.districts = [];
          this.options.organization = [];
        });
      });
  };

  getCategoryOptions = () => {
    return API.admin.ViolationTemplates.apiViolationTemplatesChildrenGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.category = [
            { text: "Неважно", value: null },
            ...(data.map((i) => ({
              text: String(i["name"]),
              value: String(i["id"]),
            })) || []),
          ];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.options.category = [];
        });
      });
  };

  get activeFilterCount() {
    return Object.values(this.form.values).filter((i) => i !== null).length;
  }
}
