import React from "react";
import { observer } from "mobx-react";

import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";

import "./styles.scss";

import { ShopSettingsFormStore } from "newApp/stores/common/ShopSettingsFormStore";

export interface IShopSettingsProps extends ShopSettingsFormStore {}

const ShopSettings: React.FC<ShopSettingsFormStore> = (props) => {
  return (
    <div className="shop-settings-component">
      <div className="shop-settings-component__controllers">
        <Checkbox
          value={props.form.values.isVisibleInMainMenu}
          onChange={props.onClickVisibleInMainMenu}
        >
          Отображать "Магазин поощрений" в главном меню
        </Checkbox>
        <Checkbox
          value={props.form.values.isVisibleInProfile}
          onChange={props.onClickVisibleInProfile}
        >
          Отображать "Магазин поощрений" в Личном кабинете
        </Checkbox>
        <Checkbox
          value={props.form.values.isVisibleInMobile}
          onChange={props.onClickVisibleInMobile}
        >
          Отображать "Магазин поощрений" в Мобильном приложении
        </Checkbox>
      </div>
      <Divider color="grey" />
    </div>
  );
};

export default observer(ShopSettings);
