import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";

export class CategoriesPageStore {
  isLoadings = {
    getCategories: false,
    setActive: false,
    setDelete: false,
  };

  categoriesList: ICategory[] = [];

  pagination = new PaginationStore({}, () => this.getCategories());

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getCategories();
  };

  getCategories = () => {
    this.isLoadings.getCategories = true;
    const skip = (this.pagination.currentPage - 1) * this.pagination.pageSize;
    const take = this.pagination.pageSize;
    // this.reset();

    API.shop.Categories.apiCategoriesGet(skip, take)
      .then(({ data }) => {
        runInAction(() => {
          this.categoriesList = data["items"];

          this.pagination.pages = Math.ceil(
            data["count"] / this.pagination.pageSize
          );
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getCategories = false;
          window.scrollTo({ behavior: "smooth", top: 0 });
        });
      });
  };

  activateCategory = (id: string) => {
    this.isLoadings.setActive = true;

    return API.shop.Categories.apiCategoriesActivateIdPost(id)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.setActive = false;
        });
      });
  };

  deactivateCategory = (id: string) => {
    this.isLoadings.setActive = true;

    return API.shop.Categories.apiCategoriesDeactivateIdPost(id)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.setActive = false;
        });
      });
  };

  toggleIsActive = (isActive: boolean, id: string) => {
    return isActive
      ? this.deactivateCategory(id).then(() => this.getCategories())
      : this.activateCategory(id).then(() => this.getCategories());
  };

  onClickDelete = (id: string) => {
    this.isLoadings.setDelete = true;

    API.shop.Categories.apiCategoriesDeleteIdPost(id)
      .then(() => {
        runInAction(() => {
          this.getCategories();
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.setDelete = false;
        });
      });
  };

  reset = () => {
    this.categoriesList = [];
    this.pagination.reset();
  };

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }
}

export interface ICategory {
  id: string;
  title: string;
  isActive: boolean;
}
