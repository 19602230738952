import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import ListItemCard from "newApp/components/ListItemCard";
import SiteNavigator from "newApp/components/SiteNavigator";
import TechSupportNavigator from "newApp/components/TechSupportNavigator";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Pagination from "newApp/kit/components/Pagination";
import { URL_SITE_TECHSUPPORT_NEW } from "newApp/routing/urls";
import { TechSupportPageStore } from "newApp/stores/pages/site/techsupport/TechSupportPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new TechSupportPageStore();

const TechSupportNewPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init(false);
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Вопросы личного кабинета - Новые</H1>
      <FlexColumn>
        <SiteNavigator />
        <TechSupportNavigator />
        {store.isLoadings.getQuestions ? (
          <LoaderSpinner />
        ) : (
          <FlexColumn gap={10}>
            {store.questions.map((question) => {
              const goOnAnswer = () =>
                push(URL_SITE_TECHSUPPORT_NEW + `/${question.id}`);
              return (
                <ListItemCard
                  isActive={question.isAnswered}
                  title={question.questionText}
                  onClickTitle={goOnAnswer}
                  key={question.id}
                  descriptions={[
                    {
                      title: "Дата создания",
                      value: question.createdDate,
                    },
                  ]}
                  headerButtons={[
                    {
                      text: "Ответить",
                      onClick: goOnAnswer,
                    },
                  ]}
                />
              );
            })}
          </FlexColumn>
        )}

        <Pagination {...store.pagination} />
      </FlexColumn>
    </MainLayout>
  );
};
export default observer(TechSupportNewPage);
