import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import OrganizationForm from "newApp/components/OrganizationForm";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_ADMINISTRATION_ORGANIZATIONS } from "newApp/routing/urls";
import { OrganizationEditPageStore } from "newApp/stores/pages/administrations/organizations/OrganizationEditPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new OrganizationEditPageStore();

const OrganizationsEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  useEffect(() => {
    if (id) store.init(id);
    return () => store.reset();
  }, [id]);

  const onClickSave = async () => {
    const result = await store.onSave();
    if (result) push(URL_ADMINISTRATION_ORGANIZATIONS);
  };

  const goOnList = () => {
    push(URL_ADMINISTRATION_ORGANIZATIONS);
  };

  return (
    <MainLayout isLoading={store.isLoadings.getOrganization}>
      <H1>Редактирование организации</H1>
      <FlexColumn>
        <ButtonBack url={URL_ADMINISTRATION_ORGANIZATIONS}>
          Назад к списку
        </ButtonBack>
        <OrganizationForm {...store.organizationForm} />
        <ButtonsContainer>
          <Button
            onClick={onClickSave}
            disabled={store.isLoadings.saveOrganization}
          >
            Сохранить изменения
          </Button>
          <Button onClick={goOnList} variant="secondary">
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(OrganizationsEditPage);
