import { makeAutoObservable } from "mobx";
import { RolesInfoStore } from "./RolesInfoStore";

export class RolesItemPageStore {
  info = new RolesInfoStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.info.init(id);
  };

  reset = () => {
    this.info.reset();
  };
}
