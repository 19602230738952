import { observer } from "mobx-react";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import React from "react";
import DashedButton from "../DashedButton";
import "./styles.scss";

export interface IListItemCardProps {
  title?: React.ReactNode;
  onClickTitle?: () => void;
  isActive?: boolean;
  headerButtons?: {
    text: React.ReactNode;
    color?: "blue" | "red";
    onClick?: () => void;
  }[];
  descriptions?: {
    title: React.ReactNode;
    value: React.ReactNode;
  }[];
  controls?: React.ReactNode[];
}

const ListItemCard: React.FC<IListItemCardProps> = (props) => {
  return (
    <div
      className={[
        "list-item-card",
        props.isActive ? "list-item-card--active" : "",
      ].join(" ")}
    >
      <FlexColumn>
        <div className="list-item-card__header">
          <div
            className={[
              "list-item-card__header-title",
              props.onClickTitle
                ? "list-item-card__header-title--clickable"
                : "",
            ].join(" ")}
            onClick={props.onClickTitle}
          >
            {props.title}
          </div>
          <div className="list-item-card__header-buttons">
            {props.headerButtons?.map((i, idx) => (
              <DashedButton key={idx} {...i}>
                {i.text}
              </DashedButton>
            ))}
          </div>
        </div>
        {props.descriptions?.map((description, idx) => (
          <div className="list-item-card__description" key={idx}>
            <div className="list-item-card__description-title">
              {description.title}
            </div>
            <div className="list-item-card__description-title">—</div>
            <div className="list-item-card__description-value">
              {description.value}
            </div>
          </div>
        ))}
        {!!props.controls?.length && <Divider />}
        {!!props.controls?.length && (
          <div className="list-item-card__controls">
            {props.controls?.map((control, idx) => (
              <React.Fragment key={idx}>{control}</React.Fragment>
            ))}
          </div>
        )}
      </FlexColumn>
    </div>
  );
};

export default observer(ListItemCard);
