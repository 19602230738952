import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import MainLayout from "../../../../../components/layouts/MainLayout";
import RoleEditCreateForm from "../../../../../components/RoleEditCreateForm";
import RoleEditModules from "../../../../../components/RoleEditModules";
import Button from "../../../../../kit/components/Button";
import ButtonsContainer from "../../../../../kit/components/ButtonsContainer";
import Divider from "../../../../../kit/components/Divider";
import FlexColumn from "../../../../../kit/components/FlexColumn";
import H1 from "../../../../../kit/components/H1";
import { URL_ADMISSION_ROLES } from "../../../../../routing/urls";
import { RoleItemEditPageStore } from "../../../../../stores/pages/admission/roles/RoleItemEditPage";

const store = new RoleItemEditPageStore();

const RoleItemEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  useEffect(() => {
    if (id) store.init(id);
    return () => store.reset();
  }, [id]);

  const onClickSave = () => {
    store.validate();
    if (store.isValid && id) {
      store.postEditedRole(id).then(() => {
        goOnRoleInfo();
      });
    }
  };

  const onClickCancel = () => {
    goOnRoleInfo();
  };

  const goOnRoleInfo = () => {
    push(URL_ADMISSION_ROLES + `/${id}`);
  };

  return (
    <MainLayout isLoading={store.isLoadings.getRole}>
      <H1>Редактирование роли</H1>
      <FlexColumn>
        <RoleEditCreateForm form={store.forms.form} />
        <RoleEditModules
          onChangeOperation={store.forms.modules.onChangeOperation}
          onChangeModule={store.forms.modules.onChangeModule}
          modules={store.forms.modules.modules}
        />
        <Divider />
        <ButtonsContainer justifyRight>
          <Button disabled={store.isLoadings.postRole} onClick={onClickSave}>
            Сохранить изменения
          </Button>
          <Button variant="secondary" onClick={onClickCancel}>
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(RoleItemEditPage);
