import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import VotingList from "newApp/components/VotingList";
import VotingsModeSwitcher from "newApp/components/VotingsModeSwitcher";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Pagination from "newApp/kit/components/Pagination";
import {
  URL_VOTING_ACTIVE,
  URL_VOTING_ADD,
  URL_VOTING_ANNOUNCEMENT,
  URL_VOTING_COMPLETED,
} from "newApp/routing/urls";
import {
  TVotesPageMode,
  VotingsPageStore,
} from "newApp/stores/pages/votings/VotingsPageStore";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

const store = new VotingsPageStore();

const VotingsPage: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  let mode: TVotesPageMode = "announcement";
  if (pathname === URL_VOTING_ANNOUNCEMENT) mode = "announcement";
  if (pathname === URL_VOTING_ACTIVE) mode = "active";
  if (pathname === URL_VOTING_COMPLETED) mode = "completed";

  useEffect(() => {
    store.init(mode);
    return () => store.reset();
  }, [mode]);

  return (
    <MainLayout>
      <H1>Голосования</H1>
      <FlexColumn>
        <VotingsModeSwitcher />
        <Button onClick={() => push(URL_VOTING_ADD)}>
          Добавить голосование
        </Button>
        {false ? (
          <LoaderSpinner />
        ) : (
          <>
            <VotingList
              {...store}
              isHideBannerButtons={mode === "completed"}
              isHideMainButtons={mode === "completed"}
            />
            <Pagination {...store.pagination} />
          </>
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(VotingsPage);
