import { AxiosResponse } from "axios";
import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import InfoTable from "newApp/kit/components/InfoTable";
import { URL_VOTING_ACTIVE, URL_VOTING_EDIT } from "newApp/routing/urls";
import {
  IVoteItemPageInfoStoreInfo,
  IVoteItemQuestion,
} from "newApp/stores/pages/votings/VoteItemPageStore/VoteItemPageInfoStore/index.types";
import React from "react";
import { useHistory, useParams } from "react-router";
import FilesPreview, { IFilesPreviewFile } from "../FilesPreview";
import VoteItemQuestionsList from "../VoteItemQuestionsList";
import { getVoteGenderTranslate } from "./getVoteGenderTranslate";
import "./styles.scss";

export interface IVoteItemDetailsProps {
  info: IVoteItemPageInfoStoreInfo;
  questions: IVoteItemQuestion[];
  onClickPublish: () => void;
  remove: () => Promise<AxiosResponse<void, any>>;
  filesPreviews: IFilesPreviewFile[];
  filesPreviewsNotImages: IFilesPreviewFile[];
}

const VoteItemDetails: React.FC<IVoteItemDetailsProps> = (props) => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const onClickRemove = () => {
    if (window.confirm("Вы уверены что хотите удалить это голосование?")) {
      props
        .remove()
        .then(() => {
          push(URL_VOTING_ACTIVE);
        })
        .catch(() => {
          window.alert("Не удалось удалить голосование.");
        });
    }
  };

  return (
    <div className="vote-item-detail">
      <FlexColumn>
        <InfoTable
          header="Данные голосования"
          content={[
            ["Название", props.info.title],
            ["Аннотация", props.info.annotation],
            ["Описание", props.info.description],
            ["Главное голосование", props.info.isImportant ? "Да" : "Нет"],
            ["Отображение на баннере", props.info.isBanner ? "Да" : "Нет"],
            [
              "Отображение результата",
              props.info.isDigitalResult ? "Цифрами" : "Процентами",
            ],
            [
              "Возрастные ограничения",
              props.info.fromAge || props.info.toAge
                ? `От ${props.info.fromAge} до ${props.info.toAge}`
                : "Нет",
            ],
            ["Ограничения по полу", getVoteGenderTranslate(props.info.gender)],
            [
              "Вознаграждение пользователя баллами",
              props.info.numberOfPoints || null,
            ],
          ]}
        />
        <FilesPreview files={props.filesPreviews} />

        <H3>Прикрепленные файлы</H3>
        <FilesPreview files={props.filesPreviewsNotImages} />

        <VoteItemQuestionsList questions={props.questions} />

        <div className="vote-item-detail__footer">
          <FlexColumn>
            <div
              className={[
                "vote-item-detail__footer-status",
                props.info.isActive
                  ? "vote-item-detail__footer-status--active"
                  : "",
              ].join(" ")}
            >
              {props.info.isActive
                ? "Голосование активно"
                : "Голосование не активно"}
            </div>
            <div className="vote-item-detail__footer-buttons">
              <Button onClick={props.onClickPublish}>
                {props.info.isActive ? "Снять с публикации" : "Опубликовать"}
              </Button>
              <div className="vote-item-detail__footer-buttons-right">
                <Button
                  onClick={() => push(URL_VOTING_EDIT + "/" + id)}
                  variant="secondary"
                >
                  Редактировать
                </Button>
                <Button onClick={onClickRemove} variant="danger">
                  Удалить голосование
                </Button>
              </div>
            </div>
          </FlexColumn>
        </div>
      </FlexColumn>
    </div>
  );
};

export default observer(VoteItemDetails);
