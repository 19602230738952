import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import CategoryForm from "newApp/components/CategoryForm";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_ADMINISTRATION_CATEGORIES } from "newApp/routing/urls";
import { CategoryCreatePageStore } from "newApp/stores/pages/administrations/categories/CategoryCreatePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new CategoryCreatePageStore();

const CategoryCreatePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => {
      store.reset();
    };
  }, []);

  const onClickCreate = async () => {
    const result = await store.onCreate();
    if (result) goOnList();
  };

  const goOnList = () => push(URL_ADMINISTRATION_CATEGORIES);

  return (
    <MainLayout>
      <H1>Создание категории</H1>
      <FlexColumn>
        <ButtonBack url={URL_ADMINISTRATION_CATEGORIES}>
          Назад к списку
        </ButtonBack>
        <CategoryForm {...store} />
        <ButtonsContainer>
          <Button onClick={onClickCreate}>Создать</Button>
          <Button onClick={goOnList} variant="secondary">
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(CategoryCreatePage);
