import { observer } from "mobx-react";
import React from "react";
import { TModule } from "../../stores/pages/MainPageStore/MainPageModulesStore/index.types";
import MainPageModuleItem from "./MainPageModuleItem";
import "./styles.scss";

interface IProps {
  modules: TModule[];
}

const MainPageModules: React.FC<IProps> = (props) => {
  return (
    <div className="main-page-modules">
      {props.modules.map((module, idx) => (
        <MainPageModuleItem {...module} key={idx} />
      ))}
    </div>
  );
};

export default observer(MainPageModules);
