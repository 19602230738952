import imageCompression from "browser-image-compression";

export const compressImage = async (
  file: File,
  compressionOptions?: Parameters<typeof imageCompression>[1]
) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    ...compressionOptions,
  };

  return await imageCompression(file, options);
};
