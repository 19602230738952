import "./styles.scss";

import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { TModule } from "../../../stores/pages/MainPageStore/MainPageModulesStore/index.types";
import IconAdministration from "../../IconAdministration";
import IconAdmission from "../../IconAdmission";
import IconAnalytics from "../../IconAnalytics";
import IconExecution from "../../IconExecution";
import IconModeration from "../../IconModeration";
import IconSite from "../../IconSite";
import IconVoting from "../../IconVoting";
import IconShop from "../../IconShop";

interface IProps extends TModule {}

const MainPageModuleItem: React.FC<IProps> = (props) => {
  const { push } = useHistory();

  return (
    <div className="main-page-module-item" onClick={() => push(props.to)}>
      <div className="main-page-module-item__body">
        <div className="main-page-module-item__body-top">
          <div className="main-page-module-item__body-top-icon">
            {props.id === "admission" && <IconAdmission />}
            {props.id === "moderation" && <IconModeration />}
            {props.id === "execution" && <IconExecution />}
            {props.id === "administration" && <IconAdministration />}
            {props.id === "voting" && <IconVoting />}
            {props.id === "site" && <IconSite />}
            {props.id === "analytics" && <IconAnalytics />}
            {props.id === "shop" && <IconShop />}
          </div>
          <div className="main-page-module-item__body-top-title">
            {props.title}
          </div>
        </div>
        <div className="main-page-module-item__body-bottom">
          {props.description}
        </div>
      </div>
    </div>
  );
};

export default observer(MainPageModuleItem);
