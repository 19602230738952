export const scrollToError = (
  classNames: string[] = [
    "text-field--error",
    "textarea--error",
    "select--error",
    "radioButtons--isError",
  ]
) => {
  try {
    const inputsWithError: Element[] = [];
    classNames?.forEach((cn) => {
      inputsWithError.push(...Array.from(document.getElementsByClassName(cn)));
    });

    if (!!inputsWithError?.length) {
      inputsWithError[0]?.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  } catch (e) {
    console.error("Невозможно прокрутить страницу до ошибки", e);
  }
};
