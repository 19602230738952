import React from "react";
import { observer } from "mobx-react";

import FilesPreview from "newApp/components/FilesPreview";
import Cropper from "newApp/kit/components/Cropper";
import Dropzone from "newApp/kit/components/Dropzone";
import H3 from "newApp/kit/components/H3";

import { ProductFormMainPhotoStore } from "newApp/stores/common/ProductFormStore/ProductFormMainPhotoStore";

export interface IProductFormMainPhotoProps extends ProductFormMainPhotoStore {}

const ProductFormMainPhoto: React.FC<IProductFormMainPhotoProps> = (props) => {
  return (
    <div>
      <H3>Изображение товара/услуги</H3>
      <div>
        {props.imagesPreviews.length ? (
          <FilesPreview
            files={props.imagesPreviews}
            onClickRemove={props.onClickRemove}
          />
        ) : (
          <Dropzone
            onAddFiles={props.onAddFiles}
            multiple={false}
            acceptFileTypes={["image/*"]}
          />
        )}
        {props.isShow.mainPhotoCropper && (
          <Cropper
            sourceImage={props.imageFile}
            steps={[
              {
                title: "Получить обрезанное изображение",
                width: 1920,
                height: 1080,
              },
            ]}
            onCropped={props.onCropped}
            onClose={props.onClose}
          />
        )}
      </div>
    </div>
  );
};

export default observer(ProductFormMainPhoto);
