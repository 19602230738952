import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import MainLayout from "../../../../components/layouts/MainLayout";
import RoleEditCreateForm from "../../../../components/RoleEditCreateForm";
import RoleEditModules from "../../../../components/RoleEditModules";
import Button from "../../../../kit/components/Button";
import ButtonsContainer from "../../../../kit/components/ButtonsContainer";
import Divider from "../../../../kit/components/Divider";
import FlexColumn from "../../../../kit/components/FlexColumn";
import H1 from "../../../../kit/components/H1";
import { URL_ADMISSION_ROLES } from "../../../../routing/urls";
import { RoleCreatePageStore } from "../../../../stores/pages/admission/roles/RoleCreatePageStore";

const store = new RoleCreatePageStore();

const RoleCreatePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const onClickCancel = () => {
    push(URL_ADMISSION_ROLES);
  };

  const onClickSave = async () => {
    await store.validate();
    if (store.isValid) {
      const roleId = await store.postRole();
      if (roleId) push(URL_ADMISSION_ROLES + `/${roleId}`);
    }
  };

  return (
    <MainLayout>
      <H1>Создание роли</H1>
      <FlexColumn>
        <RoleEditCreateForm form={store.forms.form} />
        <RoleEditModules
          onChangeOperation={store.forms.modules.onChangeOperation}
          onChangeModule={store.forms.modules.onChangeModule}
          modules={store.forms.modules.modules}
        />
        <Divider />
        <ButtonsContainer justifyRight>
          <Button disabled={store.isLoadings.postRole} onClick={onClickSave}>
            Сохранить изменения
          </Button>
          <Button variant="secondary" onClick={onClickCancel}>
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>{" "}
    </MainLayout>
  );
};

export default observer(RoleCreatePage);
