import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import FilesPreview from "newApp/components/FilesPreview";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import Dropzone from "newApp/kit/components/Dropzone";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import H3 from "newApp/kit/components/H3";
import TextArea from "newApp/kit/components/TextArea";
import TextField from "newApp/kit/components/TextField";
import { URL_ADMINISTRATION_MOBILE } from "newApp/routing/urls";
import { MobileEditPageStore } from "newApp/stores/pages/site/mobile/MobileEditPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new MobileEditPageStore();

const MobileEditPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const onClickSave = async () => {
    const result = await store.onSave();
    if (result) goOnInfo();
  };

  const goOnInfo = () => push(URL_ADMINISTRATION_MOBILE);

  return (
    <MainLayout isLoading={store.isLoadings.getMobile}>
      <H1>Редактирование информации о мобильном приложении</H1>
      <FlexColumn>
        <ButtonBack url={URL_ADMINISTRATION_MOBILE}>
          Назад к информации
        </ButtonBack>
        <TextField placeholder="Заголовок" {...store.form.adapters.title} />
        <TextArea placeholder="Текст" {...store.form.adapters.content} />
        <H3>Изображение</H3>
        <Dropzone
          onAddFiles={store.onAddFiles}
          acceptFileTypes={["image/*"]}
          multiple={false}
        />
        <FilesPreview files={store.filesPreviews} />
        <ButtonsContainer>
          <Button
            onClick={onClickSave}
            disabled={
              store.isLoadings.uploadFile || store.isLoadings.saveMobile
            }
          >
            Сохранить изменения
          </Button>
          <Button onClick={goOnInfo} variant={"secondary"}>
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(MobileEditPage);
