/* tslint:disable */
/* eslint-disable */
/**
 * Администрирование
 * Описание методов для работы с API \"Администрирование\"
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AisaeSelectListItem
 */
export interface AisaeSelectListItem {
    /**
     * 
     * @type {string}
     * @memberof AisaeSelectListItem
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AisaeSelectListItem
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface AnswerForm
 */
export interface AnswerForm {
    /**
     * 
     * @type {string}
     * @memberof AnswerForm
     */
    'Id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnswerForm
     */
    'Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnswerForm
     */
    'FileId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnswerForm
     */
    'FilesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AnswerModel
 */
export interface AnswerModel {
    /**
     * 
     * @type {string}
     * @memberof AnswerModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerModel
     */
    'Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnswerModel
     */
    'FileId'?: string | null;
    /**
     * 
     * @type {Array<VotesFilesGetVoteModel>}
     * @memberof AnswerModel
     */
    'Fiels'?: Array<VotesFilesGetVoteModel> | null;
}
/**
 * 
 * @export
 * @interface AttachedFileReference
 */
export interface AttachedFileReference {
    /**
     * 
     * @type {string}
     * @memberof AttachedFileReference
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachedFileReference
     */
    'Reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachedFileReference
     */
    'Type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachedFileReference
     */
    'Size'?: number;
}
/**
 * 
 * @export
 * @interface AuthorForm
 */
export interface AuthorForm {
    /**
     * 
     * @type {string}
     * @memberof AuthorForm
     */
    'FirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorForm
     */
    'LastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorForm
     */
    'Email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorForm
     */
    'Mobile'?: string | null;
}
/**
 * 
 * @export
 * @interface AuthorModel
 */
export interface AuthorModel {
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'Fullname'?: string | null;
}
/**
 * 
 * @export
 * @interface CategoryCreateForm
 */
export interface CategoryCreateForm {
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateForm
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateForm
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateForm
     */
    'OrganizationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateForm
     */
    'DaysLimit'?: number | null;
    /**
     * 
     * @type {ObjectState}
     * @memberof CategoryCreateForm
     */
    'State': ObjectState;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategoryCreateForm
     */
    'Tags'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CategoryCreateResponse
 */
export interface CategoryCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateResponse
     */
    'Id'?: string;
}
/**
 * 
 * @export
 * @interface CategorySetForm
 */
export interface CategorySetForm {
    /**
     * 
     * @type {string}
     * @memberof CategorySetForm
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorySetForm
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorySetForm
     */
    'OrganizationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategorySetForm
     */
    'DaysLimit'?: number | null;
    /**
     * 
     * @type {ObjectState}
     * @memberof CategorySetForm
     */
    'State'?: ObjectState;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategorySetForm
     */
    'Tags'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ChangeTypeModel
 */
export interface ChangeTypeModel {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeTypeModel
     */
    'IsBanner'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeTypeModel
     */
    'IsImportant'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ChatMessageItemModel
 */
export interface ChatMessageItemModel {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageItemModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageItemModel
     */
    'Text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageItemModel
     */
    'IsMyMessage'?: boolean;
    /**
     * 
     * @type {AuthorModel}
     * @memberof ChatMessageItemModel
     */
    'Author'?: AuthorModel;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageItemModel
     */
    'Date'?: string;
    /**
     * 
     * @type {Array<AttachedFileReference>}
     * @memberof ChatMessageItemModel
     */
    'FileReferences'?: Array<AttachedFileReference> | null;
}
/**
 * 
 * @export
 * @interface ChatMessageListItemModel
 */
export interface ChatMessageListItemModel {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListItemModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListItemModel
     */
    'Text'?: string | null;
    /**
     * 
     * @type {AuthorModel}
     * @memberof ChatMessageListItemModel
     */
    'Author'?: AuthorModel;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListItemModel
     */
    'Date'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageListItemModel
     */
    'IsMyMessage'?: boolean;
    /**
     * 
     * @type {Array<AttachedFileReference>}
     * @memberof ChatMessageListItemModel
     */
    'FileReferences'?: Array<AttachedFileReference> | null;
}
/**
 * 
 * @export
 * @interface ChatMessageListModel
 */
export interface ChatMessageListModel {
    /**
     * 
     * @type {Array<ChatMessageListItemModel>}
     * @memberof ChatMessageListModel
     */
    'Messages'?: Array<ChatMessageListItemModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListModel
     */
    'LastUpdate'?: string | null;
}
/**
 * 
 * @export
 * @interface ClosingViolation
 */
export interface ClosingViolation {
    /**
     * 
     * @type {string}
     * @memberof ClosingViolation
     */
    'ViolationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClosingViolation
     */
    'ViolationTemplateStepId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClosingViolation
     */
    'Comment'?: string | null;
}
/**
 * 
 * @export
 * @interface CoordinateModel
 */
export interface CoordinateModel {
    /**
     * 
     * @type {number}
     * @memberof CoordinateModel
     */
    'Latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinateModel
     */
    'Longitude'?: number;
}
/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'Latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'Longitude'?: number;
}
/**
 * 
 * @export
 * @interface DataEntry
 */
export interface DataEntry {
    /**
     * 
     * @type {string}
     * @memberof DataEntry
     */
    'ViolationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataEntry
     */
    'ViolationTemplateStepId'?: string;
    /**
     * 
     * @type {Array<RegisterFieldValueForm>}
     * @memberof DataEntry
     */
    'FieldValues'?: Array<RegisterFieldValueForm> | null;
}
/**
 * 
 * @export
 * @interface EditViolationTemplateForm
 */
export interface EditViolationTemplateForm {
    /**
     * 
     * @type {string}
     * @memberof EditViolationTemplateForm
     */
    'SubCategoryId'?: string;
    /**
     * 
     * @type {Array<RegisterViolationTemplateStep>}
     * @memberof EditViolationTemplateForm
     */
    'ViolationTemplateSteps'?: Array<RegisterViolationTemplateStep> | null;
}
/**
 * 
 * @export
 * @interface EnumModel
 */
export interface EnumModel {
    /**
     * 
     * @type {string}
     * @memberof EnumModel
     */
    'Id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnumModel
     */
    'Title'?: string | null;
}
/**
 * 
 * @export
 * @interface FieldTypeDTO
 */
export interface FieldTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof FieldTypeDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldTypeDTO
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface FileForm
 */
export interface FileForm {
    /**
     * 
     * @type {string}
     * @memberof FileForm
     */
    'Data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileForm
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileForm
     */
    'ContentType'?: string | null;
}
/**
 * 
 * @export
 * @interface FileUploadV2ItemResult
 */
export interface FileUploadV2ItemResult {
    /**
     * 
     * @type {string}
     * @memberof FileUploadV2ItemResult
     */
    'Id'?: string;
}
/**
 * 
 * @export
 * @interface FilterViolationDataDTO
 */
export interface FilterViolationDataDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterViolationDataDTO
     */
    'ViolationTypes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterViolationDataDTO
     */
    'ViolationStatuses'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterViolationDataDTO
     */
    'Districts'?: Array<string> | null;
    /**
     * 
     * @type {Array<OrganizationKeyValueModel>}
     * @memberof FilterViolationDataDTO
     */
    'Organizations'?: Array<OrganizationKeyValueModel> | null;
}
/**
 * 
 * @export
 * @interface FilterViolationForm
 */
export interface FilterViolationForm {
    /**
     * 
     * @type {number}
     * @memberof FilterViolationForm
     */
    'Number'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterViolationForm
     */
    'ViolationStatus'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FilterViolationForm
     */
    'IsPublished'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FilterViolationForm
     */
    'District'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FilterViolationForm
     */
    'CategoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterViolationForm
     */
    'OrganizationId'?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'Text'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'ViolationId'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject
     */
    'Photos'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject
     */
    'Videos'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject
     */
    'Audios'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject
     */
    'Docs'?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject1
     */
    'files'?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'ViolationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    'ViolationTemplateStepId'?: string;
    /**
     * 
     * @type {Array<StepFieldExecutionForm>}
     * @memberof InlineObject2
     */
    'Fields'?: Array<StepFieldExecutionForm>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject2
     */
    'Files'?: Array<any>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject2
     */
    'PastPhotos'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    'CategoryId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject3
     */
    'SubCategoryIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    'Address'?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    'Coordinates.Latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    'Coordinates.Longitude'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject3
     */
    'Photos'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject3
     */
    'Videos'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject3
     */
    'Documents'?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'ViolationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'ViolationTemplateStepId'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject4
     */
    'Photos'?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    'ViolationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    'ViolationTemplateStepId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject5
     */
    'Was'?: Array<string>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject5
     */
    'Became'?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject6
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject7
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject8
     */
    'file': any;
}
/**
 * 
 * @export
 * @interface MapFilteredViolationModel
 */
export interface MapFilteredViolationModel {
    /**
     * 
     * @type {Array<ViolationListDTO>}
     * @memberof MapFilteredViolationModel
     */
    'Violations'?: Array<ViolationListDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof MapFilteredViolationModel
     */
    'PageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapFilteredViolationModel
     */
    'PageNumber'?: number;
}
/**
 * 
 * @export
 * @interface MapFilteredViolationModelList
 */
export interface MapFilteredViolationModelList {
    /**
     * 
     * @type {Array<Array<ViolationListDTO>>}
     * @memberof MapFilteredViolationModelList
     */
    'Violations'?: Array<Array<ViolationListDTO>> | null;
    /**
     * 
     * @type {number}
     * @memberof MapFilteredViolationModelList
     */
    'PageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapFilteredViolationModelList
     */
    'PageNumber'?: number;
}
/**
 * 
 * @export
 * @interface ObjectDTO
 */
export interface ObjectDTO {
    /**
     * 
     * @type {string}
     * @memberof ObjectDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectDTO
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectDTO
     */
    'SectionId'?: string;
}
/**
 * 
 * @export
 * @interface ObjectGetListType
 */
export interface ObjectGetListType {
    /**
     * 
     * @type {string}
     * @memberof ObjectGetListType
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGetListType
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGetListType
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGetListType
     */
    'OrganizationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ObjectGetListType
     */
    'DaysLimit'?: number | null;
    /**
     * 
     * @type {ObjectState}
     * @memberof ObjectGetListType
     */
    'State'?: ObjectState;
    /**
     * 
     * @type {Array<ObjectGetListType>}
     * @memberof ObjectGetListType
     */
    'Children'?: Array<ObjectGetListType> | null;
}
/**
 * 
 * @export
 * @interface ObjectGetType
 */
export interface ObjectGetType {
    /**
     * 
     * @type {string}
     * @memberof ObjectGetType
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObjectGetType
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGetType
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectGetType
     */
    'OrganizationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ObjectGetType
     */
    'DaysLimit'?: number | null;
    /**
     * 
     * @type {ObjectState}
     * @memberof ObjectGetType
     */
    'State'?: ObjectState;
    /**
     * 
     * @type {boolean}
     * @memberof ObjectGetType
     */
    'CanDelete'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectGetType
     */
    'Tags'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ObjectState = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ObjectState = typeof ObjectState[keyof typeof ObjectState];


/**
 * 
 * @export
 * @interface OrganizationCreateForm
 */
export interface OrganizationCreateForm {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateForm
     */
    'Title': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateForm
     */
    'TitleShort': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateForm
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationCreateForm
     */
    'Active': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationCreateResponse
 */
export interface OrganizationCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreateResponse
     */
    'Id'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationForm
 */
export interface OrganizationForm {
    /**
     * 
     * @type {string}
     * @memberof OrganizationForm
     */
    'Id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationForm
     */
    'Title'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationItemRequisiteType
 */
export interface OrganizationItemRequisiteType {
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'LegalAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'PostAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Fax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Inn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Kpp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Ogrn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Okved'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Okpo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Okato'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'AccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'BankTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'Bik'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemRequisiteType
     */
    'BankCorrespAccount'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationItemType
 */
export interface OrganizationItemType {
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemType
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemType
     */
    'Title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemType
     */
    'TitleShort'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationItemType
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationItemType
     */
    'Active'?: boolean;
    /**
     * 
     * @type {OrganizationItemRequisiteType}
     * @memberof OrganizationItemType
     */
    'Item'?: OrganizationItemRequisiteType;
}
/**
 * 
 * @export
 * @interface OrganizationKeyValueModel
 */
export interface OrganizationKeyValueModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationKeyValueModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationKeyValueModel
     */
    'Title'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationListItemType
 */
export interface OrganizationListItemType {
    /**
     * 
     * @type {string}
     * @memberof OrganizationListItemType
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationListItemType
     */
    'Title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationListItemType
     */
    'TitleShort'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationListItemType
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationListItemType
     */
    'Active'?: boolean;
    /**
     * 
     * @type {Array<OrganizationListItemType>}
     * @memberof OrganizationListItemType
     */
    'Children'?: Array<OrganizationListItemType> | null;
}
/**
 * 
 * @export
 * @interface OrganizationSetForm
 */
export interface OrganizationSetForm {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSetForm
     */
    'Title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSetForm
     */
    'TitleShort'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSetForm
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSetForm
     */
    'Active'?: boolean | null;
}
/**
 * 
 * @export
 * @interface OrganizationTypeForm
 */
export interface OrganizationTypeForm {
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypeForm
     */
    'Title'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationTypeItemModel
 */
export interface OrganizationTypeItemModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypeItemModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypeItemModel
     */
    'Title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationTypeItemModel
     */
    'CanEdit'?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationTypeListItemModel
 */
export interface OrganizationTypeListItemModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypeListItemModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypeListItemModel
     */
    'Title'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationTypeListModel
 */
export interface OrganizationTypeListModel {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationTypeListModel
     */
    'CanCreate'?: boolean;
    /**
     * 
     * @type {Array<OrganizationTypeListItemModel>}
     * @memberof OrganizationTypeListModel
     */
    'Items'?: Array<OrganizationTypeListItemModel> | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'KeycloakUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'AnsweredUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'QuestionText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'AnswerText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'CreatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'AnsweredDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    'IsAnswered'?: boolean;
}
/**
 * 
 * @export
 * @interface QuestionAnswer
 */
export interface QuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    'Fullname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    'Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    'Date'?: string | null;
}
/**
 * 
 * @export
 * @interface QuestionForm
 */
export interface QuestionForm {
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    'Id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    'Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    'Type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionForm
     */
    'HasOtherOption'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionForm
     */
    'IsRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    'FileId'?: string | null;
    /**
     * 
     * @type {Array<AnswerForm>}
     * @memberof QuestionForm
     */
    'Answers'?: Array<AnswerForm> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionForm
     */
    'FilesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface QuestionListModel
 */
export interface QuestionListModel {
    /**
     * 
     * @type {Array<Question>}
     * @memberof QuestionListModel
     */
    'Questions'?: Array<Question> | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionListModel
     */
    'PageCount'?: number;
}
/**
 * 
 * @export
 * @interface QuestionModel
 */
export interface QuestionModel {
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'Type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionModel
     */
    'HasOtherOption'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionModel
     */
    'IsRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'FileId'?: string | null;
    /**
     * 
     * @type {Array<AnswerModel>}
     * @memberof QuestionModel
     */
    'Answers'?: Array<AnswerModel> | null;
    /**
     * 
     * @type {Array<VotesFilesGetVoteModel>}
     * @memberof QuestionModel
     */
    'Files'?: Array<VotesFilesGetVoteModel> | null;
}
/**
 * 
 * @export
 * @interface RegisterFieldValueForm
 */
export interface RegisterFieldValueForm {
    /**
     * 
     * @type {string}
     * @memberof RegisterFieldValueForm
     */
    'ViolationTemplateStepFieldId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterFieldValueForm
     */
    'Value'?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterViolationTemplateForm
 */
export interface RegisterViolationTemplateForm {
    /**
     * 
     * @type {string}
     * @memberof RegisterViolationTemplateForm
     */
    'SubCategoryId'?: string;
    /**
     * 
     * @type {Array<RegisterViolationTemplateStep>}
     * @memberof RegisterViolationTemplateForm
     */
    'ViolationTemplateSteps'?: Array<RegisterViolationTemplateStep> | null;
}
/**
 * 
 * @export
 * @interface RegisterViolationTemplateStep
 */
export interface RegisterViolationTemplateStep {
    /**
     * 
     * @type {string}
     * @memberof RegisterViolationTemplateStep
     */
    'StepId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegisterViolationTemplateStep
     */
    'FieldTypeIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SectionsGetType
 */
export interface SectionsGetType {
    /**
     * 
     * @type {string}
     * @memberof SectionsGetType
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsGetType
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface StepDTO
 */
export interface StepDTO {
    /**
     * 
     * @type {string}
     * @memberof StepDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StepDTO
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StepDTO
     */
    'StepType'?: string | null;
}
/**
 * 
 * @export
 * @interface StepFieldExecutionForm
 */
export interface StepFieldExecutionForm {
    /**
     * 
     * @type {string}
     * @memberof StepFieldExecutionForm
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StepFieldExecutionForm
     */
    'Value'?: string | null;
}
/**
 * 
 * @export
 * @interface Stream
 */
export interface Stream {
    /**
     * 
     * @type {boolean}
     * @memberof Stream
     */
    'CanRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Stream
     */
    'CanWrite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Stream
     */
    'CanSeek'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Stream
     */
    'CanTimeout'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Stream
     */
    'Length'?: number;
    /**
     * 
     * @type {number}
     * @memberof Stream
     */
    'Position'?: number;
    /**
     * 
     * @type {number}
     * @memberof Stream
     */
    'ReadTimeout'?: number;
    /**
     * 
     * @type {number}
     * @memberof Stream
     */
    'WriteTimeout'?: number;
}
/**
 * 
 * @export
 * @interface StreamFileModel
 */
export interface StreamFileModel {
    /**
     * 
     * @type {string}
     * @memberof StreamFileModel
     */
    'FileName'?: string | null;
    /**
     * 
     * @type {Stream}
     * @memberof StreamFileModel
     */
    'Data'?: Stream;
    /**
     * 
     * @type {string}
     * @memberof StreamFileModel
     */
    'ContentType'?: string | null;
}
/**
 * 
 * @export
 * @interface SubObjectsGetType
 */
export interface SubObjectsGetType {
    /**
     * 
     * @type {string}
     * @memberof SubObjectsGetType
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubObjectsGetType
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface UserQuestionCredentials
 */
export interface UserQuestionCredentials {
    /**
     * 
     * @type {string}
     * @memberof UserQuestionCredentials
     */
    'Fullname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserQuestionCredentials
     */
    'Phone'?: string | null;
}
/**
 * 
 * @export
 * @interface UserQuestionModel
 */
export interface UserQuestionModel {
    /**
     * 
     * @type {string}
     * @memberof UserQuestionModel
     */
    'Id'?: string;
    /**
     * 
     * @type {QuestionAnswer}
     * @memberof UserQuestionModel
     */
    'Answer'?: QuestionAnswer;
    /**
     * 
     * @type {UserQuestionCredentials}
     * @memberof UserQuestionModel
     */
    'UserFullname'?: UserQuestionCredentials;
    /**
     * 
     * @type {string}
     * @memberof UserQuestionModel
     */
    'Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserQuestionModel
     */
    'IssueDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserQuestionModel
     */
    'IsAnswered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserQuestionModel
     */
    'Phone'?: string | null;
}
/**
 * 
 * @export
 * @interface ViolationDTO
 */
export interface ViolationDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDTO
     */
    'PhotoIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDTO
     */
    'VideoIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDTO
     */
    'DocIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    'Category'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDTO
     */
    'SubCategories'?: Array<string> | null;
    /**
     * 
     * @type {OrganizationForm}
     * @memberof ViolationDTO
     */
    'Organization'?: OrganizationForm;
    /**
     * 
     * @type {number}
     * @memberof ViolationDTO
     */
    'Number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    'DateOfCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof ViolationDTO
     */
    'DaysLeft'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    'Address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    'District'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ViolationDTO
     */
    'Coordinates'?: Coordinates;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationDTO
     */
    'IsPublished'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationDTO
     */
    'IsOnAddControl'?: boolean;
    /**
     * 
     * @type {AuthorForm}
     * @memberof ViolationDTO
     */
    'Author'?: AuthorForm;
    /**
     * 
     * @type {number}
     * @memberof ViolationDTO
     */
    'SupportCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ViolationDTO
     */
    'Status'?: string | null;
}
/**
 * 
 * @export
 * @interface ViolationHistoryDTO
 */
export interface ViolationHistoryDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryDTO
     */
    'ViolationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryDTO
     */
    'Date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryDTO
     */
    'ChangedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationHistoryDTO
     */
    'Description'?: string | null;
}
/**
 * 
 * @export
 * @interface ViolationItemModel
 */
export interface ViolationItemModel {
    /**
     * 
     * @type {string}
     * @memberof ViolationItemModel
     */
    'Id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationItemModel
     */
    'PhotoIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationItemModel
     */
    'VideoIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationItemModel
     */
    'DocIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemModel
     */
    'Category'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationItemModel
     */
    'SubCategories'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemModel
     */
    'OrganizationTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationItemModel
     */
    'Number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemModel
     */
    'DateOfCreation'?: string;
    /**
     * 
     * @type {number}
     * @memberof ViolationItemModel
     */
    'DaysLeft'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemModel
     */
    'Address'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ViolationItemModel
     */
    'Coordinates'?: Coordinates;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationItemModel
     */
    'IsPublished'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationItemModel
     */
    'IsOnAddControl'?: boolean;
    /**
     * 
     * @type {AuthorForm}
     * @memberof ViolationItemModel
     */
    'Author'?: AuthorForm;
    /**
     * 
     * @type {Array<ViolationItemStepModel>}
     * @memberof ViolationItemModel
     */
    'Steps'?: Array<ViolationItemStepModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemModel
     */
    'Status'?: string | null;
}
/**
 * 
 * @export
 * @interface ViolationItemStepFileModel
 */
export interface ViolationItemStepFileModel {
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepFileModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepFileModel
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface ViolationItemStepModel
 */
export interface ViolationItemStepModel {
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepModel
     */
    'ViolationTemplateStepId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepModel
     */
    'StepTypeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepModel
     */
    'Name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationItemStepModel
     */
    'Index'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationItemStepModel
     */
    'IsDone'?: boolean;
    /**
     * 
     * @type {Array<ViolationItemStepValueModel>}
     * @memberof ViolationItemStepModel
     */
    'Values'?: Array<ViolationItemStepValueModel> | null;
    /**
     * 
     * @type {Array<ViolationItemStepFileModel>}
     * @memberof ViolationItemStepModel
     */
    'Files'?: Array<ViolationItemStepFileModel> | null;
}
/**
 * 
 * @export
 * @interface ViolationItemStepValueModel
 */
export interface ViolationItemStepValueModel {
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepValueModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepValueModel
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationItemStepValueModel
     */
    'Value'?: string | null;
}
/**
 * 
 * @export
 * @interface ViolationListDTO
 */
export interface ViolationListDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationListDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ViolationListDTO
     */
    'Number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDTO
     */
    'DateOfCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDTO
     */
    'Category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDTO
     */
    'SubCategory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDTO
     */
    'Organization'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationListDTO
     */
    'DaysLeft'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDTO
     */
    'District'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ViolationListDTO
     */
    'Coordinates'?: Coordinates;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationListDTO
     */
    'IsChecked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationListDTO
     */
    'IsPublished'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationListDTO
     */
    'IsOnAddControl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationListDTO
     */
    'HasNewMessages'?: boolean;
}
/**
 * 
 * @export
 * @interface ViolationResultDTO
 */
export interface ViolationResultDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationResultDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationResultDTO
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationResultDTO
     */
    'Answer'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationResultDTO
     */
    'PhotoIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ViolationTemplateCategory
 */
export interface ViolationTemplateCategory {
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategory
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategory
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategory
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategory
     */
    'OrganizationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationTemplateCategory
     */
    'DaysLimit'?: number | null;
    /**
     * 
     * @type {ObjectState}
     * @memberof ViolationTemplateCategory
     */
    'State'?: ObjectState;
}
/**
 * 
 * @export
 * @interface ViolationTemplateCategoryDTO
 */
export interface ViolationTemplateCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategoryDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategoryDTO
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategoryDTO
     */
    'ParentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateCategoryDTO
     */
    'OrganizationId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationTemplateCategoryDTO
     */
    'DaysLimit'?: number | null;
    /**
     * 
     * @type {ObjectState}
     * @memberof ViolationTemplateCategoryDTO
     */
    'State'?: ObjectState;
    /**
     * 
     * @type {ViolationTemplateCategoryDTO}
     * @memberof ViolationTemplateCategoryDTO
     */
    'Child'?: ViolationTemplateCategoryDTO;
}
/**
 * 
 * @export
 * @interface ViolationTemplateDTO
 */
export interface ViolationTemplateDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {ViolationTemplateCategoryDTO}
     * @memberof ViolationTemplateDTO
     */
    'Categories'?: ViolationTemplateCategoryDTO;
    /**
     * 
     * @type {Array<ViolationTemplateStepDTO>}
     * @memberof ViolationTemplateDTO
     */
    'Steps'?: Array<ViolationTemplateStepDTO> | null;
}
/**
 * 
 * @export
 * @interface ViolationTemplateListDTO
 */
export interface ViolationTemplateListDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateListDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateListDTO
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface ViolationTemplatePagesDTO
 */
export interface ViolationTemplatePagesDTO {
    /**
     * 
     * @type {Array<ViolationTemplateListDTO>}
     * @memberof ViolationTemplatePagesDTO
     */
    'ViolationTemplates'?: Array<ViolationTemplateListDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationTemplatePagesDTO
     */
    'PageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ViolationTemplatePagesDTO
     */
    'PageNumber'?: number;
}
/**
 * 
 * @export
 * @interface ViolationTemplateStepDTO
 */
export interface ViolationTemplateStepDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateStepDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateStepDTO
     */
    'Name'?: string | null;
    /**
     * 
     * @type {Array<ViolationTemplateStepFieldTypeDTO>}
     * @memberof ViolationTemplateStepDTO
     */
    'FieldTypes'?: Array<ViolationTemplateStepFieldTypeDTO> | null;
}
/**
 * 
 * @export
 * @interface ViolationTemplateStepFieldTypeDTO
 */
export interface ViolationTemplateStepFieldTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateStepFieldTypeDTO
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationTemplateStepFieldTypeDTO
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface VoteCardModel
 */
export interface VoteCardModel {
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'Title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'Annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'FileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'CroppedFileId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoteCardModel
     */
    'QuestionsNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoteCardModel
     */
    'VotersNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'Status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteCardModel
     */
    'IsImportant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteCardModel
     */
    'IsBanner'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteCardModel
     */
    'IsActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'StartDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'EndDateTime'?: string;
}
/**
 * 
 * @export
 * @interface VoteCardModelHolder
 */
export interface VoteCardModelHolder {
    /**
     * 
     * @type {Array<VoteCardModel>}
     * @memberof VoteCardModelHolder
     */
    'Votes'?: Array<VoteCardModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteCardModelHolder
     */
    'VotesStatusTypes'?: Array<EnumModel> | null;
    /**
     * 
     * @type {number}
     * @memberof VoteCardModelHolder
     */
    'Count'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModelHolder
     */
    'MainVoteId'?: string | null;
}
/**
 * 
 * @export
 * @interface VoteForm
 */
export interface VoteForm {
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'Type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'Title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'Description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'FileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'CroppedFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'PreviewFileId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoteForm
     */
    'Idp'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'Place'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'Position'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'StartDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'EndDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'Gender'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoteForm
     */
    'FromAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoteForm
     */
    'ToAge'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteForm
     */
    'IsImportant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteForm
     */
    'IsBanner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoteForm
     */
    'Annotation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteForm
     */
    'IsDigitalResult'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoteForm
     */
    'NumberOfPoints'?: number | null;
    /**
     * 
     * @type {Array<CoordinateModel>}
     * @memberof VoteForm
     */
    'Coordinates'?: Array<CoordinateModel> | null;
    /**
     * 
     * @type {Array<QuestionForm>}
     * @memberof VoteForm
     */
    'Questions'?: Array<QuestionForm> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoteForm
     */
    'FilesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface VoteModel
 */
export interface VoteModel {
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'FileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'CroppedFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'PreviewFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoteModel
     */
    'Idp'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Place'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Position'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'IsActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'StartDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'EndDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Gender'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoteModel
     */
    'FromAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VoteModel
     */
    'ToAge'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'IsImportant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'MainVoteTitle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'IsBanner'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'Annotation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'IsDigitalResult'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoteModel
     */
    'NumberOfPoints'?: number | null;
    /**
     * 
     * @type {Array<VotesFilesGetVoteModel>}
     * @memberof VoteModel
     */
    'Files'?: Array<VotesFilesGetVoteModel> | null;
    /**
     * 
     * @type {Array<CoordinateModel>}
     * @memberof VoteModel
     */
    'Coordinates'?: Array<CoordinateModel> | null;
    /**
     * 
     * @type {Array<QuestionModel>}
     * @memberof VoteModel
     */
    'Questions'?: Array<QuestionModel> | null;
}
/**
 * 
 * @export
 * @interface VoteModelHolder
 */
export interface VoteModelHolder {
    /**
     * 
     * @type {VoteModel}
     * @memberof VoteModelHolder
     */
    'VoteModel'?: VoteModel;
    /**
     * 
     * @type {string}
     * @memberof VoteModelHolder
     */
    'MainVoteTitle'?: string | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'VoteTypes'?: Array<EnumModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'PositionTypes'?: Array<EnumModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'AnswerTypes'?: Array<EnumModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'PlaceTypes'?: Array<EnumModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'Genders'?: Array<EnumModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'Districts'?: Array<EnumModel> | null;
}
/**
 * 
 * @export
 * @interface VotesFilesGetVoteModel
 */
export interface VotesFilesGetVoteModel {
    /**
     * 
     * @type {string}
     * @memberof VotesFilesGetVoteModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VotesFilesGetVoteModel
     */
    'Name'?: string | null;
}
/**
 * 
 * @export
 * @interface VotesFilesSetModel
 */
export interface VotesFilesSetModel {
    /**
     * 
     * @type {string}
     * @memberof VotesFilesSetModel
     */
    'Id'?: string;
}

/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение свободных ответов для вопроса
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsFreeanswersGet: async (questionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionId' is not null or undefined
            assertParamExists('apiAnalyticsFreeanswersGet', 'questionId', questionId)
            const localVarPath = `/api/analytics/freeanswers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение аналитики для голосования
         * @param {string} voteId Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsVoteIdGet: async (voteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voteId' is not null or undefined
            assertParamExists('apiAnalyticsVoteIdGet', 'voteId', voteId)
            const localVarPath = `/api/analytics/{voteId}`
                .replace(`{${"voteId"}}`, encodeURIComponent(String(voteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение свободных ответов для вопроса
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsFreeanswersGet(questionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsFreeanswersGet(questionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение аналитики для голосования
         * @param {string} voteId Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsVoteIdGet(voteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsVoteIdGet(voteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение свободных ответов для вопроса
         * @param {string} questionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsFreeanswersGet(questionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnalyticsFreeanswersGet(questionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение аналитики для голосования
         * @param {string} voteId Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsVoteIdGet(voteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnalyticsVoteIdGet(voteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - interface
 * @export
 * @interface AnalyticsApi
 */
export interface AnalyticsApiInterface {
    /**
     * 
     * @summary Получение свободных ответов для вопроса
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsFreeanswersGet(questionId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение аналитики для голосования
     * @param {string} voteId Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsVoteIdGet(voteId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI implements AnalyticsApiInterface {
    /**
     * 
     * @summary Получение свободных ответов для вопроса
     * @param {string} questionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsFreeanswersGet(questionId: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsFreeanswersGet(questionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение аналитики для голосования
     * @param {string} voteId Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsVoteIdGet(voteId: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsVoteIdGet(voteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание нового классификатора
         * @param {CategoryCreateForm} [categoryCreateForm] Форма создания классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsCreatePost: async (categoryCreateForm?: CategoryCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/objects/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление классификатора
         * @param {string} id Уникальный идентификатор классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsDeleteIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiObjectsDeleteIdPost', 'id', id)
            const localVarPath = `/api/objects/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка классификаторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение классификатора по id
         * @param {string} id Уникальный идентификатор классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiObjectsIdGet', 'id', id)
            const localVarPath = `/api/objects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение подкатегорий классификатора
         * @param {string} id Уникальный идентификатор подкатегории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsIdSubObjectsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiObjectsIdSubObjectsGet', 'id', id)
            const localVarPath = `/api/objects/{id}/subObjects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование классификатора
         * @param {string} id Уникальный идентификатор классификатора
         * @param {CategorySetForm} [categorySetForm] Форма редактирования классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsSetIdPost: async (id: string, categorySetForm?: CategorySetForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiObjectsSetIdPost', 'id', id)
            const localVarPath = `/api/objects/set/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categorySetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание нового классификатора
         * @param {CategoryCreateForm} [categoryCreateForm] Форма создания классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectsCreatePost(categoryCreateForm?: CategoryCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectsCreatePost(categoryCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление классификатора
         * @param {string} id Уникальный идентификатор классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectsDeleteIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectsDeleteIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка классификаторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObjectGetListType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение классификатора по id
         * @param {string} id Уникальный идентификатор классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectGetType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение подкатегорий классификатора
         * @param {string} id Уникальный идентификатор подкатегории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectsIdSubObjectsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectGetType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectsIdSubObjectsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование классификатора
         * @param {string} id Уникальный идентификатор классификатора
         * @param {CategorySetForm} [categorySetForm] Форма редактирования классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiObjectsSetIdPost(id: string, categorySetForm?: CategorySetForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiObjectsSetIdPost(id, categorySetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание нового классификатора
         * @param {CategoryCreateForm} [categoryCreateForm] Форма создания классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsCreatePost(categoryCreateForm?: CategoryCreateForm, options?: any): AxiosPromise<CategoryCreateResponse> {
            return localVarFp.apiObjectsCreatePost(categoryCreateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление классификатора
         * @param {string} id Уникальный идентификатор классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsDeleteIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiObjectsDeleteIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка классификаторов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsGet(options?: any): AxiosPromise<Array<ObjectGetListType>> {
            return localVarFp.apiObjectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение классификатора по id
         * @param {string} id Уникальный идентификатор классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsIdGet(id: string, options?: any): AxiosPromise<ObjectGetType> {
            return localVarFp.apiObjectsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение подкатегорий классификатора
         * @param {string} id Уникальный идентификатор подкатегории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsIdSubObjectsGet(id: string, options?: any): AxiosPromise<ObjectGetType> {
            return localVarFp.apiObjectsIdSubObjectsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование классификатора
         * @param {string} id Уникальный идентификатор классификатора
         * @param {CategorySetForm} [categorySetForm] Форма редактирования классификатора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiObjectsSetIdPost(id: string, categorySetForm?: CategorySetForm, options?: any): AxiosPromise<CategoryCreateResponse> {
            return localVarFp.apiObjectsSetIdPost(id, categorySetForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - interface
 * @export
 * @interface CategoryApi
 */
export interface CategoryApiInterface {
    /**
     * 
     * @summary Создание нового классификатора
     * @param {CategoryCreateForm} [categoryCreateForm] Форма создания классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApiInterface
     */
    apiObjectsCreatePost(categoryCreateForm?: CategoryCreateForm, options?: AxiosRequestConfig): AxiosPromise<CategoryCreateResponse>;

    /**
     * 
     * @summary Удаление классификатора
     * @param {string} id Уникальный идентификатор классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApiInterface
     */
    apiObjectsDeleteIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка классификаторов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApiInterface
     */
    apiObjectsGet(options?: AxiosRequestConfig): AxiosPromise<Array<ObjectGetListType>>;

    /**
     * 
     * @summary Получение классификатора по id
     * @param {string} id Уникальный идентификатор классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApiInterface
     */
    apiObjectsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ObjectGetType>;

    /**
     * 
     * @summary Получение подкатегорий классификатора
     * @param {string} id Уникальный идентификатор подкатегории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApiInterface
     */
    apiObjectsIdSubObjectsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ObjectGetType>;

    /**
     * 
     * @summary Редактирование классификатора
     * @param {string} id Уникальный идентификатор классификатора
     * @param {CategorySetForm} [categorySetForm] Форма редактирования классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApiInterface
     */
    apiObjectsSetIdPost(id: string, categorySetForm?: CategorySetForm, options?: AxiosRequestConfig): AxiosPromise<CategoryCreateResponse>;

}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI implements CategoryApiInterface {
    /**
     * 
     * @summary Создание нового классификатора
     * @param {CategoryCreateForm} [categoryCreateForm] Форма создания классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiObjectsCreatePost(categoryCreateForm?: CategoryCreateForm, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiObjectsCreatePost(categoryCreateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление классификатора
     * @param {string} id Уникальный идентификатор классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiObjectsDeleteIdPost(id: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiObjectsDeleteIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка классификаторов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiObjectsGet(options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiObjectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение классификатора по id
     * @param {string} id Уникальный идентификатор классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiObjectsIdGet(id: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiObjectsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение подкатегорий классификатора
     * @param {string} id Уникальный идентификатор подкатегории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiObjectsIdSubObjectsGet(id: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiObjectsIdSubObjectsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование классификатора
     * @param {string} id Уникальный идентификатор классификатора
     * @param {CategorySetForm} [categorySetForm] Форма редактирования классификатора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiObjectsSetIdPost(id: string, categorySetForm?: CategorySetForm, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).apiObjectsSetIdPost(id, categorySetForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение файла из чата заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatFileGet: async (fileId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/chat/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение сообщений чата заявления
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {string} [lastUpdate] Дата последнего обновления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatMessagesViolationIdGet: async (violationId: string, lastUpdate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('apiChatMessagesViolationIdGet', 'violationId', violationId)
            const localVarPath = `/api/chat/messages/{violationId}`
                .replace(`{${"violationId"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lastUpdate !== undefined) {
                localVarQueryParameter['lastUpdate'] = (lastUpdate as any instanceof Date) ?
                    (lastUpdate as any).toISOString() :
                    lastUpdate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка сообщения в чат заявления
         * @param {string} [text] 
         * @param {string} [violationId] 
         * @param {Array<any>} [photos] 
         * @param {Array<any>} [videos] 
         * @param {Array<any>} [audios] 
         * @param {Array<any>} [docs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatSendPost: async (text?: string, violationId?: string, photos?: Array<any>, videos?: Array<any>, audios?: Array<any>, docs?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/chat/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (text !== undefined) { 
                localVarFormParams.append('Text', text as any);
            }
    
            if (violationId !== undefined) { 
                localVarFormParams.append('ViolationId', violationId as any);
            }
                if (photos) {
                photos.forEach((element) => {
                    localVarFormParams.append('Photos', element as any);
                })
            }

                if (videos) {
                videos.forEach((element) => {
                    localVarFormParams.append('Videos', element as any);
                })
            }

                if (audios) {
                audios.forEach((element) => {
                    localVarFormParams.append('Audios', element as any);
                })
            }

                if (docs) {
                docs.forEach((element) => {
                    localVarFormParams.append('Docs', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение файла из чата заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatFileGet(fileId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatFileGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение сообщений чата заявления
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {string} [lastUpdate] Дата последнего обновления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageListModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatMessagesViolationIdGet(violationId, lastUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправка сообщения в чат заявления
         * @param {string} [text] 
         * @param {string} [violationId] 
         * @param {Array<any>} [photos] 
         * @param {Array<any>} [videos] 
         * @param {Array<any>} [audios] 
         * @param {Array<any>} [docs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatSendPost(text?: string, violationId?: string, photos?: Array<any>, videos?: Array<any>, audios?: Array<any>, docs?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatSendPost(text, violationId, photos, videos, audios, docs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение файла из чата заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatFileGet(fileId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiChatFileGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение сообщений чата заявления
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {string} [lastUpdate] Дата последнего обновления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: any): AxiosPromise<ChatMessageListModel> {
            return localVarFp.apiChatMessagesViolationIdGet(violationId, lastUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка сообщения в чат заявления
         * @param {string} [text] 
         * @param {string} [violationId] 
         * @param {Array<any>} [photos] 
         * @param {Array<any>} [videos] 
         * @param {Array<any>} [audios] 
         * @param {Array<any>} [docs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatSendPost(text?: string, violationId?: string, photos?: Array<any>, videos?: Array<any>, audios?: Array<any>, docs?: Array<any>, options?: any): AxiosPromise<ChatMessageItemModel> {
            return localVarFp.apiChatSendPost(text, violationId, photos, videos, audios, docs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - interface
 * @export
 * @interface ChatApi
 */
export interface ChatApiInterface {
    /**
     * 
     * @summary Получение файла из чата заявления
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatFileGet(fileId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение сообщений чата заявления
     * @param {string} violationId Уникальный идентификатор заявления
     * @param {string} [lastUpdate] Дата последнего обновления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: AxiosRequestConfig): AxiosPromise<ChatMessageListModel>;

    /**
     * 
     * @summary Отправка сообщения в чат заявления
     * @param {string} [text] 
     * @param {string} [violationId] 
     * @param {Array<any>} [photos] 
     * @param {Array<any>} [videos] 
     * @param {Array<any>} [audios] 
     * @param {Array<any>} [docs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatSendPost(text?: string, violationId?: string, photos?: Array<any>, videos?: Array<any>, audios?: Array<any>, docs?: Array<any>, options?: AxiosRequestConfig): AxiosPromise<ChatMessageItemModel>;

}

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI implements ChatApiInterface {
    /**
     * 
     * @summary Получение файла из чата заявления
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatFileGet(fileId?: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatFileGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение сообщений чата заявления
     * @param {string} violationId Уникальный идентификатор заявления
     * @param {string} [lastUpdate] Дата последнего обновления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatMessagesViolationIdGet(violationId, lastUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка сообщения в чат заявления
     * @param {string} [text] 
     * @param {string} [violationId] 
     * @param {Array<any>} [photos] 
     * @param {Array<any>} [videos] 
     * @param {Array<any>} [audios] 
     * @param {Array<any>} [docs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatSendPost(text?: string, violationId?: string, photos?: Array<any>, videos?: Array<any>, audios?: Array<any>, docs?: Array<any>, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatSendPost(text, violationId, photos, videos, audios, docs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldTypesApi - axios parameter creator
 * @export
 */
export const FieldTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение типов полей шагов шаблонов заявлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FieldTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение типа поля по id
         * @param {string} id Уникальный идентификатор типа поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldTypesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFieldTypesIdGet', 'id', id)
            const localVarPath = `/api/FieldTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldTypesApi - functional programming interface
 * @export
 */
export const FieldTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение типов полей шагов шаблонов заявлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFieldTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldTypeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFieldTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение типа поля по id
         * @param {string} id Уникальный идентификатор типа поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFieldTypesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldTypeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFieldTypesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldTypesApi - factory interface
 * @export
 */
export const FieldTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение типов полей шагов шаблонов заявлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldTypesGet(options?: any): AxiosPromise<Array<FieldTypeDTO>> {
            return localVarFp.apiFieldTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение типа поля по id
         * @param {string} id Уникальный идентификатор типа поля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFieldTypesIdGet(id: string, options?: any): AxiosPromise<FieldTypeDTO> {
            return localVarFp.apiFieldTypesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldTypesApi - interface
 * @export
 * @interface FieldTypesApi
 */
export interface FieldTypesApiInterface {
    /**
     * 
     * @summary Получение типов полей шагов шаблонов заявлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTypesApiInterface
     */
    apiFieldTypesGet(options?: AxiosRequestConfig): AxiosPromise<Array<FieldTypeDTO>>;

    /**
     * 
     * @summary Получение типа поля по id
     * @param {string} id Уникальный идентификатор типа поля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTypesApiInterface
     */
    apiFieldTypesIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<FieldTypeDTO>;

}

/**
 * FieldTypesApi - object-oriented interface
 * @export
 * @class FieldTypesApi
 * @extends {BaseAPI}
 */
export class FieldTypesApi extends BaseAPI implements FieldTypesApiInterface {
    /**
     * 
     * @summary Получение типов полей шагов шаблонов заявлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTypesApi
     */
    public apiFieldTypesGet(options?: AxiosRequestConfig) {
        return FieldTypesApiFp(this.configuration).apiFieldTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение типа поля по id
     * @param {string} id Уникальный идентификатор типа поля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTypesApi
     */
    public apiFieldTypesIdGet(id: string, options?: AxiosRequestConfig) {
        return FieldTypesApiFp(this.configuration).apiFieldTypesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Удаление файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesDeleteIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFilesDeleteIdPost', 'id', id)
            const localVarPath = `/api/files/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ссылки на скачивание файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesDownloadIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFilesDownloadIdGet', 'id', id)
            const localVarPath = `/api/files/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFilesIdGet', 'id', id)
            const localVarPath = `/api/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка файлов
         * @param {Array<FileForm>} [fileForm] Массив файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesUploadPost: async (fileForm?: Array<FileForm>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesV2UploadPost: async (files?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/v2/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Удаление файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesDeleteIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesDeleteIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение ссылки на скачивание файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesDownloadIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesDownloadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка файлов
         * @param {Array<FileForm>} [fileForm] Массив файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesUploadPost(fileForm?: Array<FileForm>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesUploadPost(fileForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFilesV2UploadPost(files?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileUploadV2ItemResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilesV2UploadPost(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Удаление файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesDeleteIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesDeleteIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение ссылки на скачивание файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesDownloadIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesDownloadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка файлов
         * @param {Array<FileForm>} [fileForm] Массив файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesUploadPost(fileForm?: Array<FileForm>, options?: any): AxiosPromise<void> {
            return localVarFp.apiFilesUploadPost(fileForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFilesV2UploadPost(files?: Array<any>, options?: any): AxiosPromise<Array<FileUploadV2ItemResult>> {
            return localVarFp.apiFilesV2UploadPost(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - interface
 * @export
 * @interface FileApi
 */
export interface FileApiInterface {
    /**
     * 
     * @summary Удаление файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFilesDeleteIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение ссылки на скачивание файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFilesDownloadIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFilesIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Загрузка файлов
     * @param {Array<FileForm>} [fileForm] Массив файлов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFilesUploadPost(fileForm?: Array<FileForm>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<any>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFilesV2UploadPost(files?: Array<any>, options?: AxiosRequestConfig): AxiosPromise<Array<FileUploadV2ItemResult>>;

}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI implements FileApiInterface {
    /**
     * 
     * @summary Удаление файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilesDeleteIdPost(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilesDeleteIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение ссылки на скачивание файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilesDownloadIdGet(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilesDownloadIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilesIdGet(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка файлов
     * @param {Array<FileForm>} [fileForm] Массив файлов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilesUploadPost(fileForm?: Array<FileForm>, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilesUploadPost(fileForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFilesV2UploadPost(files?: Array<any>, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFilesV2UploadPost(files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание организации
         * @param {OrganizationCreateForm} [organizationCreateForm] Форма создания организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsCreatePost: async (organizationCreateForm?: OrganizationCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка организаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение организации по её уникальному идентификатору
         * @param {string} id уникальный идентификатор организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationsIdGet', 'id', id)
            const localVarPath = `/api/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование организации
         * @param {string} id Уникальный идентификатор организации
         * @param {OrganizationSetForm} [organizationSetForm] Форма редактирования организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsSetIdPost: async (id: string, organizationSetForm?: OrganizationSetForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationsSetIdPost', 'id', id)
            const localVarPath = `/api/organizations/set/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationSetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание организации
         * @param {OrganizationCreateForm} [organizationCreateForm] Форма создания организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsCreatePost(organizationCreateForm?: OrganizationCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsCreatePost(organizationCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка организаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationListItemType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение организации по её уникальному идентификатору
         * @param {string} id уникальный идентификатор организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationItemType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование организации
         * @param {string} id Уникальный идентификатор организации
         * @param {OrganizationSetForm} [organizationSetForm] Форма редактирования организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsSetIdPost(id: string, organizationSetForm?: OrganizationSetForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsSetIdPost(id, organizationSetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание организации
         * @param {OrganizationCreateForm} [organizationCreateForm] Форма создания организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsCreatePost(organizationCreateForm?: OrganizationCreateForm, options?: any): AxiosPromise<OrganizationCreateResponse> {
            return localVarFp.apiOrganizationsCreatePost(organizationCreateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка организаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet(options?: any): AxiosPromise<Array<OrganizationListItemType>> {
            return localVarFp.apiOrganizationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение организации по её уникальному идентификатору
         * @param {string} id уникальный идентификатор организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdGet(id: string, options?: any): AxiosPromise<OrganizationItemType> {
            return localVarFp.apiOrganizationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование организации
         * @param {string} id Уникальный идентификатор организации
         * @param {OrganizationSetForm} [organizationSetForm] Форма редактирования организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsSetIdPost(id: string, organizationSetForm?: OrganizationSetForm, options?: any): AxiosPromise<OrganizationCreateResponse> {
            return localVarFp.apiOrganizationsSetIdPost(id, organizationSetForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - interface
 * @export
 * @interface OrganizationApi
 */
export interface OrganizationApiInterface {
    /**
     * 
     * @summary Создание организации
     * @param {OrganizationCreateForm} [organizationCreateForm] Форма создания организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    apiOrganizationsCreatePost(organizationCreateForm?: OrganizationCreateForm, options?: AxiosRequestConfig): AxiosPromise<OrganizationCreateResponse>;

    /**
     * 
     * @summary Получение списка организаций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    apiOrganizationsGet(options?: AxiosRequestConfig): AxiosPromise<Array<OrganizationListItemType>>;

    /**
     * 
     * @summary Получение организации по её уникальному идентификатору
     * @param {string} id уникальный идентификатор организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    apiOrganizationsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<OrganizationItemType>;

    /**
     * 
     * @summary Редактирование организации
     * @param {string} id Уникальный идентификатор организации
     * @param {OrganizationSetForm} [organizationSetForm] Форма редактирования организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApiInterface
     */
    apiOrganizationsSetIdPost(id: string, organizationSetForm?: OrganizationSetForm, options?: AxiosRequestConfig): AxiosPromise<OrganizationCreateResponse>;

}

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI implements OrganizationApiInterface {
    /**
     * 
     * @summary Создание организации
     * @param {OrganizationCreateForm} [organizationCreateForm] Форма создания организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationsCreatePost(organizationCreateForm?: OrganizationCreateForm, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationsCreatePost(organizationCreateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка организаций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationsGet(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение организации по её уникальному идентификатору
     * @param {string} id уникальный идентификатор организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationsIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование организации
     * @param {string} id Уникальный идентификатор организации
     * @param {OrganizationSetForm} [organizationSetForm] Форма редактирования организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationsSetIdPost(id: string, organizationSetForm?: OrganizationSetForm, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationsSetIdPost(id, organizationSetForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationTypeApi - axios parameter creator
 * @export
 */
export const OrganizationTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание типа организации
         * @param {OrganizationTypeForm} [organizationTypeForm] Форма создания типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesCreatePost: async (organizationTypeForm?: OrganizationTypeForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizationtypes/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationTypeForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование типа организации
         * @param {string} id Уникальный идентификатор типа организации
         * @param {OrganizationTypeForm} [organizationTypeForm] Форма редактирования типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesEditIdPost: async (id: string, organizationTypeForm?: OrganizationTypeForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationtypesEditIdPost', 'id', id)
            const localVarPath = `/api/organizationtypes/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationTypeForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка типов организаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizationtypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение типа организации по его id
         * @param {string} id Уникальный идентификатор типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationtypesIdGet', 'id', id)
            const localVarPath = `/api/organizationtypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationTypeApi - functional programming interface
 * @export
 */
export const OrganizationTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание типа организации
         * @param {OrganizationTypeForm} [organizationTypeForm] Форма создания типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationtypesCreatePost(organizationTypeForm?: OrganizationTypeForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationtypesCreatePost(organizationTypeForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование типа организации
         * @param {string} id Уникальный идентификатор типа организации
         * @param {OrganizationTypeForm} [organizationTypeForm] Форма редактирования типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationtypesEditIdPost(id: string, organizationTypeForm?: OrganizationTypeForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationtypesEditIdPost(id, organizationTypeForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка типов организаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationtypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationTypeListModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationtypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение типа организации по его id
         * @param {string} id Уникальный идентификатор типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationtypesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationTypeItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationtypesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationTypeApi - factory interface
 * @export
 */
export const OrganizationTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationTypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание типа организации
         * @param {OrganizationTypeForm} [organizationTypeForm] Форма создания типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesCreatePost(organizationTypeForm?: OrganizationTypeForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationtypesCreatePost(organizationTypeForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование типа организации
         * @param {string} id Уникальный идентификатор типа организации
         * @param {OrganizationTypeForm} [organizationTypeForm] Форма редактирования типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesEditIdPost(id: string, organizationTypeForm?: OrganizationTypeForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationtypesEditIdPost(id, organizationTypeForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка типов организаций
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesGet(options?: any): AxiosPromise<OrganizationTypeListModel> {
            return localVarFp.apiOrganizationtypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение типа организации по его id
         * @param {string} id Уникальный идентификатор типа организации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationtypesIdGet(id: string, options?: any): AxiosPromise<OrganizationTypeItemModel> {
            return localVarFp.apiOrganizationtypesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationTypeApi - interface
 * @export
 * @interface OrganizationTypeApi
 */
export interface OrganizationTypeApiInterface {
    /**
     * 
     * @summary Создание типа организации
     * @param {OrganizationTypeForm} [organizationTypeForm] Форма создания типа организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApiInterface
     */
    apiOrganizationtypesCreatePost(organizationTypeForm?: OrganizationTypeForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование типа организации
     * @param {string} id Уникальный идентификатор типа организации
     * @param {OrganizationTypeForm} [organizationTypeForm] Форма редактирования типа организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApiInterface
     */
    apiOrganizationtypesEditIdPost(id: string, organizationTypeForm?: OrganizationTypeForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка типов организаций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApiInterface
     */
    apiOrganizationtypesGet(options?: AxiosRequestConfig): AxiosPromise<OrganizationTypeListModel>;

    /**
     * 
     * @summary Получение типа организации по его id
     * @param {string} id Уникальный идентификатор типа организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApiInterface
     */
    apiOrganizationtypesIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<OrganizationTypeItemModel>;

}

/**
 * OrganizationTypeApi - object-oriented interface
 * @export
 * @class OrganizationTypeApi
 * @extends {BaseAPI}
 */
export class OrganizationTypeApi extends BaseAPI implements OrganizationTypeApiInterface {
    /**
     * 
     * @summary Создание типа организации
     * @param {OrganizationTypeForm} [organizationTypeForm] Форма создания типа организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApi
     */
    public apiOrganizationtypesCreatePost(organizationTypeForm?: OrganizationTypeForm, options?: AxiosRequestConfig) {
        return OrganizationTypeApiFp(this.configuration).apiOrganizationtypesCreatePost(organizationTypeForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование типа организации
     * @param {string} id Уникальный идентификатор типа организации
     * @param {OrganizationTypeForm} [organizationTypeForm] Форма редактирования типа организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApi
     */
    public apiOrganizationtypesEditIdPost(id: string, organizationTypeForm?: OrganizationTypeForm, options?: AxiosRequestConfig) {
        return OrganizationTypeApiFp(this.configuration).apiOrganizationtypesEditIdPost(id, organizationTypeForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка типов организаций
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApi
     */
    public apiOrganizationtypesGet(options?: AxiosRequestConfig) {
        return OrganizationTypeApiFp(this.configuration).apiOrganizationtypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение типа организации по его id
     * @param {string} id Уникальный идентификатор типа организации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationTypeApi
     */
    public apiOrganizationtypesIdGet(id: string, options?: AxiosRequestConfig) {
        return OrganizationTypeApiFp(this.configuration).apiOrganizationtypesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [questionId] 
         * @param {string} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsAnswerPost: async (questionId?: string, answer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/questions/answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }

            if (answer !== undefined) {
                localVarQueryParameter['answer'] = answer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [isAnswered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsGet: async (pageSize?: number, pageNumber?: number, isAnswered?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (isAnswered !== undefined) {
                localVarQueryParameter['isAnswered'] = isAnswered;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiQuestionsIdGet', 'id', id)
            const localVarPath = `/api/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [questionId] 
         * @param {string} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsAnswerPost(questionId?: string, answer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsAnswerPost(questionId, answer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [isAnswered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsGet(pageSize?: number, pageNumber?: number, isAnswered?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionListModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsGet(pageSize, pageNumber, isAnswered, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserQuestionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [questionId] 
         * @param {string} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsAnswerPost(questionId?: string, answer?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionsAnswerPost(questionId, answer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {boolean} [isAnswered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsGet(pageSize?: number, pageNumber?: number, isAnswered?: boolean, options?: any): AxiosPromise<QuestionListModel> {
            return localVarFp.apiQuestionsGet(pageSize, pageNumber, isAnswered, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsIdGet(id: string, options?: any): AxiosPromise<UserQuestionModel> {
            return localVarFp.apiQuestionsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - interface
 * @export
 * @interface QuestionApi
 */
export interface QuestionApiInterface {
    /**
     * 
     * @param {string} [questionId] 
     * @param {string} [answer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApiInterface
     */
    apiQuestionsAnswerPost(questionId?: string, answer?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {boolean} [isAnswered] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApiInterface
     */
    apiQuestionsGet(pageSize?: number, pageNumber?: number, isAnswered?: boolean, options?: AxiosRequestConfig): AxiosPromise<QuestionListModel>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApiInterface
     */
    apiQuestionsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<UserQuestionModel>;

}

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI implements QuestionApiInterface {
    /**
     * 
     * @param {string} [questionId] 
     * @param {string} [answer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionsAnswerPost(questionId?: string, answer?: string, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionsAnswerPost(questionId, answer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {boolean} [isAnswered] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionsGet(pageSize?: number, pageNumber?: number, isAnswered?: boolean, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionsGet(pageSize, pageNumber, isAnswered, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionsIdGet(id: string, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionsApi - axios parameter creator
 * @export
 */
export const SectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка сфер деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение сферы деятельности организации
         * @param {string} id Уникальный идентификатор сферы деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionsIdGet', 'id', id)
            const localVarPath = `/api/Sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение объектов сферы деятельности
         * @param {string} id Уникальный идентификатор сферы деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionsIdObjectsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSectionsIdObjectsGet', 'id', id)
            const localVarPath = `/api/Sections/{id}/objects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionsApi - functional programming interface
 * @export
 */
export const SectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка сфер деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionsGetType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение сферы деятельности организации
         * @param {string} id Уникальный идентификатор сферы деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionsGetType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение объектов сферы деятельности
         * @param {string} id Уникальный идентификатор сферы деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSectionsIdObjectsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObjectDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectionsIdObjectsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionsApi - factory interface
 * @export
 */
export const SectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка сфер деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionsGet(options?: any): AxiosPromise<Array<SectionsGetType>> {
            return localVarFp.apiSectionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение сферы деятельности организации
         * @param {string} id Уникальный идентификатор сферы деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionsIdGet(id: string, options?: any): AxiosPromise<SectionsGetType> {
            return localVarFp.apiSectionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение объектов сферы деятельности
         * @param {string} id Уникальный идентификатор сферы деятельности
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSectionsIdObjectsGet(id: string, options?: any): AxiosPromise<Array<ObjectDTO>> {
            return localVarFp.apiSectionsIdObjectsGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionsApi - interface
 * @export
 * @interface SectionsApi
 */
export interface SectionsApiInterface {
    /**
     * 
     * @summary Получение списка сфер деятельности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApiInterface
     */
    apiSectionsGet(options?: AxiosRequestConfig): AxiosPromise<Array<SectionsGetType>>;

    /**
     * 
     * @summary Получение сферы деятельности организации
     * @param {string} id Уникальный идентификатор сферы деятельности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApiInterface
     */
    apiSectionsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<SectionsGetType>;

    /**
     * 
     * @summary Получение объектов сферы деятельности
     * @param {string} id Уникальный идентификатор сферы деятельности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApiInterface
     */
    apiSectionsIdObjectsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<ObjectDTO>>;

}

/**
 * SectionsApi - object-oriented interface
 * @export
 * @class SectionsApi
 * @extends {BaseAPI}
 */
export class SectionsApi extends BaseAPI implements SectionsApiInterface {
    /**
     * 
     * @summary Получение списка сфер деятельности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public apiSectionsGet(options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).apiSectionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение сферы деятельности организации
     * @param {string} id Уникальный идентификатор сферы деятельности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public apiSectionsIdGet(id: string, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).apiSectionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение объектов сферы деятельности
     * @param {string} id Уникальный идентификатор сферы деятельности
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public apiSectionsIdObjectsGet(id: string, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).apiSectionsIdObjectsGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StepsApi - axios parameter creator
 * @export
 */
export const StepsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение всех шагов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение типов полей шага шаблона заявления
         * @param {string} id Уникальный идентификатор шага шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsIdFieldtypesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStepsIdFieldtypesGet', 'id', id)
            const localVarPath = `/api/Steps/{id}/fieldtypes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получения шага шаблона заявления по id
         * @param {string} id Уникальный идентификатор шага шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStepsIdGet', 'id', id)
            const localVarPath = `/api/Steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение типов шагов шаблонов заявлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsStepTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Steps/stepTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StepsApi - functional programming interface
 * @export
 */
export const StepsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StepsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение всех шагов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStepsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStepsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение типов полей шага шаблона заявления
         * @param {string} id Уникальный идентификатор шага шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStepsIdFieldtypesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldTypeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStepsIdFieldtypesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получения шага шаблона заявления по id
         * @param {string} id Уникальный идентификатор шага шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStepsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStepsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение типов шагов шаблонов заявлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStepsStepTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStepsStepTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StepsApi - factory interface
 * @export
 */
export const StepsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StepsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение всех шагов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsGet(options?: any): AxiosPromise<Array<StepDTO>> {
            return localVarFp.apiStepsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение типов полей шага шаблона заявления
         * @param {string} id Уникальный идентификатор шага шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsIdFieldtypesGet(id: string, options?: any): AxiosPromise<Array<FieldTypeDTO>> {
            return localVarFp.apiStepsIdFieldtypesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получения шага шаблона заявления по id
         * @param {string} id Уникальный идентификатор шага шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsIdGet(id: string, options?: any): AxiosPromise<StepDTO> {
            return localVarFp.apiStepsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение типов шагов шаблонов заявлений
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStepsStepTypesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiStepsStepTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StepsApi - interface
 * @export
 * @interface StepsApi
 */
export interface StepsApiInterface {
    /**
     * 
     * @summary Получение всех шагов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApiInterface
     */
    apiStepsGet(options?: AxiosRequestConfig): AxiosPromise<Array<StepDTO>>;

    /**
     * 
     * @summary Получение типов полей шага шаблона заявления
     * @param {string} id Уникальный идентификатор шага шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApiInterface
     */
    apiStepsIdFieldtypesGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<FieldTypeDTO>>;

    /**
     * 
     * @summary Получения шага шаблона заявления по id
     * @param {string} id Уникальный идентификатор шага шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApiInterface
     */
    apiStepsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<StepDTO>;

    /**
     * 
     * @summary Получение типов шагов шаблонов заявлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApiInterface
     */
    apiStepsStepTypesGet(options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

}

/**
 * StepsApi - object-oriented interface
 * @export
 * @class StepsApi
 * @extends {BaseAPI}
 */
export class StepsApi extends BaseAPI implements StepsApiInterface {
    /**
     * 
     * @summary Получение всех шагов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public apiStepsGet(options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).apiStepsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение типов полей шага шаблона заявления
     * @param {string} id Уникальный идентификатор шага шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public apiStepsIdFieldtypesGet(id: string, options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).apiStepsIdFieldtypesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получения шага шаблона заявления по id
     * @param {string} id Уникальный идентификатор шага шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public apiStepsIdGet(id: string, options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).apiStepsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение типов шагов шаблонов заявлений
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public apiStepsStepTypesGet(options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).apiStepsStepTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubObjectsApi - axios parameter creator
 * @export
 */
export const SubObjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка подкатегорий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubObjectsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SubObjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение подкатегории
         * @param {string} id Уникальный идентификатор подкатегории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubObjectsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSubObjectsIdGet', 'id', id)
            const localVarPath = `/api/SubObjects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubObjectsApi - functional programming interface
 * @export
 */
export const SubObjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubObjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка подкатегорий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubObjectsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubObjectsGetType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubObjectsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение подкатегории
         * @param {string} id Уникальный идентификатор подкатегории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubObjectsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubObjectsGetType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubObjectsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubObjectsApi - factory interface
 * @export
 */
export const SubObjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubObjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка подкатегорий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubObjectsGet(options?: any): AxiosPromise<Array<SubObjectsGetType>> {
            return localVarFp.apiSubObjectsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение подкатегории
         * @param {string} id Уникальный идентификатор подкатегории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubObjectsIdGet(id: string, options?: any): AxiosPromise<SubObjectsGetType> {
            return localVarFp.apiSubObjectsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubObjectsApi - interface
 * @export
 * @interface SubObjectsApi
 */
export interface SubObjectsApiInterface {
    /**
     * 
     * @summary Получение списка подкатегорий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubObjectsApiInterface
     */
    apiSubObjectsGet(options?: AxiosRequestConfig): AxiosPromise<Array<SubObjectsGetType>>;

    /**
     * 
     * @summary Получение подкатегории
     * @param {string} id Уникальный идентификатор подкатегории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubObjectsApiInterface
     */
    apiSubObjectsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<SubObjectsGetType>;

}

/**
 * SubObjectsApi - object-oriented interface
 * @export
 * @class SubObjectsApi
 * @extends {BaseAPI}
 */
export class SubObjectsApi extends BaseAPI implements SubObjectsApiInterface {
    /**
     * 
     * @summary Получение списка подкатегорий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubObjectsApi
     */
    public apiSubObjectsGet(options?: AxiosRequestConfig) {
        return SubObjectsApiFp(this.configuration).apiSubObjectsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение подкатегории
     * @param {string} id Уникальный идентификатор подкатегории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubObjectsApi
     */
    public apiSubObjectsIdGet(id: string, options?: AxiosRequestConfig) {
        return SubObjectsApiFp(this.configuration).apiSubObjectsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViolationAnalyticsApi - axios parameter creator
 * @export
 */
export const ViolationAnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOrganizationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/list/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListRootCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/list/rootCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReceivedViolationDownloadGet: async (beginDate?: string, endDate?: string, categoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/receivedViolation/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['BeginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (categoryIds) {
                localVarQueryParameter['CategoryIds'] = categoryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReceivedViolationGet: async (beginDate?: string, endDate?: string, categoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/receivedViolation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['BeginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (categoryIds) {
                localVarQueryParameter['CategoryIds'] = categoryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [organizationIds] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsViolationWorkDownloadGet: async (organizationIds?: Array<string>, beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/violationWork/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationIds) {
                localVarQueryParameter['OrganizationIds'] = organizationIds;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['BeginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [organizationIds] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsViolationWorkGet: async (organizationIds?: Array<string>, beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reports/violationWork`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationIds) {
                localVarQueryParameter['OrganizationIds'] = organizationIds;
            }

            if (beginDate !== undefined) {
                localVarQueryParameter['BeginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViolationAnalyticsApi - functional programming interface
 * @export
 */
export const ViolationAnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViolationAnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsListOrganizationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AisaeSelectListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsListOrganizationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsListRootCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AisaeSelectListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsListRootCategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReceivedViolationDownloadGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReceivedViolationDownloadGet(beginDate, endDate, categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsReceivedViolationGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsReceivedViolationGet(beginDate, endDate, categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [organizationIds] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsViolationWorkDownloadGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsViolationWorkDownloadGet(organizationIds, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [organizationIds] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportsViolationWorkGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportsViolationWorkGet(organizationIds, beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViolationAnalyticsApi - factory interface
 * @export
 */
export const ViolationAnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViolationAnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListOrganizationsGet(options?: any): AxiosPromise<Array<AisaeSelectListItem>> {
            return localVarFp.apiReportsListOrganizationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsListRootCategoriesGet(options?: any): AxiosPromise<Array<AisaeSelectListItem>> {
            return localVarFp.apiReportsListRootCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReceivedViolationDownloadGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiReportsReceivedViolationDownloadGet(beginDate, endDate, categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {Array<string>} [categoryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsReceivedViolationGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiReportsReceivedViolationGet(beginDate, endDate, categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [organizationIds] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsViolationWorkDownloadGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiReportsViolationWorkDownloadGet(organizationIds, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [organizationIds] 
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportsViolationWorkGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiReportsViolationWorkGet(organizationIds, beginDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViolationAnalyticsApi - interface
 * @export
 * @interface ViolationAnalyticsApi
 */
export interface ViolationAnalyticsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApiInterface
     */
    apiReportsListOrganizationsGet(options?: AxiosRequestConfig): AxiosPromise<Array<AisaeSelectListItem>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApiInterface
     */
    apiReportsListRootCategoriesGet(options?: AxiosRequestConfig): AxiosPromise<Array<AisaeSelectListItem>>;

    /**
     * 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [categoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApiInterface
     */
    apiReportsReceivedViolationDownloadGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [categoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApiInterface
     */
    apiReportsReceivedViolationGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<string>} [organizationIds] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApiInterface
     */
    apiReportsViolationWorkDownloadGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Array<string>} [organizationIds] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApiInterface
     */
    apiReportsViolationWorkGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ViolationAnalyticsApi - object-oriented interface
 * @export
 * @class ViolationAnalyticsApi
 * @extends {BaseAPI}
 */
export class ViolationAnalyticsApi extends BaseAPI implements ViolationAnalyticsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApi
     */
    public apiReportsListOrganizationsGet(options?: AxiosRequestConfig) {
        return ViolationAnalyticsApiFp(this.configuration).apiReportsListOrganizationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApi
     */
    public apiReportsListRootCategoriesGet(options?: AxiosRequestConfig) {
        return ViolationAnalyticsApiFp(this.configuration).apiReportsListRootCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [categoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApi
     */
    public apiReportsReceivedViolationDownloadGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return ViolationAnalyticsApiFp(this.configuration).apiReportsReceivedViolationDownloadGet(beginDate, endDate, categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {Array<string>} [categoryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApi
     */
    public apiReportsReceivedViolationGet(beginDate?: string, endDate?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return ViolationAnalyticsApiFp(this.configuration).apiReportsReceivedViolationGet(beginDate, endDate, categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [organizationIds] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApi
     */
    public apiReportsViolationWorkDownloadGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return ViolationAnalyticsApiFp(this.configuration).apiReportsViolationWorkDownloadGet(organizationIds, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [organizationIds] 
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationAnalyticsApi
     */
    public apiReportsViolationWorkGet(organizationIds?: Array<string>, beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return ViolationAnalyticsApiFp(this.configuration).apiReportsViolationWorkGet(organizationIds, beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViolationExecutionApi - axios parameter creator
 * @export
 */
export const ViolationExecutionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получения истории изменения заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionHistoryGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/violationExecution/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отметка о просмотре заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionIdCheckPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationExecutionIdCheckPost', 'id', id)
            const localVarPath = `/api/violationExecution/{id}/check`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationExecutionIdGet', 'id', id)
            const localVarPath = `/api/violationExecution/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Закрытие шага
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<StepFieldExecutionForm>} [fields] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [pastPhotos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionStepExecutePost: async (violationId?: string, violationTemplateStepId?: string, fields?: Array<StepFieldExecutionForm>, files?: Array<any>, pastPhotos?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/violationExecution/step/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (violationId !== undefined) { 
                localVarFormParams.append('ViolationId', violationId as any);
            }
    
            if (violationTemplateStepId !== undefined) { 
                localVarFormParams.append('ViolationTemplateStepId', violationTemplateStepId as any);
            }
                if (fields) {
                localVarFormParams.append('Fields', fields.join(COLLECTION_FORMATS.csv));
            }

                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

                if (pastPhotos) {
                localVarFormParams.append('PastPhotos', pastPhotos.join(COLLECTION_FORMATS.csv));
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [number] 
         * @param {Array<number>} [violationStatuses] 
         * @param {boolean} [isPublished] 
         * @param {string} [district] 
         * @param {boolean} [orderByDescending] 
         * @param {string} [categoryId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionViolationsGet: async (number?: number, violationStatuses?: Array<number>, isPublished?: boolean, district?: string, orderByDescending?: boolean, categoryId?: string, pageSize?: number, pageNumber?: number, organizationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/violationExecution/violations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (number !== undefined) {
                localVarQueryParameter['Number'] = number;
            }

            if (violationStatuses) {
                localVarQueryParameter['ViolationStatuses'] = violationStatuses;
            }

            if (isPublished !== undefined) {
                localVarQueryParameter['IsPublished'] = isPublished;
            }

            if (district !== undefined) {
                localVarQueryParameter['District'] = district;
            }

            if (orderByDescending !== undefined) {
                localVarQueryParameter['OrderByDescending'] = orderByDescending;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['CategoryId'] = categoryId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['OrganizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionViolationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationExecutionViolationsIdGet', 'id', id)
            const localVarPath = `/api/violationExecution/violations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение шагов заявлений по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionViolationsIdStepsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationExecutionViolationsIdStepsGet', 'id', id)
            const localVarPath = `/api/violationExecution/violations/{id}/steps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViolationExecutionApi - functional programming interface
 * @export
 */
export const ViolationExecutionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViolationExecutionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получения истории изменения заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationExecutionHistoryGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationHistoryDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationExecutionHistoryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отметка о просмотре заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationExecutionIdCheckPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationExecutionIdCheckPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationExecutionIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationExecutionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Закрытие шага
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<StepFieldExecutionForm>} [fields] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [pastPhotos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationExecutionStepExecutePost(violationId?: string, violationTemplateStepId?: string, fields?: Array<StepFieldExecutionForm>, files?: Array<any>, pastPhotos?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationExecutionStepExecutePost(violationId, violationTemplateStepId, fields, files, pastPhotos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [number] 
         * @param {Array<number>} [violationStatuses] 
         * @param {boolean} [isPublished] 
         * @param {string} [district] 
         * @param {boolean} [orderByDescending] 
         * @param {string} [categoryId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationExecutionViolationsGet(number?: number, violationStatuses?: Array<number>, isPublished?: boolean, district?: string, orderByDescending?: boolean, categoryId?: string, pageSize?: number, pageNumber?: number, organizationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationExecutionViolationsGet(number, violationStatuses, isPublished, district, orderByDescending, categoryId, pageSize, pageNumber, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationExecutionViolationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationExecutionViolationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение шагов заявлений по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationExecutionViolationsIdStepsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationItemStepModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationExecutionViolationsIdStepsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViolationExecutionApi - factory interface
 * @export
 */
export const ViolationExecutionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViolationExecutionApiFp(configuration)
    return {
        /**
         * 
         * @summary Получения истории изменения заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionHistoryGet(id?: string, options?: any): AxiosPromise<Array<ViolationHistoryDTO>> {
            return localVarFp.apiViolationExecutionHistoryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отметка о просмотре заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionIdCheckPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationExecutionIdCheckPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationExecutionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Закрытие шага
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<StepFieldExecutionForm>} [fields] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [pastPhotos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionStepExecutePost(violationId?: string, violationTemplateStepId?: string, fields?: Array<StepFieldExecutionForm>, files?: Array<any>, pastPhotos?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationExecutionStepExecutePost(violationId, violationTemplateStepId, fields, files, pastPhotos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [number] 
         * @param {Array<number>} [violationStatuses] 
         * @param {boolean} [isPublished] 
         * @param {string} [district] 
         * @param {boolean} [orderByDescending] 
         * @param {string} [categoryId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionViolationsGet(number?: number, violationStatuses?: Array<number>, isPublished?: boolean, district?: string, orderByDescending?: boolean, categoryId?: string, pageSize?: number, pageNumber?: number, organizationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationExecutionViolationsGet(number, violationStatuses, isPublished, district, orderByDescending, categoryId, pageSize, pageNumber, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionViolationsIdGet(id: string, options?: any): AxiosPromise<ViolationItemModel> {
            return localVarFp.apiViolationExecutionViolationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение шагов заявлений по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationExecutionViolationsIdStepsGet(id: string, options?: any): AxiosPromise<Array<ViolationItemStepModel>> {
            return localVarFp.apiViolationExecutionViolationsIdStepsGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViolationExecutionApi - interface
 * @export
 * @interface ViolationExecutionApi
 */
export interface ViolationExecutionApiInterface {
    /**
     * 
     * @summary Получения истории изменения заявления
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApiInterface
     */
    apiViolationExecutionHistoryGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationHistoryDTO>>;

    /**
     * 
     * @summary Отметка о просмотре заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApiInterface
     */
    apiViolationExecutionIdCheckPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApiInterface
     */
    apiViolationExecutionIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Закрытие шага
     * @param {string} [violationId] 
     * @param {string} [violationTemplateStepId] 
     * @param {Array<StepFieldExecutionForm>} [fields] 
     * @param {Array<any>} [files] 
     * @param {Array<string>} [pastPhotos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApiInterface
     */
    apiViolationExecutionStepExecutePost(violationId?: string, violationTemplateStepId?: string, fields?: Array<StepFieldExecutionForm>, files?: Array<any>, pastPhotos?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [number] 
     * @param {Array<number>} [violationStatuses] 
     * @param {boolean} [isPublished] 
     * @param {string} [district] 
     * @param {boolean} [orderByDescending] 
     * @param {string} [categoryId] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApiInterface
     */
    apiViolationExecutionViolationsGet(number?: number, violationStatuses?: Array<number>, isPublished?: boolean, district?: string, orderByDescending?: boolean, categoryId?: string, pageSize?: number, pageNumber?: number, organizationId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApiInterface
     */
    apiViolationExecutionViolationsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ViolationItemModel>;

    /**
     * 
     * @summary Получение шагов заявлений по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApiInterface
     */
    apiViolationExecutionViolationsIdStepsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationItemStepModel>>;

}

/**
 * ViolationExecutionApi - object-oriented interface
 * @export
 * @class ViolationExecutionApi
 * @extends {BaseAPI}
 */
export class ViolationExecutionApi extends BaseAPI implements ViolationExecutionApiInterface {
    /**
     * 
     * @summary Получения истории изменения заявления
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApi
     */
    public apiViolationExecutionHistoryGet(id?: string, options?: AxiosRequestConfig) {
        return ViolationExecutionApiFp(this.configuration).apiViolationExecutionHistoryGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отметка о просмотре заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApi
     */
    public apiViolationExecutionIdCheckPost(id: string, options?: AxiosRequestConfig) {
        return ViolationExecutionApiFp(this.configuration).apiViolationExecutionIdCheckPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApi
     */
    public apiViolationExecutionIdGet(id: string, options?: AxiosRequestConfig) {
        return ViolationExecutionApiFp(this.configuration).apiViolationExecutionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Закрытие шага
     * @param {string} [violationId] 
     * @param {string} [violationTemplateStepId] 
     * @param {Array<StepFieldExecutionForm>} [fields] 
     * @param {Array<any>} [files] 
     * @param {Array<string>} [pastPhotos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApi
     */
    public apiViolationExecutionStepExecutePost(violationId?: string, violationTemplateStepId?: string, fields?: Array<StepFieldExecutionForm>, files?: Array<any>, pastPhotos?: Array<string>, options?: AxiosRequestConfig) {
        return ViolationExecutionApiFp(this.configuration).apiViolationExecutionStepExecutePost(violationId, violationTemplateStepId, fields, files, pastPhotos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [number] 
     * @param {Array<number>} [violationStatuses] 
     * @param {boolean} [isPublished] 
     * @param {string} [district] 
     * @param {boolean} [orderByDescending] 
     * @param {string} [categoryId] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApi
     */
    public apiViolationExecutionViolationsGet(number?: number, violationStatuses?: Array<number>, isPublished?: boolean, district?: string, orderByDescending?: boolean, categoryId?: string, pageSize?: number, pageNumber?: number, organizationId?: string, options?: AxiosRequestConfig) {
        return ViolationExecutionApiFp(this.configuration).apiViolationExecutionViolationsGet(number, violationStatuses, isPublished, district, orderByDescending, categoryId, pageSize, pageNumber, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApi
     */
    public apiViolationExecutionViolationsIdGet(id: string, options?: AxiosRequestConfig) {
        return ViolationExecutionApiFp(this.configuration).apiViolationExecutionViolationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение шагов заявлений по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationExecutionApi
     */
    public apiViolationExecutionViolationsIdStepsGet(id: string, options?: AxiosRequestConfig) {
        return ViolationExecutionApiFp(this.configuration).apiViolationExecutionViolationsIdStepsGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViolationNumberApi - axios parameter creator
 * @export
 */
export const ViolationNumberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение номера для заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationNumberGetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ViolationNumber/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViolationNumberApi - functional programming interface
 * @export
 */
export const ViolationNumberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViolationNumberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение номера для заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationNumberGetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationNumberGetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViolationNumberApi - factory interface
 * @export
 */
export const ViolationNumberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViolationNumberApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение номера для заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationNumberGetGet(options?: any): AxiosPromise<number> {
            return localVarFp.apiViolationNumberGetGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViolationNumberApi - interface
 * @export
 * @interface ViolationNumberApi
 */
export interface ViolationNumberApiInterface {
    /**
     * 
     * @summary Получение номера для заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationNumberApiInterface
     */
    apiViolationNumberGetGet(options?: AxiosRequestConfig): AxiosPromise<number>;

}

/**
 * ViolationNumberApi - object-oriented interface
 * @export
 * @class ViolationNumberApi
 * @extends {BaseAPI}
 */
export class ViolationNumberApi extends BaseAPI implements ViolationNumberApiInterface {
    /**
     * 
     * @summary Получение номера для заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationNumberApi
     */
    public apiViolationNumberGetGet(options?: AxiosRequestConfig) {
        return ViolationNumberApiFp(this.configuration).apiViolationNumberGetGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViolationTemplatesApi - axios parameter creator
 * @export
 */
export const ViolationTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение дочерних элементов категории
         * @param {string} [categoryId] Идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesChildrenGet: async (categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ViolationTemplates/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание шаблона заявления
         * @param {RegisterViolationTemplateForm} [registerViolationTemplateForm] Форма шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesCreatePost: async (registerViolationTemplateForm?: RegisterViolationTemplateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ViolationTemplates/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerViolationTemplateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка шаблонов заявлений постранично
         * @param {number} [pagenumber] Номер страницы
         * @param {number} [pagesize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesGet: async (pagenumber?: number, pagesize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ViolationTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pagenumber !== undefined) {
                localVarQueryParameter['pagenumber'] = pagenumber;
            }

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление шаблона заявления
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdDeletePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationTemplatesIdDeletePost', 'id', id)
            const localVarPath = `/api/ViolationTemplates/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование шаблона заявления
         * @param {string} id Уникальный идентификатор шаблона заявления
         * @param {EditViolationTemplateForm} [editViolationTemplateForm] Форма шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdEditPost: async (id: string, editViolationTemplateForm?: EditViolationTemplateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationTemplatesIdEditPost', 'id', id)
            const localVarPath = `/api/ViolationTemplates/{id}/edit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editViolationTemplateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение шаблона заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationTemplatesIdGet', 'id', id)
            const localVarPath = `/api/ViolationTemplates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение шагов заявления
         * @param {string} id Уникальный идентификатор шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdStepsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationTemplatesIdStepsGet', 'id', id)
            const localVarPath = `/api/ViolationTemplates/{id}/steps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение подкатегорий
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesSubcategoriesGet: async (categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ViolationTemplates/subcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViolationTemplatesApi - functional programming interface
 * @export
 */
export const ViolationTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViolationTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение дочерних элементов категории
         * @param {string} [categoryId] Идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesChildrenGet(categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationTemplateCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesChildrenGet(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание шаблона заявления
         * @param {RegisterViolationTemplateForm} [registerViolationTemplateForm] Форма шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesCreatePost(registerViolationTemplateForm?: RegisterViolationTemplateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesCreatePost(registerViolationTemplateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка шаблонов заявлений постранично
         * @param {number} [pagenumber] Номер страницы
         * @param {number} [pagesize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesGet(pagenumber?: number, pagesize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationTemplatePagesDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesGet(pagenumber, pagesize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление шаблона заявления
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesIdDeletePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesIdDeletePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование шаблона заявления
         * @param {string} id Уникальный идентификатор шаблона заявления
         * @param {EditViolationTemplateForm} [editViolationTemplateForm] Форма шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesIdEditPost(id: string, editViolationTemplateForm?: EditViolationTemplateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesIdEditPost(id, editViolationTemplateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение шаблона заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationTemplateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение шагов заявления
         * @param {string} id Уникальный идентификатор шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesIdStepsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationTemplateStepDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesIdStepsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение подкатегорий
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationTemplatesSubcategoriesGet(categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObjectDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationTemplatesSubcategoriesGet(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViolationTemplatesApi - factory interface
 * @export
 */
export const ViolationTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViolationTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение дочерних элементов категории
         * @param {string} [categoryId] Идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesChildrenGet(categoryId?: string, options?: any): AxiosPromise<Array<ViolationTemplateCategory>> {
            return localVarFp.apiViolationTemplatesChildrenGet(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание шаблона заявления
         * @param {RegisterViolationTemplateForm} [registerViolationTemplateForm] Форма шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesCreatePost(registerViolationTemplateForm?: RegisterViolationTemplateForm, options?: any): AxiosPromise<ViolationTemplateDTO> {
            return localVarFp.apiViolationTemplatesCreatePost(registerViolationTemplateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка шаблонов заявлений постранично
         * @param {number} [pagenumber] Номер страницы
         * @param {number} [pagesize] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesGet(pagenumber?: number, pagesize?: number, options?: any): AxiosPromise<ViolationTemplatePagesDTO> {
            return localVarFp.apiViolationTemplatesGet(pagenumber, pagesize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление шаблона заявления
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdDeletePost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationTemplatesIdDeletePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование шаблона заявления
         * @param {string} id Уникальный идентификатор шаблона заявления
         * @param {EditViolationTemplateForm} [editViolationTemplateForm] Форма шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdEditPost(id: string, editViolationTemplateForm?: EditViolationTemplateForm, options?: any): AxiosPromise<ViolationTemplateDTO> {
            return localVarFp.apiViolationTemplatesIdEditPost(id, editViolationTemplateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение шаблона заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdGet(id: string, options?: any): AxiosPromise<ViolationTemplateDTO> {
            return localVarFp.apiViolationTemplatesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение шагов заявления
         * @param {string} id Уникальный идентификатор шаблона заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesIdStepsGet(id: string, options?: any): AxiosPromise<Array<ViolationTemplateStepDTO>> {
            return localVarFp.apiViolationTemplatesIdStepsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение подкатегорий
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationTemplatesSubcategoriesGet(categoryId?: string, options?: any): AxiosPromise<Array<ObjectDTO>> {
            return localVarFp.apiViolationTemplatesSubcategoriesGet(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViolationTemplatesApi - interface
 * @export
 * @interface ViolationTemplatesApi
 */
export interface ViolationTemplatesApiInterface {
    /**
     * 
     * @summary Получение дочерних элементов категории
     * @param {string} [categoryId] Идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesChildrenGet(categoryId?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationTemplateCategory>>;

    /**
     * 
     * @summary Создание шаблона заявления
     * @param {RegisterViolationTemplateForm} [registerViolationTemplateForm] Форма шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesCreatePost(registerViolationTemplateForm?: RegisterViolationTemplateForm, options?: AxiosRequestConfig): AxiosPromise<ViolationTemplateDTO>;

    /**
     * 
     * @summary Получение списка шаблонов заявлений постранично
     * @param {number} [pagenumber] Номер страницы
     * @param {number} [pagesize] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesGet(pagenumber?: number, pagesize?: number, options?: AxiosRequestConfig): AxiosPromise<ViolationTemplatePagesDTO>;

    /**
     * 
     * @summary Удаление шаблона заявления
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesIdDeletePost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование шаблона заявления
     * @param {string} id Уникальный идентификатор шаблона заявления
     * @param {EditViolationTemplateForm} [editViolationTemplateForm] Форма шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesIdEditPost(id: string, editViolationTemplateForm?: EditViolationTemplateForm, options?: AxiosRequestConfig): AxiosPromise<ViolationTemplateDTO>;

    /**
     * 
     * @summary Получение шаблона заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ViolationTemplateDTO>;

    /**
     * 
     * @summary Получение шагов заявления
     * @param {string} id Уникальный идентификатор шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesIdStepsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationTemplateStepDTO>>;

    /**
     * 
     * @summary Получение подкатегорий
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApiInterface
     */
    apiViolationTemplatesSubcategoriesGet(categoryId?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ObjectDTO>>;

}

/**
 * ViolationTemplatesApi - object-oriented interface
 * @export
 * @class ViolationTemplatesApi
 * @extends {BaseAPI}
 */
export class ViolationTemplatesApi extends BaseAPI implements ViolationTemplatesApiInterface {
    /**
     * 
     * @summary Получение дочерних элементов категории
     * @param {string} [categoryId] Идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesChildrenGet(categoryId?: string, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesChildrenGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание шаблона заявления
     * @param {RegisterViolationTemplateForm} [registerViolationTemplateForm] Форма шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesCreatePost(registerViolationTemplateForm?: RegisterViolationTemplateForm, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesCreatePost(registerViolationTemplateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка шаблонов заявлений постранично
     * @param {number} [pagenumber] Номер страницы
     * @param {number} [pagesize] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesGet(pagenumber?: number, pagesize?: number, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesGet(pagenumber, pagesize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление шаблона заявления
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesIdDeletePost(id: string, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesIdDeletePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование шаблона заявления
     * @param {string} id Уникальный идентификатор шаблона заявления
     * @param {EditViolationTemplateForm} [editViolationTemplateForm] Форма шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesIdEditPost(id: string, editViolationTemplateForm?: EditViolationTemplateForm, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesIdEditPost(id, editViolationTemplateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение шаблона заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesIdGet(id: string, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение шагов заявления
     * @param {string} id Уникальный идентификатор шаблона заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesIdStepsGet(id: string, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesIdStepsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение подкатегорий
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationTemplatesApi
     */
    public apiViolationTemplatesSubcategoriesGet(categoryId?: string, options?: AxiosRequestConfig) {
        return ViolationTemplatesApiFp(this.configuration).apiViolationTemplatesSubcategoriesGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViolationsApi - axios parameter creator
 * @export
 */
export const ViolationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Отправка заявления на дополнительный контроль
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsAdditionalControlPost: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/additionalControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Закрытие нарушения
         * @param {ClosingViolation} [closingViolation] Форма закрытия нарушения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsClosePost: async (closingViolation?: ClosingViolation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(closingViolation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание нового заявления
         * @param {string} [categoryId] 
         * @param {Array<string>} [subCategoryIds] 
         * @param {string} [address] 
         * @param {number} [coordinatesLatitude] 
         * @param {number} [coordinatesLongitude] 
         * @param {Array<any>} [photos] 
         * @param {Array<any>} [videos] 
         * @param {Array<any>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsCreatePost: async (categoryId?: string, subCategoryIds?: Array<string>, address?: string, coordinatesLatitude?: number, coordinatesLongitude?: number, photos?: Array<any>, videos?: Array<any>, documents?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (categoryId !== undefined) { 
                localVarFormParams.append('CategoryId', categoryId as any);
            }
                if (subCategoryIds) {
                localVarFormParams.append('SubCategoryIds', subCategoryIds.join(COLLECTION_FORMATS.csv));
            }

    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (coordinatesLatitude !== undefined) { 
                localVarFormParams.append('Coordinates.Latitude', coordinatesLatitude as any);
            }
    
            if (coordinatesLongitude !== undefined) { 
                localVarFormParams.append('Coordinates.Longitude', coordinatesLongitude as any);
            }
                if (photos) {
                photos.forEach((element) => {
                    localVarFormParams.append('Photos', element as any);
                })
            }

                if (videos) {
                videos.forEach((element) => {
                    localVarFormParams.append('Videos', element as any);
                })
            }

                if (documents) {
                documents.forEach((element) => {
                    localVarFormParams.append('Documents', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataEntry} [dataEntry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsDataentryPost: async (dataEntry?: DataEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/dataentry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к заявлению
         * @param {string} [fileId] Уникальный идентивикатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFileGet: async (fileId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Данные параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFilterdataGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/filterdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка заявлений с заданными параметрами
         * @param {boolean} [orderByDescending] Параметр сортировки
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFilteredlistPost: async (orderByDescending?: boolean, pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/filteredlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderByDescending !== undefined) {
                localVarQueryParameter['orderByDescending'] = orderByDescending;
            }

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }

            if (pagenumber !== undefined) {
                localVarQueryParameter['pagenumber'] = pagenumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterViolationForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получения истории изменения заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsHistoryGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение статуса заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {string} [changer] Статус
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdChangestatusPost: async (id: string, changer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdChangestatusPost', 'id', id)
            const localVarPath = `/api/Violations/{id}/changestatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (changer !== undefined) {
                localVarQueryParameter['changer'] = changer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отметка о просмотре заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdCheckPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdCheckPost', 'id', id)
            const localVarPath = `/api/Violations/{id}/check`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление заяваления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdDelete', 'id', id)
            const localVarPath = `/api/Violations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdGet', 'id', id)
            const localVarPath = `/api/Violations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение изображеня, прикрепленного к заявлению
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdPhotosGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdPhotosGet', 'id', id)
            const localVarPath = `/api/Violations/{id}/photos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Публикация заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdPublishPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdPublishPost', 'id', id)
            const localVarPath = `/api/Violations/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отклонение заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {string} [comment] Комментарий к заявлению
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdRejectPost: async (id: string, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdRejectPost', 'id', id)
            const localVarPath = `/api/Violations/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsMapfilteredlistPost: async (pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/mapfilteredlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }

            if (pagenumber !== undefined) {
                localVarQueryParameter['pagenumber'] = pagenumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterViolationForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение количества новых заявлений по статусу
         * @param {number} [status] Статус заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsNewGet: async (status?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файлов для результатов заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsResultsFileGet: async (fileId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/results/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получения результатов заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsResultsGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка фотографий к заявлению
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<any>} [photos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsUploadphotosPost: async (violationId?: string, violationTemplateStepId?: string, photos?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/uploadphotos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (violationId !== undefined) { 
                localVarFormParams.append('ViolationId', violationId as any);
            }
    
            if (violationTemplateStepId !== undefined) { 
                localVarFormParams.append('ViolationTemplateStepId', violationTemplateStepId as any);
            }
                if (photos) {
                photos.forEach((element) => {
                    localVarFormParams.append('Photos', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsV2MapfilteredlistPost: async (pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/v2/mapfilteredlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pagesize !== undefined) {
                localVarQueryParameter['pagesize'] = pagesize;
            }

            if (pagenumber !== undefined) {
                localVarQueryParameter['pagenumber'] = pagenumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterViolationForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<string>} [was] 
         * @param {Array<any>} [became] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsWasbecamePost: async (violationId?: string, violationTemplateStepId?: string, was?: Array<string>, became?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/wasbecame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (violationId !== undefined) { 
                localVarFormParams.append('ViolationId', violationId as any);
            }
    
            if (violationTemplateStepId !== undefined) { 
                localVarFormParams.append('ViolationTemplateStepId', violationTemplateStepId as any);
            }
                if (was) {
                localVarFormParams.append('Was', was.join(COLLECTION_FORMATS.csv));
            }

                if (became) {
                became.forEach((element) => {
                    localVarFormParams.append('Became', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViolationsApi - functional programming interface
 * @export
 */
export const ViolationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViolationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Отправка заявления на дополнительный контроль
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsAdditionalControlPost(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsAdditionalControlPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Закрытие нарушения
         * @param {ClosingViolation} [closingViolation] Форма закрытия нарушения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsClosePost(closingViolation?: ClosingViolation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsClosePost(closingViolation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание нового заявления
         * @param {string} [categoryId] 
         * @param {Array<string>} [subCategoryIds] 
         * @param {string} [address] 
         * @param {number} [coordinatesLatitude] 
         * @param {number} [coordinatesLongitude] 
         * @param {Array<any>} [photos] 
         * @param {Array<any>} [videos] 
         * @param {Array<any>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, coordinatesLatitude?: number, coordinatesLongitude?: number, photos?: Array<any>, videos?: Array<any>, documents?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsCreatePost(categoryId, subCategoryIds, address, coordinatesLatitude, coordinatesLongitude, photos, videos, documents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataEntry} [dataEntry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsDataentryPost(dataEntry?: DataEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsDataentryPost(dataEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к заявлению
         * @param {string} [fileId] Уникальный идентивикатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsFileGet(fileId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsFileGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Данные параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsFilterdataGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterViolationDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsFilterdataGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка заявлений с заданными параметрами
         * @param {boolean} [orderByDescending] Параметр сортировки
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsFilteredlistPost(orderByDescending?: boolean, pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsFilteredlistPost(orderByDescending, pagesize, pagenumber, filterViolationForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получения истории изменения заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsHistoryGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationHistoryDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsHistoryGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменение статуса заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {string} [changer] Статус
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdChangestatusPost(id: string, changer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdChangestatusPost(id, changer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отметка о просмотре заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdCheckPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdCheckPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление заяваления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение изображеня, прикрепленного к заявлению
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdPhotosGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdPhotosGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Публикация заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdPublishPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdPublishPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отклонение заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {string} [comment] Комментарий к заявлению
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdRejectPost(id: string, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdRejectPost(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsMapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapFilteredViolationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsMapfilteredlistPost(pagesize, pagenumber, filterViolationForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение количества новых заявлений по статусу
         * @param {number} [status] Статус заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsNewGet(status?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsNewGet(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файлов для результатов заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsResultsFileGet(fileId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamFileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsResultsFileGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получения результатов заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsResultsGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsResultsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка фотографий к заявлению
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<any>} [photos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsUploadphotosPost(violationId?: string, violationTemplateStepId?: string, photos?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsUploadphotosPost(violationId, violationTemplateStepId, photos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsV2MapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapFilteredViolationModelList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsV2MapfilteredlistPost(pagesize, pagenumber, filterViolationForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<string>} [was] 
         * @param {Array<any>} [became] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsWasbecamePost(violationId?: string, violationTemplateStepId?: string, was?: Array<string>, became?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsWasbecamePost(violationId, violationTemplateStepId, was, became, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViolationsApi - factory interface
 * @export
 */
export const ViolationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViolationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Отправка заявления на дополнительный контроль
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsAdditionalControlPost(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsAdditionalControlPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Закрытие нарушения
         * @param {ClosingViolation} [closingViolation] Форма закрытия нарушения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsClosePost(closingViolation?: ClosingViolation, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsClosePost(closingViolation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание нового заявления
         * @param {string} [categoryId] 
         * @param {Array<string>} [subCategoryIds] 
         * @param {string} [address] 
         * @param {number} [coordinatesLatitude] 
         * @param {number} [coordinatesLongitude] 
         * @param {Array<any>} [photos] 
         * @param {Array<any>} [videos] 
         * @param {Array<any>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, coordinatesLatitude?: number, coordinatesLongitude?: number, photos?: Array<any>, videos?: Array<any>, documents?: Array<any>, options?: any): AxiosPromise<ViolationDTO> {
            return localVarFp.apiViolationsCreatePost(categoryId, subCategoryIds, address, coordinatesLatitude, coordinatesLongitude, photos, videos, documents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataEntry} [dataEntry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsDataentryPost(dataEntry?: DataEntry, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsDataentryPost(dataEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к заявлению
         * @param {string} [fileId] Уникальный идентивикатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFileGet(fileId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsFileGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Данные параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFilterdataGet(options?: any): AxiosPromise<FilterViolationDataDTO> {
            return localVarFp.apiViolationsFilterdataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка заявлений с заданными параметрами
         * @param {boolean} [orderByDescending] Параметр сортировки
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFilteredlistPost(orderByDescending?: boolean, pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsFilteredlistPost(orderByDescending, pagesize, pagenumber, filterViolationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получения истории изменения заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsHistoryGet(id?: string, options?: any): AxiosPromise<Array<ViolationHistoryDTO>> {
            return localVarFp.apiViolationsHistoryGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение статуса заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {string} [changer] Статус
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdChangestatusPost(id: string, changer?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsIdChangestatusPost(id, changer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отметка о просмотре заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdCheckPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsIdCheckPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление заяваления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdGet(id: string, options?: any): AxiosPromise<ViolationDTO> {
            return localVarFp.apiViolationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение изображеня, прикрепленного к заявлению
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdPhotosGet(id: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiViolationsIdPhotosGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Публикация заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdPublishPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsIdPublishPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отклонение заявления
         * @param {string} id Уникальный идентификатор заявления
         * @param {string} [comment] Комментарий к заявлению
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdRejectPost(id: string, comment?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsIdRejectPost(id, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsMapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: any): AxiosPromise<MapFilteredViolationModel> {
            return localVarFp.apiViolationsMapfilteredlistPost(pagesize, pagenumber, filterViolationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение количества новых заявлений по статусу
         * @param {number} [status] Статус заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsNewGet(status?: number, options?: any): AxiosPromise<number> {
            return localVarFp.apiViolationsNewGet(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файлов для результатов заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsResultsFileGet(fileId?: string, options?: any): AxiosPromise<StreamFileModel> {
            return localVarFp.apiViolationsResultsFileGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получения результатов заявления
         * @param {string} [id] Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsResultsGet(id?: string, options?: any): AxiosPromise<ViolationResultDTO> {
            return localVarFp.apiViolationsResultsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка фотографий к заявлению
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<any>} [photos] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsUploadphotosPost(violationId?: string, violationTemplateStepId?: string, photos?: Array<any>, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsUploadphotosPost(violationId, violationTemplateStepId, photos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
         * @param {number} [pagesize] Количество элементов на странице
         * @param {number} [pagenumber] Номер страницы
         * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsV2MapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: any): AxiosPromise<MapFilteredViolationModelList> {
            return localVarFp.apiViolationsV2MapfilteredlistPost(pagesize, pagenumber, filterViolationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [violationId] 
         * @param {string} [violationTemplateStepId] 
         * @param {Array<string>} [was] 
         * @param {Array<any>} [became] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsWasbecamePost(violationId?: string, violationTemplateStepId?: string, was?: Array<string>, became?: Array<any>, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsWasbecamePost(violationId, violationTemplateStepId, was, became, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViolationsApi - interface
 * @export
 * @interface ViolationsApi
 */
export interface ViolationsApiInterface {
    /**
     * 
     * @summary Отправка заявления на дополнительный контроль
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsAdditionalControlPost(id?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Закрытие нарушения
     * @param {ClosingViolation} [closingViolation] Форма закрытия нарушения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsClosePost(closingViolation?: ClosingViolation, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Создание нового заявления
     * @param {string} [categoryId] 
     * @param {Array<string>} [subCategoryIds] 
     * @param {string} [address] 
     * @param {number} [coordinatesLatitude] 
     * @param {number} [coordinatesLongitude] 
     * @param {Array<any>} [photos] 
     * @param {Array<any>} [videos] 
     * @param {Array<any>} [documents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, coordinatesLatitude?: number, coordinatesLongitude?: number, photos?: Array<any>, videos?: Array<any>, documents?: Array<any>, options?: AxiosRequestConfig): AxiosPromise<ViolationDTO>;

    /**
     * 
     * @param {DataEntry} [dataEntry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsDataentryPost(dataEntry?: DataEntry, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение файла, прикрепленного к заявлению
     * @param {string} [fileId] Уникальный идентивикатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsFileGet(fileId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Данные параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsFilterdataGet(options?: AxiosRequestConfig): AxiosPromise<FilterViolationDataDTO>;

    /**
     * 
     * @summary Получение списка заявлений с заданными параметрами
     * @param {boolean} [orderByDescending] Параметр сортировки
     * @param {number} [pagesize] Количество элементов на странице
     * @param {number} [pagenumber] Номер страницы
     * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsFilteredlistPost(orderByDescending?: boolean, pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получения истории изменения заявления
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsHistoryGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationHistoryDTO>>;

    /**
     * 
     * @summary Изменение статуса заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {string} [changer] Статус
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdChangestatusPost(id: string, changer?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Отметка о просмотре заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdCheckPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Удаление заяваления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ViolationDTO>;

    /**
     * 
     * @summary Получение изображеня, прикрепленного к заявлению
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdPhotosGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @summary Публикация заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdPublishPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Отклонение заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {string} [comment] Комментарий к заявлению
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdRejectPost(id: string, comment?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
     * @param {number} [pagesize] Количество элементов на странице
     * @param {number} [pagenumber] Номер страницы
     * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsMapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig): AxiosPromise<MapFilteredViolationModel>;

    /**
     * 
     * @summary Получение количества новых заявлений по статусу
     * @param {number} [status] Статус заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsNewGet(status?: number, options?: AxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Получение файлов для результатов заявления
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsResultsFileGet(fileId?: string, options?: AxiosRequestConfig): AxiosPromise<StreamFileModel>;

    /**
     * 
     * @summary Получения результатов заявления
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsResultsGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<ViolationResultDTO>;

    /**
     * 
     * @summary Загрузка фотографий к заявлению
     * @param {string} [violationId] 
     * @param {string} [violationTemplateStepId] 
     * @param {Array<any>} [photos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsUploadphotosPost(violationId?: string, violationTemplateStepId?: string, photos?: Array<any>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
     * @param {number} [pagesize] Количество элементов на странице
     * @param {number} [pagenumber] Номер страницы
     * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsV2MapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig): AxiosPromise<MapFilteredViolationModelList>;

    /**
     * 
     * @param {string} [violationId] 
     * @param {string} [violationTemplateStepId] 
     * @param {Array<string>} [was] 
     * @param {Array<any>} [became] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsWasbecamePost(violationId?: string, violationTemplateStepId?: string, was?: Array<string>, became?: Array<any>, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ViolationsApi - object-oriented interface
 * @export
 * @class ViolationsApi
 * @extends {BaseAPI}
 */
export class ViolationsApi extends BaseAPI implements ViolationsApiInterface {
    /**
     * 
     * @summary Отправка заявления на дополнительный контроль
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsAdditionalControlPost(id?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsAdditionalControlPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Закрытие нарушения
     * @param {ClosingViolation} [closingViolation] Форма закрытия нарушения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsClosePost(closingViolation?: ClosingViolation, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsClosePost(closingViolation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание нового заявления
     * @param {string} [categoryId] 
     * @param {Array<string>} [subCategoryIds] 
     * @param {string} [address] 
     * @param {number} [coordinatesLatitude] 
     * @param {number} [coordinatesLongitude] 
     * @param {Array<any>} [photos] 
     * @param {Array<any>} [videos] 
     * @param {Array<any>} [documents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, coordinatesLatitude?: number, coordinatesLongitude?: number, photos?: Array<any>, videos?: Array<any>, documents?: Array<any>, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsCreatePost(categoryId, subCategoryIds, address, coordinatesLatitude, coordinatesLongitude, photos, videos, documents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataEntry} [dataEntry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsDataentryPost(dataEntry?: DataEntry, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsDataentryPost(dataEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла, прикрепленного к заявлению
     * @param {string} [fileId] Уникальный идентивикатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsFileGet(fileId?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsFileGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Данные параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsFilterdataGet(options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsFilterdataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка заявлений с заданными параметрами
     * @param {boolean} [orderByDescending] Параметр сортировки
     * @param {number} [pagesize] Количество элементов на странице
     * @param {number} [pagenumber] Номер страницы
     * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsFilteredlistPost(orderByDescending?: boolean, pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsFilteredlistPost(orderByDescending, pagesize, pagenumber, filterViolationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получения истории изменения заявления
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsHistoryGet(id?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsHistoryGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение статуса заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {string} [changer] Статус
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdChangestatusPost(id: string, changer?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdChangestatusPost(id, changer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отметка о просмотре заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdCheckPost(id: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdCheckPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление заяваления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdDelete(id: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdGet(id: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение изображеня, прикрепленного к заявлению
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdPhotosGet(id: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdPhotosGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Публикация заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdPublishPost(id: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdPublishPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отклонение заявления
     * @param {string} id Уникальный идентификатор заявления
     * @param {string} [comment] Комментарий к заявлению
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdRejectPost(id: string, comment?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdRejectPost(id, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
     * @param {number} [pagesize] Количество элементов на странице
     * @param {number} [pagenumber] Номер страницы
     * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsMapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsMapfilteredlistPost(pagesize, pagenumber, filterViolationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение количества новых заявлений по статусу
     * @param {number} [status] Статус заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsNewGet(status?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsNewGet(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файлов для результатов заявления
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsResultsFileGet(fileId?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsResultsFileGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получения результатов заявления
     * @param {string} [id] Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsResultsGet(id?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsResultsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка фотографий к заявлению
     * @param {string} [violationId] 
     * @param {string} [violationTemplateStepId] 
     * @param {Array<any>} [photos] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsUploadphotosPost(violationId?: string, violationTemplateStepId?: string, photos?: Array<any>, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsUploadphotosPost(violationId, violationTemplateStepId, photos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка заявлений для отображения на карте с заданными параметрами фильтрации
     * @param {number} [pagesize] Количество элементов на странице
     * @param {number} [pagenumber] Номер страницы
     * @param {FilterViolationForm} [filterViolationForm] Форма параметров фильтрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsV2MapfilteredlistPost(pagesize?: number, pagenumber?: number, filterViolationForm?: FilterViolationForm, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsV2MapfilteredlistPost(pagesize, pagenumber, filterViolationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [violationId] 
     * @param {string} [violationTemplateStepId] 
     * @param {Array<string>} [was] 
     * @param {Array<any>} [became] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsWasbecamePost(violationId?: string, violationTemplateStepId?: string, was?: Array<string>, became?: Array<any>, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsWasbecamePost(violationId, violationTemplateStepId, was, became, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoteApi - axios parameter creator
 * @export
 */
export const VoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Активация голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesActivateIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesActivateIdPost', 'id', id)
            const localVarPath = `/api/votes/activate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка анонсов голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAnnouncementGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка доступных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAvailableGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка завершенных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesCompletedGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание голосования
         * @param {VoteForm} [voteForm] Заполненная форма голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesCreatePost: async (voteForm?: VoteForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(voteForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Деактивация голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesDeactivateIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesDeactivateIdPost', 'id', id)
            const localVarPath = `/api/votes/deactivate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesDeleteIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesDeleteIdPost', 'id', id)
            const localVarPath = `/api/votes/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить результат голосования в файле Эксель
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesDownloadIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesDownloadIdGet', 'id', id)
            const localVarPath = `/api/votes/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {VoteForm} [voteForm] Измененная форма голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesEditIdPost: async (id: string, voteForm?: VoteForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesEditIdPost', 'id', id)
            const localVarPath = `/api/votes/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(voteForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка всех голосований
         * @param {number} [page] 
         * @param {number} [number] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Смена типа голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {ChangeTypeModel} [changeTypeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdChangetypePost: async (id: string, changeTypeModel?: ChangeTypeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdChangetypePost', 'id', id)
            const localVarPath = `/api/votes/{id}/changetype`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeTypeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение голосования по id
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdGet', 'id', id)
            const localVarPath = `/api/votes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение главного голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesMainGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/main`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoteApi - functional programming interface
 * @export
 */
export const VoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Активация голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesActivateIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesActivateIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка анонсов голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesAnnouncementGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteCardModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesAnnouncementGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка доступных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesAvailableGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteCardModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesAvailableGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка завершенных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesCompletedGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteCardModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesCompletedGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание голосования
         * @param {VoteForm} [voteForm] Заполненная форма голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesCreatePost(voteForm?: VoteForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesCreatePost(voteForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Деактивация голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesDeactivateIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesDeactivateIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesDeleteIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesDeleteIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить результат голосования в файле Эксель
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesDownloadIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesDownloadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {VoteForm} [voteForm] Измененная форма голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesEditIdPost(id: string, voteForm?: VoteForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesEditIdPost(id, voteForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка всех голосований
         * @param {number} [page] 
         * @param {number} [number] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteCardModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Смена типа голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {ChangeTypeModel} [changeTypeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdChangetypePost(id: string, changeTypeModel?: ChangeTypeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdChangetypePost(id, changeTypeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение голосования по id
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение главного голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesMainGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesMainGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoteApi - factory interface
 * @export
 */
export const VoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Активация голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesActivateIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesActivateIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка анонсов голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAnnouncementGet(page?: number, number?: number, options?: any): AxiosPromise<VoteCardModelHolder> {
            return localVarFp.apiVotesAnnouncementGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка доступных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAvailableGet(page?: number, number?: number, options?: any): AxiosPromise<VoteCardModelHolder> {
            return localVarFp.apiVotesAvailableGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка завершенных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesCompletedGet(page?: number, number?: number, options?: any): AxiosPromise<VoteCardModelHolder> {
            return localVarFp.apiVotesCompletedGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание голосования
         * @param {VoteForm} [voteForm] Заполненная форма голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesCreatePost(voteForm?: VoteForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesCreatePost(voteForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Деактивация голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesDeactivateIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesDeactivateIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesDeleteIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesDeleteIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить результат голосования в файле Эксель
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesDownloadIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesDownloadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {VoteForm} [voteForm] Измененная форма голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesEditIdPost(id: string, voteForm?: VoteForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesEditIdPost(id, voteForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка всех голосований
         * @param {number} [page] 
         * @param {number} [number] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesGet(page?: number, number?: number, options?: any): AxiosPromise<VoteCardModelHolder> {
            return localVarFp.apiVotesGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Смена типа голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {ChangeTypeModel} [changeTypeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdChangetypePost(id: string, changeTypeModel?: ChangeTypeModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesIdChangetypePost(id, changeTypeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение голосования по id
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdGet(id: string, options?: any): AxiosPromise<VoteModelHolder> {
            return localVarFp.apiVotesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение главного голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesMainGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiVotesMainGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoteApi - interface
 * @export
 * @interface VoteApi
 */
export interface VoteApiInterface {
    /**
     * 
     * @summary Активация голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesActivateIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка анонсов голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesAnnouncementGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<VoteCardModelHolder>;

    /**
     * 
     * @summary Получение списка доступных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesAvailableGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<VoteCardModelHolder>;

    /**
     * 
     * @summary Получение списка завершенных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesCompletedGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<VoteCardModelHolder>;

    /**
     * 
     * @summary Создание голосования
     * @param {VoteForm} [voteForm] Заполненная форма голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesCreatePost(voteForm?: VoteForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Деактивация голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesDeactivateIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Удаление голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesDeleteIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получить результат голосования в файле Эксель
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesDownloadIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {VoteForm} [voteForm] Измененная форма голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesEditIdPost(id: string, voteForm?: VoteForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка всех голосований
     * @param {number} [page] 
     * @param {number} [number] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<VoteCardModelHolder>;

    /**
     * 
     * @summary Смена типа голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {ChangeTypeModel} [changeTypeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesIdChangetypePost(id: string, changeTypeModel?: ChangeTypeModel, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение голосования по id
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<VoteModelHolder>;

    /**
     * 
     * @summary Получение главного голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesMainGet(options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * VoteApi - object-oriented interface
 * @export
 * @class VoteApi
 * @extends {BaseAPI}
 */
export class VoteApi extends BaseAPI implements VoteApiInterface {
    /**
     * 
     * @summary Активация голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesActivateIdPost(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesActivateIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка анонсов голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesAnnouncementGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesAnnouncementGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка доступных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesAvailableGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesAvailableGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка завершенных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesCompletedGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesCompletedGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание голосования
     * @param {VoteForm} [voteForm] Заполненная форма голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesCreatePost(voteForm?: VoteForm, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesCreatePost(voteForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Деактивация голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesDeactivateIdPost(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesDeactivateIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesDeleteIdPost(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesDeleteIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить результат голосования в файле Эксель
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesDownloadIdGet(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesDownloadIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {VoteForm} [voteForm] Измененная форма голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesEditIdPost(id: string, voteForm?: VoteForm, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesEditIdPost(id, voteForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка всех голосований
     * @param {number} [page] 
     * @param {number} [number] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Смена типа голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {ChangeTypeModel} [changeTypeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesIdChangetypePost(id: string, changeTypeModel?: ChangeTypeModel, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesIdChangetypePost(id, changeTypeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение голосования по id
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesIdGet(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение главного голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesMainGet(options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesMainGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VotesFilesApi - axios parameter creator
 * @export
 */
export const VotesFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Удаление файла, прикрепленного к ответу
         * @param {string} fileId уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnswersFilesFileIdDeletePost: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiAnswersFilesFileIdDeletePost', 'fileId', fileId)
            const localVarPath = `/api/answers/files/{fileId}/delete`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к ответу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnswersFilesFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiAnswersFilesFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/answers/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к ответу
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnswersFilesSetPost: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAnswersFilesSetPost', 'file', file)
            const localVarPath = `/api/answers/files/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление файла, прикрепленного к ответу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsFilesFileIdDeletePost: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiQuestionsFilesFileIdDeletePost', 'fileId', fileId)
            const localVarPath = `/api/questions/files/{fileId}/delete`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к вопросу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsFilesFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiQuestionsFilesFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/questions/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к вопросу
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsFilesSetPost: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiQuestionsFilesSetPost', 'file', file)
            const localVarPath = `/api/questions/files/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление файла, прикрепленного к голосованию
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesFileIdDeletePost: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiVotesFilesFileIdDeletePost', 'fileId', fileId)
            const localVarPath = `/api/votes/files/{fileId}/delete`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к голосованию
         * @param {string} fileId Уникальный идентификтор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiVotesFilesFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/votes/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к голосованию
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesSetPost: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiVotesFilesSetPost', 'file', file)
            const localVarPath = `/api/votes/files/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VotesFilesApi - functional programming interface
 * @export
 */
export const VotesFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VotesFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Удаление файла, прикрепленного к ответу
         * @param {string} fileId уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnswersFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnswersFilesFileIdDeletePost(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к ответу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnswersFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnswersFilesFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к ответу
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnswersFilesSetPost(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VotesFilesSetModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnswersFilesSetPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление файла, прикрепленного к ответу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsFilesFileIdDeletePost(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к вопросу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsFilesFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к вопросу
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsFilesSetPost(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VotesFilesSetModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsFilesSetPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление файла, прикрепленного к голосованию
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesFilesFileIdDeletePost(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к голосованию
         * @param {string} fileId Уникальный идентификтор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesFilesFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к голосованию
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesFilesSetPost(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VotesFilesSetModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesFilesSetPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VotesFilesApi - factory interface
 * @export
 */
export const VotesFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VotesFilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Удаление файла, прикрепленного к ответу
         * @param {string} fileId уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnswersFilesFileIdDeletePost(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnswersFilesFileIdDeletePost(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к ответу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnswersFilesFileIdGet(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnswersFilesFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к ответу
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnswersFilesSetPost(file: any, options?: any): AxiosPromise<VotesFilesSetModel> {
            return localVarFp.apiAnswersFilesSetPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление файла, прикрепленного к ответу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsFilesFileIdDeletePost(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionsFilesFileIdDeletePost(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к вопросу
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsFilesFileIdGet(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionsFilesFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к вопросу
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsFilesSetPost(file: any, options?: any): AxiosPromise<VotesFilesSetModel> {
            return localVarFp.apiQuestionsFilesSetPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление файла, прикрепленного к голосованию
         * @param {string} fileId Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesFileIdDeletePost(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesFilesFileIdDeletePost(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к голосованию
         * @param {string} fileId Уникальный идентификтор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesFileIdGet(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesFilesFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка файла, прикрепленного к голосованию
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesSetPost(file: any, options?: any): AxiosPromise<VotesFilesSetModel> {
            return localVarFp.apiVotesFilesSetPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VotesFilesApi - interface
 * @export
 * @interface VotesFilesApi
 */
export interface VotesFilesApiInterface {
    /**
     * 
     * @summary Удаление файла, прикрепленного к ответу
     * @param {string} fileId уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiAnswersFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение файла, прикрепленного к ответу
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiAnswersFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Загрузка файла, прикрепленного к ответу
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiAnswersFilesSetPost(file: any, options?: AxiosRequestConfig): AxiosPromise<VotesFilesSetModel>;

    /**
     * 
     * @summary Удаление файла, прикрепленного к ответу
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiQuestionsFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение файла, прикрепленного к вопросу
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiQuestionsFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Загрузка файла, прикрепленного к вопросу
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiQuestionsFilesSetPost(file: any, options?: AxiosRequestConfig): AxiosPromise<VotesFilesSetModel>;

    /**
     * 
     * @summary Удаление файла, прикрепленного к голосованию
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiVotesFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение файла, прикрепленного к голосованию
     * @param {string} fileId Уникальный идентификтор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiVotesFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Загрузка файла, прикрепленного к голосованию
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiVotesFilesSetPost(file: any, options?: AxiosRequestConfig): AxiosPromise<VotesFilesSetModel>;

}

/**
 * VotesFilesApi - object-oriented interface
 * @export
 * @class VotesFilesApi
 * @extends {BaseAPI}
 */
export class VotesFilesApi extends BaseAPI implements VotesFilesApiInterface {
    /**
     * 
     * @summary Удаление файла, прикрепленного к ответу
     * @param {string} fileId уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiAnswersFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiAnswersFilesFileIdDeletePost(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла, прикрепленного к ответу
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiAnswersFilesFileIdGet(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiAnswersFilesFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка файла, прикрепленного к ответу
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiAnswersFilesSetPost(file: any, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiAnswersFilesSetPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление файла, прикрепленного к ответу
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiQuestionsFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiQuestionsFilesFileIdDeletePost(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла, прикрепленного к вопросу
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiQuestionsFilesFileIdGet(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiQuestionsFilesFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка файла, прикрепленного к вопросу
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiQuestionsFilesSetPost(file: any, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiQuestionsFilesSetPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление файла, прикрепленного к голосованию
     * @param {string} fileId Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiVotesFilesFileIdDeletePost(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiVotesFilesFileIdDeletePost(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла, прикрепленного к голосованию
     * @param {string} fileId Уникальный идентификтор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiVotesFilesFileIdGet(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiVotesFilesFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка файла, прикрепленного к голосованию
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiVotesFilesSetPost(file: any, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiVotesFilesSetPost(file, options).then((request) => request(this.axios, this.basePath));
    }
}


