import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { CategoryFormStore } from "newApp/stores/common/CategoryFormStore";

export class CategoryEditPageStore {
  isLoadings = {
    getCategory: false,
    saveCategory: false,
  };
  id = "";

  categoryForm = new CategoryFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getCategory();
    this.categoryForm.init();
  };

  reset = () => {
    this.categoryForm.reset();
  };

  getCategory = () => {
    this.isLoadings.getCategory = true;
    return API.admin.Category.apiObjectsIdGet(this.id)
      .then(({ data }) => {
        runInAction(() => {
          this.categoryForm.form.changeValueByKey(data["name"], "name");
          this.categoryForm.form.changeValueByKey(data["parentId"], "parentId");
          this.categoryForm.form.changeValueByKey(
            data["organizationId"],
            "organizationId"
          );
          this.categoryForm.form.changeValueByKey(
            data["daysLimit"],
            "daysLimit"
          );
          this.categoryForm.form.changeValueByKey(
            data["state"] === 1,
            "isActive"
          );
          this.categoryForm.categoryTags.tags = data["tags"];
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getCategory = false;
        });
      });
  };

  onSave = async () => {
    await this.categoryForm.form.validate();
    if (this.categoryForm.form.isValid) {
      return await this.saveCategory();
    }
  };

  saveCategory = () => {
    this.isLoadings.saveCategory = true;
    return API.admin.Category.apiObjectsSetIdPost(this.id, {
      DaysLimit: this.categoryForm.form.values.daysLimit,
      Name: this.categoryForm.form.values.name,
      OrganizationId: this.categoryForm.form.values.organizationId,
      ParentId: this.categoryForm.form.values.parentId,
      State: this.categoryForm.form.values.isActive ? 1 : 0,
      Tags: this.categoryForm.categoryTags.tags,
    })
      .then(() => true)
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveCategory = false;
        });
      });
  };
}
