import { observer } from "mobx-react";
import FilesPreview from "newApp/components/FilesPreview";
import { VOTES_FILE_TYPES } from "newApp/constants/dropzone";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import Divider from "newApp/kit/components/Divider";
import Dropzone from "newApp/kit/components/Dropzone";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import TextField from "newApp/kit/components/TextField";
import { AnswerStore } from "newApp/stores/common/VoteFormStore/VoteQuestionsFormStore/QuestionStore/AnswerStore";
import React from "react";

export interface IVoteAnswerFormProps extends Omit<AnswerStore, "isValid"> {
  index?: number;
  removeAnswer?: (idx: number) => void;
}

const VoteAnswerForm: React.FC<IVoteAnswerFormProps> = (props) => {
  return (
    <FlexColumn>
      <H3>Ответ {props.index + 1}</H3>
      <TextField placeholder="Ответ" {...props.form.adapters.text} />
      <FilesPreview files={props.files} onClickRemove={props.removeFile} />
      <Dropzone
        acceptFileTypes={VOTES_FILE_TYPES}
        multiple={true}
        onAddFiles={props.onAddFiles}
      />

      <ButtonsContainer>
        <Button
          onClick={() => props.removeAnswer(props.index)}
          size="small"
          variant="secondary"
        >
          Удалить ответ
        </Button>
      </ButtonsContainer>
      <Divider color="grey" />
    </FlexColumn>
  );
};

export default observer(VoteAnswerForm);
