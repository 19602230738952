import { observer } from "mobx-react";
import FaqList from "newApp/components/FaqList";
import FaqNavigator from "newApp/components/FaqNavigator";
import MainLayout from "newApp/components/layouts/MainLayout";
import SiteNavigator from "newApp/components/SiteNavigator";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Pagination from "newApp/kit/components/Pagination";
import { URL_SITE_FAQ_CREATE } from "newApp/routing/urls";
import { FaqListPageStore } from "newApp/stores/pages/site/faq/FaqListPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new FaqListPageStore();

const FaqListPage: React.FC = () => {
  const { push } = useHistory();
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Часто задаваемые вопросы</H1>
      <FlexColumn>
        <SiteNavigator />
        <FaqNavigator />
        <Button onClick={() => push(URL_SITE_FAQ_CREATE)}>
          Добавить вопрос
        </Button>
        {store.isLoadings.getFaqs ? <LoaderSpinner /> : <FaqList {...store} />}
        <Pagination {...store.pagination} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(FaqListPage);
