import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import Checkbox from "newApp/kit/components/CheckBox";
import DateTimePicker from "newApp/kit/components/DateTimePicker";
import Divider from "newApp/kit/components/Divider";
import Dropzone from "newApp/kit/components/Dropzone";
import FlexColumn from "newApp/kit/components/FlexColumn";
import FlexRow from "newApp/kit/components/FlexRow";
import H3 from "newApp/kit/components/H3";
import RadioButtons from "newApp/kit/components/RadioButtons";
import Select from "newApp/kit/components/Select";
import TextArea from "newApp/kit/components/TextArea";
import TextField from "newApp/kit/components/TextField";
import { VoteDetailsFormStore } from "newApp/stores/common/VoteFormStore/VoteDetailsFormStore";
import React from "react";

import CheckboxHideBlock from "../CheckboxHideBlock";
import FilesPreview from "../FilesPreview";
import VoteMap from "../Map/VoteMap";
import VoteDetailsFileForm from "./VoteDetailsFileForm";
import { VOTES_FILE_TYPES } from "newApp/constants/dropzone";

export interface IVoteDetailsFormProps {
  store: VoteDetailsFormStore;
}

const VoteDetailsForm: React.FC<IVoteDetailsFormProps> = ({ store }) => {
  return (
    <FlexColumn>
      <TextField placeholder="Название" {...store.form.adapters.title} />
      <FlexRow>
        <DateTimePicker
          placeholder="Дата и время начала"
          {...store.form.adapters.startDateTime}
        />
        <DateTimePicker
          placeholder="Дата и время окончания"
          {...store.form.adapters.endDateTime}
        />
      </FlexRow>
      <TextArea placeholder="Аннотация" {...store.form.adapters.annotation} />
      <TextArea placeholder="Описание" {...store.form.adapters.description} />

      <VoteDetailsFileForm {...store.fileForm} />
      <H3>Файлы</H3>
      {store.files.length > 0 && (
        <FilesPreview files={store.files} onClickRemove={store.removeFile} />
      )}
      <Dropzone
        onAddFiles={store.onAddFiles}
        multiple={true}
        acceptFileTypes={VOTES_FILE_TYPES}
      />

      <FlexRow>
        <Checkbox {...store.form.adapters.isBanner}>
          Отображать на баннере
        </Checkbox>
        <Checkbox
          {...store.form.adapters.isImportant}
          onChange={(value) => {
            if (
              value &&
              store.form.values.mainVoteTitle &&
              store.form.values.title !== store.form.values.mainVoteTitle
            ) {
              if (
                window.confirm(
                  `На сайте установлено главное голосование ${store.form.values.mainVoteTitle}. Заменить его на данное голосование?`
                )
              ) {
                store.form.changeValueByKey(value, "isImportant");
              }
            } else {
              store.form.changeValueByKey(value, "isImportant");
            }
          }}
        >
          Главное голосование
        </Checkbox>
      </FlexRow>
      <Divider />
      <H3>Формат отображения результата</H3>
      <RadioButtons
        {...store.form.adapters.isDigitalResult}
        options={store.options.isDigitalResult}
        isHorizontal
      />
      <Divider />

      <H3>Настройки</H3>
      <CheckboxHideBlock
        {...store.form.adapters.isAgeLimit}
        checkboxTitle=" Возрастная категория"
      >
        <FlexRow>
          <TextField
            type="number"
            placeholder="Возраст от"
            {...store.form.adapters.fromAge}
          />
          <TextField
            type="number"
            placeholder="Возраст до"
            {...store.form.adapters.toAge}
          />
        </FlexRow>
      </CheckboxHideBlock>
      <CheckboxHideBlock
        {...store.form.adapters.isGenderLimit}
        checkboxTitle="Половой признак"
      >
        <RadioButtons
          {...store.form.adapters.gender}
          options={store.options.gender}
          isHorizontal
        />
      </CheckboxHideBlock>
      <CheckboxHideBlock
        {...store.form.adapters.isReward}
        onChange={store.removeNumberOfPoints}
        checkboxTitle="Вознаграждение пользователя баллами"
      >
        <TextField
          type="number"
          placeholder="Количество баллов"
          {...store.form.adapters.numberOfPoints}
        />
      </CheckboxHideBlock>
      <CheckboxHideBlock
        {...store.form.adapters.isTerritoryLimit}
        checkboxTitle="Территориальный признак"
      >
        <FlexColumn>
          <VoteMap {...store} onChangeCoords={() => {}} />
          <FlexRow>
            <Select
              placeholder="Тип места"
              options={store.options.placeType}
              {...store.form.adapters.place}
            />
            <Select
              placeholder="Тип позиции"
              options={store.options.positionTypes}
              {...store.form.adapters.position}
            />
          </FlexRow>
          <ButtonsContainer>
            <Button onClick={() => {}}>Задать координаты</Button>
            <Button onClick={() => {}} variant="secondary">
              Сбросить
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      </CheckboxHideBlock>
    </FlexColumn>
  );
};

export default observer(VoteDetailsForm);
