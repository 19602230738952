import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

export type THistoryItem = {
  id: string;
  violationId: string;
  date: string;
  changedBy: string;
  description: string;
};

export class DeclarationHistoryStore {
  isLoading = false;

  declarationId: string = "";

  history: THistoryItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (declarationId: string) => {
    this.declarationId = declarationId;
  };

  reset = () => {
    this.resetHistory();
    this.isLoading = false;
  };

  resetHistory = () => {
    this.history = [];
  };

  getHistory = () => {
    this.isLoading = true;
    return API.admin.Violations.apiViolationsHistoryGet(this.declarationId)
      .then((res) => {
        runInAction(() => {
          const data = res.data as THistoryItem[];
          this.history = data;
        });
      })
      .catch(() => this.resetHistory())
      .finally(() => {
        runInAction(() => (this.isLoading = false));
      });
  };
}
