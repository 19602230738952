import LoaderSpinner from "components/Loader";
import { observer } from "mobx-react";
import AnalyticsAcceptedFilter from "newApp/components/AnalyticsAcceptedFilter";
import AnalyticsNavigator from "newApp/components/AnalyticsNavigator";
import AnalyticsTable from "newApp/components/AnalyticsTable";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { AnalyticsAcceptedPageStore } from "newApp/stores/pages/analytics/AnalyticsAcceptedPageStore";
import React, { useEffect } from "react";

const store = new AnalyticsAcceptedPageStore();

const AnalyticsAcceptedPage: React.FC = () => {
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Аналитика - Поступившие</H1>
      <FlexColumn>
        <AnalyticsNavigator />
        <ButtonsContainer>
          <AnalyticsAcceptedFilter
            filter={store.filter}
            getList={() => store.getReport()}
          />
          <a href={store.downloadUrl} target="_blank" rel="noreferrer">
            <Button onClick={() => {}} variant="secondary" size="small">
              Скачать таблицу
            </Button>
          </a>
        </ButtonsContainer>
        {store.isLoadings.getReport ? (
          <LoaderSpinner />
        ) : (
          <AnalyticsTable table={store.table} />
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(AnalyticsAcceptedPage);
