import { makeAutoObservable } from "mobx";
import { API } from "../../../../api";
import { UserEditCreateFormStore } from "../../../common/UserEditCreateFormStore";

export class UserItemCreatePageStore {
  isLoadings = { postUser: false };

  forms = new UserEditCreateFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.forms.init();
  };

  reset = () => {
    this.forms.reset();
  };

  saveEditedUser = () => {
    this.forms.validate();
    if (this.forms.isValid) {
      return;
    } else {
      return false;
    }
  };

  tryCreate = () => {
    this.forms.validate();
    if (this.forms.isValid) {
      return this.postNewUser();
    } else {
      return false;
    }
  };

  postNewUser = () => {
    return API.access.Users.apiUsersCreatePost({
      form: {
        data: {
          lastName: this.forms.form.values.lastName || "",
          firstName: this.forms.form.values.firstName || "",
          middleName: this.forms.form.values.middleName || null,
          email: this.forms.form.values.email || "",
          phone: this.forms.form.values.phone || null,
          otherPhones: this.forms.form.values.otherPhones || null,
          login: this.forms.form.values.login || "",
          password: this.forms.form.values.password || null,
          passwordConfirm: this.forms.form.values.passwordConfirm || null,
          isDeactivated: this.forms.form.values.isDeactivated || false,
        },
        credentials: { organizations: this.forms.roles.organizationsForSave },
      },
    })
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        try {
          if (data) {
            Object.entries(data).forEach(([key, value]) => {
              this.forms.form.setError(
                (data[key] as string[]).join(". "),
                String(key).toLowerCase() as any
              );
            });
          }
        } catch (error: any) {
          console.error(error);
        }

        return null;
      });
  };
}
