import React, {
  ChangeEvent,
  MouseEventHandler,
  useState,
  useRef,
  FocusEvent,
} from "react";
import Tooltip from "../Tooltip";
import TextareaAutosize from "react-textarea-autosize";
import "./styles.scss";
import classNames from "classnames";
import { TTextAreaProps } from "./index.types";

const TextArea: React.FC<TTextAreaProps> = ({
  placeholder,
  isRequired,
  isDisabled,
  requiredLabel = "Обязательно",
  errorTitle,
  errorMessage,
  onChange,
  value,
  minRows = 3,
  maxRows = 99,
  onBlur,
  onFocus,
  className,
  ...props
}) => {
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);

  const openErrorTooltip = () => setShowErrorTooltip(true);
  const closeErrorTooltip = () => setShowErrorTooltip(false);

  const areaRef = useRef<HTMLTextAreaElement>(null);

  const toggleErrorTooltip = () => {
    setShowErrorTooltip((prev) => !prev);
  };

  const handleErrorButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    toggleErrorTooltip();
    areaRef.current?.focus();
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value, e.target.name);
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    closeErrorTooltip();
    onBlur && onBlur(e);
  };

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    openErrorTooltip();
    onFocus && onFocus(e);
  };

  return (
    <div
      className={classNames(
        "textarea",
        errorMessage && "textarea--error",
        className
      )}
    >
      <TextareaAutosize
        {...props}
        className="textarea__textarea"
        required={isRequired}
        disabled={isDisabled}
        onChange={handleChange}
        value={value || ""}
        ref={areaRef}
        placeholder=" "
        minRows={minRows}
        maxRows={maxRows}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {placeholder && (
        <label className={"textarea__placeholder"} htmlFor={props.id}>
          {placeholder}
        </label>
      )}

      {errorMessage && (
        <button
          className={"textarea__error-button"}
          aria-label="Информация об ошибке"
          onMouseDown={handleErrorButtonClick}
          tabIndex={-1}
        />
      )}

      {errorMessage && showErrorTooltip && (
        <Tooltip
          onClose={closeErrorTooltip}
          title={errorTitle}
          text={errorMessage}
          className="textarea__tooltip"
        />
      )}

      {isRequired && (
        <div className="textarea__required-label">{requiredLabel}</div>
      )}
    </div>
  );
};

export default TextArea;
