import React from "react";
import "./styles.scss";

export interface IDividerProps {
  color?: "blue" | "grey";
  height?: number;
}

const Divider: React.FC<IDividerProps> = ({ height = 1, ...props }) => {
  return (
    <div
      className={["divider", props.color ? `divider--${props.color}` : ""].join(
        " "
      )}
      style={{ height: `${height}px` }}
    />
  );
};

export default Divider;
