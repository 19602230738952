import { observer } from "mobx-react";
import React, { useEffect } from "react";

import H1 from "newApp/kit/components/H1";
import MainLayout from "newApp/components/layouts/MainLayout";
import ShopNavigator from "newApp/components/ShopNavigator";
import H2 from "newApp/kit/components/H2";
import NewsPreview from "newApp/components/NewsPreview";

import ballsStore from "newApp/stores/pages/shop/balls";
import "./styles.scss";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import DashedButton from "newApp/components/DashedButton";
import { useHistory } from "react-router";
import { URL_SHOP_BALLS } from "newApp/routing/urls";

const BallsPage = () => {
  const history = useHistory();

  useEffect(() => {
    ballsStore.init();
  }, []);

  return (
    <MainLayout isLoading={ballsStore.isLoading}>
      <div className="balls-page">
        <H1>Баллы</H1>
        <ShopNavigator />
        <H2>Информация для пользователя у которого нет ЕСК</H2>
        <div className="balls-page__text-block">
          <NewsPreview content={ballsStore.haveNoESK} />
          <ButtonsContainer>
            <DashedButton
              onClick={() => {
                history.push(URL_SHOP_BALLS + "/edit?have=false");
              }}
            >
              Редактировать
            </DashedButton>
          </ButtonsContainer>
        </div>
        <H2>Информация для пользователя у которого есть ЕСК</H2>
        <div className="balls-page__text-block">
          <NewsPreview content={ballsStore.haveESK} />
          <ButtonsContainer>
            <DashedButton
              onClick={() => {
                history.push(URL_SHOP_BALLS + "/edit?have=true");
              }}
            >
              Редактировать
            </DashedButton>
          </ButtonsContainer>
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(BallsPage);
