/* tslint:disable */
/* eslint-disable */
/**
 * Администрирование
 * Описание методов для работы с API \"Магазин поощрений\"
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CategoryForm
 */
export interface CategoryForm {
    /**
     * 
     * @type {string}
     * @memberof CategoryForm
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CategoryModel
 */
export interface CategoryModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryModel
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface CategoryPagedModel
 */
export interface CategoryPagedModel {
    /**
     * 
     * @type {number}
     * @memberof CategoryPagedModel
     */
    'count'?: number;
    /**
     * 
     * @type {Array<CategoryModel>}
     * @memberof CategoryPagedModel
     */
    'items'?: Array<CategoryModel> | null;
}
/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface DepartmentModel
 */
export interface DepartmentModel {
    /**
     * 
     * @type {string}
     * @memberof DepartmentModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentModel
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentModel
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof DepartmentModel
     */
    'coordinates'?: Coordinates;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    'image': any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    'croppedImage': any;
}
/**
 * 
 * @export
 * @interface PointsInfoForm
 */
export interface PointsInfoForm {
    /**
     * 
     * @type {string}
     * @memberof PointsInfoForm
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PointsInfoForm
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface PointsInfoModel
 */
export interface PointsInfoModel {
    /**
     * 
     * @type {string}
     * @memberof PointsInfoModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PointsInfoModel
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'isGoods'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDto
     */
    'categoriesIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'organizationAddress'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ProductDto
     */
    'organizationCoordinates'?: Coordinates;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductDto
     */
    'productStatus'?: ProductStatus;
}
/**
 * 
 * @export
 * @interface ProductDto2
 */
export interface ProductDto2 {
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto2
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto2
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDto2
     */
    'categoriesIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'creationDate'?: string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductDto2
     */
    'productStatus'?: ProductStatus;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto2
     */
    'isEsk'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDto2
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface ProductForm
 */
export interface ProductForm {
    /**
     * 
     * @type {string}
     * @memberof ProductForm
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProductForm
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductForm
     */
    'isGoods'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductForm
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ProductForm
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductForm
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductForm
     */
    'creationDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductForm
     */
    'categories'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductForm
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductForm
     */
    'organizationAddress'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ProductForm
     */
    'organizationCoordinates'?: Coordinates;
}
/**
 * 
 * @export
 * @interface ProductForm2
 */
export interface ProductForm2 {
    /**
     * 
     * @type {string}
     * @memberof ProductForm2
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProductForm2
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductForm2
     */
    'isGoods'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductForm2
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ProductForm2
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductForm2
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductForm2
     */
    'creationDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductForm2
     */
    'categories'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductForm2
     */
    'departmentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductForm2
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    'isGoods'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    'categoriesIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'creationDate'?: string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductModel
     */
    'productStatus'?: ProductStatus;
}
/**
 * 
 * @export
 * @interface ProductPagedModel
 */
export interface ProductPagedModel {
    /**
     * 
     * @type {number}
     * @memberof ProductPagedModel
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductModel>}
     * @memberof ProductPagedModel
     */
    'items'?: Array<ProductModel> | null;
}
/**
 * 
 * @export
 * @interface ProductStatisticsModel
 */
export interface ProductStatisticsModel {
    /**
     * 
     * @type {string}
     * @memberof ProductStatisticsModel
     */
    'productName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStatisticsModel
     */
    'productNumber'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProductStatus = {
    New: 'New',
    Approved: 'Approved',
    Deleted: 'Deleted',
    Disapproved: 'Disapproved'
} as const;

export type ProductStatus = typeof ProductStatus[keyof typeof ProductStatus];


/**
 * 
 * @export
 * @interface ProjectSettingsForm
 */
export interface ProjectSettingsForm {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsForm
     */
    'isVisibleInMainMenu'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsForm
     */
    'isVisibleInProfile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsForm
     */
    'isVisibleInMobile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ProjectSettingsModel
 */
export interface ProjectSettingsModel {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsModel
     */
    'isVisibleInMainMenu'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsModel
     */
    'isVisibleInProfile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsModel
     */
    'isVisibleInMobile'?: boolean;
}

/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получить статистику по приобретенным товарам
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProductStatisticsGet: async (beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/productStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['BeginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получить реестр приобретенных товаров в Эксель
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProductsRegisterGet: async (beginDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/productsRegister`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (beginDate !== undefined) {
                localVarQueryParameter['BeginDate'] = (beginDate as any instanceof Date) ?
                    (beginDate as any).toISOString() :
                    beginDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получить статистику по приобретенным товарам
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsProductStatisticsGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductStatisticsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsProductStatisticsGet(beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получить реестр приобретенных товаров в Эксель
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsProductsRegisterGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsProductsRegisterGet(beginDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @summary получить статистику по приобретенным товарам
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProductStatisticsGet(beginDate?: string, endDate?: string, options?: any): AxiosPromise<Array<ProductStatisticsModel>> {
            return localVarFp.apiAnalyticsProductStatisticsGet(beginDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получить реестр приобретенных товаров в Эксель
         * @param {string} [beginDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProductsRegisterGet(beginDate?: string, endDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnalyticsProductsRegisterGet(beginDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - interface
 * @export
 * @interface AnalyticsApi
 */
export interface AnalyticsApiInterface {
    /**
     * 
     * @summary получить статистику по приобретенным товарам
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsProductStatisticsGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ProductStatisticsModel>>;

    /**
     * 
     * @summary получить реестр приобретенных товаров в Эксель
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsProductsRegisterGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI implements AnalyticsApiInterface {
    /**
     * 
     * @summary получить статистику по приобретенным товарам
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsProductStatisticsGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsProductStatisticsGet(beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получить реестр приобретенных товаров в Эксель
     * @param {string} [beginDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsProductsRegisterGet(beginDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsProductsRegisterGet(beginDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Активация категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesActivateIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesActivateIdPost', 'id', id)
            const localVarPath = `/api/categories/activate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание категории товара/услуги
         * @param {CategoryForm} [categoryForm] Форма создания категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesCreatePost: async (categoryForm?: CategoryForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Деактивация категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesDeactivateIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesDeactivateIdPost', 'id', id)
            const localVarPath = `/api/categories/deactivate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление категории товара
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesDeleteIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesDeleteIdPost', 'id', id)
            const localVarPath = `/api/categories/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {CategoryForm} [categoryForm] Форма редактирования категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesEditIdPost: async (id: string, categoryForm?: CategoryForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesEditIdPost', 'id', id)
            const localVarPath = `/api/categories/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка категорий товаров и услуг
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGet: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получения категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesIdGet', 'id', id)
            const localVarPath = `/api/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Активация категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesActivateIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesActivateIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание категории товара/услуги
         * @param {CategoryForm} [categoryForm] Форма создания категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesCreatePost(categoryForm?: CategoryForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesCreatePost(categoryForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Деактивация категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesDeactivateIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesDeactivateIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление категории товара
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesDeleteIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesDeleteIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {CategoryForm} [categoryForm] Форма редактирования категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesEditIdPost(id: string, categoryForm?: CategoryForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesEditIdPost(id, categoryForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка категорий товаров и услуг
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesGet(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryPagedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesGet(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получения категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Активация категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesActivateIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCategoriesActivateIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание категории товара/услуги
         * @param {CategoryForm} [categoryForm] Форма создания категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesCreatePost(categoryForm?: CategoryForm, options?: any): AxiosPromise<string> {
            return localVarFp.apiCategoriesCreatePost(categoryForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Деактивация категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesDeactivateIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCategoriesDeactivateIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление категории товара
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesDeleteIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCategoriesDeleteIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {CategoryForm} [categoryForm] Форма редактирования категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesEditIdPost(id: string, categoryForm?: CategoryForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiCategoriesEditIdPost(id, categoryForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка категорий товаров и услуг
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGet(skip?: number, take?: number, options?: any): AxiosPromise<Array<CategoryPagedModel>> {
            return localVarFp.apiCategoriesGet(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получения категории товара/услуги
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdGet(id: string, options?: any): AxiosPromise<CategoryModel> {
            return localVarFp.apiCategoriesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - interface
 * @export
 * @interface CategoriesApi
 */
export interface CategoriesApiInterface {
    /**
     * 
     * @summary Активация категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesActivateIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Создание категории товара/услуги
     * @param {CategoryForm} [categoryForm] Форма создания категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesCreatePost(categoryForm?: CategoryForm, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Деактивация категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesDeactivateIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Удаление категории товара
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesDeleteIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {CategoryForm} [categoryForm] Форма редактирования категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesEditIdPost(id: string, categoryForm?: CategoryForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка категорий товаров и услуг
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesGet(skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<Array<CategoryPagedModel>>;

    /**
     * 
     * @summary Получения категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<CategoryModel>;

}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI implements CategoriesApiInterface {
    /**
     * 
     * @summary Активация категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesActivateIdPost(id: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesActivateIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание категории товара/услуги
     * @param {CategoryForm} [categoryForm] Форма создания категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesCreatePost(categoryForm?: CategoryForm, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesCreatePost(categoryForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Деактивация категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesDeactivateIdPost(id: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesDeactivateIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление категории товара
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesDeleteIdPost(id: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesDeleteIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {CategoryForm} [categoryForm] Форма редактирования категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesEditIdPost(id: string, categoryForm?: CategoryForm, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesEditIdPost(id, categoryForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка категорий товаров и услуг
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesGet(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesGet(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получения категории товара/услуги
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesIdGet(id: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartmentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartmentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentsGet(options?: any): AxiosPromise<Array<DepartmentModel>> {
            return localVarFp.apiDepartmentsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsApi - interface
 * @export
 * @interface DepartmentsApi
 */
export interface DepartmentsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApiInterface
     */
    apiDepartmentsGet(options?: AxiosRequestConfig): AxiosPromise<Array<DepartmentModel>>;

}

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI implements DepartmentsApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public apiDepartmentsGet(options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).apiDepartmentsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PointsInfoApi - axios parameter creator
 * @export
 */
export const PointsInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получить информацию о странице для пользователей с картой ЕСК
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoHasESKGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pointsinfo/hasESK`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавить/редактировать информацию о странице для пользователей с картой ЕСК
         * @param {PointsInfoForm} [pointsInfoForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoHasESKPost: async (pointsInfoForm?: PointsInfoForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pointsinfo/hasESK`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointsInfoForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получить информацию о странице для пользователей БЕЗ карты ЕСК
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoWithoutESKGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pointsinfo/withoutESK`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавить/редактировать информацию о странице для пользователей БЕЗ карты ЕСК
         * @param {PointsInfoForm} [pointsInfoForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoWithoutESKPost: async (pointsInfoForm?: PointsInfoForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pointsinfo/withoutESK`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointsInfoForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsInfoApi - functional programming interface
 * @export
 */
export const PointsInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получить информацию о странице для пользователей с картой ЕСК
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPointsinfoHasESKGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointsInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPointsinfoHasESKGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавить/редактировать информацию о странице для пользователей с картой ЕСК
         * @param {PointsInfoForm} [pointsInfoForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPointsinfoHasESKPost(pointsInfoForm?: PointsInfoForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointsInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPointsinfoHasESKPost(pointsInfoForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получить информацию о странице для пользователей БЕЗ карты ЕСК
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPointsinfoWithoutESKGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointsInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPointsinfoWithoutESKGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавить/редактировать информацию о странице для пользователей БЕЗ карты ЕСК
         * @param {PointsInfoForm} [pointsInfoForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPointsinfoWithoutESKPost(pointsInfoForm?: PointsInfoForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointsInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPointsinfoWithoutESKPost(pointsInfoForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointsInfoApi - factory interface
 * @export
 */
export const PointsInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary получить информацию о странице для пользователей с картой ЕСК
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoHasESKGet(options?: any): AxiosPromise<PointsInfoModel> {
            return localVarFp.apiPointsinfoHasESKGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавить/редактировать информацию о странице для пользователей с картой ЕСК
         * @param {PointsInfoForm} [pointsInfoForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoHasESKPost(pointsInfoForm?: PointsInfoForm, options?: any): AxiosPromise<PointsInfoModel> {
            return localVarFp.apiPointsinfoHasESKPost(pointsInfoForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получить информацию о странице для пользователей БЕЗ карты ЕСК
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoWithoutESKGet(options?: any): AxiosPromise<PointsInfoModel> {
            return localVarFp.apiPointsinfoWithoutESKGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавить/редактировать информацию о странице для пользователей БЕЗ карты ЕСК
         * @param {PointsInfoForm} [pointsInfoForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPointsinfoWithoutESKPost(pointsInfoForm?: PointsInfoForm, options?: any): AxiosPromise<PointsInfoModel> {
            return localVarFp.apiPointsinfoWithoutESKPost(pointsInfoForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointsInfoApi - interface
 * @export
 * @interface PointsInfoApi
 */
export interface PointsInfoApiInterface {
    /**
     * 
     * @summary получить информацию о странице для пользователей с картой ЕСК
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApiInterface
     */
    apiPointsinfoHasESKGet(options?: AxiosRequestConfig): AxiosPromise<PointsInfoModel>;

    /**
     * 
     * @summary добавить/редактировать информацию о странице для пользователей с картой ЕСК
     * @param {PointsInfoForm} [pointsInfoForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApiInterface
     */
    apiPointsinfoHasESKPost(pointsInfoForm?: PointsInfoForm, options?: AxiosRequestConfig): AxiosPromise<PointsInfoModel>;

    /**
     * 
     * @summary получить информацию о странице для пользователей БЕЗ карты ЕСК
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApiInterface
     */
    apiPointsinfoWithoutESKGet(options?: AxiosRequestConfig): AxiosPromise<PointsInfoModel>;

    /**
     * 
     * @summary добавить/редактировать информацию о странице для пользователей БЕЗ карты ЕСК
     * @param {PointsInfoForm} [pointsInfoForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApiInterface
     */
    apiPointsinfoWithoutESKPost(pointsInfoForm?: PointsInfoForm, options?: AxiosRequestConfig): AxiosPromise<PointsInfoModel>;

}

/**
 * PointsInfoApi - object-oriented interface
 * @export
 * @class PointsInfoApi
 * @extends {BaseAPI}
 */
export class PointsInfoApi extends BaseAPI implements PointsInfoApiInterface {
    /**
     * 
     * @summary получить информацию о странице для пользователей с картой ЕСК
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApi
     */
    public apiPointsinfoHasESKGet(options?: AxiosRequestConfig) {
        return PointsInfoApiFp(this.configuration).apiPointsinfoHasESKGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавить/редактировать информацию о странице для пользователей с картой ЕСК
     * @param {PointsInfoForm} [pointsInfoForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApi
     */
    public apiPointsinfoHasESKPost(pointsInfoForm?: PointsInfoForm, options?: AxiosRequestConfig) {
        return PointsInfoApiFp(this.configuration).apiPointsinfoHasESKPost(pointsInfoForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получить информацию о странице для пользователей БЕЗ карты ЕСК
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApi
     */
    public apiPointsinfoWithoutESKGet(options?: AxiosRequestConfig) {
        return PointsInfoApiFp(this.configuration).apiPointsinfoWithoutESKGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавить/редактировать информацию о странице для пользователей БЕЗ карты ЕСК
     * @param {PointsInfoForm} [pointsInfoForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsInfoApi
     */
    public apiPointsinfoWithoutESKPost(pointsInfoForm?: PointsInfoForm, options?: AxiosRequestConfig) {
        return PointsInfoApiFp(this.configuration).apiPointsinfoWithoutESKPost(pointsInfoForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Активация товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsActivateIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsActivateIdPost', 'id', id)
            const localVarPath = `/api/products/activate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание товара/услуги
         * @param {ProductForm} [productForm] Форма создания товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCreatePost: async (productForm?: ProductForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание товара/услуги
         * @param {ProductForm2} [productForm2] Форма создания товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCreateV2Post: async (productForm2?: ProductForm2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/create/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productForm2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение обрезанного изображение товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCroppedimageCroppedImageIdGet: async (croppedImageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'croppedImageId' is not null or undefined
            assertParamExists('apiProductsCroppedimageCroppedImageIdGet', 'croppedImageId', croppedImageId)
            const localVarPath = `/api/products/croppedimage/{croppedImageId}`
                .replace(`{${"croppedImageId"}}`, encodeURIComponent(String(croppedImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление обрезанного изображения товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCroppedimageDeleteCroppedImageIdPost: async (croppedImageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'croppedImageId' is not null or undefined
            assertParamExists('apiProductsCroppedimageDeleteCroppedImageIdPost', 'croppedImageId', croppedImageId)
            const localVarPath = `/api/products/croppedimage/delete/{croppedImageId}`
                .replace(`{${"croppedImageId"}}`, encodeURIComponent(String(croppedImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование обрезанного изображения товара/услуги
         * @param {any} croppedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCroppedimagePost: async (croppedImage: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'croppedImage' is not null or undefined
            assertParamExists('apiProductsCroppedimagePost', 'croppedImage', croppedImage)
            const localVarPath = `/api/products/croppedimage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (croppedImage !== undefined) { 
                localVarFormParams.append('croppedImage', croppedImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Деактивация товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsDeactivateIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsDeactivateIdPost', 'id', id)
            const localVarPath = `/api/products/deactivate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отклонить товар от ЕСК
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsDisapproveIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsDisapproveIdPost', 'id', id)
            const localVarPath = `/api/products/disapprove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {ProductForm} [productForm] Форма редактирования товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsEditIdPost: async (id: string, productForm?: ProductForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsEditIdPost', 'id', id)
            const localVarPath = `/api/products/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {ProductForm2} [productForm2] Форма редактирования товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsEditIdV2Post: async (id: string, productForm2?: ProductForm2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsEditIdV2Post', 'id', id)
            const localVarPath = `/api/products/edit/{id}/v2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productForm2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка товаров/услуг
         * @param {boolean} [isActive] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet: async (isActive?: boolean, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdGet', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdV2Get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdV2Get', 'id', id)
            const localVarPath = `/api/products/{id}/v2`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление изображения товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImageDeleteImageIdPost: async (imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('apiProductsImageDeleteImageIdPost', 'imageId', imageId)
            const localVarPath = `/api/products/image/delete/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение изображение товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImageImageIdGet: async (imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('apiProductsImageImageIdGet', 'imageId', imageId)
            const localVarPath = `/api/products/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование изображения товара/услуги
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImagePost: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('apiProductsImagePost', 'image', image)
            const localVarPath = `/api/products/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Активация товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsActivateIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsActivateIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание товара/услуги
         * @param {ProductForm} [productForm] Форма создания товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsCreatePost(productForm?: ProductForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsCreatePost(productForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание товара/услуги
         * @param {ProductForm2} [productForm2] Форма создания товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsCreateV2Post(productForm2?: ProductForm2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsCreateV2Post(productForm2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение обрезанного изображение товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsCroppedimageCroppedImageIdGet(croppedImageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление обрезанного изображения товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsCroppedimageDeleteCroppedImageIdPost(croppedImageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsCroppedimageDeleteCroppedImageIdPost(croppedImageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование обрезанного изображения товара/услуги
         * @param {any} croppedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsCroppedimagePost(croppedImage: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsCroppedimagePost(croppedImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Деактивация товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsDeactivateIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsDeactivateIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отклонить товар от ЕСК
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsDisapproveIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsDisapproveIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {ProductForm} [productForm] Форма редактирования товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsEditIdPost(id: string, productForm?: ProductForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsEditIdPost(id, productForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {ProductForm2} [productForm2] Форма редактирования товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsEditIdV2Post(id: string, productForm2?: ProductForm2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsEditIdV2Post(id, productForm2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка товаров/услуг
         * @param {boolean} [isActive] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsGet(isActive?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductPagedModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsGet(isActive, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdV2Get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdV2Get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление изображения товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsImageDeleteImageIdPost(imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsImageDeleteImageIdPost(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение изображение товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsImageImageIdGet(imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsImageImageIdGet(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование изображения товара/услуги
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsImagePost(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsImagePost(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Активация товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsActivateIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsActivateIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание товара/услуги
         * @param {ProductForm} [productForm] Форма создания товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCreatePost(productForm?: ProductForm, options?: any): AxiosPromise<string> {
            return localVarFp.apiProductsCreatePost(productForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание товара/услуги
         * @param {ProductForm2} [productForm2] Форма создания товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCreateV2Post(productForm2?: ProductForm2, options?: any): AxiosPromise<string> {
            return localVarFp.apiProductsCreateV2Post(productForm2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение обрезанного изображение товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsCroppedimageCroppedImageIdGet(croppedImageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление обрезанного изображения товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCroppedimageDeleteCroppedImageIdPost(croppedImageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsCroppedimageDeleteCroppedImageIdPost(croppedImageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование обрезанного изображения товара/услуги
         * @param {any} croppedImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsCroppedimagePost(croppedImage: any, options?: any): AxiosPromise<string> {
            return localVarFp.apiProductsCroppedimagePost(croppedImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Деактивация товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsDeactivateIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsDeactivateIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отклонить товар от ЕСК
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsDisapproveIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsDisapproveIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {ProductForm} [productForm] Форма редактирования товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsEditIdPost(id: string, productForm?: ProductForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsEditIdPost(id, productForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {ProductForm2} [productForm2] Форма редактирования товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsEditIdV2Post(id: string, productForm2?: ProductForm2, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsEditIdV2Post(id, productForm2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка товаров/услуг
         * @param {boolean} [isActive] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet(isActive?: boolean, skip?: number, take?: number, options?: any): AxiosPromise<Array<ProductPagedModel>> {
            return localVarFp.apiProductsGet(isActive, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet(id: string, options?: any): AxiosPromise<ProductDto> {
            return localVarFp.apiProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение товара/услуги
         * @param {string} id Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdV2Get(id: string, options?: any): AxiosPromise<ProductDto2> {
            return localVarFp.apiProductsIdV2Get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление изображения товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImageDeleteImageIdPost(imageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsImageDeleteImageIdPost(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение изображение товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImageImageIdGet(imageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsImageImageIdGet(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование изображения товара/услуги
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImagePost(image: any, options?: any): AxiosPromise<string> {
            return localVarFp.apiProductsImagePost(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - interface
 * @export
 * @interface ProductsApi
 */
export interface ProductsApiInterface {
    /**
     * 
     * @summary Активация товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsActivateIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Создание товара/услуги
     * @param {ProductForm} [productForm] Форма создания товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsCreatePost(productForm?: ProductForm, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Создание товара/услуги
     * @param {ProductForm2} [productForm2] Форма создания товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsCreateV2Post(productForm2?: ProductForm2, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Получение обрезанного изображение товара/услуги
     * @param {string} croppedImageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Удаление обрезанного изображения товара/услуги
     * @param {string} croppedImageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsCroppedimageDeleteCroppedImageIdPost(croppedImageId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование обрезанного изображения товара/услуги
     * @param {any} croppedImage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsCroppedimagePost(croppedImage: any, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Деактивация товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsDeactivateIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Отклонить товар от ЕСК
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsDisapproveIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {ProductForm} [productForm] Форма редактирования товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsEditIdPost(id: string, productForm?: ProductForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {ProductForm2} [productForm2] Форма редактирования товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsEditIdV2Post(id: string, productForm2?: ProductForm2, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка товаров/услуг
     * @param {boolean} [isActive] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsGet(isActive?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ProductPagedModel>>;

    /**
     * 
     * @summary Получение товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ProductDto>;

    /**
     * 
     * @summary Получение товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsIdV2Get(id: string, options?: AxiosRequestConfig): AxiosPromise<ProductDto2>;

    /**
     * 
     * @summary Удаление изображения товара/услуги
     * @param {string} imageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsImageDeleteImageIdPost(imageId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение изображение товара/услуги
     * @param {string} imageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsImageImageIdGet(imageId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование изображения товара/услуги
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiProductsImagePost(image: any, options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI implements ProductsApiInterface {
    /**
     * 
     * @summary Активация товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsActivateIdPost(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsActivateIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание товара/услуги
     * @param {ProductForm} [productForm] Форма создания товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsCreatePost(productForm?: ProductForm, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsCreatePost(productForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание товара/услуги
     * @param {ProductForm2} [productForm2] Форма создания товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsCreateV2Post(productForm2?: ProductForm2, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsCreateV2Post(productForm2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение обрезанного изображение товара/услуги
     * @param {string} croppedImageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsCroppedimageCroppedImageIdGet(croppedImageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление обрезанного изображения товара/услуги
     * @param {string} croppedImageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsCroppedimageDeleteCroppedImageIdPost(croppedImageId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsCroppedimageDeleteCroppedImageIdPost(croppedImageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование обрезанного изображения товара/услуги
     * @param {any} croppedImage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsCroppedimagePost(croppedImage: any, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsCroppedimagePost(croppedImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Деактивация товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsDeactivateIdPost(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsDeactivateIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отклонить товар от ЕСК
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsDisapproveIdPost(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsDisapproveIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {ProductForm} [productForm] Форма редактирования товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsEditIdPost(id: string, productForm?: ProductForm, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsEditIdPost(id, productForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {ProductForm2} [productForm2] Форма редактирования товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsEditIdV2Post(id: string, productForm2?: ProductForm2, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsEditIdV2Post(id, productForm2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка товаров/услуг
     * @param {boolean} [isActive] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsGet(isActive?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsGet(isActive, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsIdGet(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение товара/услуги
     * @param {string} id Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsIdV2Get(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsIdV2Get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление изображения товара/услуги
     * @param {string} imageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsImageDeleteImageIdPost(imageId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsImageDeleteImageIdPost(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение изображение товара/услуги
     * @param {string} imageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsImageImageIdGet(imageId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsImageImageIdGet(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование изображения товара/услуги
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsImagePost(image: any, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsImagePost(image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectSettingsApi - axios parameter creator
 * @export
 */
export const ProjectSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение настроек отображение проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projectsettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование настроек отображения проекта
         * @param {ProjectSettingsForm} [projectSettingsForm] Форма настроек
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsettingsPost: async (projectSettingsForm?: ProjectSettingsForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projectsettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectSettingsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectSettingsApi - functional programming interface
 * @export
 */
export const ProjectSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение настроек отображение проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectsettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectsettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование настроек отображения проекта
         * @param {ProjectSettingsForm} [projectSettingsForm] Форма настроек
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProjectsettingsPost(projectSettingsForm?: ProjectSettingsForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProjectsettingsPost(projectSettingsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectSettingsApi - factory interface
 * @export
 */
export const ProjectSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение настроек отображение проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsettingsGet(options?: any): AxiosPromise<ProjectSettingsModel> {
            return localVarFp.apiProjectsettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование настроек отображения проекта
         * @param {ProjectSettingsForm} [projectSettingsForm] Форма настроек
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProjectsettingsPost(projectSettingsForm?: ProjectSettingsForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiProjectsettingsPost(projectSettingsForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectSettingsApi - interface
 * @export
 * @interface ProjectSettingsApi
 */
export interface ProjectSettingsApiInterface {
    /**
     * 
     * @summary Получение настроек отображение проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApiInterface
     */
    apiProjectsettingsGet(options?: AxiosRequestConfig): AxiosPromise<ProjectSettingsModel>;

    /**
     * 
     * @summary Редактирование настроек отображения проекта
     * @param {ProjectSettingsForm} [projectSettingsForm] Форма настроек
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApiInterface
     */
    apiProjectsettingsPost(projectSettingsForm?: ProjectSettingsForm, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProjectSettingsApi - object-oriented interface
 * @export
 * @class ProjectSettingsApi
 * @extends {BaseAPI}
 */
export class ProjectSettingsApi extends BaseAPI implements ProjectSettingsApiInterface {
    /**
     * 
     * @summary Получение настроек отображение проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public apiProjectsettingsGet(options?: AxiosRequestConfig) {
        return ProjectSettingsApiFp(this.configuration).apiProjectsettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование настроек отображения проекта
     * @param {ProjectSettingsForm} [projectSettingsForm] Форма настроек
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public apiProjectsettingsPost(projectSettingsForm?: ProjectSettingsForm, options?: AxiosRequestConfig) {
        return ProjectSettingsApiFp(this.configuration).apiProjectsettingsPost(projectSettingsForm, options).then((request) => request(this.axios, this.basePath));
    }
}


