import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { StepsFormStore } from "newApp/stores/common/StepsFormStore";
import { StepItemStore } from "newApp/stores/common/StepsFormStore/StepItemStore";

export class StepsEditPageStore {
  isLoadings = {
    getSteps: false,
    saveSteps: false,
  };

  id = "";

  stepsForm = new StepsFormStore();

  error: null | {
    id?: string;
    text?: string;
  } = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getSteps();
    this.stepsForm.init();
  };

  reset = () => {
    this.stepsForm.reset();
    this.id = "";
    this.resetError();
  };

  resetError = () => {
    this.error = null;
  };

  onSave = async () => {
    await this.stepsForm.validate();
    if (this.stepsForm.isValid) {
      const result = await this.saveSteps();
      return result;
    }
    return false;
  };

  getSteps = () => {
    this.isLoadings.getSteps = true;
    return API.admin.ViolationTemplates.apiViolationTemplatesIdGet(this.id)
      .then(({ data }) => {
        try {
          runInAction(() => {
            this.setCategoryId(data["categories"] as any);
            this.stepsForm.steps = data["steps"].map(
              (s) =>
                new StepItemStore({
                  stepType: s["id"],
                  fields: s["fieldTypes"]?.map((i) => i["id"]),
                })
            );
          });
        } catch (error: any) {
          console.error(error);
        }
      })
      .catch((e) => {
        console.error(e);
        return false;
      })
      .then(() => {
        runInAction(() => {
          this.isLoadings.getSteps = false;
        });
      });
  };

  setCategoryId = (category: ICategory) => {
    let categoryId = "";

    const parser = (c: ICategory) => {
      categoryId = c?.id || null;
      if (c.child) parser(c.child);
    };
    parser(category);
    this.stepsForm.form.changeValueByKey(categoryId, "categoryId");
  };

  saveSteps = () => {
    this.isLoadings.saveSteps = true;
    return API.admin.ViolationTemplates.apiViolationTemplatesIdEditPost(
      this.id,
      {
        SubCategoryId: this.stepsForm.form.values.categoryId,
        ViolationTemplateSteps: this.stepsForm.steps.map((s) => ({
          StepId: s.form.values.stepType,
          FieldTypeIds: s.fields,
        })),
      }
    )
      .then(() => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        runInAction(() => {
          const id = e?.response?.data?.data?.id || null;
          const text = e?.response?.data?.error || "Ошибка";
          if (id || text) {
            this.error = { id, text };
          }
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveSteps = false;
        });
      });
  };
}

interface ICategory {
  child: null | ICategory;
  daysLimit: number;
  id: string;
  name: string;
  organizationId: string;
  parentId: string;
  state: number;
}
