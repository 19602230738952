import Dropzone from "newApp/kit/components/Dropzone";
import { ComponentProps } from "react";

type TDropzoneFileTypes = ComponentProps<typeof Dropzone>["acceptFileTypes"];

export const VOTES_FILE_TYPES: TDropzoneFileTypes = [
  "image/*",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/pdf",
];
