import LoaderSpinner from "components/Loader";
import { observer } from "mobx-react";
import DeclarationsListFilter from "newApp/components/DeclarationsListFilter";
import ExecutionNavigator from "newApp/components/ExecutionNavigator";
import MainLayout from "newApp/components/layouts/MainLayout";
import ListCounter from "newApp/components/ListCounter";
import ListOrMap from "newApp/components/ListOrMap";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import Pagination from "newApp/kit/components/Pagination";
import {
  URL_EXECUTION_ACCEPTED,
  URL_EXECUTION_CLOSED,
  URL_EXECUTION_INWORK,
} from "newApp/routing/urls";
import { ExecutionListPageStore } from "newApp/stores/pages/execution/DeclarationListFilterStore";
import React, { useEffect } from "react";
import { useLocation } from "react-router";

const store = new ExecutionListPageStore();

const ExecutionListPage: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    store.init();
    if (pathname === URL_EXECUTION_ACCEPTED) store.getAcceptedDeclarations();
    if (pathname === URL_EXECUTION_INWORK) store.getInWorkDeclarations();
    if (pathname === URL_EXECUTION_CLOSED) store.getInWorkClosed();

    return () => store.reset();
  }, [pathname]);
  return (
    <MainLayout>
      <H1>Исполнение сообщений</H1>
      <FlexColumn>
        <ExecutionNavigator />

        {store.isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <DeclarationsListFilter
              form={store.filter.form}
              isOpen={!!store.filter.isShowModal}
              onClose={store.filter.closeModal}
              options={store.filter.options}
              getList={store.getDeclarations}
            />
            <Divider color="grey" />
            <ListCounter countOnPage={store.declarations.length} />
            <ListOrMap declarations={store.declarations} />
            <Pagination {...store.pagination} />
          </>
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ExecutionListPage);
