import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { IDeclarationListItem } from "newApp/components/DeclarationsList/index.types";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";
import { getRuDate } from "newApp/utils/getRuDate";

import { DeclarationListFilterStore } from "../../../../common/ModerationListFilterStore";
import { TGetDeclationsResponseData } from "./index.types";
import { NewMessagesStore } from "./NewMessagesStore";

export class ModerationDeclarationListStore {
  isLoading = false;
  declarations: IDeclarationListItem[] = [];
  pagination = new PaginationStore(
    {
      sizes: [10, 20, 50, 100, 1000],
      pageSize: 20,
    },
    () => this.getDeclarations()
  );
  newmessages = new NewMessagesStore();
  isHasNewMessageInCreated: boolean = false;
  isHasNewMessagesInWork: boolean = false;

  status: number | undefined;
  filter = new DeclarationListFilterStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.newmessages.init();
    this.filter.init();
  };

  reset = () => {
    this.resetDeclarations();
    this.pagination.reset();
    this.newmessages.reset();
    this.filter.reset();
  };

  resetDeclarations = () => {
    this.declarations = [];
    this.isHasNewMessageInCreated = false;
    this.isHasNewMessagesInWork = false;
  };

  getDeclarations = () => {
    const orderByDescending =
      this.filter.form.values.orderByDescending === null
        ? undefined
        : this.filter.form.values.orderByDescending;

    runInAction(() => {
      this.isLoading = true;
    });
    return API.admin.Violations.apiViolationsFilteredlistPost(
      orderByDescending,
      this.pagination.pageSize,
      this.pagination.currentPage,
      {
        ViolationStatus: this.status,
        Number: this.filter.form.values.number
          ? Number(this.filter.form.values.number)
          : undefined,
        IsPublished:
          this.filter.form.values.isPublished === null
            ? undefined
            : !!this.filter.form.values.isPublished,
        District:
          this.filter.form.values.district === null
            ? undefined
            : this.filter.form.values.district,
        CategoryId:
          this.filter.form.values.categoryId === null
            ? undefined
            : this.filter.form.values.categoryId,
        OrganizationId:
          this.filter.form.values.organizationId === null
            ? undefined
            : this.filter.form.values.organizationId,
      }
    )
      .then((res: any) => {
        runInAction(() => {
          this.declarations = (
            res.data! as TGetDeclationsResponseData
          ).object.declarationPage.map((d) => ({
            category: d.Category || null,
            date: getRuDate(d.DateOfCreation),
            daysLeft: d.DaysLeft || null,
            id: String(d.Id),
            number: d.Number || null,
            organization: d.Organization || null,
            subCategory: d.SubCategory || null,
            isHasNewMessage: !!d.HasNewMessages,
            location: [d.Coordinates.Latitude, d.Coordinates.Longitude],
            isNotChecked: !d.IsChecked,
            isOnControl: !!d["IsOnAddControl"],
          }));
          this.pagination.pages = Number(
            (res.data! as TGetDeclationsResponseData).object.pageCount as number
          );

          this.isHasNewMessageInCreated = (
            res.data! as TGetDeclationsResponseData
          ).object.hasNewMessageInCreated;

          this.isHasNewMessagesInWork = (
            res.data! as TGetDeclationsResponseData
          ).object.hasNewMessagesInWork;
        });
      })
      .catch(this.resetDeclarations)
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  getCreated = () => {
    this.status = 0;
    this.getDeclarations();
  };

  getAccepted = () => {
    this.status = 1;
    this.getDeclarations();
  };

  getRejected = () => {
    this.status = 3;
    this.getDeclarations();
  };

  getClosed = () => {
    this.status = 4;
    this.getDeclarations();
  };
}
