import { observer } from "mobx-react";
import React from "react";
import FlexColumn from "../../kit/components/FlexColumn";
import H3 from "../../kit/components/H3";
import TextField from "../../kit/components/TextField";
import { FormStore } from "../../kit/stores/FormStore";
import { TUserData } from "../../stores/common/UserEditCreateFormStore/index.types";

interface IProps {
  form: FormStore<TUserData>;
}

const UserEditCreateForm: React.FC<IProps> = ({ form }) => {
  return (
    <div className="user-form">
      <H3>Личная информация</H3>
      <FlexColumn>
        <TextField placeholder="Фамилия" {...form.adapters.lastName} />
        <TextField placeholder="Имя" {...form.adapters.firstName} />
        <TextField placeholder="Отчество" {...form.adapters.middleName} />
        <TextField placeholder="email" {...form.adapters.email} />
        <TextField placeholder="Мобильный телефон" {...form.adapters.phone} />
        <TextField
          placeholder="Дополнительный телефон"
          {...form.adapters.otherPhones}
        />
      </FlexColumn>
      <H3>Данные для входа</H3>
      <FlexColumn>
        <TextField placeholder="Логин" {...form.adapters.login} />
        <TextField
          type="password"
          placeholder="Пароль"
          {...form.adapters.password}
        />
        <TextField
          type="password"
          placeholder="Повторите пароль"
          {...form.adapters.passwordConfirm}
        />
      </FlexColumn>
    </div>
  );
};

export default observer(UserEditCreateForm);
