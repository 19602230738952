import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import MainLayout from "../../../../../components/layouts/MainLayout";
import UserEditCreateForm from "../../../../../components/UserEditCreateForm";
import UserEditCreateRoles from "../../../../../components/UserEditCreateRoles";
import Button from "../../../../../kit/components/Button";
import ButtonsContainer from "../../../../../kit/components/ButtonsContainer";
import Divider from "../../../../../kit/components/Divider";
import FlexColumn from "../../../../../kit/components/FlexColumn";
import H1 from "../../../../../kit/components/H1";
import { URL_ADMISSION_USERS } from "../../../../../routing/urls";
import { UserItemEditPageStore } from "../../../../../stores/pages/admission/UserItemEditPageStore";

const store = new UserItemEditPageStore();

const UserItemEditPage: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) store.init(id);
    return () => store.reset();
  }, [id]);

  const goToUserItemPage = () => {
    push(URL_ADMISSION_USERS + `/${id}`);
  };

  const onClickSave = async () => {
    if (id) {
      const isSaved = await store.saveEditedUser(id);
      if (isSaved) goToUserItemPage();
    }
  };

  const onClickCancel = () => {
    goToUserItemPage();
  };

  return (
    <MainLayout
      isLoading={store.isLoadings.getUser || store.isLoadings.postUser}
    >
      <H1>Редактирование пользователя</H1>
      <FlexColumn>
        <UserEditCreateForm form={store.forms.form} />
        <UserEditCreateRoles
          values={store.forms.roles.values}
          options={store.forms.roles.rolesOptions}
          onChange={store.forms.roles.onChange}
        />
        <Divider />
        <ButtonsContainer justifyRight>
          <Button onClick={onClickSave}>Сохранить изменения</Button>
          <Button variant="secondary" onClick={onClickCancel}>
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(UserItemEditPage);
