import "./styles.scss";

import { observer } from "mobx-react";
import React from "react";

import Button from "../Button";
import IconLeft from "../IconLeft";
import IconRight from "../IconRight";
import { IPaginationProps } from "./index.types";

const Pagination: React.FC<IPaginationProps> = (props) => {
  const {
    currentPage,
    pageSize,
    sizes = [],
    pages,
    onChange,
    onSizeClick,
    className = "",
  } = props;

  let navButtons: JSX.Element[] = [];

  const startCount = currentPage < 5 ? 1 : currentPage - 2;

  if (currentPage > 4) {
    const goToStart = () => {
      onChange && onChange(1, "pageNumber");
    };
    navButtons.push(
      <Button
        size="small"
        onClick={goToStart}
        variant={"secondary"}
        className="pagination__button"
        key={1}
      >
        1
      </Button>
    );
    navButtons.push(
      <Button
        variant="secondary"
        size="small"
        className="pagination__button--disabled"
        key="start"
        onClick={() => {}}
        disabled
        data-testid={"disable-button"}
      >
        ...
      </Button>
    );
  }

  for (let i = startCount; i <= pages; i++) {
    if (navButtons.length > 6) {
      const handler = () => {
        onChange && onChange(pages, "pageNumber");
      };
      navButtons.push(
        <Button
          variant="secondary"
          size="small"
          className="pagination__button--disabled"
          key="end"
          onClick={() => {}}
          data-testid={"disable-button"}
          disabled
        >
          ...
        </Button>
      );

      navButtons.push(
        <Button
          size="small"
          onClick={handler}
          variant={"secondary"}
          className="pagination__button"
          key={pages}
        >
          {pages}
        </Button>
      );

      break;
    }

    const handler = () => {
      onChange && onChange(i, "pageNumber");
    };

    navButtons.push(
      <Button
        size="small"
        onClick={handler}
        variant={i === currentPage ? "primary" : "secondary"}
        className="pagination__button"
        key={i}
      >
        {i}
      </Button>
    );
  }

  const increment = () => {
    onChange && onChange(currentPage + 1, "pageNumber");
  };

  const decrement = () => {
    onChange && onChange(currentPage - 1, "pageNumber");
  };

  return (
    <div className={"pagination " + className} data-testid="pagination">
      {pages > 1 && (
        <div className="pagination__count">
          <Button
            aria-label="decrement"
            id="decrement"
            size="small"
            onClick={decrement}
            variant="secondary"
            disabled={currentPage <= 1}
            className={currentPage <= 1 ? "pagination__button--disabled" : ""}
          >
            <IconLeft />
          </Button>
          <div className="pagination__buttons-group">{navButtons}</div>
          <Button
            aria-label="increment"
            size="small"
            onClick={increment}
            variant="secondary"
            disabled={currentPage === pages}
            className={
              currentPage === pages ? "pagination__button--disabled" : ""
            }
          >
            <IconRight />
          </Button>
        </div>
      )}
      {!!sizes?.length && (
        <>
          <p className="page__support">Количество элементов на странице</p>
          <div className="pagination__buttons-group">
            {sizes?.map((size) => {
              const handler = () => {
                onSizeClick && onSizeClick(size, "pageSize");
              };

              return (
                <Button
                  size="small"
                  onClick={handler}
                  variant={pageSize === size ? "primary" : "secondary"}
                  className="pagination__button"
                  key={size}
                >
                  {size}
                </Button>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Pagination);
