import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";
import { getDataURL } from "newApp/kit/utils/getDataURL";
import { AnswerStore, IAnswerStoreForm } from "./AnswerStore";
import validationSchema from "./validationSchema";

export class QuestionStore {
  form = new FormStore<IQuestionForm>(
    {
      text: null,
      type: null,
      isRequired: null,
      hasPhoto: null,
      id: null,
      fileId: null,
      hasOtherOption: null,
      files: [],
    },
    validationSchema
  );

  isLoadings = {
    uploadFile: false,
  };

  files: IFilesPreviewFile[] = [];

  answers: AnswerStore[] = [];
  fileIds: string[] = [];

  options: { type: ISelectOption[] } = {
    type: [
      { value: "Radiobutton", text: "Один из списка" },
      { value: "Checkbox", text: "Несколько из списка" },
      { value: "TextField", text: "Свободный ответ" },
    ],
  };

  constructor(form?: IQuestionConstructorForm) {
    if (form) this.setForm(form);
    if (form?.answers) this.setAnswers(form?.answers);
    makeAutoObservable(this);
  }

  setForm = (form: IQuestionConstructorForm) => {
    this.form.setValues({
      fileId: form.fileId,
      hasOtherOption: !!form.hasOtherOption,
      id: form.id,
      isRequired: !!form.isRequired,
      text: form.text,
      type: form.type,
      hasPhoto: !!form.hasPhoto,
    });
    if (form.files.length) {
      form.files.forEach((item) => this.fileIds.push(item.id));
      this.files = form.files.map((item) => ({
        reference: URL_API_ADMIN + `/api/questions/files/${item.id}`,
        type:
          item.name.split(".")[1] === "png"
            ? "image"
            : item.name.split(".")[1] === "jpg"
            ? "image"
            : item.name.split(".")[1] === "jpeg"
            ? "image"
            : item.name.split(".")[1],
        id: item.id,
        title: item.name,
      }));
    }
  };

  setAnswers = (answers: IAnswerStoreForm[]) => {
    this.answers = answers.map((a) => new AnswerStore(a));
  };

  resetFiles = () => {
    this.files = [];
  };

  setFile = async (params: {
    id?: string;
    file?: File;
    reference?: string;
  }) => {
    this.files.push({
      reference: params.reference || URL_API_ADMIN + "/api/files/" + params.id,
      title: params.file.name,
    });
  };

  onAddFiles = (files: File[]) => {
    runInAction(() => {
      //   this.resetFiles();
      files.forEach((f, idx) => {
        getDataURL(f)
          .then((reference) => {
            runInAction(() => {
              this.files.push({
                file: f,
                type: f.type.split("/")[0],
                reference,
              });
            });
          })
          .catch(() => {
            console.error("Ошибка в создании превью");
          });
      });
      files.forEach((file) => {
        this.uploadFile(file)
          .then((data) => {
            this.form.changeValueByKey(data[0]["id"], "fileId");
          })
          .catch(() => {
            this.form.reset("fileId");
          });
      });
    });
  };

  removeFile = (idx: number) => {
    const id = this.fileIds[idx];
    API.admin.Files.apiQuestionsFilesFileIdDeletePost(id).then(() => {
      this.files.splice(idx, 1);
      this.fileIds.splice(idx, 1);
    });
  };

  addAnswer = () => {
    this.answers.push(new AnswerStore());
  };

  removeAnswer = (idx: number) => {
    this.answers.splice(idx, 1);
  };

  validate = () => {
    this.form.validate();
    this.answers.forEach((a) => a.validate());
  };

  get isValid() {
    return this.form.isValid && !this.answers.some((i) => !i.isValid);
  }

  uploadFile = (file: File) => {
    this.isLoadings.uploadFile = true;
    return API.admin.Files.apiQuestionsFilesSetPost(file)
      .then(({ data }) => {
        this.fileIds.push(data.Id);
        return data;
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.uploadFile = false;
        });
      });
  };

  onClickRemove = () => {
    this.files = [];
  };

  onChangeType = (value: any, key?: string) => {
    this.form.changeValue(value, key);
    if (value === "TextField") {
      this.form.changeValueByKey(true, "hasOtherOption");
      this.answers = [];
    } else {
      this.form.changeValueByKey(false, "hasOtherOption");
    }
  };
}

export interface IQuestionForm {
  text: null | string;
  fileId: null | string;
  type: null | string;
  isRequired: null | boolean;
  hasPhoto?: null | boolean | string;
  id: null | string;
  hasOtherOption: null | boolean;
  files: any[];
}

export interface IQuestionConstructorForm extends IQuestionForm {
  answers: IAnswerStoreForm[];
}
