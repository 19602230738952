import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import RubricForm from "newApp/components/RubricForm";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_NEWS_RUBRICS } from "newApp/routing/urls";
import { RubricEditPageStore } from "newApp/stores/pages/site/rubrics/RubricEditPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new RubricEditPageStore();

const RubricEditPage: React.FC = () => {
  const { push } = useHistory();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  const onClickSave = async () => {
    const saveSuccess = await store.onClickSave();
    if (saveSuccess) push(URL_SITE_NEWS_RUBRICS);
  };

  return (
    <MainLayout isLoading={store.isLoadings.getRubric}>
      <H1>Редактирование рубрики</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_NEWS_RUBRICS}>
          Назад к списку рубрик
        </ButtonBack>
        <RubricForm {...store.rubricForm} />
        <ButtonsContainer>
          <Button onClick={onClickSave} disabled={store.isLoadings.saveRubric}>
            Сохранить изменения
          </Button>
          <Button
            onClick={() => push(URL_SITE_NEWS_RUBRICS)}
            variant="secondary"
          >
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(RubricEditPage);
