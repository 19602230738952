import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../../../api";

export class UsersListStore {
  isLoading = false;

  users: IUser[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getUsers();
  };

  reset = () => {
    this.users = [];
  };

  getUsers = () => {
    this.isLoading = true;
    return API.access.Users.apiUsersGet()
      .then((res: any) => {
        runInAction(() => {
          try {
            type TData = { users: IUser[] };
            this.users = (res.data! as TData).users;
          } catch (error: any) {
            console.error(error);
            this.users = [];
          }
        });
      })
      .catch(() => {
        runInAction(() => {
          this.users = [];
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };
}

export type IUser = {
  id: string | null;
  name: string | null;
  login: string | null;
  adLogin: string | null;
  email: string | null;
  notInThisSystem: false;
  isDeactivated: false;
  isOnline: false;
  lastActionPeriod: string | null;
  lastActionDate: string | null;
  organizations: (string | null)[];
  roles: (string | null)[];
};
