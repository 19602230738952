import "./styles.scss";

import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";
import { URL_VOTING_EDIT } from "newApp/routing/urls";
import React from "react";
import { useHistory } from "react-router";

export interface IVotingListItem {
  title: string;
  onChangeBanner: () => void;
  onChangeMain: () => void;
  onChangePublish: () => void;
  remove: () => void;
  isBanner: boolean;
  isMain: boolean;
  isPublish: boolean;
  startDate?: Date | null;
  finishDate?: Date | null;
  id: string;
  isLoading: boolean;
}
export interface VotingListProps {
  votings?: IVotingListItem[];
  mainVote: null | {
    id: string;
    title: string;
  };
  isHideBannerButtons?: boolean;
  isHideMainButtons?: boolean;
}

const VotingList: React.FC<VotingListProps> = (props) => {
  const {
    push,
    location: { pathname },
  } = useHistory();

  return (
    <div className="voting-list">
      {props.votings?.map((item, idx) => (
        <div
          className={[
            "voting-list__item",
            item.isPublish ? "voting-list__item--is-publish" : "",
          ].join(" ")}
          key={idx}
        >
          <div className="voting-list__item-top">
            <div className="voting-list__item-top-info">
              <div
                className="voting-list__item-top-info-title"
                onClick={() => push(pathname + "/" + item.id)}
              >
                {item.title}
              </div>
              <div className="voting-list__item-top-info-dates">
                {item.startDate
                  ? new Date(item.startDate).toLocaleDateString() +
                    ", " +
                    new Date(item.startDate).toLocaleTimeString()
                  : ""}{" "}
                {item.finishDate
                  ? "— " +
                    new Date(item.finishDate).toLocaleDateString() +
                    ", " +
                    new Date(item.finishDate).toLocaleTimeString()
                  : ""}
              </div>
            </div>
            <div className="voting-list__item-top-buttons">
              <div className="voting-list__item-top-buttons-container">
                <div
                  className="voting-list__item-top-buttons-edit"
                  onClick={() => push(URL_VOTING_EDIT + "/" + item.id)}
                >
                  Редактировать
                </div>
                <div className="voting-list__item-top-buttons-divider" />
                <div
                  className="voting-list__item-top-buttons-remove"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Вы уверены что хотите удалить ${item.title}? Отменить это действие будет невозможно`
                      )
                    ) {
                      item.remove();
                    }
                  }}
                >
                  Удалить
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="voting-list__item-bottom">
            {!props.isHideBannerButtons && (
              <Checkbox
                onChange={item.onChangeBanner}
                value={item.isBanner}
                disabled={item.isLoading}
              >
                Баннер
              </Checkbox>
            )}
            {!props.isHideMainButtons && (
              <Checkbox
                onChange={() => {
                  if (!item.isMain && item.id !== props.mainVote?.id) {
                    if (
                      window.confirm(
                        `На сайте установлено главное голосование ${
                          props?.mainVote?.title || ""
                        }. Заменить его на данное голосование?`
                      )
                    )
                      item.onChangeMain();
                  } else {
                    item.onChangeMain();
                  }
                }}
                value={item.isMain}
                disabled={item.isLoading}
              >
                Главная
              </Checkbox>
            )}
            <Checkbox
              onChange={item.onChangePublish}
              value={item.isPublish}
              disabled={item.isLoading}
            >
              Опубликовано
            </Checkbox>
          </div>
        </div>
      ))}
    </div>
  );
};

export default observer(VotingList);
