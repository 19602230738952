export const URL_BASE = "";
export const URL_MAIN = URL_BASE + "/";
export const URL_LOGIN = URL_MAIN + "login";
export const URL_PROFILE = URL_MAIN + "profile";

//  Заявления
export const URL_DECLARATION = URL_BASE;
export const URL_MODERATION = URL_DECLARATION + "/moderation";
export const URL_MODERATION_ID = URL_MODERATION + "/:id";
export const URL_MODERATION_CREATED = URL_MODERATION + "/created";
export const URL_MODERATION_ACCEPTED = URL_MODERATION + "/accepted";
export const URL_MODERATION_REJECTED = URL_MODERATION + "/rejected";
export const URL_MODERATION_CLOSED = URL_MODERATION + "/closed";
export const URL_MODERATION_CREATED_ID = URL_MODERATION_CREATED + "/:id";
export const URL_MODERATION_ACCEPTED_ID = URL_MODERATION_ACCEPTED + "/:id";
export const URL_MODERATION_REJECTED_ID = URL_MODERATION_REJECTED + "/:id";
export const URL_MODERATION_CLOSED_ID = URL_MODERATION_CLOSED + "/:id";

export const URL_EXECUTION = URL_DECLARATION + "/execution";
export const URL_EXECUTION_ACCEPTED = URL_EXECUTION + "/accepted";
export const URL_EXECUTION_INWORK = URL_EXECUTION + "/inwork";
export const URL_EXECUTION_CLOSED = URL_EXECUTION + "/closed";
export const URL_EXECUTION_ACCEPTED_ID = URL_EXECUTION_ACCEPTED + "/:id";
export const URL_EXECUTION_INWORK_ID = URL_EXECUTION_INWORK + "/:id";
export const URL_EXECUTION_CLOSED_ID = URL_EXECUTION_CLOSED + "/:id";

export const URL_ADMISSION = URL_BASE + "/admission";

export const URL_ADMISSION_ROLES = URL_ADMISSION + "/roles";
export const URL_ADMISSION_ROLES_ID = URL_ADMISSION_ROLES + "/:id";
export const URL_ADMISSION_ROLES_ID_EDIT = URL_ADMISSION_ROLES_ID + "/edit";
export const URL_ADMISSION_ROLES_CREATE = URL_ADMISSION_ROLES + "/add";

export const URL_ADMISSION_USERS = URL_ADMISSION + "/users";
export const URL_ADMISSION_USERS_ID = URL_ADMISSION_USERS + "/:id";
export const URL_ADMISSION_USERS_ID_EDIT = URL_ADMISSION_USERS + "/:id/edit";
export const URL_ADMISSION_USERS_CREATE = URL_ADMISSION_USERS + "/add";

export const URL_SITE = URL_BASE + "/site";
export const URL_SITE_NEWS = URL_SITE + "/news";
export const URL_SITE_NEWS_ID = URL_SITE_NEWS + "/:id";
export const URL_SITE_NEWS_ID_EDIT = URL_SITE_NEWS_ID + "/edit";
export const URL_SITE_NEWS_CREATE = URL_SITE_NEWS + "/create";
export const URL_SITE_NEWS_RUBRICS = URL_SITE_NEWS + "/rubrics";
export const URL_SITE_NEWS_RUBRICS_ID = URL_SITE_NEWS_RUBRICS + "/:id";
export const URL_SITE_NEWS_RUBRICS_ID_EDIT = URL_SITE_NEWS_RUBRICS_ID + "/edit";
export const URL_SITE_NEWS_RUBRICS_CREATE = URL_SITE_NEWS_RUBRICS + "/create";
export const URL_SITE_FAQ = URL_SITE + "/faq";
export const URL_SITE_DOCS = URL_SITE + "/docs";
export const URL_SITE_DOCS_TERMS = URL_SITE_DOCS + "/terms";
export const URL_SITE_DOCS_TERMS_EDIT = URL_SITE_DOCS_TERMS + "/edit";
export const URL_SITE_DOCS_AGREEMENTS = URL_SITE_DOCS + "/agreements";
export const URL_SITE_DOCS_AGREEMENTS_EDIT = URL_SITE_DOCS_AGREEMENTS + "/edit";
export const URL_SITE_DOCS_POLICY = URL_SITE_DOCS + "/policy";
export const URL_SITE_DOCS_POLICY_EDIT = URL_SITE_DOCS_POLICY + "/edit";
export const URL_SITE_FAQ_CREATE = URL_SITE_FAQ + "/create";
export const URL_SITE_FAQ_ID = URL_SITE_FAQ + "/:id";
export const URL_SITE_FAQ_ID_EDIT = URL_SITE_FAQ_ID + "/edit";
export const URL_SITE_FAQ_RUBRICS = URL_SITE_FAQ + "/rubrics";
export const URL_SITE_FAQ_RUBRICS_CREATE = URL_SITE_FAQ_RUBRICS + "/create";
export const URL_SITE_FAQ_RUBRICS_ID = URL_SITE_FAQ_RUBRICS + "/:id";
export const URL_SITE_FAQ_RUBRICS_ID_EDIT = URL_SITE_FAQ_RUBRICS_ID + "/edit";
export const URL_SITE_DESCRIPTION = URL_SITE + "/projectdescription";
export const URL_SITE_DESCRIPTION_EDIT = URL_SITE_DESCRIPTION + "/edit";
export const URL_SITE_TECHSUPPORT = URL_SITE + "/techsupport";
export const URL_SITE_TECHSUPPORT_NEW = URL_SITE_TECHSUPPORT + "/new";
export const URL_SITE_TECHSUPPORT_NEW_ID = URL_SITE_TECHSUPPORT_NEW + "/:id";
export const URL_SITE_TECHSUPPORT_ANSWERED = URL_SITE_TECHSUPPORT + "/answered";
export const URL_SITE_TECHSUPPORT_ANSWERED_ID =
  URL_SITE_TECHSUPPORT_ANSWERED + "/:id";
export const URL_SITE_EMAILTEMPLATES = "/emailtemplates";
export const URL_SITE_EMAILTEMPLATES_CREATE =
  URL_SITE_EMAILTEMPLATES + "/create";
export const URL_SITE_EMAILTEMPLATES_EDIT = URL_SITE_EMAILTEMPLATES + "/:id";

export const URL_VOTING = URL_BASE + "/voting";
export const URL_VOTING_ADD = URL_VOTING + "/add";
export const URL_VOTING_ANNOUNCEMENT = URL_VOTING + "/announcement";
export const URL_VOTING_ANNOUNCEMENT_ID = URL_VOTING_ANNOUNCEMENT + "/:id";
export const URL_VOTING_ACTIVE = URL_VOTING + "/active";
export const URL_VOTING_ACTIVE_ID = URL_VOTING_ACTIVE + "/:id";
export const URL_VOTING_COMPLETED = URL_VOTING + "/completed";
export const URL_VOTING_COMPLETED_ID = URL_VOTING_COMPLETED + "/:id";
export const URL_VOTING_EDIT = URL_VOTING + "/edit";
export const URL_VOTING_EDIT_ID = URL_VOTING_EDIT + "/:id";

export const URL_ADMINISTRATION = URL_BASE + "/administration";

export const URL_ADMINISTRATION_ORGANIZATIONS =
  URL_ADMINISTRATION + "/organizations";
export const URL_ADMINISTRATION_ORGANIZATIONS_ID =
  URL_ADMINISTRATION_ORGANIZATIONS + "/:id";
export const URL_ADMINISTRATION_ORGANIZATIONS_ID_EDIT =
  URL_ADMINISTRATION_ORGANIZATIONS_ID + "/edit";
export const URL_ADMINISTRATION_ORGANIZATIONS_CREATE =
  URL_ADMINISTRATION_ORGANIZATIONS + "/create";
export const URL_ADMINISTRATION_CATEGORIES = URL_ADMINISTRATION + "/categories";
export const URL_ADMINISTRATION_STEPS = URL_ADMINISTRATION + "/steps";
export const URL_ADMINISTRATION_STEPS_CREATE =
  URL_ADMINISTRATION_STEPS + "/create";
export const URL_ADMINISTRATION_STEPS_ID = URL_ADMINISTRATION_STEPS + "/:id";
export const URL_ADMINISTRATION_STEPS_ID_EDIT =
  URL_ADMINISTRATION_STEPS_ID + "/edit";
export const URL_ADMINISTRATION_CATEGORIES_CREATE =
  URL_ADMINISTRATION_CATEGORIES + "/create";
export const URL_ADMINISTRATION_CATEGORIES_ID =
  URL_ADMINISTRATION_CATEGORIES + "/:id";
export const URL_ADMINISTRATION_CATEGORIES_ID_EDIT =
  URL_ADMINISTRATION_CATEGORIES_ID + "/edit";
export const URL_ADMINISTRATION_MOBILE = URL_ADMINISTRATION + "/mobile";
export const URL_ADMINISTRATION_MOBILE_EDIT =
  URL_ADMINISTRATION_MOBILE + "/edit";

export const URL_ANALYTICS = URL_BASE + "/analytics";
export const URL_ANALYTICS_INWORK = URL_ANALYTICS + "/inwork";
export const URL_ANALYTICS_ACCEPTED = URL_ANALYTICS + "/accepted";

export const URL_SHOP = "/shop";
export const URL_SHOP_PRODUCTS = URL_SHOP + "/products";
export const URL_SHOP_PRODUCTS_ID = URL_SHOP_PRODUCTS + "/:id";
export const URL_SHOP_PRODUCTS_ID_EDIT = URL_SHOP_PRODUCTS_ID + "/edit";
export const URL_SHOP_PRODUCTS_CREATE = URL_SHOP + "/create";
export const URL_SHOP_CATEGORIES = URL_SHOP + "/categories";
export const URL_SHOP_CATEGORIES_ID = URL_SHOP_CATEGORIES + "/:id";
export const URL_SHOP_CATEGORIES_ID_EDIT = URL_SHOP_CATEGORIES_ID + "/edit";
export const URL_SHOP_CATEGORIES_CREATE = URL_SHOP_CATEGORIES + "/create";
export const URL_SHOP_SETTINGS = URL_SHOP + "/settings";
export const URL_SHOP_ANALYTICS = URL_SHOP + "/analytics";
export const URL_SHOP_BALLS = URL_SHOP + "/balls";
export const URL_SHOP_BALLS_EDIT = URL_SHOP_BALLS + "/edit";
