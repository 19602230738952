import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FormStore } from "newApp/kit/stores/FormStore";
import validationSchema from "./validationSchema";

export class EditBallsStore {
  type: string | null = null;
  isLoading = false;
  form = new FormStore(
    {
      description: null,
    },
    validationSchema
  );

  constructor() {
    makeAutoObservable(this);
  }

  getEditedType = (type: string) => {
    runInAction(() => {
      this.isLoading = true;
    });
    if (type === "true")
      API.shop.PointsInfo.apiPointsinfoHasESKGet()
        .then(({ data }) => {
          runInAction(() => {
            this.form.changeValueByKey(data.content, "description");
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });

    if (type === "false")
      API.shop.PointsInfo.apiPointsinfoWithoutESKGet()
        .then(({ data }) => {
          runInAction(() => {
            this.form.changeValueByKey(data.content, "description");
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });

    this.type = type;
  };

  onSave = async () => {
    await this.form.validate();
    if (this.form.isValid) {
      return await this.postChangeDescription();
    }
  };

  postChangeDescription = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    if (this.type === "true")
      return API.shop.PointsInfo.apiPointsinfoHasESKPost({
        title: "Информация для пользователя у которого есть ЕСК",
        content: this.form.values.description,
      })
        .then(() => true)
        .catch((e) => {
          console.error(e);
          return false;
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });

    if (this.type === "false")
      return API.shop.PointsInfo.apiPointsinfoWithoutESKPost({
        title: "Информация для пользователя у которого нет ЕСК",
        content: this.form.values.description,
      })
        .then(() => true)
        .catch((e) => {
          console.error(e);
          return false;
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });
  };

  reset = () => {
    this.form.reset();
    this.type = null;
  };
}
