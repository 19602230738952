import AdministrationPage from "../../pages/administration";
import CategoriesPage from "../../pages/administration/categories";
import CategoryCreatePage from "../../pages/administration/categories/create";
import CategoryEditPage from "../../pages/administration/categories/id/edit";
import OrganizationsPage from "../../pages/administration/organizations";
import OrganizationsCreatePage from "../../pages/administration/organizations/create";
import OrganizationsEditPage from "../../pages/administration/organizations/id/edit";
import StepsPage from "../../pages/administration/steps";
import StepCreatePage from "../../pages/administration/steps/create";
import StepsEditPage from "../../pages/administration/steps/id/edit";
import {
  URL_ADMINISTRATION,
  URL_ADMINISTRATION_CATEGORIES,
  URL_ADMINISTRATION_CATEGORIES_CREATE,
  URL_ADMINISTRATION_CATEGORIES_ID_EDIT,
  URL_ADMINISTRATION_ORGANIZATIONS,
  URL_ADMINISTRATION_ORGANIZATIONS_CREATE,
  URL_ADMINISTRATION_ORGANIZATIONS_ID_EDIT,
  URL_ADMINISTRATION_STEPS,
  URL_ADMINISTRATION_STEPS_CREATE,
  URL_ADMINISTRATION_STEPS_ID_EDIT,
} from "../urls";

const administrationRouting: { path: string; component: React.ReactNode }[] = [
  {
    path: URL_ADMINISTRATION,
    component: <AdministrationPage />,
  },
  {
    path: URL_ADMINISTRATION_ORGANIZATIONS,
    component: <OrganizationsPage />,
  },
  {
    path: URL_ADMINISTRATION_ORGANIZATIONS_CREATE,
    component: <OrganizationsCreatePage />,
  },
  {
    path: URL_ADMINISTRATION_ORGANIZATIONS_ID_EDIT,
    component: <OrganizationsEditPage />,
  },
  {
    path: URL_ADMINISTRATION_STEPS,
    component: <StepsPage />,
  },
  {
    path: URL_ADMINISTRATION_STEPS_CREATE,
    component: <StepCreatePage />,
  },
  {
    path: URL_ADMINISTRATION_CATEGORIES,
    component: <CategoriesPage />,
  },
  {
    path: URL_ADMINISTRATION_CATEGORIES_CREATE,
    component: <CategoryCreatePage />,
  },
  {
    path: URL_ADMINISTRATION_CATEGORIES_ID_EDIT,
    component: <CategoryEditPage />,
  },
  {
    path: URL_ADMINISTRATION_STEPS_ID_EDIT,
    component: <StepsEditPage />,
  },
];

export default administrationRouting;
