import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { IDeclarationListItem } from "newApp/components/DeclarationsList/index.types";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";
import { DeclarationListFilterStore } from "newApp/stores/common/ModerationListFilterStore";

export class ExecutionListPageStore {
  declarations: IDeclarationListItem[] = [];
  filter = new DeclarationListFilterStore();
  isLoading = false;
  status: number | undefined;
  pagination = new PaginationStore(
    {
      sizes: [10, 20, 50, 100, 1000],
      pageSize: 20,
    },
    () => this.getDeclarations()
  );

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.filter.init();
  };

  reset = () => {
    this.resetDeclarations();
    this.pagination.reset();
    this.filter.reset();
  };

  resetDeclarations = () => {
    this.declarations = [];
  };

  getDeclarations = () => {
    runInAction(() => {
      this.isLoading = true;
    });
    return API.admin.ViolationExecution.apiViolationExecutionViolationsGet(
      this.filter.form.values.number
        ? Number(this.filter.form.values.number)
        : undefined,
      [this.status],
      this.filter.form.values.isPublished === null
        ? undefined
        : !!this.filter.form.values.isPublished,
      this.filter.form.values.district === null
        ? undefined
        : this.filter.form.values.isPublished,
      this.filter.form.values.orderByDescending === null
        ? undefined
        : !!this.filter.form.values.orderByDescending,
      this.filter.form.values.categoryId === null
        ? undefined
        : this.filter.form.values.categoryId,
      this.pagination.pageSize,
      this.pagination.currentPage,
      this.filter.form.values.organizationId === null
        ? undefined
        : this.filter.form.values.organizationId
    )
      .then((res: any) => {
        runInAction(() => {
          this.declarations =
            res?.data?.object?.declarationPage?.map((i) => ({
              id: i["Id"] || null,
              number: i["Number"] || null,
              date: new Date(i["DateOfCreation"]).toLocaleDateString() || null,
              category: i["Category"] || null,
              subCategory: i["SubCategory"] || null,
              organization: i["Organization"] || null,
              daysLeft: i["DaysLeft"] || null,
              district: i["District"] || null,
              isNotChecked: !i["IsChecked"],
              location: [
                i["Coordinates"]["Latitude"],
                i["Coordinates"]["Longitude"],
              ] || [0, 0],
              isOnControl: !!i["IsOnAddControl"],
            })) || [];

          this.pagination.pages = Number(res?.data?.object?.pageCount);
        });
      })
      .catch(this.resetDeclarations)
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  getAcceptedDeclarations = () => {
    this.status = 1;
    this.getDeclarations();
  };

  getInWorkDeclarations = () => {
    this.status = 2;
    this.getDeclarations();
  };

  getInWorkClosed = () => {
    this.status = 4;
    this.getDeclarations();
  };
}
