import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import React from "react";
import { IExecutionStepProps } from "..";
import StepValues from "../StepValues";

const StepFinish: React.FC<IExecutionStepProps> = (props) => {
  return (
    <FlexColumn>
      <StepValues
        values={props.values}
        form={props.form}
        filesStore={props.filesStore}
        isDone={props.isDone}
      />
      <ButtonsContainer>
        {!props.isDone && (
          <Button onClick={props.executeStep}>
            Завершить работу с сообщением
          </Button>
        )}
      </ButtonsContainer>
    </FlexColumn>
  );
};

export default observer(StepFinish);
