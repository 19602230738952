import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import NewsPreview from "newApp/components/NewsPreview";
import SiteNavigator from "newApp/components/SiteNavigator";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_DESCRIPTION } from "newApp/routing/urls";
import store from "newApp/stores/pages/site/projectdescription/ProjectDescriptionPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const ProjectDescriptionPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Описание проекта</H1>
      <FlexColumn>
        <SiteNavigator />
        <ButtonsContainer>
          <Button onClick={() => push(URL_SITE_DESCRIPTION + "/edit")}>
            Редактировать
          </Button>
        </ButtonsContainer>
        <NewsPreview content={store.description} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ProjectDescriptionPage);
