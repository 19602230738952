import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";
import { getDataURL } from "newApp/kit/utils/getDataURL";
import formValidationSchema from "./formValidationSchema";

export class FaqFormStore {
  isLoadings = {
    getRubricsOptions: false,
    addFiles: false,
  };

  form = new FormStore<IForm>(
    {
      answer: null,
      date: new Date(),
      id: null,
      question: null,
      rubricId: null,
      state: null,
    },
    formValidationSchema
  );

  options: { rubrics: ISelectOption[] } = { rubrics: [] };

  filesPreviews: IFilesPreviewFile[] = [];
  filesForDeleteIds: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getRubricsOptions();
  };

  reset = () => {
    this.form.reset();
    this.resetFiles();
  };

  resetFiles = () => {
    this.filesPreviews = [];
    this.filesForDeleteIds = [];
  };

  getRubricsOptions = () => {
    this.isLoadings.getRubricsOptions = true;
    return API.administration.FAQRubrics.faqRubricListGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.rubrics = data.map((i) => ({
            text: i.title,
            value: i.id,
          }));
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubricsOptions = false;
        });
      });
  };

  onAddFiles = (files: File[]) => {
    files.forEach((file) => {
      getDataURL(file)
        .then((reference) => {
          runInAction(() => {
            this.filesPreviews.push({ reference, file, title: file.name });
          });
        })
        .catch((e) => console.error(e));
    });
  };

  validate = () => {
    this.form.validate();
  };

  get isValid() {
    return this.form.isValid;
  }

  uploadFiles = async (faqId: string) => {
    for await (const i of this.filesPreviews.filter((i) => !!i.file)) {
      await API.administration.FAQ.faqFaqIdFilePost(faqId, i.file);
    }
  };

  onClickRemove = (idx: number, file?: IFilesPreviewFile) => {
    this.filesForDeleteIds.push(file.id);
    this.filesPreviews = this.filesPreviews.filter((i) => i.id !== file.id);
  };

  setFiles = (files: { id?: string; name?: string }[]) => {
    this.resetFiles();
    this.filesPreviews = files.map((f) => ({
      reference: URL_API_ADMINISTRATION + `/faq/file/${f.id}`,
      title: f.name,
      id: f.id,
    }));
  };

  removeFiles = async () => {
    for await (const id of this.filesForDeleteIds) {
      await API.administration.FAQ.faqFileIdDelete(id);
    }
    runInAction(() => {
      this.filesForDeleteIds = [];
    });
  };
}

interface IForm {
  answer: null | string;
  date: null | string | Date;
  id: null | string;
  question: null | string;
  rubricId: null | string;
  state: null | number | string;
}
