import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { OrganizationFormStore } from "newApp/stores/common/OrganizationFormStore";

export class OrganizationsCreatePageStore {
  isLoadings = {
    createOrganization: false,
  };

  organizationForm = new OrganizationFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.organizationForm.init();
  };

  reset = () => {
    this.organizationForm.reset();
  };

  onCreate = async () => {
    await this.organizationForm.form.validate();
    if (this.organizationForm.form.isValid) {
      return await this.createOrganization();
    }
  };

  createOrganization = () => {
    this.isLoadings.createOrganization = true;
    return API.admin.Organization.apiOrganizationsCreatePost({
      Active: true,
      Title: this.organizationForm.form.values.title,
      TitleShort: this.organizationForm.form.values.titleShort,
      ParentId: this.organizationForm.form.values.parentId,
    })
      .then(({ data }) => data["id"])
      .catch((e) => {
        console.error(e);
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createOrganization = false;
        });
      });
  };
}
