import { formatISO } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { VoteFormStore } from "newApp/stores/common/VoteFormStore";

export class VoteCreatePageStore {
  isLoadings = { createVote: false };

  voteForm = new VoteFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.voteForm.init();
  };

  reset = () => {
    this.voteForm.reset();
  };

  onSave = async () => {
    await this.voteForm.validate();
    if (this.voteForm.isValid) {
      await this.voteForm.details.fileForm.uploadFiles();
      return await this.createVote();
    }
    return false;
  };

  createVote = () => {
    this.isLoadings.createVote = true;
    return API.admin.Vote.apiVotesCreatePost({
      Title: this.voteForm.details.form.values.title,
      Annotation: this.voteForm.details.form.values.annotation,
      Description: this.voteForm.details.form.values.description,
      IsDigitalResult: !!this.voteForm.details.form.values.isDigitalResult,
      NumberOfPoints: this.voteForm.details.form.values.numberOfPoints
        ? Number(this.voteForm.details.form.values.numberOfPoints)
        : null,
      EndDateTime: formatISO(this.voteForm.details.form.values.endDateTime),
      StartDateTime: formatISO(this.voteForm.details.form.values.startDateTime),
      IsImportant: !!this.voteForm.details.form.values.isImportant,
      IsBanner: !!this.voteForm.details.form.values.isBanner,
      Gender: this.voteForm.details.form.values.isGenderLimit
        ? this.voteForm.details.form.values.gender
        : null,
      FileId: this.voteForm.details.fileForm.mainFileId || undefined,
      FilesIds: this.voteForm.details.filesIds,
      CroppedFileId: this.voteForm.details.fileForm.croppedFileId || undefined,
      PreviewFileId: this.voteForm.details.fileForm.previewFileId || undefined,
      FromAge: this.voteForm.details.form.values.fromAge,
      ToAge: this.voteForm.details.form.values.toAge,

      Coordinates: null,
      Place: "None",
      Position: "None",
      Idp: 0,
      Type: "Interview",
      Questions: this.voteForm.questions.questions.map((q) => ({
        Text: q.form.values.text || undefined,
        IsRequired: !!q.form.values.isRequired || undefined,
        Type: q.form.values.type || undefined,
        HasOtherOption: !!q.form.values.hasOtherOption || undefined,
        Id: q.form.values.id || undefined,
        FilesIds: q.fileIds || undefined,

        Answers: q.answers.map((a) => ({
          Id: a.form.values.id,
          Text: a.form.values.text,
          FilesIds: a.fileIds,
        })),
      })),
    })
      .then(({ data }) => data["id"])
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createVote = false;
        });
      });
  };
}
