import { observer } from "mobx-react";
import React from "react";
import { Pie } from "react-chartjs-2";

export interface IChartPieProps {
  data: {
    label: string | null;
    value: number;
  }[];
  notNamedLabelText?: string;
}

const ChartPie: React.FC<IChartPieProps> = (props) => {
  const data = {
    labels: props.data.map(
      (i) => i.label || props.notNamedLabelText || "Неизвестно"
    ),
    datasets: [
      {
        data: props.data.map((i) => Number(i.value)),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],

        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} />;
};

export default observer(ChartPie);
