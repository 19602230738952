import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import getUTSIsoString from "newApp/utils/getUTSIsoString";

import { ProductFormStore } from "../../../../common/ProductFormStore";

export class ProductsCreatePageStore {
  isLoadings = {
    createProduct: false,
  };

  productForm = new ProductFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.productForm.init();
  };

  reset = () => {
    this.productForm.reset();
  };

  validate = async () => {
    await this.productForm.validate();
  };

  get isValid() {
    return this.productForm.isValid;
  }

  get isLoadingImage() {
    return Object.values(this.productForm.mainPhoto.isLoadings).some((i) => i);
  }

  createProduct = () => {
    this.isLoadings.createProduct = true;

    return API.shop.Products.apiProductsCreateV2Post({
      title: this.productForm.form.values.title,
      description: this.productForm.form.values.description,
      //   type: this.productForm.form.values.type,
      price: Number(this.productForm.form.values.price),
      quantity: Number(this.productForm.form.values.quantity),
      //   wayToGet: this.productForm.form.values.wayToGet,
      imageId: this.productForm.mainPhoto.imageId,
      // ДИМА!!! РАСКОММЕНТИРУЙ, КОГДА СВЕТА СКАЖЕТ, ЧТО НАДО ДОБАВИТЬ ОБРЕЗАННОЕ ИЗОБРАЖЕНИЕ
      croppedImageId: this.productForm.mainPhoto.croppedImageId,
      creationDate: this.productForm.form.values.date
        ? getUTSIsoString(this.productForm.form.values.date)
        : null,
      categories: this.productForm.arrays.categories.map((i) => String(i)),
      departmentId: this.productForm.form.values.organizationId,
    })
      .then(({ data }) => data)
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createProduct = false;
        });
      });
  };

  onClickCreateProduct = async () => {
    await this.validate();
    if (this.isValid) {
      const id = this.createProduct();
      return id;
    }
  };
}
