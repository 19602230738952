import { config, axiosInstance } from "../axios";
import { URL_API_SHOP } from "../urls";
import * as api from "./openapi";

export const apiShop = {
  Products: new api.ProductsApi(config, URL_API_SHOP, axiosInstance),
  Categories: new api.CategoriesApi(config, URL_API_SHOP, axiosInstance),
  ProjectSettings: new api.ProjectSettingsApi(
    config,
    URL_API_SHOP,
    axiosInstance
  ),
  Analytics: new api.AnalyticsApi(config, URL_API_SHOP, axiosInstance),
  PointsInfo: new api.PointsInfoApi(config, URL_API_SHOP, axiosInstance),
  Departments: new api.DepartmentsApi(config, URL_API_SHOP, axiosInstance),
};
