import { observer } from "mobx-react";
import {
  URL_EXECUTION_ACCEPTED,
  URL_EXECUTION_CLOSED,
  URL_EXECUTION_INWORK,
} from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Tabs from "../../kit/components/Tabs";

interface IProps {
  newMessage?: React.ReactNode;
}

const ExecutionTabs: React.FC<IProps> = (props) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();

  const tabs = [
    {
      title: <div>Новые {props.newMessage}</div>,
      onClick: () => push(URL_EXECUTION_ACCEPTED + search),
      isActive: pathname === URL_EXECUTION_ACCEPTED,
    },
    {
      title: "В работе",
      onClick: () => push(URL_EXECUTION_INWORK + search),
      isActive: pathname === URL_EXECUTION_INWORK,
    },
    {
      title: "Архив",
      onClick: () => push(URL_EXECUTION_CLOSED + search),
      isActive: pathname === URL_EXECUTION_CLOSED,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(ExecutionTabs);
