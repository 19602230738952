import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import InfoTable from "newApp/kit/components/InfoTable";
import { URL_LOGIN } from "newApp/routing/urls";
import AuthStore from "newApp/stores/common/AuthStore";
import { ProfilePageStore } from "newApp/stores/pages/ProfilePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new ProfilePageStore();

const ProfilePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const onClickExit = () => {
    AuthStore.logOut();
    push(URL_LOGIN);
  };

  return (
    <MainLayout isLoading={store.isLoading}>
      <H1>Личный кабинет</H1>
      <FlexColumn>
        <InfoTable
          content={[
            ["Имя", store.info.firstName],
            ["Фамилия", store.info.lastName],
            ["Отчество", store.info.surName],
            ["email", store.info.email],
            ["Логин", store.info.login],
            ["Телефон", store.info.phone],
            ["Другие контакты", store.info.otherPhones],
            ["Заблокирован", store.info.isDeactivated ? "Да" : "Нет"],
          ]}
        />
        <ButtonsContainer>
          <Button variant="danger" size="small" onClick={onClickExit}>
            Выйти
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ProfilePage);
