import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_SHOP } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";

export class ProductsItemPageStore {
  isLoadings = {
    getProduct: false,
    deleteProduct: false,
    activateProduct: false,
  };

  product: IProductItem = {
    id: null,
    title: null,
    description: null,
    price: null,
    quantity: null,
    wayToGet: null,
    creationDate: null,
    categoriesIds: null,
    type: null,
    imageId: null,
    isActive: null,
    organizationName: null,
    organizationAddress: null,
    organizationCoordinates: null,
  };

  imagesPreviews: IFilesPreviewFile[] = [];

  categoriesList: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.getProduct(id);
  };

  getProduct = (id: string) => {
    this.isLoadings.getProduct = true;

    API.shop.Products.apiProductsIdGet(id)
      .then(({ data }) => {
        runInAction(() => {
          Object.entries(data).forEach(([key, value]) => {
            this.product[key] = value;
          });

          this.product.creationDate = `${new Date(
            data.creationDate
          ).toLocaleDateString()} ${new Date(
            data.creationDate
          ).toLocaleTimeString()}`;

          if (data.imageId) {
            this.imagesPreviews.push({
              type: "Image",
              reference: `${URL_API_SHOP}/api/products/image/${data.imageId}`,
            });
          }

          if (data.croppedImageId) {
            this.imagesPreviews.push({
              type: "Image",
              reference: `${URL_API_SHOP}/api/products/croppedimage/${data.croppedImageId}`,
            });
          }
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getProduct = false;
          this.getCategories();
        });
      });
  };

  getCategories = () => {
    API.shop.Categories.apiCategoriesGet().then(({ data }) => {
      runInAction(() => {
        data["items"].forEach((item) => {
          this.product.categoriesIds.forEach((cat) => {
            if (cat === item.id) {
              this.categoriesList.push(item.title);
            }
          });
        });
      });
    });
  };

  deleteProduct = (id: string) => {
    this.isLoadings.deleteProduct = true;

    API.shop.Products.apiProductsDeactivateIdPost(id)
      .then(() => {
        runInAction(() => {
          this.getProduct(id);
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.deleteProduct = false;
        });
      });
  };

  activateProduct = (id: string) => {
    this.isLoadings.activateProduct = true;

    API.shop.Products.apiProductsActivateIdPost(id)
      .then(() => {
        runInAction(() => {
          this.getProduct(id);
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.activateProduct = false;
        });
      });
  };

  resetProduct = () => {
    Object.keys(this.product).forEach((key) => {
      this.product = { ...this.product, [key]: null };
    });
  };

  reset = () => {
    this.resetProduct();
    this.imagesPreviews = [];
    this.categoriesList = [];
  };

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }
}

interface IProductItem {
  id: string | null;
  title: string | null;
  description: string | null;
  price: number | string | null;
  quantity: number | string | null;
  wayToGet: string | null;
  creationDate: string | null;
  categoriesIds: string[] | null;
  type: string | null;
  imageId: string | null;
  isActive: boolean | null;
  organizationAddress: string | null;
  organizationName: string | null;
  organizationCoordinates: {
    latitude: number;
    longitude: number;
  } | null;
}
