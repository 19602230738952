import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import Dropzone from "newApp/kit/components/Dropzone";
import FilesList from "newApp/kit/components/FilesList";
import TextArea from "newApp/kit/components/TextArea";
import React, { useEffect, useRef } from "react";
import FilesPreview from "../FilesPreview";
import IconSend from "../icons/IconSend";
import groupDates from "./groupDates";
import { IChatProps } from "./index.types";
import "./styles.scss";

const Chat: React.FC<IChatProps> = (props) => {
  const groupedDates = groupDates(props.messages);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (groupedDates.length) ref.current?.scrollIntoView();
  }, [groupedDates.length, props.messages.length]);

  return (
    <div className="chat">
      <div className="chat__messages">
        {groupedDates.map((group) => (
          <React.Fragment key={group.date}>
            <div className="chat__date">
              <div className="chat__date-line" />
              <div className="chat__date-text">{group.date}</div>
              <div className="chat__date-line" />
            </div>
            {group.messages.map((message) => (
              <div
                className={[
                  "chat__message",
                  message.isMyMessage ? "chat__message--my-message" : "",
                ].join(" ")}
                key={message.id}
              >
                <div className="chat__message-box" key={message.id}>
                  <div className="chat__message-header">
                    <span>{message.author.fullname} </span>
                    <span>{new Date(message.date).toLocaleTimeString()}</span>
                  </div>
                  <div className="chat__message-body">{message.text}</div>
                  {!!message.fileReferences.length && (
                    <FilesPreview files={message.fileReferences} />
                  )}
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
        <div ref={ref} />
      </div>
      <div className="chat__new-message">
        <div className="chat__new-message-top">
          <TextArea
            autoFocus={true}
            maxRows={8}
            placeholder="Ведите ваше сообщение"
            {...props.form.adapters.newMessage}
            onKeyPress={(e) => {
              if (e.ctrlKey && e.key === "Enter") {
                props.sendMessage();
              }
            }}
          />
          <div>
            <Button size="small" onClick={props.sendMessage}>
              <IconSend />
            </Button>
          </div>
        </div>
        <div>
          <Dropzone {...props.filesStore.dropzoneAdapter} />
          <FilesList {...props.filesStore.filesListAdapter} />
        </div>
      </div>
    </div>
  );
};

export default observer(Chat);
