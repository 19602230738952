import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { FormStore } from "newApp/kit/stores/FormStore";
import { getDataURL } from "newApp/kit/utils/getDataURL";
import validationSchema from "./validationSchema";

export class MobileEditPageStore {
  isLoadings = {
    getMobile: false,
    uploadFile: false,
    onSave: false,
    saveMobile: false,
  };

  form = new FormStore(
    {
      content: null,
      hasImage: null,
      id: null,
      title: null,
    },
    validationSchema
  );

  filesPreviews: IFilesPreviewFile[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getMobile();
  };

  reset = () => {};

  getMobile = () => {
    this.isLoadings.getMobile = true;
    return API.administration.MobileProject.mobileprojectGet()
      .then(({ data }) => {
        runInAction(() => {
          Object.entries(data).forEach(([key, value]) => {
            this.form.changeValue(value, key);
          });
          this.filesPreviews = [
            {
              title: "Изображение для мобильного приложения",
              reference: URL_API_ADMINISTRATION + `/mobileproject/image`,
              type: "image",
            },
          ];
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getMobile = false;
        });
      });
  };

  saveMobile = () => {
    this.isLoadings.saveMobile = true;
    return API.administration.MobileProject.mobileprojectPost({
      ...this.form.values,
    })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveMobile = false;
        });
      });
  };

  onAddFiles = (files: File[]) => {
    getDataURL(files[0])
      .then((reference) => {
        runInAction(() => {
          this.filesPreviews = [
            {
              reference,
              type: "image",
              title: "Изображение мобильного приложения",
              file: files[0],
            },
          ];
        });
      })
      .catch((e) => console.error(e));
  };

  uploadFile = (file: File) => {
    this.isLoadings.uploadFile = true;
    return API.administration.MobileProject.mobileprojectImagePost(file)
      .then(() => {})
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.uploadFile = false;
        });
      });
  };

  onSave = async () => {
    await this.form.validate();
    if (this.form.isValid) {
      const newFile = this.filesPreviews.find((i) => !!i.file);
      if (newFile) await this.uploadFile(newFile.file);
      await this.saveMobile();
      return true;
    }
    return false;
  };
}
