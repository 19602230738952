import { makeAutoObservable, runInAction } from "mobx";

import { API } from "../../../../../../api";
import { IRoleProp } from "../../../../../../components/RolesListTable/index.types";
import { IResponseData, IRole } from "./index.types";

export class RolesListStore {
  private rolesList: IRole[] = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getList();
  };

  reset = () => {
    this.isLoading = false;
    this.rolesList = [];
  };

  private getList = () => {
    this.isLoading = true;
    return API.access.Roles.apiRolesGet()
      .then((response: any) => {
        runInAction(() => {
          const responseData = response.data! as IResponseData;
          this.rolesList = responseData.roles;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.reset();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  get roles(): IRoleProp[] {
    return this.rolesList.map((role) => ({
      id: role.id,
      name: role.title,
      accessLevel: role.priority,
      module: role.modules.join(", "),
    }));
  }
}
