import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import HeaderNavigationMenu from "../HeaderNavigationMenu";
import HeaderNavigationMore from "../HeaderNavigationMore";
import "./styles.scss";

interface IProps {
  links: {
    text: string;
    to: string;
  }[];
}

const HeaderNavigation: React.FC<IProps> = (props) => {
  const [mainMenuElementsCount, setMainMenuElementsCount] = useState(1);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeHandler = () => {
      const oneElementMaxWidth: number = 180;
      const containerWidth: number = Number(ref.current?.offsetWidth);
      const firstCount = Math.round(containerWidth / oneElementMaxWidth);
      setMainMenuElementsCount(firstCount);
    };
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const linksMain = props.links.slice(0, mainMenuElementsCount - 1);
  const linksMore = props.links.slice(mainMenuElementsCount - 1);

  return (
    <div className="header-navigation" ref={ref}>
      <HeaderNavigationMenu links={linksMain} />
      <HeaderNavigationMore links={linksMore} />
    </div>
  );
};

export default observer(HeaderNavigation);
