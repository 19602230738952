import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IAnalyticsTable } from "newApp/components/AnalyticsTable";
import { AnalyticsFilterStore } from "newApp/stores/common/AnalyticsFilterStore";

export class AnalyticsInWorkPageStore {
  isLoadings = { getReport: false };

  table: null | IAnalyticsTable = null;
  filter = new AnalyticsFilterStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getReport();
    this.filter.init();
  };

  reset = () => {};

  getReport = () => {
    this.isLoadings.getReport = true;
    return API.admin.ViolationAnalytics.apiReportsViolationWorkGet(
      this.filter.organizationIds.length
        ? this.filter.organizationIds
        : undefined,
      this.filter.dates.beginDate,
      this.filter.dates.endDate
    )
      .then(({ data }) => {
        runInAction(() => {
          this.table = data as any;
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getReport = false;
        });
      });
  };

  get downloadUrl() {
    let url = URL_API_ADMIN + `/api/reports/violationWork/download?`;
    if (this.filter.dates.beginDate) {
      url += `BeginDate=${this.filter.dates.beginDate}&`;
    }
    if (this.filter.dates.endDate) {
      url += `EndDate=${this.filter.dates.endDate}&`;
    }
    if (this.filter.organizationIds.length) {
      this.filter.organizationIds.forEach((id) => {
        url += `OrganizationIds=${id}&`;
      });
    }
    return url;
  }
}
