import { observer } from "mobx-react";
import React from "react";
import Table from "../../kit/components/Table";
import { URL_ADMISSION_USERS } from "../../routing/urls";
import { IUser } from "../../stores/pages/admission/users/UsersListStore";
import Link from "../Link";

interface IProps {
  users: IUser[];
}

const UsersPageTable: React.FC<IProps> = (props) => {
  return (
    <Table
      headers={["ФИО", "Логин", "Последняя активность", "Роли", "Организации"]}
      content={props.users.map((user) => [
        <Link to={URL_ADMISSION_USERS + `/${user.id}`}>{user.name}</Link>,
        user.login || "-",
        user.lastActionDate || "-",
        user?.roles?.join(", ") || "-",
        user?.organizations?.join(", ") || "-",
      ])}
    />
  );
};

export default observer(UsersPageTable);
