import { observer } from "mobx-react";
import React from "react";
import "./index.scss";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export interface ITextEditorProps {
  value: string;
  onChange: (value: string, name?: string) => void;
  name?: string;
  errorMessage?: string | null;
  className?: string;
}

const TextEditor: React.FC<ITextEditorProps> = (props) => {
  return (
    <>
      <div
        className={[props.className, props.errorMessage ? "warning" : ""].join(
          " "
        )}
      >
        <CKEditor
          editor={Editor}
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "|",
                "indent",
                "outdent",
                "alignment",
                "|",
                "imageUpload",
                "blockQuote",
                "insertTable",
                "mediaEmbed",
                "undo",
                "redo",
              ],
            },
            language: "ru",
            image: {
              styles: [
                "inline",
                "alignLeft",
                "alignRight",
                "alignCenter",
                "alignBlockLeft",
                "alignBlockRight",
                "block",
                "side",
              ],
              toolbar: [
                "imageTextAlternative",
                "imageStyle:block",
                "imageStyle:alignLeft",
                "imageStyle:alignRight",
                "imageResize",
              ],
            },
            table: {
              contentToolbar: [
                "tableColumn",
                "tableRow",
                "mergeTableCells",
                "tableCellProperties",
                "tableProperties",
              ],
            },
            licenseKey: "",
          }}
          data={props.value}
          onChange={(_, editor) => {
            const data = editor.getData();
            props.onChange(data, props.name);
          }}
        />
      </div>
      {props.errorMessage && (
        <p className="warning-text margin-bottom-12">{props.errorMessage}</p>
      )}
    </>
  );
};

export default observer(TextEditor);
