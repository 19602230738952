import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router";

import DeclarationsList from "../DeclarationsList";
import { IDeclarationListItem } from "../DeclarationsList/index.types";
import DeclarationsMap from "../DeclarationsMap";
import { MODE, MODE_MAP } from "../ListModeTabs";

export interface IListOrMapProps {
  declarations: IDeclarationListItem[];
}

const ListOrMap: React.FC<IListOrMapProps> = (props) => {
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get(MODE);

  if (mode === MODE_MAP)
    return <DeclarationsMap declarations={props.declarations} />;
  return <DeclarationsList declarations={props.declarations} />;
};

export default observer(ListOrMap);
