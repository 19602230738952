import { IChatMessage } from '../index.types';

export type TGroupedDate = { date: string; messages: IChatMessage[] };

const groupDates = (dates: IChatMessage[]): TGroupedDate[] => {
  let groupedDates: TGroupedDate[] = [];
  dates.forEach((message) => {
    const day = new Date(message.date).toLocaleDateString();
    const date = groupedDates.find((date) => date.date === day);
    if (date) {
      date.messages.push(message);
    } else {
      groupedDates.push({
        date: day,
        messages: [message],
      });
    }
  });
  groupedDates = groupedDates.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
  groupedDates = groupedDates.map((date) => ({
    date: date.date,
    messages: date.messages.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    ),
  }));
  return groupedDates;
};

export default groupDates;
