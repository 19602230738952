import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import Select from "newApp/kit/components/Select";
import TextField from "newApp/kit/components/TextField";
import { CategoryFormStore } from "newApp/stores/common/CategoryFormStore";
import React from "react";
import CategorySelect from "../CategorySelect";
import CategoryTags from "../CategoryTags";

export interface ICategoryFormProps {
  categoryForm: CategoryFormStore;
}

const CategoryForm: React.FC<ICategoryFormProps> = (props) => {
  return (
    <FlexColumn>
      <CategorySelect
        placeholder="Расположение"
        options={props.categoryForm.options.categories}
        {...props.categoryForm.form.adapters.parentId}
      />
      <Divider />
      <TextField
        placeholder="Наименование"
        {...props.categoryForm.form.adapters.name}
      />
      <Checkbox {...props.categoryForm.form.adapters.isActive}>
        Активно
      </Checkbox>
      <H3>Информация</H3>
      <Select
        placeholder="Ответственная организация"
        options={props.categoryForm.options.organizations}
        {...props.categoryForm.form.adapters.organizationId}
      />
      <TextField
        placeholder="Срок устранения нарушений (дней)"
        type={"number"}
        {...props.categoryForm.form.adapters.daysLimit}
      />
      <Divider />
      <H3>Теги</H3>
      <CategoryTags {...props.categoryForm.categoryTags} />
    </FlexColumn>
  );
};

export default observer(CategoryForm);
