import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { IRubric } from "..";

export class FaqListItemStore {
  isLoadings = {
    deleteFaq: false,
    changeIsActive: false,
  };
  form: IFaqListItemForm = {};
  rootStore: IRootStore;

  constructor(form: IFaqListItemForm, rootStore: IRootStore) {
    Object.entries(form).forEach(([key, value]) => {
      this.form[key] = value;
    });
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get dateTime(): string {
    if (this.form.date)
      return (
        new Date(this.form.date).toLocaleDateString() +
        " " +
        new Date(this.form.date).toLocaleTimeString()
      );
    return "Неизвестно";
  }

  get rubricDescription() {
    return (
      this.rootStore.rubrics?.find((i) => i.id === this.form?.rubricId)
        ?.title || "Неизвестно"
    );
  }

  deleteFaq = () => {
    this.isLoadings.deleteFaq = true;
    return API.administration.FAQ.faqIdDelete(this.form.id)
      .then(() => {
        this.rootStore.getFaqs();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.deleteFaq = false;
        });
      });
  };

  changeIsActive = () => {
    this.isLoadings.changeIsActive = true;
    return API.administration.FAQ.faqIdPost(this.form.id, {
      ...this.form,
      state: this.form.state === 0 ? 1 : 0,
    })
      .then(() => {
        this.rootStore.getFaqs();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeIsActive = false;
        });
      });
  };
}

interface IFaqListItemForm {
  date?: string;
  id?: string;
  question?: string;
  rubricId?: string;
  state?: number;
}

interface IRootStore {
  rubrics: IRubric[];
  getFaqs: () => void;
}
