import { makeAutoObservable } from "mobx";
import { UserForm } from "./UserForm";

export class UsersItemPageStore {
  user = new UserForm();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.user.init(id);
  };

  reset = () => {
    this.user.reset();
  };

  get isLoading() {
    return this.user.isLoadings.getUser;
  }
}
