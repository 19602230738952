import { makeAutoObservable } from "mobx";
import { UsersListStore } from "./UsersListStore";

export class UsersPageStore {
  userList = new UsersListStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.userList.init();
  };

  reset = () => {
    this.userList.reset();
  };

  get isLoading() {
    return this.userList.isLoading;
  }
}
