import React from "react";

const IconAnalytics = () => {
  return (
    <svg width="56" height="60" viewBox="0 0 86.8 94">
      <path
        fill="#5CC4CA"
        d="M86 31.2c-.5-4.8-4.1-11.1-7.9-14 0 0-5.8-4.3-13-8.5-7.2-4.3-13.8-7.2-13.8-7.2C49.1.5 46.2 0 43.4 0c-2.9 0-5.8.5-7.9 1.5 0 0-6.5 2.9-13.8 7.2-7.2 4.3-13 8.5-13 8.5-3.9 2.9-7.4 9.2-7.9 14 0 0-.8 7.2-.8 15.8 0 8.5.8 15.8.8 15.8.5 4.8 4.1 11.1 7.9 14 0 0 5.8 4.3 13 8.6s13.8 7.2 13.8 7.2c2.2 1 5.1 1.5 7.9 1.5 2.9 0 5.8-.5 7.9-1.5 0 0 6.5-2.9 13.8-7.2 7.2-4.3 13-8.6 13-8.6 3.9-2.9 7.4-9.2 7.9-14 0 0 .8-7.2.8-15.8 0-8.5-.8-15.8-.8-15.8zm-4 31.1c-.4 3.8-3.4 9-6.3 11.2-.1 0-5.7 4.2-12.6 8.3-6.9 4.1-13.3 7-13.4 7-1.6.7-3.9 1.1-6.3 1.1s-4.7-.4-6.3-1.1c-.1 0-6.4-2.9-13.4-7-6.9-4.1-12.6-8.3-12.6-8.3-3-2.2-5.9-7.5-6.3-11.2-.1 0-.8-7.1-.8-15.3s.7-15.3.7-15.3c.4-3.8 3.4-9 6.3-11.2.1 0 5.7-4.2 12.6-8.3s13.3-7 13.4-7c1.7-.8 4-1.2 6.4-1.2s4.7.4 6.3 1.1c.1 0 6.4 2.9 13.4 7 6.9 4.1 12.6 8.3 12.6 8.3 3 2.2 5.9 7.5 6.3 11.2 0 .1.7 7.1.7 15.3.1 8.3-.7 15.4-.7 15.4z"
      ></path>
      <circle
        fill="none"
        stroke="#5CC4CA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeMiterlimit="10"
        cx="43.4"
        cy="44.9"
        r="19"
      ></circle>
      <path
        fill="none"
        stroke="#5CC4CA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M55.8 59.3l8 8.1"
      ></path>
      <path
        fill="none"
        stroke="#5CC4CA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M18.8 45.9h15.1l4 9 7-22 4.9 13h18.1"
      ></path>
    </svg>
  );
};

export default IconAnalytics;
