import { observer } from "mobx-react";
import Accordion from "newApp/kit/components/Accordion";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import InfoTable from "newApp/kit/components/InfoTable";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Table from "newApp/kit/components/Table";
import { VoteItemPageResultsStore } from "newApp/stores/pages/votings/VoteItemPageStore/VoteItemPageResultsStore";
import React from "react";
import ChartPie from "../ChartPie";
import FreeAnswersList from "../FreeAnswersList";

export interface IVoteItemResultsProps extends VoteItemPageResultsStore {}

const VoteItemResults: React.FC<IVoteItemResultsProps> = (props) => {
  return (
    <FlexColumn>
      <InfoTable
        content={[
          [
            "Всего граждан приняло участие",
            String(Number(props.info.votersNumber)),
          ],
        ]}
      />
      {props.questions.map((question, questionIdx) => (
        <div key={question.id}>
          <H3>
            {questionIdx + 1}. {question.text}
          </H3>
          <Table
            headers={[
              "Ответ",
              "Количество голосов",
              "Процент",
              `Пол женский/мужской`,
            ]}
            content={[
              ...question.answers.map((answer) => [
                answer.text || "Свободный ответ",
                String(answer.votersNumber),
                String(answer.votersPercentage),
                `${String(answer.femaleVotersNumber || 0)}/${String(
                  answer.maleVotersNumber || 0
                )}`,
              ]),
              ["Всего", question.votersNumber],
            ]}
          />
          {question.hasOtherOption && (
            <Accordion
              headerText="Показать все свободные ответы"
              onOpenFunction={question.getFreeAnswers}
            >
              {question.isLoadings.getFreeAnswers ? (
                <LoaderSpinner />
              ) : (
                <FreeAnswersList freeAnswers={question.freeAnswers} />
              )}
            </Accordion>
          )}
          <Accordion headerText="Показать диаграмму">
            <ChartPie
              data={question.answers.map((answer) => ({
                label: answer.text,
                value: answer.votersNumber,
              }))}
              notNamedLabelText="Свободный ответ"
            />
          </Accordion>
        </div>
      ))}
    </FlexColumn>
  );
};

export default observer(VoteItemResults);
