import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";

export class MobilePageStore {
  isLoadings = { getMobile: false };

  form = {
    content: null,
    hasImage: null,
    id: null,
    title: null,
  };

  filesPreviews: IFilesPreviewFile[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getMobile();
  };

  reset = () => {
    this.resetForm();
    this.filesPreviews = [];
  };

  resetForm = () => {
    Object.keys(this.form).forEach((key) => {
      this.form[key] = null;
    });
  };

  getMobile = () => {
    this.isLoadings.getMobile = true;
    return API.administration.MobileProject.mobileprojectGet()
      .then(({ data }) => {
        runInAction(() => {
          Object.entries(data).forEach(([key, value]) => {
            this.form[key] = value;
          });
          this.filesPreviews = [
            {
              title: "Изображение для мобильного приложения",
              reference: URL_API_ADMINISTRATION + `/mobileproject/image`,
              type: "image",
            },
          ];
        });
      })
      .catch(() => {
        runInAction(() => {});
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getMobile = false;
        });
      });
  };
}
