import { compressImage } from "../../utils/compressImage";
import { getDataURL } from "../../utils/getDataURL";

export const getCropBase64 = (
  cropper: Cropper,
  width: number,
  fileType?: File["type"]
): Promise<string> =>
  new Promise((resolve) => {
    if (cropper) {
      try {
        cropper
          .getCroppedCanvas({
            maxWidth: 4096,
            maxHeight: 4096,
          })
          .toBlob((blob) => {
            if (blob) {
              compressImage(blob as File, {
                maxWidthOrHeight: width,
                fileType,
              })
                .then((compressedFile) => getDataURL(compressedFile))
                .then((dataURL) => resolve(dataURL));
            }
          });
      } catch (error) {
        throw new Error("cannot convert crop");
      }
    }
  });
