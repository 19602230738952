import { makeAutoObservable } from "mobx";

import { IQuestionConstructorForm, QuestionStore } from "./QuestionStore";

export class VoteQuestionsFormStore {
  questions: QuestionStore[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    if (!this.questions.length) this.addQuestion();
  };

  reset = () => {
    this.questions = [];
  };

  addQuestion = () => {
    this.questions.push(new QuestionStore());
  };

  removeQuestion = (idx: number) => {
    this.questions.splice(idx, 1);
  };

  setQuestions = (questions: IQuestionConstructorForm[]) => {
    this.questions = questions.map((q) => new QuestionStore(q));
  };

  validate = () => {
    this.questions.forEach((i) => {
      i.validate();
    });
  };

  get isValid() {
    return !this.questions.some((i) => !i.isValid);
  }
}
