import LoaderSpinner from "components/Loader";
import { observer } from "mobx-react";
import DaysLeftCount from "newApp/components/DaysLeftCount";
import DeclarationExecutionItemBody from "newApp/components/DeclarationExecutionItemBody";
import DeclarationItemMap from "newApp/components/DeclarationItemMap";
import DeclarationModeSwitcher, {
  DECLARATION_MODE,
  DECLARATION_MODE_MAP,
} from "newApp/components/DeclarationModeSwitcher";
import MainLayout from "newApp/components/layouts/MainLayout";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { ExecutionItemPageStore } from "newApp/stores/pages/execution/ExecutionItemPageStore";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router";

const store = new ExecutionItemPageStore();

const ExecutionItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get(DECLARATION_MODE);

  useEffect(() => {
    if (id) store.init(id);
    return () => store.reset();
  }, [id]);

  return (
    <MainLayout>
      {store.isLoadings.getDeclaration ? (
        <LoaderSpinner />
      ) : (
        <FlexColumn>
          <H1>Сообщение № {store.info.number}</H1>
          <DaysLeftCount daysLeft={store.info.daysLeft} />
          <DeclarationModeSwitcher />

          {mode === DECLARATION_MODE_MAP ? (
            <DeclarationItemMap info={store.info} />
          ) : (
            <DeclarationExecutionItemBody {...store} steps={store.steps} />
          )}
        </FlexColumn>
      )}
    </MainLayout>
  );
};

export default observer(ExecutionItemPage);
