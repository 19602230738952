import "./styles.scss";

import { observer } from "mobx-react";
import { FaqListItemStore } from "newApp/stores/pages/site/faq/FaqListPageStore/FaqListItemStore";
import React from "react";

import ListItemCard from "../ListItemCard";
import Checkbox from "newApp/kit/components/CheckBox";
import { useHistory } from "react-router";
import { URL_SITE_FAQ } from "newApp/routing/urls";

export interface IFaqListProps {
  faqList: FaqListItemStore[];
}

const FaqList: React.FC<IFaqListProps> = (props) => {
  const { push } = useHistory();

  return (
    <div className="faq-list">
      {props.faqList.map((faq, idx) => (
        <ListItemCard
          title={faq.form.question}
          onClickTitle={() => push(URL_SITE_FAQ + `/${faq.form.id}`)}
          isActive={!!faq.form.state}
          headerButtons={[
            {
              text: "Редактировать",
              onClick: () => push(URL_SITE_FAQ + `/${faq.form.id}/edit`),
            },
            {
              text: "Удалить",
              color: "red",
              onClick: () => {
                window.confirm(
                  `Вы уверены что хотите удалить ${faq.form.question}?`
                ) && faq.deleteFaq();
              },
            },
          ]}
          descriptions={[
            {
              title: "Дата",
              value: faq.dateTime,
            },
            {
              title: "Рубрика",
              value: faq.rubricDescription,
            },
          ]}
          controls={[
            <Checkbox
              value={!!faq.form.state}
              onChange={() => faq.changeIsActive()}
              disabled={faq.isLoadings.changeIsActive}
            >
              Опубликовано
            </Checkbox>,
          ]}
          key={idx}
        />
      ))}
    </div>
  );
};

export default observer(FaqList);
