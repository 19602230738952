import React from "react";
import Checkbox from "../CheckBox";
import { IProps, TValues } from "./index.types";
import "./styles.scss";

const Checkboxes: React.FC<IProps> = (props) => {
  return (
    <div className="checkboxes">
      {props.label && <div className="checkboxes__title">{props.label}</div>}
      {props.isRequired && (
        <div className="checkboxes__isRequired">Обязательно</div>
      )}
      <div
        className={[
          "checkboxes__container",
          props.isHorizontal ? "checkboxes__container--horizontal" : "",
        ].join(" ")}
      >
        {props.options.map((option, idx) => (
          <Checkbox
            onChange={() => {
              const checkedValues: TValues[] = props.values.includes(
                option.value
              )
                ? props.values.filter((v) => v !== option.value)
                : [...props.values, option.value];
              props.onChange(option.value, props.name, checkedValues);
            }}
            value={props.values.includes(option.value)}
            key={idx}
          >
            {option.text}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default Checkboxes;
