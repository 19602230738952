import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { axiosInstance } from "newApp/api/axios";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import FilesListStore from "newApp/kit/stores/FilesListStore";
import { FormStore } from "newApp/kit/stores/FormStore";
import { getDataURL } from "newApp/kit/utils/getDataURL";

export class ExecutionStepStore {
  files: string[] = [];
  isDone: boolean = false;
  name: string | null = null;
  stepTypeId: string | null = null;
  declarationId: string | null = null;
  violationTemplateStepId: string | null = null;
  values: IStepValueItem[] = [];
  getDeclaration?: () => void;
  declarationImages: IFilesPreviewFile[] = [];
  declarationSelectedImage: IFilesPreviewFile | null;
  declarationSelectedPreview: any;
  nowFile: File | undefined;

  form = new FormStore({
    reason: null,
    textArea: null,
  });

  filesStore = new FilesListStore(async () => ({ id: "" }), {
    validation: {
      acceptFileTypes: ["image/*", "video/*", "application/*"],
    },
    isUploadManual: true,
  });

  constructor(form: {
    isDone?: boolean;
    name?: string | null;
    declarationId?: string | null;
    stepTypeId?: string | null;
    violationTemplateStepId?: string | null;
    getDeclaration?: () => void;
    values: IStepValueItem[];
    declarationImages: IFilesPreviewFile[];
  }) {
    Object.entries(form).forEach(([key, value]) => {
      this[key] = value;
    });
    if (form.declarationImages?.length) {
      this.declarationSelectedImage = form.declarationImages[0];
    }
    makeAutoObservable(this);
  }

  onClickGetInWork = () => {
    this.executeStep();
  };

  onReject = () => {
    return API.admin.Violations.apiViolationsIdRejectPost(
      String(this.declarationId),
      String(this.form.values.reason)
    )
      .then(() => true)
      .catch(() => false);
  };

  executeStep = () => {
    const data = new FormData();
    data.append("violationTemplateStepId", this.violationTemplateStepId);
    data.append("violationId", this.declarationId);
    data.append("PastPhotos", this.declarationSelectedImage?.id || null);
    data.append(
      "Comment",
      this.form.values.textArea ? String(this.form.values.textArea) : ""
    );
    data.append("Fields[0].Id", "7fcb5a4c-055e-4cc8-8712-7fd1c869575f");
    data.append(
      "Fields[0].Value",
      this.form.values.textArea ? String(this.form.values.textArea) : ""
    );
    this.filesStore.files.forEach((file) => {
      data.append("Files", file.file);
    });
    data.append("Files", this.nowFile as any);

    const url = URL_API_ADMIN + "/api/violationExecution/step/execute";
    return axiosInstance
      .post(url, data)
      .then(() => {
        if (this.getDeclaration) this.getDeclaration();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  onChangeDeclarationImage = (image: IFilesPreviewFile) => {
    this.declarationSelectedImage = image;
  };

  onAddFilesNow = (files: File[]) => {
    this.nowFile = files[0];
    getDataURL(this.nowFile)
      .then((result) => {
        runInAction(() => {
          this.declarationSelectedPreview = result;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.declarationSelectedPreview = null;
        });
      });
  };

  removeDeclarationSelectedPreview = () => {
    this.declarationSelectedPreview = null;
  };
}

export interface IStepValueItem {
  id: string;
  name: string | null;
  value: string | null;
}
