import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import TextEditor from "newApp/components/TextEditor";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_DOCS_AGREEMENTS } from "newApp/routing/urls";
import { DocsEditStore } from "../store";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new DocsEditStore();

const ProjectDescriptionEditPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const goOnDescription = () => push(URL_SITE_DOCS_AGREEMENTS);

  const onClickSave = async () => {
    const result = await store.onSave();
    if (result) {
      goOnDescription();
    }
  };

  return (
    <MainLayout isLoading={store.isLoading}>
      <H1>Пользовательское соглашение - редактирование</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_DOCS_AGREEMENTS}>Назад к описанию</ButtonBack>
        <TextEditor
          value={store.form.values.description}
          onChange={(value) =>
            store.form.changeValueByKey(value, "description")
          }
          errorMessage={store.form.errors.description}
        />
        <ButtonsContainer>
          <Button onClick={onClickSave} disabled={store.isLoading}>
            Сохранить изменения
          </Button>
          <Button onClick={goOnDescription} variant="secondary">
            Отмена
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ProjectDescriptionEditPage);
