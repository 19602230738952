import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { ITemplateItem } from "newApp/components/StepsTable/index.types";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";
import { IGetTemplateResponse } from "./responseExample";

export class StepsPageStore {
  isLoadings = {
    getTemplates: false,
    removeItem: false,
  };

  templates: ITemplateItem[] = [];
  pagination = new PaginationStore(undefined, () => this.getTemplates());

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getTemplates();
  };

  reset = () => {
    this.templates = [];
  };

  getTemplates = () => {
    this.isLoadings.getTemplates = true;
    return API.admin.ViolationTemplates.apiViolationTemplatesGet(
      this.pagination.currentPage,
      this.pagination.pageSize
    )
      .then(({ data }) => {
        runInAction(() => {
          try {
            this.templates = (
              data as unknown as IGetTemplateResponse
            ).violationTemplates.map((t) => ({
              id: t.Id,
              title: t.Name,
              level: 0,
            }));
            this.pagination.pages = Number(data["pageCount"]);
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getTemplates = false;
        });
      });
  };

  removeItem = (id: string) => {
    this.isLoadings.removeItem = true;
    return API.admin.ViolationTemplates.apiViolationTemplatesIdDeletePost(id)
      .then(() => {
        this.getTemplates();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.removeItem = false;
        });
      });
  };
}
