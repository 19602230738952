import axios from "axios";
import TokenStore from "../stores/common/TokenStore";

export const config = undefined;

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (req) => {
  await TokenStore.checkAndRefreshTokens();
  const token = TokenStore.accessToken;
  if (token && req.headers) req.headers.Authorization = `Bearer ${token}`;
  return req;
});
