import { makeAutoObservable } from "mobx";
import { FormStore } from "newApp/kit/stores/FormStore";

import { formValidationSchema } from "./validationSchema";

export class FaqRubricFormStore {
  isLoadings = { getOptions: false };

  form = new FormStore(
    {
      title: null,
    },
    formValidationSchema
  );

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {
    this.form.reset();
  };
}
