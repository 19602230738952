import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

export interface IDotedButtonProps {
  onClick?: () => void;
  color?: "blue" | "red";
  title?: string;
}

const DashedButton: React.FC<IDotedButtonProps> = (props) => {
  return (
    <div
      className={[
        "dashed-button",
        props.color ? `dashed-button--${props.color}` : "",
        props.onClick ? "dashed-button--clickable" : "",
      ].join(" ")}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </div>
  );
};

export default observer(DashedButton);
