import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { RubricListItemStore } from "./RubricListItemStore";

class FaqRubricsListPageStore {
  isLoadings = { getRubrics: false };

  rubrics: RubricListItemStore[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getRubrics();
  };

  reset = () => {
    this.rubrics = [];
  };

  getRubrics = () => {
    this.isLoadings.getRubrics = true;
    return API.administration.FAQRubrics.faqRubricListGet()
      .then(({ data }) => {
        runInAction(() => {
          this.rubrics = data.map((i) => new RubricListItemStore(i));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubrics = false;
        });
      });
  };
}

export default new FaqRubricsListPageStore();
