import { observer } from "mobx-react";
import React from "react";
import DividerVertical from "../DividerVertical";
import "./styles.scss";

export interface IListCounterProps {
  countAll?: number;
  countOnPage?: number;
}

const ListCounter: React.FC<IListCounterProps> = (props) => {
  return (
    <div className="list-counter">
      {!!props.countAll && (
        <>
          <div className="list-counter__item">
            <span className="list-counter__item-title">Всего - </span>
            <span className="list-counter__item-value">{props.countAll}</span>
          </div>
          <DividerVertical />
        </>
      )}
      {!!props.countOnPage && (
        <div className="list-counter__item">
          <span className="list-counter__item-title">На странице - </span>
          <span className="list-counter__item-value">{props.countOnPage}</span>
        </div>
      )}
    </div>
  );
};

export default observer(ListCounter);
