import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import Checkboxes from "newApp/kit/components/Checkboxes";
import DatePicker from "newApp/kit/components/DatePicker";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import Modal from "newApp/kit/components/Modal";
import Select from "newApp/kit/components/Select";
import { AnalyticsAcceptedFilterStore } from "newApp/stores/pages/analytics/AnalyticsAcceptedPageStore/AnalyticsAcceptedFilterStore";
import React, { useState } from "react";

import IconFilter from "../IconFilter";

export interface IAnalyticsAcceptedFilterProps {
  filter: AnalyticsAcceptedFilterStore;
  getList: () => void;
}

const AnalyticsAcceptedFilter: React.FC<IAnalyticsAcceptedFilterProps> = (
  props
) => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const onClickSearch = () => {
    props.getList();
    setIsShowPopup(false);
  };
  const onClickCancel = () => {
    props.filter.reset();
    setIsShowPopup(false);
    props.getList();
  };

  return (
    <>
      <Button
        onClick={() => setIsShowPopup(true)}
        variant="secondary"
        size="small"
      >
        <IconFilter />
        <span> Фильтр</span>
      </Button>
      <Modal isOpen={isShowPopup} onClose={() => setIsShowPopup(false)}>
        <H1>Фильтр</H1>
        <FlexColumn>
          <Select
            placeholder="Период"
            {...props.filter.form.adapters.period}
            options={props.filter.options.period}
          />
          {props.filter.form.values.period === "custom" && (
            <>
              <DatePicker
                placeholder="Начальная дата"
                {...props.filter.form.adapters.beginDate}
              />
              <DatePicker
                placeholder="Конечная дата"
                {...props.filter.form.adapters.endDate}
              />
            </>
          )}
          <Checkboxes
            onChange={props.filter.onChangeCheckboxes}
            options={props.filter.options.category}
            values={props.filter.categoryIds}
          />
          <ButtonsContainer>
            <Button onClick={onClickSearch}>Искать</Button>
            <Button onClick={onClickCancel} variant="secondary">
              Сбросить
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      </Modal>
    </>
  );
};

export default observer(AnalyticsAcceptedFilter);
