import React from "react";

import "./index.scss";

const TextArea = (props) => {
  return (
    <>
      <textarea
        className={`${props.className} input__textarea ${
          props.errors ? "warning" : ""
        }`}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        disabled={props.disabled || false}
      />
      {props.errors && (
        <p className="warning-text margin-bottom-12">{props.errors}</p>
      )}
    </>
  );
};

export default TextArea;
