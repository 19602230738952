import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "newApp/constants/validation/validators";
import * as yup from "yup";

export default yup.object().shape({
  issueDate: VALIDATOR_ANY,
  fullname: VALIDATOR_ANY,
  answerFullName: VALIDATOR_ANY,
  answerDate: VALIDATOR_ANY,
  phone: VALIDATOR_ANY,
  text: VALIDATOR_ANY,
  answer: VALIDATOR_STRING_REQUIRED,
});
