import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_SHOP } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import imageCompression from "browser-image-compression";
import { getDataURL } from "newApp/kit/utils/getDataURL";

export class ProductFormMainPhotoStore {
  isLoadings = {
    uploadImage: false,
    uploadCroppedImage: false,
  };

  isShow = {
    mainPhotoCropper: false,
  };

  imagesPreviews: IFilesPreviewFile[] = [];

  imageFile: File | null = null;
  croppedImageFile: IFilesPreviewFile | null = null;

  imageId: string | null = null;
  croppedImageId: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.imageId = null;
    this.croppedImageId = null;
    this.imageFile = null;
    this.croppedImageFile = null;
    this.imagesPreviews = [];
  };

  onCropped = async (results: string[]) => {
    const mainFilePreview = await getDataURL(this.imageFile);

    this.imagesPreviews = [
      {
        reference: mainFilePreview,
        file: this.imageFile,
        title: "Оригинальное изображение",
        type: "Image",
      },
      {
        reference: results[0],
        title: "Обрезанное изображение",
        type: "Image",
      },
    ];

    this.uploadFiles(this.imageFile, results[0]);

    this.isShow.mainPhotoCropper = false;
  };

  onAddFiles = (images: File[]) => {
    this.imageFile = images[0];
    this.isShow.mainPhotoCropper = true;
  };

  onClose = () => {
    this.isShow.mainPhotoCropper = false;
  };

  onClickRemove = () => {
    this.reset();
  };

  uploadFiles = (image, croppedImage) => {
    this.uploadMainImage(image);
    this.uploadCroppedImage(croppedImage);
  };

  uploadMainImage = (image) => {
    this.isLoadings.uploadImage = true;

    API.shop.Products.apiProductsImagePost(image)
      .then(({ data }) => {
        runInAction(() => {
          this.imageId = data;
        });
      })
      .catch((e) => {
        runInAction(() => {
          this.imageId = null;
          console.error(e);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.uploadImage = false;
        });
      });
  };

  uploadCroppedImage = async (image) => {
    this.isLoadings.uploadCroppedImage = true;

    const blob = await imageCompression.getFilefromDataUrl(image, "preview");

    API.shop.Products.apiProductsCroppedimagePost(blob)
      .then(({ data }) => {
        runInAction(() => {
          this.croppedImageId = data;
        });
      })
      .catch((e) => {
        runInAction(() => {
          this.croppedImageId = null;
          console.error(e);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.uploadCroppedImage = false;
        });
      });
  };

  getImages = (imageId, croppedImageId) => {
    this.imageId = imageId;

    if (imageId) {
      this.imagesPreviews.push({
        reference: URL_API_SHOP + "/api/products/image/" + imageId,
        type: "Image",
      });
    }

    if (croppedImageId) {
      this.croppedImageId = croppedImageId;
      this.imagesPreviews.push({
        reference:
          URL_API_SHOP + "/api/products/croppedimage/" + croppedImageId,
        type: "Image",
      });
    }
  };
}
