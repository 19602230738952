import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FaqRubricFormStore } from "newApp/stores/common/FaqRubricFormStore";

class FaqRubricsCreatePageStore {
  isLoadings = { creatFaqRubric: false };

  faqRubricForm = new FaqRubricFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.faqRubricForm.init();
  };

  reset = () => {
    this.faqRubricForm.reset();
  };

  creatFaqRubric = () => {
    this.isLoadings.creatFaqRubric = true;
    return API.administration.FAQRubrics.faqRubricPost({
      title: this.faqRubricForm.form.values.title,
    })
      .then(({ data }) => data.id)
      .catch((e) => {
        console.error(e);
        return null;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.creatFaqRubric = false;
        });
      });
  };

  onSave = async () => {
    await this.faqRubricForm.form.validate();
    if (this.faqRubricForm.form.isValid) {
      return await this.creatFaqRubric();
    }
    return null;
  };
}

export default new FaqRubricsCreatePageStore();
