import { FC } from "react";

interface IIconPros {
  className?: string;
}

const IconSuccess: FC<IIconPros> = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="#699F38"
      />
      <path
        d="M12.7778 5L8.11111 9L5.22222 6.46341L3 8.41463L5.88889 10.9512L8.22222 13L10.4444 11.0488L15 7.04878"
        fill="white"
      />
    </svg>
  );
};

export default IconSuccess;
