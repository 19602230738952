import { makeAutoObservable } from "mobx";
import { FormStore } from "newApp/kit/stores/FormStore";
import formValidationSchema from "./formValidationSchema";

export class ShopCategoryFormStore {
  form = new FormStore(
    {
      title: null,
    },
    formValidationSchema
  );

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.form.reset();
  };
}
