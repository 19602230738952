import { autorun, makeAutoObservable, runInAction } from "mobx";
import { IDropzoneProps } from "../../components/Dropzone/index.types";
import { IFilesListProps } from "../../components/FilesList/index.types";
import { getFileSizeMB } from "../../utils/getFileSizeMB";
import FileItemStore from "./FileListItem";
import { IFilesListValidation, TFileUploader } from "./index.types";

interface IFilesListConfig {
  isUploadManual: Boolean;
  validation: IFilesListValidation;
}
class FilesListStore {
  files: FileItemStore[] = [];
  errorMessage?: string | null = null;
  isValid: boolean = false;
  config: IFilesListConfig;
  private uploadFile: TFileUploader;

  constructor(uploadFile: TFileUploader, config?: Partial<IFilesListConfig>) {
    this.uploadFile = uploadFile;
    this.config = {
      isUploadManual: config?.isUploadManual || false,
      validation: config?.validation || {},
    };

    this.validate();

    autorun(() => {
      if (!this.config.isUploadManual && this.isValid) {
        this.uploadFiles();
      }
    });
    makeAutoObservable(this);
  }

  get fileSizeAll() {
    return this.files.reduce(
      (result, currentFile) => result + currentFile.file.size,
      0
    );
  }

  get filesTotal() {
    return this.files.length;
  }

  get filesListAdapter(): IFilesListProps {
    return {
      files: this.files,
      onRemoveFile: this.removeFile,
      onRemoveFileAll: this.removeFileAll,
      errorTopMessage: this.errorMessage,
    };
  }

  get dropzoneAdapter(): IDropzoneProps {
    return {
      onAddFiles: this.addFiles,
      acceptFileTypes: this.config.validation.acceptFileTypes,
      maxFileSize: this.config.validation.maxFileSize,
      maxFileSizeAll: this.config.validation.maxFileSizeAll,
      maxFiles: this.config.validation.maxFiles,
    };
  }

  get isUploaded() {
    return this.files.every((file) => file.status === "success");
  }

  get isUploading() {
    return this.files.some((file) => file.status === "loading");
  }

  get uploadButtonAdapter() {
    return {
      onClick: this.uploadFiles,
      disabled: this.isUploading || !this.isValid,
    };
  }

  addFiles = (addedFiles: File[]) => {
    this.files = [
      ...this.files,
      ...addedFiles.map<FileItemStore>((file) => {
        const newFile = new FileItemStore(file, this.uploadFile);
        return newFile;
      }),
    ];
    this.validate();
  };

  removeFile = (idx: number) => {
    this.files.splice(idx, 1);
    this.validate();
  };

  removeFileAll = () => {
    this.files = [];
    this.validate();
  };

  uploadFiles = () => {
    this.files.forEach((file) => {
      if (file.status === "idle") {
        runInAction(() => file.upload());
      }
    });
  };

  validate = () => {
    this.errorMessage = null;
    let _isValid = true;
    const _errorMessages = [];

    if (
      this.config.validation.minFiles &&
      this.files.length < this.config.validation.minFiles
    ) {
      _isValid = false;
      _errorMessages.push(
        `Количество файлов меньше допустимого! (${this.filesTotal}/${this.config.validation.minFiles})`
      );
    }

    if (
      this.config.validation.maxFiles &&
      this.files.length > this.config.validation.maxFiles
    ) {
      _isValid = false;
      _errorMessages.push(
        `Количество файлов больше допустимого! (${this.filesTotal}/${this.config.validation.maxFiles})`
      );
    }

    if (
      this.config.validation.maxFileSizeAll &&
      this.fileSizeAll > this.config.validation.maxFileSizeAll
    ) {
      _isValid = false;
      _errorMessages.push(
        ` Размер файлов превышает допустимый! (${getFileSizeMB(
          this.fileSizeAll
        )}/${getFileSizeMB(this.config.validation.maxFileSizeAll)} Мб)`
      );
    }

    this.files.forEach((file) => {
      const _fileErrorMessages = [];
      if (
        this.config.validation.maxFileSize &&
        file.file.size > this.config.validation.maxFileSize
      ) {
        _isValid = false;
        file.status = "error";
        _fileErrorMessages.push("Размер файла превышает допустимый!");
      }

      if (
        this.config.validation.acceptFileTypes &&
        !this.config.validation.acceptFileTypes.find((type) =>
          file.file?.type.includes(type.replace("*", ""))
        )
      ) {
        _isValid = false;
        file.status = "error";
        _fileErrorMessages.push("Неподдерживаемый тип файла!");
      }

      file.errorMessage = _fileErrorMessages.join(" ");
    });

    this.isValid = _isValid;
    this.errorMessage = _errorMessages.join(" ") || null;
  };
}

export default FilesListStore;
