import {
  URL_ADMINISTRATION_STEPS,
  URL_ADMISSION_USERS,
  URL_ANALYTICS_INWORK,
  URL_EXECUTION_ACCEPTED,
  URL_MODERATION_CREATED,
  URL_SITE,
  URL_VOTING_ACTIVE,
  URL_SHOP_PRODUCTS,
} from "newApp/routing/urls";

import { TModule } from "./index.types";

export const moduleVariants: TModule[] = [
  {
    id: "admission",
    title: "Распределение прав доступа",
    description: "Управление ролевой моделью, создание пользователей.",
    to: URL_ADMISSION_USERS,
  },
  {
    id: "moderation",
    title: "Модерирование сообщений",
    description: "Модерация полученных сообщений, контроль их исполнения.",
    to: URL_MODERATION_CREATED,
  },
  {
    id: "execution",
    title: "Исполнение сообщений",
    description:
      "Работа исполнителей с сообщениями, обратная связь для заявителей.",
    to: URL_EXECUTION_ACCEPTED,
  },
  {
    id: "administration",
    title: "Администрирование",
    description:
      "Управление справочником организаций, классификатором заявлений, конструктором шагов сообщений.",
    to: URL_ADMINISTRATION_STEPS,
  },
  {
    id: "voting",
    title: "Голосование",
    description:
      "Создание и управление голосованиями, просмотр результатов голосований.",
    to: URL_VOTING_ACTIVE,
  },
  {
    id: "site",
    title: "Сайт",
    description:
      "Управление новостным разделом, разделом описания проекта и Часто задаваемыми вопросами.",
    to: URL_SITE,
  },
  {
    id: "analytics",
    title: "Аналитика",
    description:
      "Аналитика по различным категориям и организациям, выгрузка отчетов в файл.",
    to: URL_ANALYTICS_INWORK,
  },
  {
    id: "shop",
    title: "Магазин поощрений",
    description:
      "Управление товарами, услугами и их категориями, информационными блоками.",
    to: URL_SHOP_PRODUCTS,
  },
];
