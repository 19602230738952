import { observer } from "mobx-react";
import React from "react";
import { IStepsContainerProps } from "./index.types";
import "./styles.scss";

const StepsContainer: React.FC<IStepsContainerProps> = ({ steps }) => {
  return (
    <div className="steps-container">
      {steps.map((step, idx) => {
        console.log("step", step.number, step.name, step.body);

        return (
          <div
            className={[
              "steps-container__item",
              step.isComplete ? "steps-container__item--complete" : "",
            ].join(" ")}
            key={idx}
          >
            <div className="steps-container__item-header">
              <div className="steps-container__item-header-number">
                <div className="steps-container__item-header-number-content">
                  {!!step.number ? step.number : idx + 1}
                </div>
              </div>
              <div className="steps-container__item-header-title">
                {step.name}
              </div>
            </div>
            <div className="steps-container__item-body">{step.body}</div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(StepsContainer);
