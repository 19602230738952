import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import getUTSIsoString from "newApp/utils/getUTSIsoString";

import { ProductFormStore } from "../../../../common/ProductFormStore";

export class ProductsEditPageStore {
  isLoadings = {
    getProduct: false,
    saveProduct: false,
    disapproveProduct: false,
  };
  id: string = null;
  isEsk = false;

  productForm = new ProductFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = async (id: string) => {
    this.id = id;
    await this.getProduct(id);
    this.productForm.init();
  };

  getProduct = (id: string) => {
    this.isLoadings.getProduct = true;

    return API.shop.Products.apiProductsIdV2Get(id)
      .then(({ data }) => {
        runInAction(() => {
          this.productForm.form.changeValueByKey(data.title || null, "title");
          this.productForm.form.changeValueByKey(data.price || null, "price");
          this.productForm.form.changeValueByKey(
            data.departmentId || null,
            "organizationId"
          );

          //   this.productForm.currentType = data.type || "Goods";
          this.productForm.arrays.categories = data.categoriesIds;
          this.productForm.form.changeValueByKey(
            data.description || null,
            "description"
          );

          this.productForm.form.changeValueByKey(
            data.quantity || null,
            "quantity"
          );

          this.productForm.form.changeValueByKey(
            data.creationDate ? new Date(data.creationDate) : new Date(),
            "date"
          );
          this.productForm.mainPhoto.getImages(
            data.imageId ? data.imageId : null,
            data.croppedImageId ? data.croppedImageId : null
          );
          this.isEsk = data.isEsk;
        });
      })
      .catch((e) => {
        runInAction(() => {
          console.error(e);
          this.productForm.reset();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getProduct = false;
        });
      });
  };

  reset = () => {
    this.productForm.reset();
    this.isEsk = false;
  };

  validate = async () => {
    await this.productForm.validate();
  };

  get isValid() {
    return this.productForm.isValid;
  }

  get isLoadingImage() {
    return Object.values(this.productForm.mainPhoto.isLoadings).some((i) => i);
  }

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }

  saveProduct = () => {
    this.isLoadings.saveProduct = true;

    return API.shop.Products.apiProductsEditIdV2Post(this.id, {
      title: this.productForm.form.values.title,
      description: this.productForm.form.values.description,
      //   type: this.productForm.form.values.type,
      price: this.productForm.form.values.price,
      quantity: Number(this.productForm.form.values.quantity || 0),
      //   wayToGet: this.productForm.form.values.wayToGet,
      imageId: this.productForm.mainPhoto.imageId,
      // ДИМА!!! РАСКОММЕНТИРУЙ, КОГДА СВЕТА СКАЖЕТ, ЧТО НАДО ДОБАВИТЬ ОБРЕЗАННОЕ ИЗОБРАЖЕНИЕ
      croppedImageId: this.productForm.mainPhoto.croppedImageId,
      creationDate: getUTSIsoString(this.productForm.form.values.date),
      categories: this.productForm.arrays.categories.map((i) => String(i)),
      // organizationAddress: this.productForm.form.values.organizationAddress,
      // organizationName: this.productForm.form.values.organizationName,
      // organizationCoordinates: this.productForm.orgCoords,
      departmentId: this.productForm.form.values.organizationId,
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveProduct = false;
        });
      });
  };

  onClickSaveProduct = async () => {
    await this.validate();
    if (this.isValid) {
      const res = this.saveProduct();
      return res;
    }
  };

  disapproveProduct = async () => {
    this.isLoadings.disapproveProduct = true;
    try {
      await API.shop.Products.apiProductsDisapproveIdPost(this.id);
    } catch (error) {
    } finally {
      runInAction(() => (this.isLoadings.disapproveProduct = false));
    }
  };
}
