import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "newApp/constants/validation/validators";
import * as yup from "yup";

export default yup.object().shape({
  title: VALIDATOR_STRING_REQUIRED,
  endDateTime: VALIDATOR_STRING_REQUIRED,
  startDateTime: VALIDATOR_STRING_REQUIRED,
  place: VALIDATOR_ANY,
  mainVoteTitle: VALIDATOR_ANY,

  annotation: VALIDATOR_ANY,
  position: VALIDATOR_ANY,
  description: VALIDATOR_ANY,

  isBanner: VALIDATOR_ANY,
  isImportant: VALIDATOR_ANY,

  isTerritoryLimit: VALIDATOR_ANY,
  isDigitalResult: VALIDATOR_ANY,

  isAgeLimit: VALIDATOR_ANY,
  fromAge: VALIDATOR_ANY.when("isAgeLimit", {
    is: true,
    then: VALIDATOR_STRING_REQUIRED,
  }),
  toAge: VALIDATOR_ANY.when("isAgeLimit", {
    is: true,
    then: VALIDATOR_STRING_REQUIRED,
  }),

  isGenderLimit: VALIDATOR_ANY,
  gender: VALIDATOR_ANY.when("isGenderLimit", {
    is: true,
    then: VALIDATOR_STRING_REQUIRED,
  }),

  isReward: VALIDATOR_ANY,
  numberOfPoints: VALIDATOR_ANY.when("isReward", {
    is: true,
    then: VALIDATOR_STRING_REQUIRED,
  }),
});
