import { ComponentPropsWithoutRef, FC } from "react";

const IconClip: FC<ComponentPropsWithoutRef<"svg">> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6708 2.99723C17.8124 2.09038 16.6709 1.591 15.4569 1.591C14.2428 1.591 13.1014 2.09038 12.2428 2.99723L5.07778 10.5656C4.01448 11.6887 4.01448 13.5163 5.07784 14.6396C6.14121 15.7627 7.8714 15.7627 8.9347 14.6394L13.6527 9.65579C14.0078 9.28079 14.0078 8.67283 13.6527 8.29782C13.2978 7.92282 12.7222 7.92282 12.3671 8.29782L7.6491 13.2815C7.29469 13.656 6.71792 13.6559 6.36344 13.2815C6.00897 12.9071 6.00897 12.2979 6.36338 11.9235L13.5284 4.35527C14.0436 3.8112 14.7284 3.51154 15.4569 3.51154C16.1853 3.51154 16.8701 3.8112 17.3852 4.35527C18.4485 5.47849 18.4486 7.30602 17.3853 8.42925L9.57752 16.6764C7.80522 18.5484 4.92161 18.5483 3.14948 16.6764C2.29096 15.7696 1.81813 14.564 1.81813 13.2815C1.81813 11.9991 2.29096 10.7934 3.14948 9.8865L10.9571 1.6392C11.3121 1.2642 11.3121 0.656236 10.9571 0.28123C10.6022 -0.0937113 10.0265 -0.0937754 9.67152 0.28123L1.86388 8.52853C0.661919 9.7981 0 11.4861 0 13.2815C0 15.077 0.661919 16.765 1.86388 18.0345C3.10457 19.345 4.73373 20.0002 6.36344 20C7.99279 19.9999 9.62267 19.3447 10.8631 18.0345L18.6709 9.78715C20.4431 7.91513 20.443 4.86925 18.6708 2.99723Z"
        fill="#535B63"
      />
    </svg>
  );
};

export default IconClip;
