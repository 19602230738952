import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import MainLayout from "../../../../components/layouts/MainLayout";
import Button from "../../../../kit/components/Button";
import ButtonsContainer from "../../../../kit/components/ButtonsContainer";
import FlexColumn from "../../../../kit/components/FlexColumn";
import H1 from "../../../../kit/components/H1";
import H3 from "../../../../kit/components/H3";
import InfoTable from "../../../../kit/components/InfoTable";
import Table from "../../../../kit/components/Table";
import { UsersItemPageStore } from "../../../../stores/pages/admission/UsersItemPageStore";

const store = new UsersItemPageStore();

const UsersItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (id) store.init(id);
    return () => store.reset();
  }, [id]);

  const roles: [string | null, string | null][] = [];
  store.user.organizations.forEach((organization) => {
    organization.roles.forEach((role) => {
      roles.push([organization.organizationTitle, role.roleTitle]);
    });
  });

  return (
    <MainLayout isLoading={store.isLoading}>
      <FlexColumn>
        <H1>{store.user.fullName}</H1>
        <ButtonsContainer>
          <Button
            size="small"
            variant="secondary"
            onClick={() => push(pathname + `/edit`)}
          >
            Редактировать данные
          </Button>
          {!!store.user.data.isDeactivated ? (
            <Button
              size="small"
              variant="primary"
              onClick={() => {
                if (id) store.user.activate(id);
              }}
              disabled={store.user.isLoadings.activate}
            >
              Разблокировать
            </Button>
          ) : (
            <Button
              size="small"
              variant="danger"
              onClick={() => {
                if (id) store.user.deactivate(id);
              }}
              disabled={store.user.isLoadings.deactivate}
            >
              Заблокировать
            </Button>
          )}
        </ButtonsContainer>
        <InfoTable
          header="Данные пользователя"
          content={[
            ["Фамилия", store.user.data.lastName],
            ["Имя", store.user.data.firstName],
            [`Отчество`, store.user.data.middleName],
            [`E-Mail`, store.user.data.email],
            [`Телефон`, store.user.data.phone],
            [`Телефон 2`, store.user.data.otherPhones],
            [`СНИЛС`, store.user.data.login],
            [`Логин`, store.user.data.login],
          ]}
        />
        <div>
          <H3>Организации и роли</H3>
          <Table headers={["Организация", "Роль"]} content={roles} />
        </div>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(UsersItemPage);
