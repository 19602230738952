import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import VoteForm from "newApp/components/VoteForm";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { scrollToError } from "newApp/kit/utils/scrollToError";
import { URL_VOTING_ACTIVE } from "newApp/routing/urls";
import { VoteEditPageStore } from "newApp/stores/pages/votings/VoteEditPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new VoteEditPageStore();

const VoteEditPage: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  const goOnList = () => push(URL_VOTING_ACTIVE);
  const onClickSave = async () => {
    const result = await store.onSave();
    scrollToError();
    if (result) push(URL_VOTING_ACTIVE + `/${id}`);
  };

  return (
    <MainLayout isLoading={store.isLoadings.getVote}>
      <H1>Редактировать голосование</H1>
      <FlexColumn>
        <ButtonBack url={URL_VOTING_ACTIVE}>Назад к списку</ButtonBack>
        <VoteForm {...store} />
        <ButtonsContainer>
          <Button onClick={onClickSave}>Сохранить изменения</Button>
          <Button onClick={goOnList} variant="secondary">
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(VoteEditPage);
