import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

export interface ITabsItemProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

const TabsItem: React.FC<ITabsItemProps> = (props) => {
  return (
    <div
      className={["tabs-item", props.isActive ? "tabs-item--active" : ""].join(
        " "
      )}
      onClick={props.onClick}
    >
      <div className="tabs-item__title">{props.title}</div>
      {props.description && (
        <div className="tabs-item__description">{props.description}</div>
      )}
    </div>
  );
};

export default observer(TabsItem);
