/** Класс для изоляции локал стораджа ведь в мобилках его нет */
export class LocalStorage {
  private storage: Storage;

  constructor() {
    // TODO здесь должна быть проверка что сторадж существует, а если нет то надо использовать библиотеку
    this.storage = localStorage;
  }

  removeAll = () => {
    localStorage.clear();
  };

  get accessToken() {
    return this.storage.getItem("token");
  }
  set accessToken(value: string | null) {
    if (value) {
      this.storage.setItem("token", value);
    } else {
      this.storage.removeItem("token");
    }
  }

  get refreshToken() {
    return this.storage.getItem("refresh");
  }
  set refreshToken(value: string | null) {
    if (value) {
      this.storage.setItem("refresh", value);
    } else {
      this.storage.removeItem("refresh");
    }
  }

  get refreshTokenExpiration() {
    return this.storage.getItem("refresh-exp")
      ? Number(this.storage.getItem("refresh-exp"))
      : null;
  }
  set refreshTokenExpiration(value: number | null) {
    if (value) {
      this.storage.setItem("refresh-exp", String(value));
    } else {
      this.storage.removeItem("refresh-exp");
    }
  }

  get organizationId() {
    return this.storage.getItem("organization")
      ? String(this.storage.getItem("organization"))
      : null;
  }
  set organizationId(value: string | null) {
    if (value) {
      this.storage.setItem("organization", String(value));
    } else {
      this.storage.removeItem("organization");
    }
  }
}
