import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

export interface IVoteItemDatesProps {
  dateStart: string | null | Date;
  dateFinish: string | null | Date;
}

const VoteItemDates: React.FC<IVoteItemDatesProps> = (props) => {
  return (
    <div className="vote-item-dates">
      <div className="vote-item-dates__item">
        <div className="vote-item-dates__item-title">Дата начала</div>
        {props.dateStart && (
          <div className="vote-item-dates__item-value">
            {new Date(props.dateStart).toLocaleDateString()}
          </div>
        )}
      </div>
      <div className="vote-item-dates__item">
        <div className="vote-item-dates__item-title">Время начала</div>
        {props.dateStart && (
          <div className="vote-item-dates__item-value">
            {new Date(props.dateStart).toLocaleTimeString()}
          </div>
        )}
      </div>

      <div className="vote-item-dates__divider" />
      <div className="vote-item-dates__item">
        <div className="vote-item-dates__item-title">Дата окончания</div>
        {props.dateFinish && (
          <div className="vote-item-dates__item-value">
            {new Date(props.dateFinish).toLocaleDateString()}
          </div>
        )}
      </div>
      <div className="vote-item-dates__item">
        <div className="vote-item-dates__item-title">Время окончания</div>
        {props.dateFinish && (
          <div className="vote-item-dates__item-value">
            {new Date(props.dateFinish).toLocaleTimeString()}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(VoteItemDates);
