import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { NewsRubricsSetForm } from "newApp/api/administration/openapi";

export class RubricsListItemStore {
  isLoadings = {
    postRubric: false,
    deleteRubric: false,
  };

  form: IRubricListItemForm = {
    id: null,
    isActive: null,
    isDeletable: null,
    title: null,
  };

  rootStore: IRootStore;

  constructor(form: IRubricListItemForm, rootStore: IRootStore) {
    Object.entries(form).forEach(([key, value]) => {
      this.form[key] = value;
    });
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  onChangeActive = () => {
    return this.postRubric({
      title: this.form.title,
      isActive: !this.form.isActive,
    })
      .then(() => this.rootStore.getRubrics())
      .catch((e) => console.error(e));
  };

  postRubric = (form: NewsRubricsSetForm) => {
    this.isLoadings.postRubric = true;
    return API.administration.NewsRubrics.newsRubricIdPost(
      this.form.id,
      form
    ).finally(() => {
      runInAction(() => {
        this.isLoadings.postRubric = false;
      });
    });
  };

  deleteRubric = () => {
    this.isLoadings.deleteRubric = true;
    return API.administration.NewsRubrics.newsRubricIdDeletePost(
      this.form.id
    ).finally(() => {
      runInAction(() => {
        this.isLoadings.deleteRubric = false;
      });
    });
  };

  onClickDelete = () => {
    this.deleteRubric()
      .then(() => this.rootStore.getRubrics())
      .catch((e) => console.error(e));
  };
}

export interface IRubricListItemForm {
  id?: string | null;
  isActive?: boolean | null;
  isDeletable?: boolean | null;
  title?: string | null;
}

interface IRootStore {
  getRubrics: () => void;
}
