import { observer } from "mobx-react";
import { URL_SITE_FAQ_RUBRICS } from "newApp/routing/urls";
import { RubricListItemStore } from "newApp/stores/pages/site/faq/rubrics/FaqRubricsListPageStore/RubricListItemStore";
import React from "react";
import { useHistory } from "react-router";
import ListItemCard from "../ListItemCard";
import "./styles.scss";

export interface IFaqRubricsListProps {
  rubrics: RubricListItemStore[];
}

const FaqRubricsList: React.FC<IFaqRubricsListProps> = (props) => {
  const { push } = useHistory();

  return (
    <div className="faq-rubrics-list">
      {props.rubrics.map((rubric, idx) => (
        <ListItemCard
          title={rubric.form.title}
          onClickTitle={() =>
            push(URL_SITE_FAQ_RUBRICS + `/${rubric.form.id}/edit`)
          }
          key={idx}
          headerButtons={[
            {
              text: "Редактировать",
              onClick: () =>
                push(URL_SITE_FAQ_RUBRICS + `/${rubric.form.id}/edit`),
            },
          ]}
        />
      ))}
    </div>
  );
};

export default observer(FaqRubricsList);
