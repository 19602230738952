import { makeAutoObservable } from "mobx";
import TokenStore from "../TokenStore";

class AuthStore {
  private _isAuth: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isAuth() {
    return this._isAuth;
  }

  checkAuth = () => {
    this._isAuth = !!TokenStore.accessToken && !!TokenStore.refreshToken;
  };

  logOut = () => {
    TokenStore.clearTokens();
  };
}

export default new AuthStore();
