import { FC } from "react";

interface IIconPros {
  className?: string;
}

const IconClose: FC<IIconPros> = ({ className }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.11889 4L0.127805 1.00859C0.0455285 0.926239 0.000130379 0.816378 2.98023e-07 0.699232C2.98023e-07 0.582022 0.0453984 0.472031 0.127805 0.389814L0.389919 0.127748C0.472326 0.0452062 0.582179 0 0.699447 0C0.81652 0 0.926374 0.0452062 1.00878 0.127748L3.99987 3.11897L6.99109 0.127748C7.07337 0.0452062 7.18328 0 7.30049 0C7.41756 0 7.52748 0.0452062 7.60976 0.127748L7.872 0.389814C8.04267 0.560492 8.04267 0.838103 7.872 1.00859L4.88085 4L7.872 6.99128C7.95434 7.07376 7.99967 7.18362 7.99967 7.30077C7.99967 7.41791 7.95434 7.52777 7.872 7.61019L7.60982 7.87225C7.52754 7.95473 7.41756 8 7.30055 8C7.18335 8 7.07343 7.95473 6.99115 7.87225L3.99994 4.88097L1.00885 7.87225C0.926439 7.95473 0.816585 8 0.699512 8H0.699382C0.582244 8 0.472391 7.95473 0.389984 7.87225L0.12787 7.61019C0.045594 7.52784 0.000195313 7.41791 0.000195313 7.30077C0.000195313 7.18362 0.045594 7.07376 0.12787 6.99135L3.11889 4Z"
        fill="#4C4C4C"
      />
    </svg>
  );
};

export default IconClose;
