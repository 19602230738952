import React from "react";

const IconExecution = () => {
  return (
    <svg width="56" height="60" viewBox="0 0 87 94">
      <path
        fill="#882C80"
        d="M82.1 62.4c-.4 3.8-3.4 9.1-6.4 11.2-.1 0-5.7 4.2-12.6 8.4-7 4.1-13.3 7-13.3 7-1.6.7-3.9 1.1-6.3 1.1s-4.7-.4-6.3-1.1c-.1 0-6.5-2.9-13.3-7-7-4.1-12.5-8.3-12.6-8.4-3-2.2-6-7.5-6.4-11.2 0-.1-.7-7.2-.7-15.3-.1-8.3.7-15.3.7-15.4.4-3.8 3.4-9.1 6.4-11.2.1 0 5.7-4.2 12.6-8.4 7-4.1 13.3-7 13.3-7 1.6-.7 3.9-1.1 6.3-1.1s4.7.4 6.3 1.1c.1 0 6.4 2.9 13.3 7s12.5 8.3 12.6 8.4c3 2.2 6 7.5 6.4 11.2 0 .1.7 7.2.7 15.3.1 8.2-.7 15.3-.7 15.4zm4-31.2c-.5-4.9-4.1-11.1-8-14 0 0-5.8-4.3-13-8.6S51.4 1.5 51.4 1.5c-2.2-1-5.1-1.5-8-1.5s-5.8.5-8 1.5c0 0-6.6 3-13.7 7.2-7.2 4.3-12.9 8.6-12.9 8.6-3.9 2.9-7.5 9.2-8 14 0 0-.8 7.3-.8 15.7 0 8.6.8 15.7.8 15.7.5 4.9 4.1 11.1 8 14 0 0 5.8 4.3 13 8.6 7.3 4.3 13.7 7.3 13.7 7.3 2.2 1 5.1 1.5 8 1.5 2.9 0 5.8-.5 8-1.5 0 0 6.6-3 13.7-7.2 7.3-4.3 13-8.6 13-8.6 3.9-2.9 7.5-9.2 8-14 0 0 .8-7.3.8-15.7-.1-8.6-.9-15.9-.9-15.9z"
      ></path>
      <defs>
        <path
          id="a"
          d="M82.1 62.4c-.4 3.8-3.4 9.1-6.4 11.2-.1 0-5.7 4.2-12.6 8.4-7 4.1-13.3 7-13.3 7-1.6.7-3.9 1.1-6.3 1.1s-4.7-.4-6.3-1.1c-.1 0-6.5-2.9-13.3-7-7-4.1-12.5-8.3-12.6-8.4-3-2.2-6-7.5-6.4-11.2 0-.1-.7-7.2-.7-15.3-.1-8.3.7-15.3.7-15.4.4-3.8 3.4-9.1 6.4-11.2.1 0 5.7-4.2 12.6-8.4 7-4.1 13.3-7 13.3-7 1.6-.7 3.9-1.1 6.3-1.1s4.7.4 6.3 1.1c.1 0 6.4 2.9 13.3 7s12.5 8.3 12.6 8.4c3 2.2 6 7.5 6.4 11.2 0 .1.7 7.2.7 15.3.1 8.2-.7 15.3-.7 15.4zm4-31.2c-.5-4.9-4.1-11.1-8-14 0 0-5.8-4.3-13-8.6S51.4 1.5 51.4 1.5c-2.2-1-5.1-1.5-8-1.5s-5.8.5-8 1.5c0 0-6.6 3-13.7 7.2-7.2 4.3-12.9 8.6-12.9 8.6-3.9 2.9-7.5 9.2-8 14 0 0-.8 7.3-.8 15.7 0 8.6.8 15.7.8 15.7.5 4.9 4.1 11.1 8 14 0 0 5.8 4.3 13 8.6 7.3 4.3 13.7 7.3 13.7 7.3 2.2 1 5.1 1.5 8 1.5 2.9 0 5.8-.5 8-1.5 0 0 6.6-3 13.7-7.2 7.3-4.3 13-8.6 13-8.6 3.9-2.9 7.5-9.2 8-14 0 0 .8-7.3.8-15.7-.1-8.6-.9-15.9-.9-15.9z"
        ></path>
      </defs>
      <clipPath id="b">
        <use overflow="visible"></use>
      </clipPath>
      <path
        clipPath="url(#b)"
        fill="#882C80"
        d="M-6.2-6.3h99.3v106.5H-6.2z"
      ></path>
      <path
        fill="#882C80"
        d="M58.5 66.9c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8c.1 4.5-3.5 8-8 8zM27.9 27h30.8v20h-.1c-6.7 0-12 5.4-12 12 0 2.2.6 4.3 1.6 6H27.9V27zm34.7 20.7V25c0-1.1-.9-2-2-2H25.9c-1.1 0-2 .9-2 2v42c0 1.1.9 2 2 2h26c1.9 1.3 4.2 2 6.7 2 6.7 0 12-5.4 12-12 0-5.2-3.3-9.7-8-11.3z"
      ></path>
      <defs>
        <path
          id="c"
          d="M58.5 66.9c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8c.1 4.5-3.5 8-8 8zM27.9 27h30.8v20h-.1c-6.7 0-12 5.4-12 12 0 2.2.6 4.3 1.6 6H27.9V27zm34.7 20.7V25c0-1.1-.9-2-2-2H25.9c-1.1 0-2 .9-2 2v42c0 1.1.9 2 2 2h26c1.9 1.3 4.2 2 6.7 2 6.7 0 12-5.4 12-12 0-5.2-3.3-9.7-8-11.3z"
        ></path>
      </defs>
      <clipPath id="d">
        <use overflow="visible"></use>
      </clipPath>
      <path
        clipPath="url(#d)"
        fill="#882C80"
        d="M17.6 16.7h59.2v60.5H17.6z"
      ></path>
      <g>
        <path
          fill="#882C80"
          d="M62.2 55l-4.6 4.2-1.7-1.7c-.8-.8-2.1-.8-2.8 0-.8.8-.8 2.1 0 2.8l3 3c.4.4.9.6 1.4.6s1-.2 1.4-.5l6-5.5c.8-.7.9-2 .1-2.8-.7-.8-2-.8-2.8-.1"
        ></path>
        <defs>
          <path
            id="e"
            d="M62.2 55l-4.6 4.2-1.7-1.7c-.8-.8-2.1-.8-2.8 0-.8.8-.8 2.1 0 2.8l3 3c.4.4.9.6 1.4.6s1-.2 1.4-.5l6-5.5c.8-.7.9-2 .1-2.8-.7-.8-2-.8-2.8-.1"
          ></path>
        </defs>
        <clipPath id="f">
          <use overflow="visible"></use>
        </clipPath>
        <path
          clipPath="url(#f)"
          fill="#882C80"
          d="M46.3 48.2h25.6v22H46.3z"
        ></path>
      </g>
      <g>
        <path
          fill="#882C80"
          d="M33.5 36h19c1.1 0 2-.9 2-2s-.9-2-2-2h-19c-1.1 0-2 .9-2 2 .1 1.1 1 2 2 2"
        ></path>
        <defs>
          <path
            id="g"
            d="M33.5 36h19c1.1 0 2-.9 2-2s-.9-2-2-2h-19c-1.1 0-2 .9-2 2 .1 1.1 1 2 2 2"
          ></path>
        </defs>
        <clipPath id="h">
          <use xlinkHref="#g" overflow="visible"></use>
        </clipPath>
        <path
          clipPath="url(#h)"
          fill="#882C80"
          d="M25.3 25.8h35.5v16.5H25.3z"
        ></path>
      </g>
      <g>
        <path
          fill="#882C80"
          d="M54.5 43c0-1.1-.9-2-2-2h-19c-1.1 0-2 .9-2 2s.9 2 2 2h19c1.2 0 2-.9 2-2"
        ></path>
        <defs>
          <path
            id="i"
            d="M54.5 43c0-1.1-.9-2-2-2h-19c-1.1 0-2 .9-2 2s.9 2 2 2h19c1.2 0 2-.9 2-2"
          ></path>
        </defs>
        <clipPath id="j">
          <use xlinkHref="#i" overflow="visible"></use>
        </clipPath>
        <path
          clipPath="url(#j)"
          fill="#882C80"
          d="M25.3 34.7h35.5v16.5H25.3z"
        ></path>
      </g>
    </svg>
  );
};

export default IconExecution;
