import React from "react";
import { observer } from "mobx-react";

import Tabs from "newApp/kit/components/Tabs";

import {
  URL_SHOP_PRODUCTS,
  URL_SHOP_CATEGORIES,
  URL_SHOP_SETTINGS,
  URL_SHOP_ANALYTICS,
  URL_SHOP_BALLS,
} from "newApp/routing/urls";
import { useHistory, useLocation } from "react-router";

const ShopNavigator: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const tabs = [
    {
      title: "Магазин",
      onClick: () => push(URL_SHOP_PRODUCTS),
      isActive: [URL_SHOP_PRODUCTS].includes(pathname),
    },
    {
      title: "Рубрики",
      onClick: () => push(URL_SHOP_CATEGORIES),
      isActive: pathname === URL_SHOP_CATEGORIES,
    },
    {
      title: "Аналитика",
      onClick: () => push(URL_SHOP_ANALYTICS),
      isActive: pathname === URL_SHOP_ANALYTICS,
    },
    {
      title: "Баллы",
      onClick: () => push(URL_SHOP_BALLS),
      isActive: pathname === URL_SHOP_BALLS,
    },
    {
      title: "Настройки",
      onClick: () => push(URL_SHOP_SETTINGS),
      isActive: pathname === URL_SHOP_SETTINGS,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(ShopNavigator);
