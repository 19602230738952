import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import VoteForm from "newApp/components/VoteForm";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { scrollToError } from "newApp/kit/utils/scrollToError";
import { URL_VOTING, URL_VOTING_ACTIVE } from "newApp/routing/urls";
import { VoteCreatePageStore } from "newApp/stores/pages/votings/VoteCreatePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new VoteCreatePageStore();

const VoteCreatePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const onClickCreate = async () => {
    const result = await store.onSave();
    scrollToError();
    if (result) push(URL_VOTING_ACTIVE + `/${result}`);
  };

  const goOnList = () => push(URL_VOTING);

  return (
    <MainLayout>
      <H1>Создать голосование</H1>
      <FlexColumn>
        <ButtonBack url={URL_VOTING}>Назад к списку</ButtonBack>
        <VoteForm {...store} />
        <ButtonsContainer>
          <Button onClick={onClickCreate}>Создать</Button>
          <Button onClick={goOnList} variant="secondary">
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(VoteCreatePage);
