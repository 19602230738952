import { ComponentPropsWithoutRef, FC } from "react";

const IconClose: FC<ComponentPropsWithoutRef<"svg">> = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.45807 7L0.223658 1.76503C0.079674 1.62092 0.000227247 1.42866 -3.94881e-07 1.22366C-3.94881e-07 1.01854 0.0794463 0.826054 0.223658 0.682174L0.682358 0.223559C0.826569 0.0791108 1.01881 0 1.22403 0C1.42891 0 1.62115 0.0791108 1.76537 0.223559L6.99977 5.45819L12.2344 0.223559C12.3784 0.0791108 12.5707 0 12.7759 0C12.9807 0 13.1731 0.0791108 13.3171 0.223559L13.776 0.682174C14.0747 0.980861 14.0747 1.46668 13.776 1.76503L8.54148 7L13.776 12.2347C13.9201 12.3791 13.9994 12.5713 13.9994 12.7763C13.9994 12.9813 13.9201 13.1736 13.776 13.3178L13.3172 13.7764C13.1732 13.9208 12.9807 14 12.776 14C12.5709 14 12.3785 13.9208 12.2345 13.7764L6.99989 8.54169L1.76548 13.7764C1.62127 13.9208 1.42902 14 1.22415 14H1.22392C1.01893 14 0.826683 13.9208 0.682471 13.7764L0.223772 13.3178C0.0797886 13.1737 0.000340881 12.9813 0.000340881 12.7763C0.000340881 12.5713 0.0797886 12.3791 0.223772 12.2349L5.45807 7Z"
        fill="#4C4C4C"
      />
    </svg>
  );
};

export default IconClose;
