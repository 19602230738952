import { toJS } from "mobx";
import { observer } from "mobx-react";
import { ExecutionStepStore } from "newApp/stores/pages/execution/ExecutionItemPageStore/ExecutionStepStore";
import React from "react";
import StepEmpty from "./StepEmpty";
import StepExecute from "./StepExecute";
import StepFinish from "./StepFinish";
import StepInwork from "./StepInwork";
import StepKafka from "./StepKafka";
import StepWasNow from "./StepWasNow";

export interface IExecutionStepProps extends ExecutionStepStore {}

const ExecutionStep: React.FC<IExecutionStepProps> = (props) => {
  //   if (props.isDone) return null;

  console.log("props steps", toJS(props.stepTypeId));

  switch (props.stepTypeId) {
    case "50471291-ed27-4e20-b80d-f135606b956c":
      return <StepInwork {...props} />;
    case "dbe9ec25-80db-4670-a75a-0bf2fd9685af":
      return <StepExecute {...props} />;
    case "5a4a6c43-1473-4122-8358-ff19013b5a58":
      return <StepFinish {...props} />;
    case "6c46cd1a-d79e-4f8f-8e2e-e484b6745bcd":
      return <StepKafka {...props} />;
    case "d0617e23-60c1-4739-9b65-189d9ef91f88":
      return <StepWasNow {...props} />;
    default:
      return <StepEmpty {...props} />;
  }
};

export default observer(ExecutionStep);
