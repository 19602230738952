import { makeAutoObservable } from "mobx";

interface IConstructorParams {
  currentPage?: number;
  pageSize?: number;
  sizes?: number[];
  pages?: number;
}

export class PaginationStore {
  currentPage: number;
  pageSize: number;
  sizes: number[];
  pages: number;
  getPage?: () => any;

  constructor(params?: IConstructorParams, getPage?: () => any) {
    this.currentPage = params?.currentPage || 1;
    this.pageSize = params?.pageSize || 20;
    this.sizes = params?.sizes || [10, 20, 50, 100];
    this.pages = params?.pages || 1;
    this.getPage = getPage;
    makeAutoObservable(this);
  }

  onChange = (currentPage: number) => {
    this.currentPage = currentPage;
    if (this.getPage) this.getPage();
  };

  onSizeClick = (pageSize: number) => {
    this.currentPage = 1;
    this.pageSize = pageSize;
    if (this.getPage) this.getPage();
  };

  reset = () => {
    this.currentPage = 1;
    this.pageSize = 20;
    this.pages = 1;
  };
}
