import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import { VoteQuestionsFormStore } from "newApp/stores/common/VoteFormStore/VoteQuestionsFormStore";
import React from "react";
import FlexColumn from "../../kit/components/FlexColumn";
import H3 from "../../kit/components/H3";
import VoteQuestionForm from "./VoteQuestionForm";

export interface IVoteQuestionsFormProps
  extends Omit<VoteQuestionsFormStore, "isValid"> {}

const VoteQuestionsForm: React.FC<IVoteQuestionsFormProps> = (props) => {
  return (
    <FlexColumn>
      <H3>Вопросы голосования ({props.questions.length})</H3>
      {props.questions.map((question, idx) => {
        return (
          <VoteQuestionForm
            {...question}
            index={idx}
            key={idx}
            remove={() => props.removeQuestion(idx)}
          />
        );
      })}
      <Button onClick={() => props.addQuestion()} variant="secondary">
        Добавить вопрос
      </Button>
    </FlexColumn>
  );
};

export default observer(VoteQuestionsForm);
