import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "newApp/constants/validation/validators";
import * as yup from "yup";

export default yup.object().shape({
  fileId: VALIDATOR_ANY,
  text: VALIDATOR_STRING_REQUIRED,
  type: VALIDATOR_STRING_REQUIRED,
  isRequired: VALIDATOR_ANY,
  hasPhoto: VALIDATOR_ANY,
  id: VALIDATOR_ANY,
  hasOtherOption: VALIDATOR_ANY,
  files: VALIDATOR_ANY,
});
