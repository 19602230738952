import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router";

import { ITabsItemProps } from "../../kit/components/Tabs/TabsItem";
import ListModeSwitcher from "../ListModeSwitcher";

export const DECLARATION_MODE_INFO = "info";
export const DECLARATION_MODE_MAP = "map";
export const DECLARATION_MODE = "mode";

const DeclarationModeSwitcher: React.FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get(DECLARATION_MODE);
  const setQuery = (mode: string) =>
    push({ search: `${DECLARATION_MODE}=${mode}` });

  const tabs: ITabsItemProps[] = [
    {
      title: "Сообщение",
      onClick: () => setQuery(DECLARATION_MODE_INFO),
      isActive: !mode || mode === DECLARATION_MODE_INFO,
    },
    {
      title: "Карта",
      onClick: () => setQuery(DECLARATION_MODE_MAP),
      isActive: mode === DECLARATION_MODE_MAP,
    },
  ];

  return <ListModeSwitcher items={tabs} />;
};

export default observer(DeclarationModeSwitcher);
