import React from "react";

const IconShop = () => {
  return (
    <svg
      width="56"
      height="62"
      viewBox="0 0 64 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.0454 16.5159C32.0454 17.9341 30.8887 19.0851 29.4635 19.0851C28.0368 19.0851 26.8816 17.9341 26.8816 16.5159C26.8816 15.097 28.0368 13.9468 29.4635 13.9468C30.8887 13.9468 32.0454 15.097 32.0454 16.5159Z"
        fill="#60BC46"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.9468 16.5159C37.9468 17.9341 36.7894 19.0851 35.3649 19.0851C33.9383 19.0851 32.7831 17.9341 32.7831 16.5159C32.7831 15.097 33.9383 13.9468 35.3649 13.9468C36.7916 13.9468 37.9468 15.097 37.9468 16.5159Z"
        fill="#60BC46"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.9989 2.93617C33.7826 2.93617 35.4741 3.23639 36.6396 3.75903C36.6861 3.77959 41.3629 5.88922 46.5031 8.90247C51.624 11.9025 55.7793 14.9701 55.8192 15.0009C58.0211 16.6327 60.2098 20.4857 60.5019 23.2449C60.5078 23.2963 61.0493 28.4889 61.0493 34.5C61.0493 40.5111 60.5078 45.7037 60.5019 45.7543C60.2098 48.5143 58.0211 52.3666 55.8192 53.9976C55.7779 54.0277 51.624 57.099 46.5016 60.1019C41.3784 63.1027 36.6861 65.2204 36.6426 65.241C35.4741 65.7643 33.7826 66.0638 31.9989 66.0638C30.2167 66.0638 28.5252 65.7643 27.3596 65.2417C27.3139 65.2197 22.6186 63.1027 17.4977 60.1027C12.3745 57.099 8.21918 54.0277 8.17713 53.9969C5.97739 52.3666 3.79018 48.5143 3.49658 45.7551C3.49216 45.703 2.94996 40.5287 2.94996 34.5C2.94996 28.4889 3.49216 23.2963 3.49658 23.2464C3.79018 20.4857 5.97886 16.6327 8.18009 15.0002C8.22066 14.9701 12.3583 11.9113 17.4962 8.90173C22.6193 5.89877 27.3139 3.77959 27.3596 3.75977C28.5252 3.23639 30.2167 2.93617 31.9989 2.93617ZM31.9989 0C29.8781 0 27.7558 0.361149 26.1469 1.08271C26.1469 1.08271 21.3343 3.24447 15.9994 6.37149C10.6638 9.49631 6.41704 12.6461 6.41704 12.6461C3.57404 14.7535 0.940536 19.3846 0.562846 22.9374C0.562846 22.9374 0 28.2474 0 34.5C0 40.7526 0.562846 46.0634 0.562846 46.0634C0.940536 49.6154 3.57404 54.2457 6.41556 56.3517C6.41556 56.3517 10.6638 59.5037 15.9994 62.6322C21.3343 65.757 26.1469 67.9173 26.1469 67.9173C27.7558 68.6396 29.8781 69 31.9989 69C34.1212 69 36.2435 68.6396 37.8546 67.9173C37.8546 67.9173 42.6635 65.757 47.9991 62.6322C53.3339 59.5037 57.583 56.3517 57.583 56.3517C60.4252 54.2457 63.0595 49.6154 63.4372 46.0634C63.4372 46.0634 64 40.7526 64 34.5C64 28.2474 63.4372 22.9374 63.4372 22.9374C63.0595 19.3846 60.4252 14.7535 57.583 12.6461C57.583 12.6461 53.3339 9.49631 48.0006 6.37149C42.6635 3.24447 37.8546 1.08271 37.8546 1.08271C36.2435 0.361149 34.1212 0 31.9989 0Z"
        fill="#60BC46"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.4125 28.5529C44.4125 28.5529 48.2742 34.5383 48.2742 38.8295C48.2742 45.9497 42.4739 46.2448 35.3169 46.2448H29.5092C22.3545 46.2448 16.5542 45.9497 16.5542 38.8295C16.5542 34.5383 20.4137 28.5529 20.4137 28.5529H44.4125ZM44.4125 25.6167H20.4137C19.4082 25.6167 18.4729 26.1261 17.9299 26.9673C17.4881 27.6544 13.6035 33.8211 13.6035 38.8295C13.6035 44.4346 16.6589 46.8672 19.2223 47.9213C22.0565 49.0862 25.5575 49.1809 29.5092 49.1809H35.3169C39.2694 49.1809 42.7719 49.0862 45.606 47.9213C48.1687 46.8672 51.2249 44.4346 51.2249 38.8295C51.2249 33.8211 47.3388 27.6537 46.8955 26.9666C46.3533 26.1261 45.4172 25.6167 44.4125 25.6167Z"
        fill="#60BC46"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.4777 24.9577H18.613C17.7972 24.9577 17.1377 24.3 17.1377 23.4896C17.1377 22.6785 17.7972 22.0215 18.613 22.0215H45.4777C46.2914 22.0215 46.9531 22.6785 46.9531 23.4896C46.9531 24.3 46.2914 24.9577 45.4777 24.9577Z"
        fill="#60BC46"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.9961 23.8566C34.6184 23.8566 34.2407 23.7127 33.953 23.4264L29.8958 19.3892C29.3197 18.8159 29.3197 17.8866 29.8958 17.3133C30.4719 16.7393 31.4058 16.7393 31.9819 17.3133L36.0391 21.3506C36.6153 21.9238 36.6153 22.8531 36.0391 23.4264C35.7507 23.7127 35.3738 23.8566 34.9961 23.8566Z"
        fill="#60BC46"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.8323 23.8566C29.4547 23.8566 29.077 23.7127 28.7893 23.4264C28.2131 22.8531 28.2131 21.9238 28.7893 21.3506L32.8465 17.3133C33.4226 16.7393 34.3565 16.7393 34.9326 17.3133C35.5088 17.8866 35.5088 18.8159 34.9326 19.3892L30.8754 23.4264C30.587 23.7127 30.21 23.8566 29.8323 23.8566Z"
        fill="#60BC46"
      />
    </svg>
  );
};

export default IconShop;
