import React, { FC, forwardRef, useEffect, useImperativeHandle } from "react";
import { useIMask } from "react-imask";
import { TMaskedInputProps } from "./index.types";

const MaskedInput: FC<TMaskedInputProps> = forwardRef(
  ({ maskOptions, onChange, ...props }, ref) => {
    const imask = useIMask(maskOptions, {
      onAccept: (_, maskRef) => {
        // event должен приходить 3м параметром в onAccept, но приходит undefined, поэтому использую maskRef
        onChange &&
          onChange({
            target: maskRef.el.input,
          } as React.ChangeEvent<HTMLInputElement>);
      },
    });

    const imaskInputRef = imask.ref as React.MutableRefObject<HTMLInputElement>;

    useImperativeHandle(ref, () => imaskInputRef.current, [imaskInputRef]);

    // synchronize values
    useEffect(() => {
      imask.maskRef.current.value = props.value || "";
      imask.maskRef.current.updateValue();
    }, [imask, props.value]);

    return <input {...props} ref={imaskInputRef} onChange={() => {}} />;
  }
);

export default MaskedInput;
