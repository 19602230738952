import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SHOP_BALLS } from "newApp/routing/urls";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import TextEditor from "newApp/components/TextEditor";
import { EditBallsStore } from "./store";

const editBallstore = new EditBallsStore();

const BallsEditPage: React.FC = () => {
  const history = useHistory();
  const { location } = useHistory();

  const [, isHave] = location.search.split("=");

  useEffect(() => {
    editBallstore.getEditedType(isHave);

    return () => {
      editBallstore.reset();
    };
  }, [isHave]);

  const onClickSave = async () => {
    const result = await editBallstore.onSave();
    if (result) {
      goOnInfo();
    }
  };

  const goOnInfo = () => {
    history.push(URL_SHOP_BALLS);
  };

  return (
    <MainLayout>
      <H1>
        {isHave === "true"
          ? "Информация для пользователя у которого есть ЕСК"
          : "Информация для пользователя у которого нет ЕСК"}
      </H1>
      <FlexColumn>
        <ButtonBack url={URL_SHOP_BALLS}>Назад к информации</ButtonBack>
        <TextEditor
          value={editBallstore.form.values.description}
          onChange={(value) =>
            editBallstore.form.changeValueByKey(value, "description")
          }
          errorMessage={editBallstore.form.errors.description}
        />
        <ButtonsContainer>
          <Button onClick={onClickSave}>Сохранить изменения</Button>
          <Button onClick={goOnInfo} variant={"secondary"}>
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(BallsEditPage);
