import React from "react";

const IconAdministration = () => {
  return (
    <svg viewBox="0 0 100 108" width="56" height="60">
      <g fill="#c22253">
        <path d="M50.1 4.6c2.8 0 5.4.5 7.2 1.3.1 0 7.4 3.3 15.4 8s14.5 9.5 14.5 9.5C90.6 26 94 32 94.5 36.3c0 .1.9 8.2.9 17.6 0 9.4-.8 17.5-.9 17.6-.5 4.3-3.9 10.3-7.3 12.9-.1 0-6.5 4.8-14.5 9.5s-15.3 8-15.4 8c-1.8.8-4.5 1.3-7.2 1.3-2.8 0-5.4-.5-7.2-1.3-.1 0-7.4-3.3-15.4-8S13 84.4 13 84.4c-3.4-2.6-6.8-8.6-7.3-12.9 0-.1-.9-8.2-.9-17.6 0-9.4.8-17.5.9-17.6C6.2 32 9.6 26 13 23.4c.1 0 6.5-4.8 14.5-9.5s15.3-8 15.4-8c1.8-.8 4.4-1.3 7.2-1.3m0-4.6c-3.3 0-6.6.6-9.1 1.7 0 0-7.5 3.4-15.8 8.3s-14.9 9.8-14.9 9.8c-4.4 3.3-8.5 10.5-9.1 16.1 0 0-.9 8.3-.9 18.1-.1 9.7.8 18 .8 18 .6 5.6 4.7 12.8 9.1 16.1 0 0 6.6 4.9 14.9 9.8s15.8 8.3 15.8 8.3c2.5 1.1 5.8 1.7 9.1 1.7s6.6-.6 9.1-1.7c0 0 7.5-3.4 15.8-8.3C83.4 93 90 88.1 90 88.1c4.4-3.3 8.5-10.5 9.1-16.1 0 0 .9-8.3.9-18.1s-.9-18.1-.9-18.1c-.6-5.6-4.7-12.8-9.1-16.1 0 0-6.6-4.9-14.9-9.8S59.3 1.6 59.3 1.6C56.7.6 53.4 0 50.1 0"></path>
        <path d="M73.6 77.4H24.4c-1.7 0-3.1-1.4-3.1-3.1V34.4c0-1.7 1.4-3.1 3.1-3.1H46c1.7 0 3.1 1.4 3.1 3.1v3.7h24.4c1.7 0 3.1 1.4 3.1 3.1v4.9h7.2c1.7 0 3.1 1.4 3.1 3.1 0 .3-.1.5-.2.8l-10 24.8c-.3 1.5-1.6 2.6-3.1 2.6zm-47.8-4h46.9V50.2H35.4l-9.6 23.2zm50.9-23.2v14.1l5.8-14.1h-5.8zM25.3 35.3v29l6.4-15.4c.2-1.5 1.5-2.7 3.1-2.7h37.9v-4H47.1c-1.1 0-2-.9-2-2v-4.9H25.3z"></path>
      </g>
    </svg>
  );
};

export default IconAdministration;
