import React, { useState } from "react";
import Tooltip from "../Tooltip";
import { IRadioButtonProps } from "./index.types";
import "./styles.scss";

const RadioButtons: React.FC<IRadioButtonProps> = (props) => {
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);
  const handleErrorButtonClick = () => setShowErrorTooltip(!showErrorTooltip);
  const closeErrorTooltip = () => setShowErrorTooltip(false);

  return (
    <div
      className={[
        "radioButtons",
        !!props.errorTitle || !!props.errorMessage
          ? "radioButtons--isError"
          : "",
      ].join(" ")}
    >
      {props.label && <div className="radioButtons__title">{props.label}</div>}
      {props.isRequired && (
        <div className="radioButtons__isRequired">Обязательно</div>
      )}
      <div
        className={[
          "radioButtons__container",
          props.isHorizontal ? "radioButtons__container--horizontal" : "",
        ].join(" ")}
      >
        {props.options.map((option, idx) => {
          const isChecked: boolean = props.value === option.value;
          return (
            <label
              className={[
                "radiobutton",
                isChecked ? "radiobutton--active" : "",
                option.isDisabled ? "radiobutton--disabled" : "",
              ].join(" ")}
              key={idx}
            >
              <input
                type="radio"
                checked={isChecked}
                name={props.name}
                onChange={() => props.onChange(option.value, props.name)}
                disabled={!!option.isDisabled}
              />
              <div
                className={[
                  "radiobutton__checkmark",
                  isChecked ? "radiobutton__checkmark--active" : "",
                ].join(" ")}
              />
              <span className="radiobutton__text">{option.text}</span>
            </label>
          );
        })}
      </div>
      {props.errorMessage && (
        <button
          className={"radioButtons__error-button"}
          aria-label="Информация об ошибке"
          onMouseDown={handleErrorButtonClick}
          tabIndex={-1}
        />
      )}

      {props.errorMessage && showErrorTooltip && (
        <Tooltip
          onClose={closeErrorTooltip}
          title={props.errorTitle}
          text={props.errorMessage}
          className="textarea__tooltip"
        />
      )}
    </div>
  );
};

export default RadioButtons;
