import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router";

import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";
import DashedButton from "../DashedButton";
import DividerVertical from "../DividerVertical";
import IconPhoto from "../IconPhoto";

import { URL_SHOP_PRODUCTS } from "newApp/routing/urls";

import { IProductsListItem } from "newApp/stores/pages/shop/products/ProductsPageStore";
import "./styles.scss";

interface IProductList {
  productsList: IProductsListItem[];
  onClickDelete: (id: string) => void;
  toggleIsActive: (isActive: boolean, id: string) => void;
}

const ProductsList: React.FC<IProductList> = (props) => {
  const { push } = useHistory();

  return (
    <div className="products-list">
      {props.productsList.map((product, idx) => {
        const gotToProductsItem = () =>
          push(URL_SHOP_PRODUCTS + `/${product.id}`);

        return (
          <div className="products-list__item" key={product.id || idx}>
            <div
              className="products-list__item-image"
              onClick={gotToProductsItem}
            >
              {product.imageId ? (
                <img
                  className="products-list__item-image-preview"
                  src={product.imageId}
                  alt={"product"}
                />
              ) : (
                <IconPhoto />
              )}
            </div>
            <div className="products-list__item-body">
              <div className="products-list__item-header">
                <div
                  className="products-list__item-title"
                  onClick={gotToProductsItem}
                >
                  {product.title}
                </div>
                <div className="products-list__item-buttons">
                  <DashedButton
                    onClick={() =>
                      push(URL_SHOP_PRODUCTS + `/${product.id}/edit`)
                    }
                  >
                    Редактировать
                  </DashedButton>
                  <DividerVertical />
                  <DashedButton
                    color="red"
                    onClick={() => {
                      window.confirm(
                        `Вы точно хотите удалить данный товар/услугу?`
                      ) && props.onClickDelete(product.id);
                    }}
                  >
                    Удалить
                  </DashedButton>
                </div>
              </div>
              <div className="products-list__item-date">
                <span className="products-list__item-date-title">
                  Дата создания —{" "}
                </span>
                <span className="products-list__item-date-value">
                  {product.creationDate}
                </span>
              </div>
              <Divider color="grey" />
              <div className="products-list__item-controls">
                <Checkbox
                  value={product.isActive}
                  onChange={() =>
                    props.toggleIsActive(product.isActive, product.id)
                  }
                >
                  {product.isActive ? "Активно" : "Не активно"}
                </Checkbox>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(ProductsList);
