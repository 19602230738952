import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { IVoteItemPageInfoStoreInfo, IVoteItemQuestion } from "./index.types";

export class VoteItemPageInfoStore {
  isLoadings = {
    changeBanner: false,
    changePublish: false,
    changeMain: false,
    remove: false,
    getItem: false,
  };
  private rootStore: { id: string };
  info: IVoteItemPageInfoStoreInfo = {
    title: null,
    description: null,
    annotation: null,
    startDateTime: null,
    endDateTime: null,
    gender: null,
    isImportant: null,
    isBanner: null,
    isActive: null,
    isDigitalResult: null,
    fromAge: null,
    toAge: null,
    numberOfPoints: null,
  };

  questions: IVoteItemQuestion[] = [];
  filesPreviews: IFilesPreviewFile[] = [];

  filesPreviewsNotImages: IFilesPreviewFile[] = [];

  constructor(rootStore: { id: string }) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  init = () => {
    this.getItem();
  };

  resetInfo = () => {
    Object.keys(this.info).forEach((key) => {
      this.info[key] = null;
    });
  };

  reset = () => {
    this.resetInfo();
    this.filesPreviews = [];
  };

  private getItem = () => {
    this.isLoadings.getItem = true;
    const id = this.rootStore.id;
    return API.admin.Vote.apiVotesIdGet(id)
      .then(({ data }) => {
        runInAction(() => {
          try {
            Object.entries(data["voteModel"]).forEach(([key, value]) => {
              this.info[key] = value;
            });

            this.filesPreviewsNotImages = data["voteModel"]["files"].map(
              (i) => ({
                title: i.name,
                reference: URL_API_ADMIN + "/api/votes/files/" + i.id,
                type:
                  i.name.split(".")[1] === "png"
                    ? "image"
                    : i.name.split(".")[1] === "jpg"
                    ? "image"
                    : i.name.split(".")[1] === "jpeg"
                    ? "image"
                    : i.name.split(".")[1],
              })
            );

            this.questions = data["voteModel"]["questions"].map((i) => ({
              files: i.files.map((file) => ({
                title: file.name,
                reference: URL_API_ADMIN + "/api/questions/files/" + file.id,
                type:
                  file.name.split(".")[1] === "png"
                    ? "image"
                    : file.name.split(".")[1] === "jpg"
                    ? "image"
                    : file.name.split(".")[1] === "jpeg"
                    ? "image"
                    : file.name.split(".")[1],
              })),

              hasOtherOption: i["hasOtherOption"] || null,
              id: i["id"] || null,
              isRequired: i["isRequired"] || null,
              text: i["text"] || null,
              type: i["type"] || null,
              answers:
                i["answers"].map((a) => ({
                  files: a.files.map((a_file) => ({
                    title: a_file.name,
                    reference:
                      URL_API_ADMIN + "/api/answers/files/" + a_file.id,
                    type:
                      a_file.name.split(".")[1] === "png"
                        ? "image"
                        : a_file.name.split(".")[1] === "jpg"
                        ? "image"
                        : a_file.name.split(".")[1] === "jpeg"
                        ? "image"
                        : a_file.name.split(".")[1],
                  })),
                  id: a["id"] || null,
                  text: a["text"] || null,
                })) || [],
            }));

            if (data["voteModel"]["fileId"]) {
              this.filesPreviews.push({
                reference:
                  URL_API_ADMIN + `/api/files/${data["voteModel"]["fileId"]}`,
                type: "Image",
                title: "Главное изображение",
              });
            }
            if (data["voteModel"]["croppedFileId"]) {
              this.filesPreviews.push({
                reference:
                  URL_API_ADMIN +
                  `/api/files/${data["voteModel"]["croppedFileId"]}`,
                type: "Image",
                title: "Обрезанное изображение",
              });
            }
            if (data["voteModel"]["previewFileId"]) {
              this.filesPreviews.push({
                reference:
                  URL_API_ADMIN +
                  `/api/files/${data["voteModel"]["previewFileId"]}`,
                type: "Image",
                title: "Превью",
              });
            }
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getItem = false;
        });
      });
  };

  onClickPublish = () => {
    if (this.info.isActive) {
      this.deactivate();
    } else {
      this.activate();
    }
  };

  private activate = () => {
    this.isLoadings.changePublish = true;
    return API.admin.Vote.apiVotesActivateIdPost(this.rootStore.id)
      .then(() => {
        this.getItem();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changePublish = false;
        });
      });
  };

  private deactivate = () => {
    this.isLoadings.changePublish = true;
    return API.admin.Vote.apiVotesDeactivateIdPost(this.rootStore.id)
      .then(() => {
        this.getItem();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changePublish = false;
        });
      });
  };

  remove = () => {
    this.isLoadings.remove = true;
    return API.admin.Vote.apiVotesDeleteIdPost(this.rootStore.id).finally(
      () => {
        runInAction(() => {
          this.isLoadings.remove = false;
        });
      }
    );
  };
}
