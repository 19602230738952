import {
  URL_VOTING_ACTIVE,
  URL_VOTING_ANNOUNCEMENT,
  URL_VOTING_COMPLETED,
} from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";
import "./styles.scss";

const VotingsModeSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const links: { path: string; text: string }[] = [
    { path: URL_VOTING_ANNOUNCEMENT, text: "Анонсы" },
    { path: URL_VOTING_ACTIVE, text: "Активные" },
    { path: URL_VOTING_COMPLETED, text: "Завершенные" },
  ];

  return (
    <div className="votings-mode-switcher">
      {links.map((link, indx) => (
        <div
          className={[
            "votings-mode-switcher__item",
            pathname === link.path ? "votings-mode-switcher__item--active" : "",
          ].join(" ")}
          key={indx}
          onClick={() => push(link.path)}
        >
          {link.text}
        </div>
      ))}
    </div>
  );
};

export default VotingsModeSwitcher;
