import "./styles.scss";

import { observer } from "mobx-react";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import React from "react";

import FilesPreview from "../FilesPreview";
import VoteItemQuestionAnswersList from "../VoteItemQuestionAnswersList";
import { IVoteItemQuestion } from "newApp/stores/pages/votings/VoteItemPageStore/VoteItemPageInfoStore/index.types";

export interface IVoteItemQuestionsListProps {
  questions: IVoteItemQuestion[];
}

const VoteItemQuestionsList: React.FC<IVoteItemQuestionsListProps> = (
  props
) => {
  return (
    <div className="vote-item-questions-list">
      <H3>Вопросы голосования ({props.questions.length})</H3>
      <div className="vote-item-questions-list__items">
        <FlexColumn>
          {props.questions.map((question, idx) => {
            return (
              <div className="vote-item-questions-list__item" key={question.id}>
                <FlexColumn>
                  <div className="vote-item-questions-list__item-title">
                    Вопрос {idx + 1}
                  </div>
                  <div className="vote-item-questions-list__item-text">
                    {question.text}
                  </div>
                  {question.files.length !== 0 && (
                    <FilesPreview files={question.files} />
                  )}
                  <Divider color="grey" />
                  <VoteItemQuestionAnswersList answers={question.answers} />
                  <Divider color="blue" height={4} />
                </FlexColumn>
              </div>
            );
          })}
        </FlexColumn>
      </div>
    </div>
  );
};

export default observer(VoteItemQuestionsList);
