import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { StepsFormStore } from "newApp/stores/common/StepsFormStore";

export class StepCreatePage {
  isLoadings = { createSteps: false };
  stepsForm = new StepsFormStore();
  error: null | {
    id?: string;
    text?: string;
  } = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.stepsForm.init();
  };

  reset = () => {
    this.stepsForm.reset();
  };

  resetError = () => {
    this.error = null;
  };

  onCreate = async () => {
    await this.stepsForm.validate();
    if (this.stepsForm.isValid) {
      return await this.createSteps();
    }
    return false;
  };

  createSteps = () => {
    this.isLoadings.createSteps = true;
    this.resetError();
    return API.admin.ViolationTemplates.apiViolationTemplatesCreatePost({
      SubCategoryId: this.stepsForm.form.values.categoryId,
      ViolationTemplateSteps: this.stepsForm.steps.map((s) => ({
        StepId: s.form.values.stepType,
        FieldTypeIds: s.fields,
      })),
    })
      .then(() => true)
      .catch((e) => {
        console.error(e);
        runInAction(() => {
          const id = e?.response?.data?.data?.id || null;
          const text = e?.response?.data?.error || "Ошибка";
          if (id || text) {
            this.error = { id, text };
          }
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createSteps = false;
        });
      });
  };
}
