import { observer } from "mobx-react";
import { TDeclarationInfo } from "newApp/stores/pages/moderation/DeclarationModerationItemPageStore/index.types";
import React from "react";

import Map from "../Map";
import { IMapMarker } from "../Map/index.types";
import MapMarkerPopup from "../MapMarkerPopup";
import "./styles.scss";

interface IProps {
  info: TDeclarationInfo;
}

const DeclarationItemMap: React.FC<IProps> = (props) => {
  const markers: IMapMarker[] = [
    {
      position: [Number(props.info.latitude), Number(props.info.longitude)],
      popup: <MapMarkerPopup info={props.info} />,
    },
  ];

  return (
    <div className="declaration-item-map">
      <Map markers={markers} />
    </div>
  );
};

export default observer(DeclarationItemMap);
