import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import Select from "newApp/kit/components/Select";
import { URL_SITE_EMAILTEMPLATES } from "newApp/routing/urls";
import { EmailTemplatesStore } from "../store";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import TextEditor from "newApp/components/TextEditor";
import H3 from "newApp/kit/components/H3";
import Checkbox from "newApp/kit/components/CheckBox";
import TextField from "newApp/kit/components/TextField";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import { ISelectOption } from "newApp/kit/components/Select/index.types";

const store = new EmailTemplatesStore();

const EmailTemplatesEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    store.init(Number(id));
    return () => {
      store.reset();
    };
  }, [id]);

  const { push } = useHistory();

  const onClickSave = async () => {
    await store.saveEmailTemplate().then(() => {
      history.push(`${URL_SITE_EMAILTEMPLATES}`);
    });
  };

  return (
    <div className="template-page">
      <MainLayout isLoading={store.isLoading.page}>
        <H1>Редактирование шаблона письма</H1>
        <FlexColumn>
          <ButtonBack url={URL_SITE_EMAILTEMPLATES}>
            Назад к списку шаблонов
          </ButtonBack>
          <Select
            {...store.form.adapters.templateType}
            options={store.type as ISelectOption[]}
            onChange={store.getEmailTemplate}
            placeholder="Тип шаблона"
          />
          <TextField {...store.form.adapters.templateTitle} />
          <TextEditor
            onChange={(value) => store.form.changeValueByKey(value, "content")}
            value={store.form.values.content || ""}
            errorMessage={store.form.errors.content}
          />
          <FlexColumn>
            <Checkbox {...store.form.adapters.buttonAvailable}>Кнопки</Checkbox>
            <Checkbox {...store.form.adapters.showImage}>Изображение</Checkbox>
          </FlexColumn>
          {store.isLoading.templates ? (
            <LoaderSpinner />
          ) : (
            <>
              <H3>Шаблоны</H3>
              <ul className="template-page__list">
                {store.templateParams?.map((templateItem, idx) => {
                  return (
                    <li key={idx}>
                      <span className="template-page__list--templ">{`{${templateItem.paramName}}`}</span>
                      <span className="template-page__list--desc">
                        {templateItem.paramDescription}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          <ButtonsContainer>
            <Button onClick={onClickSave} disabled={store.isLoading.templates}>
              Сохранить
            </Button>
            <Button
              onClick={() => push(URL_SITE_EMAILTEMPLATES)}
              variant="secondary"
            >
              Отменить
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      </MainLayout>
    </div>
  );
};

export default observer(EmailTemplatesEditPage);
