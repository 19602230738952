import { makeAutoObservable } from "mobx";
import { API } from "newApp/api";
import { VoteItemPageInfoStore } from "./VoteItemPageInfoStore";
import { VoteItemPageResultsStore } from "./VoteItemPageResultsStore";
import FileSaver from "file-saver";

export class VoteItemPageStore {
  id: string = "";

  details = new VoteItemPageInfoStore(this);
  results = new VoteItemPageResultsStore(this);

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.details.init();
    this.results.init();
  };

  reset = () => {
    this.details.reset();
    this.results.reset();
  };

  downloadVoteStat = (id: string) => {
    API.admin.Vote.apiVotesDownloadIdGet(id, {
      responseType: "blob",
    }).then((res) => {
      const { data } = res;
      const name = "Результаты голосования.xlsx";
      const fileData = new File([data as unknown as BlobPart], name);
      FileSaver.saveAs(fileData, name);
    });
  };
}
