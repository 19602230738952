import "./styles.scss";

import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";
import { URL_SITE_NEWS } from "newApp/routing/urls";
import { INewsListItem } from "newApp/stores/pages/site/news/NewsPageStore";
import React from "react";
import { useHistory } from "react-router";

import DashedButton from "../DashedButton";
import DividerVertical from "../DividerVertical";
import IconPhoto from "../IconPhoto";

export interface INewsListProps {
  newsList: INewsListItem[];
  onClickDelete: (id: string) => void;
  onClickBanner: (id: string) => void;
  onClickMain: (id: string) => void;
  onClickActive: (id: string) => void;
}

const NewsList: React.FC<INewsListProps> = (props) => {
  const { push } = useHistory();

  return (
    <div className="news-list">
      {props.newsList.map((i, idx) => {
        const goToNewsItem = () => push(URL_SITE_NEWS + `/${i.id}`);

        return (
          <div key={i.id || idx} className="news-list__item">
            <div className="news-list__item-image" onClick={goToNewsItem}>
              {i.image ? (
                <img
                  className="news-list__item-image-preview"
                  src={i.image}
                  alt={"news-main"}
                />
              ) : (
                <IconPhoto />
              )}
            </div>
            <div className="news-list__item-body">
              <div className="news-list__item-header">
                <div className="news-list__item-title" onClick={goToNewsItem}>
                  {i.title}
                </div>
                <div className="news-list__item-buttons">
                  <DashedButton
                    onClick={() => push(URL_SITE_NEWS + `/${i.id}/edit`)}
                  >
                    Редактировать
                  </DashedButton>
                  <DividerVertical />
                  <DashedButton
                    color="red"
                    onClick={() => {
                      window.confirm(
                        `Вы уверены что хотите удалить новость, "${i.title}"?`
                      ) && props.onClickDelete(i.id);
                    }}
                  >
                    Удалить
                  </DashedButton>
                </div>
              </div>
              <div className="news-list__item-date">
                <span className="news-list__item-date-title">
                  Дата создания —{" "}
                </span>
                <span className="news-list__item-date-value">{i.date}</span>
              </div>
              <Divider color="grey" />
              <div className="news-list__item-controls">
                <Checkbox
                  value={!!i.isBanner}
                  onChange={() => props.onClickBanner(i.id)}
                >
                  Баннер
                </Checkbox>
                <Checkbox
                  value={!!i.isMain}
                  onChange={() => {
                    if (!i.isMain) {
                      if (
                        window.confirm(
                          `Главная новость может быть только одна. Вы уверены что хотите установить "${i.title}"?`
                        )
                      ) {
                        props.onClickMain(i.id);
                      }
                    } else {
                      props.onClickMain(i.id);
                    }
                  }}
                >
                  Главная
                </Checkbox>
                <Checkbox
                  value={!!i.state}
                  onChange={() => props.onClickActive(i.id)}
                >
                  Активно
                </Checkbox>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(NewsList);
