import { observer } from "mobx-react";
import FilesPreview from "newApp/components/FilesPreview";
import Cropper from "newApp/kit/components/Cropper";
import Dropzone from "newApp/kit/components/Dropzone";
import H3 from "newApp/kit/components/H3";
import { VoteDetailsFileFormStore } from "newApp/stores/common/VoteFormStore/VoteDetailsFormStore/VoteDetailsFileFormStore";
import React, { useEffect, useState } from "react";

export interface IVoteDetailsFileFormProps extends VoteDetailsFileFormStore {}

const VoteDetailsFileForm: React.FC<IVoteDetailsFileFormProps> = (props) => {
  useEffect(() => {
    if (props.file) {
      setVisible(true);
    }
  }, [props.file]);

  const [visible, setVisible] = useState(false);

  const handleCloseClick = () => {
    setVisible(false);
    props.onClose();
  };

  return (
    <div>
      <H3>Главное изображение</H3>
      <div>
        {props.filesPreviews.length ? (
          <FilesPreview
            files={props.filesPreviews}
            onClickRemove={props.onClickRemove}
          />
        ) : (
          <Dropzone
            onAddFiles={props.onAddFiles}
            multiple={false}
            acceptFileTypes={["image/*"]}
          />
        )}
        {!!props.file && (
          <Cropper
            isOpen={visible}
            sourceImage={props.file}
            steps={[
              {
                title: "Получить обрезанное изображение",
                width: 1920,
                height: 1080,
              },
              { title: "Получить превью", width: 1080, height: 1920 },
            ]}
            onCropped={props.onCropped}
            onClose={handleCloseClick}
          />
        )}
      </div>
    </div>
  );
};

export default observer(VoteDetailsFileForm);
