import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router";

import Tabs from "../../kit/components/Tabs";
import { ITabsItemProps } from "../../kit/components/Tabs/TabsItem";

export const DECLARATION_BODY_MODE_INFO = "info";
export const DECLARATION_BODY_MODE_CHAT = "chat";
export const DECLARATION_BODY_MODE_HISTORY = "history";
export const DECLARATION_BODY_MODE = "bodymode";

const DeclarationItemBodyModeSwitcher: React.FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const bodyMode = new URLSearchParams(search).get(DECLARATION_BODY_MODE);
  const setQuery = (mode: string) =>
    push({ search: `${DECLARATION_BODY_MODE}=${mode}` });

  const tabs: ITabsItemProps[] = [
    {
      title: "Сообщение",
      onClick: () => setQuery(DECLARATION_BODY_MODE_INFO),
      isActive: !bodyMode || bodyMode === DECLARATION_BODY_MODE_INFO,
    },
    {
      title: "Чат",
      onClick: () => setQuery(DECLARATION_BODY_MODE_CHAT),
      isActive: bodyMode === DECLARATION_BODY_MODE_CHAT,
    },
    {
      title: "История работы",
      onClick: () => setQuery(DECLARATION_BODY_MODE_HISTORY),
      isActive: bodyMode === DECLARATION_BODY_MODE_HISTORY,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(DeclarationItemBodyModeSwitcher);
