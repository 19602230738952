import { observer } from "mobx-react";
import FilesPreview from "newApp/components/FilesPreview";
import Dropzone from "newApp/kit/components/Dropzone";
import H3 from "newApp/kit/components/H3";
import { NewsFormPhotosStore } from "newApp/stores/common/NewsFormStore/NewsFormPhotosStore";
import React from "react";

export interface INewsFormPhotosProps extends NewsFormPhotosStore {}

const NewsFormPhotos: React.FC<INewsFormPhotosProps> = (props) => {
  return (
    <div>
      <H3>Загруженные фотографии ({props.photosPreviews.length})</H3>
      <FilesPreview
        files={props.photosPreviews}
        onClickRemove={props.onClickRemovePhoto}
      />
      <Dropzone onAddFiles={props.onAddPhotos} acceptFileTypes={["image/*"]} />
    </div>
  );
};

export default observer(NewsFormPhotos);
