import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

class ProjectDescriptionPageStore {
  isLoadings = { getDescription: false };

  description: null | string = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getDescription();
  };

  reset = () => {
    this.description = null;
  };

  getDescription = () => {
    this.isLoadings.getDescription = true;
    return API.administration.ProjectDescription.projectdescriptionGet()
      .then(({ data }) => {
        runInAction(() => {
          this.description = data.content;
        });
      })
      .catch(() => {
        runInAction(() => {});
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getDescription = false;
        });
      });
  };
}

export default new ProjectDescriptionPageStore();
