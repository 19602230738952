import { observer } from "mobx-react";
import { DeclarationHistoryStore } from "newApp/stores/common/DeclarationHistoryStore";
import React, { useEffect } from "react";

import LoaderSpinner from "../../kit/components/LoaderSpinner";
import Table from "../../kit/components/Table";

interface IProps {
  store: DeclarationHistoryStore;
}

const DeclarationItemHistory: React.FC<IProps> = ({ store }) => {
  useEffect(() => {
    if (store.declarationId) store.getHistory();
    return () => store.reset();
  }, [store, store.declarationId]);

  if (store.isLoading) return <LoaderSpinner />;

  return (
    <div className="declaration-history">
      <Table
        headers={["Дата", "Изменил(-а)", "Описание"]}
        content={store.history.map((i) => [
          i.date ? new Date(i.date).toLocaleDateString() : "—",
          i.changedBy || "—",
          i.description || "—",
        ])}
      />
    </div>
  );
};

export default observer(DeclarationItemHistory);
