import { makeAutoObservable } from "mobx";

import { ShopSettingsFormStore } from "newApp/stores/common/ShopSettingsFormStore";

export class SettingsPageStore {
  form = new ShopSettingsFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.form.init();
  };

  reset = () => {
    this.form.reset();
  };

  get isLoading() {
    return Object.values(this.form.isLoadings).some((i) => i);
  }
}
