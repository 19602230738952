import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import RubricForm from "newApp/components/RubricForm";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_NEWS_RUBRICS } from "newApp/routing/urls";
import store from "newApp/stores/pages/site/rubrics/RubricsCreatePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const RubricsCreatePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const onClickCreate = () => {
    store.validate();
    if (store.isValid) {
      store
        .createRubric()
        .then(() => push(URL_SITE_NEWS_RUBRICS))
        .catch(() => {
          alert("Неудалось создать рубрику");
        });
    }
  };

  return (
    <MainLayout>
      <H1>Создание рубрики</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_NEWS_RUBRICS}>
          Назад к списку рубрик
        </ButtonBack>
        <RubricForm {...store.rubricForm} />
        <ButtonsContainer>
          <Button onClick={onClickCreate}>Создать</Button>
          <Button
            onClick={() => push(URL_SITE_NEWS_RUBRICS)}
            variant="secondary"
          >
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(RubricsCreatePage);
