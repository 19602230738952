import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FaqFormStore } from "newApp/stores/common/FaqFormStore";

export class FaqCreatePageStore {
  isLoadings = {
    createFaq: false,
    onCreate: false,
  };

  faqForm = new FaqFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.faqForm.init();
  };

  reset = () => {
    this.faqForm.reset();
  };

  onCreate = async () => {
    runInAction(() => (this.isLoadings.onCreate = true));
    this.faqForm.validate();
    if (this.faqForm.form.isValid) {
      const id = await this.createFaq();
      if (id && typeof id === "string") await this.faqForm.uploadFiles(id);
      runInAction(() => (this.isLoadings.onCreate = false));
      return id;
    }
    runInAction(() => (this.isLoadings.onCreate = false));
  };

  createFaq = () => {
    this.isLoadings.createFaq = true;
    return API.administration.FAQ.faqPost({
      answer: this.faqForm.form.values.answer,
      question: this.faqForm.form.values.question,
      date: new Date(this.faqForm.form.values.date).toISOString(),
      rubricId: this.faqForm.form.values.rubricId,
    })
      .then(({ data }) => data.id)
      .catch(() => false)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createFaq = false;
        });
      });
  };
}
