import ShopPage from "../../pages/shop/products";

import ProductCreatePage from "../../pages/shop/products/create";
import ProductItemPage from "../../pages/shop/products/id";
import ProductItemEditPage from "../../pages/shop/products/id/edit";
import CategoriesPage from "../../pages/shop/categories";
import CategoriesCreatePage from "../../pages/shop/categories/create";
import CategoriesItemEditPage from "../../pages/shop/categories/id/edit";
import SettingsPage from "../../pages/shop/settings";
import BallsPage from "../../pages/shop/balls";
import AnalyticsPage from "newApp/pages/shop/analytics";

import {
  URL_SHOP_PRODUCTS,
  URL_SHOP_PRODUCTS_ID,
  URL_SHOP_PRODUCTS_ID_EDIT,
  URL_SHOP_PRODUCTS_CREATE,
  URL_SHOP_CATEGORIES,
  URL_SHOP_CATEGORIES_ID_EDIT,
  URL_SHOP_CATEGORIES_CREATE,
  URL_SHOP_SETTINGS,
  URL_SHOP_ANALYTICS,
  URL_SHOP_BALLS,
  URL_SHOP_BALLS_EDIT,
} from "../urls";
import BallsEditPage from "newApp/pages/shop/balls/edit";

const shopRouting: { path: string; component: React.ReactNode }[] = [
  {
    path: URL_SHOP_PRODUCTS,
    component: <ShopPage />,
  },
  {
    path: URL_SHOP_CATEGORIES,
    component: <CategoriesPage />,
  },
  {
    path: URL_SHOP_SETTINGS,
    component: <SettingsPage />,
  },
  {
    path: URL_SHOP_PRODUCTS_CREATE,
    component: <ProductCreatePage />,
  },
  {
    path: URL_SHOP_PRODUCTS_ID,
    component: <ProductItemPage />,
  },
  {
    path: URL_SHOP_PRODUCTS_ID_EDIT,
    component: <ProductItemEditPage />,
  },
  {
    path: URL_SHOP_CATEGORIES_CREATE,
    component: <CategoriesCreatePage />,
  },
  {
    path: URL_SHOP_CATEGORIES_ID_EDIT,
    component: <CategoriesItemEditPage />,
  },
  {
    path: URL_SHOP_ANALYTICS,
    component: <AnalyticsPage />,
  },
  {
    path: URL_SHOP_BALLS,
    component: <BallsPage />,
  },
  {
    path: URL_SHOP_BALLS_EDIT,
    component: <BallsEditPage />,
  },
];

export default shopRouting;
