import { observer } from "mobx-react";
import {
  URL_MODERATION_ACCEPTED,
  URL_MODERATION_CLOSED,
  URL_MODERATION_CREATED,
  URL_MODERATION_REJECTED,
} from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";
import IconMessage from "../../components/IconMessage";
import Tabs from "../../kit/components/Tabs";
import "./styles.scss";

interface IProps {
  newMessage?: React.ReactNode;
  isHasNewMessagesInCreated?: boolean;
  isHasNewMessagesInWork?: boolean;
}

const ModerationTabs: React.FC<IProps> = (props) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();

  const tabs = [
    {
      title: (
        <div className="tab-with-icon">
          Создано {props.newMessage}{" "}
          {props.isHasNewMessagesInCreated && <IconMessage />}
        </div>
      ),
      onClick: () => push(URL_MODERATION_CREATED + search),
      isActive: pathname === URL_MODERATION_CREATED,
    },
    {
      title: (
        <div className="tab-with-icon">
          В работе {props.isHasNewMessagesInWork && <IconMessage />}
        </div>
      ),
      onClick: () => push(URL_MODERATION_ACCEPTED + search),
      isActive: pathname === URL_MODERATION_ACCEPTED,
    },
    {
      title: "Отклонено",
      onClick: () => push(URL_MODERATION_REJECTED + search),
      isActive: pathname === URL_MODERATION_REJECTED,
    },
    {
      title: "Завершено",
      onClick: () => push(URL_MODERATION_CLOSED + search),
      isActive: pathname === URL_MODERATION_CLOSED,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(ModerationTabs);
