import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { VoteItemResultItemStore } from "./VoteItemResultItemStore";

export class VoteItemPageResultsStore {
  isLoadings = { getResults: false };
  rootStore: TRootStore;

  info = {
    votersNumber: null,
  };

  questions: VoteItemResultItemStore[] = [];

  constructor(rootStore: TRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  init = () => {
    this.getResults();
  };

  reset = () => {
    this.questions = [];
  };

  getResults = () => {
    this.isLoadings.getResults = true;
    return API.admin.Analytics.apiAnalyticsVoteIdGet(this.rootStore.id)
      .then(({ data }) => {
        runInAction(() => {
          try {
            Object.entries({ ...(data as any) }).forEach(([key, value]) => {
              this.info[key] = value;
            });
            this.questions = (data as any)["questions"].map(
              (i) => new VoteItemResultItemStore(i)
            );
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getResults = false;
        });
      });
  };
}

type TRootStore = { id: string };
