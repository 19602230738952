import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

export class CategoriesPageStore {
  isLoadings = {
    getCategories: false,
    changeItemActive: false,
    postDeleteItem: false,
    makeDeactivated: false,
  };

  categories: ICategory[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getCategories();
  };

  reset = () => {
    this.resetCategories();
  };

  resetCategories = () => {
    this.categories = [];
  };

  getCategories = () => {
    this.isLoadings.getCategories = true;
    this.resetCategories();
    return API.admin.Category.apiObjectsGet()
      .then(({ data }) => {
        runInAction(() => {
          data.forEach((i) => this.parser(i as any));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getCategories = false;
        });
      });
  };

  parser = (c: ICategory, level = 0) => {
    this.categories.push({ ...c, level });
    if (c.children?.length) {
      c.children?.forEach((o) => this.parser(o, level + 1));
    }
  };

  changeItemActive = (id: string, state: number) => {
    this.isLoadings.changeItemActive = true;
    return API.admin.Category.apiObjectsSetIdPost(id, {
      State: state === 1 ? 0 : 1,
    })
      .then(() => {
        this.getCategories();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeItemActive = false;
        });
      });
  };

  onDeleteItem = (id: string) => {
    API.admin.Category.apiObjectsIdGet(id).then(({ data }) => {
      if (!!data["canDelete"]) {
        if (
          window.confirm(
            [
              "Данная категория не используется и ее можно удалить.",
              "Вы уверенны что хотите ее удалить?",
            ].join("\n")
          )
        ) {
          this.postDeleteItem(id);
        }
      } else {
        if (
          window.confirm(
            [
              "Данная категория используется и ее нельзя удалить.",
              "Вы уверенны что хотите ее деактивировать?",
            ].join("\n")
          )
        ) {
        }
        this.makeDeactivated(id);
      }
    });
  };

  postDeleteItem = (id: string) => {
    this.isLoadings.postDeleteItem = true;
    return API.admin.Category.apiObjectsDeleteIdPost(id)
      .then(() => {
        this.getCategories();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.postDeleteItem = false;
        });
      });
  };

  makeDeactivated = (id: string) => {
    this.isLoadings.makeDeactivated = true;
    return API.admin.Category.apiObjectsSetIdPost(id, { State: 2 })
      .then(() => {
        this.getCategories();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.makeDeactivated = false;
        });
      });
  };
}

export interface ICategory {
  daysLimit: number;
  level: number;
  id: string;
  name: string;
  organizationId: string;
  parentId: string | null;
  state: number;
  children?: ICategory[];
}
