import React from "react";

const IconVideo: React.FC = () => {
  return (
    <svg
      width="32.000004"
      height="23.578947"
      viewBox="0 0 8.4666672 6.2385964"
      version="1.1"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2" />
      <g id="layer1">
        <g id="g18" transform="scale(0.44561404)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 2.59091,0 C 1.15998,0 0,1.17525 0,2.625 v 8.75 C 0,12.8248 1.15998,14 2.59091,14 H 16.4091 C 17.84,14 19,12.8248 19,11.375 V 2.625 C 19,1.17525 17.84,0 16.4091,0 Z m 4.8186,3.54481 5.37379,3.15 c 0.1054,0.0623 0.1712,0.1785 0.1712,0.3052 0,0.1267 -0.0658,0.24289 -0.1712,0.30519 l -5.37379,3.15 C 7.35848,10.4853 7.30137,10.5 7.24495,10.5 7.18652,10.5 7.12807,10.4839 7.07567,10.4524 6.97223,10.3894 6.90909,10.2746 6.90909,10.15 V 3.85001 c 0,-0.1246 0.06314,-0.23939 0.16658,-0.3024 0.10278,-0.0623 0.22974,-0.0637 0.33384,-0.0028 z"
            fill="#689857"
            id="path9"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconVideo;
