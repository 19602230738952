import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { RubricFormStore } from "newApp/stores/common/RubricFormStore";

export class RubricEditPageStore {
  isLoadings = {
    getRubric: false,
    saveRubric: false,
  };
  id = "";

  rubricForm = new RubricFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getRubric();
  };

  reset = () => {
    this.rubricForm.reset();
  };

  getRubric = () => {
    this.isLoadings.getRubric = true;
    return API.administration.NewsRubrics.newsRubricListGet()
      .then(({ data }) => {
        const rubric = data.find((i) => i.id === this.id);
        this.rubricForm.form.changeValueByKey(rubric.title, "title");
        this.rubricForm.form.changeValueByKey(!!rubric.isActive, "isActive");
      })
      .catch(this.reset)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubric = false;
        });
      });
  };

  saveRubric = () => {
    this.isLoadings.saveRubric = true;
    return API.administration.NewsRubrics.newsRubricIdPost(this.id, {
      isActive: this.rubricForm.form.values.isActive,
      title: this.rubricForm.form.values.title,
    })
      .then(() => true)
      .catch((e) => {
        console.error(e);
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveRubric = false;
        });
      });
  };

  onClickSave = async () => {
    await this.rubricForm.validate();
    if (this.rubricForm.form.isValid) {
      return await this.saveRubric();
    }
  };
}
