import * as yup from "yup";
import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "../../../constants/validation/validators";

export default yup.object().shape({
  title: VALIDATOR_STRING_REQUIRED,
  description: VALIDATOR_STRING_REQUIRED.nullable(),
  //   type: VALIDATOR_ANY,
  price: VALIDATOR_STRING_REQUIRED,
  quantity: VALIDATOR_STRING_REQUIRED,
  //   wayToGet: VALIDATOR_STRING_REQUIRED,
  date: VALIDATOR_ANY,
  categories: VALIDATOR_ANY,
  organizationId: VALIDATOR_STRING_REQUIRED,
});
