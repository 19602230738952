import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import InfoTable from "newApp/kit/components/InfoTable";
import { URL_SITE_TECHSUPPORT_ANSWERED } from "newApp/routing/urls";
import { TechSupportNewItemPageStore } from "newApp/stores/pages/site/techsupport/TechSupportNewItemPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new TechSupportNewItemPageStore();

const TechSupportAnsweredItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  const goOnList = () => push(URL_SITE_TECHSUPPORT_ANSWERED);

  return (
    <MainLayout>
      <H1>Вопрос пользователя сайта</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_TECHSUPPORT_ANSWERED}>
          Назад к списку вопросов
        </ButtonBack>
        <InfoTable
          content={[
            ["Дата", store.form.values.issueDate],
            ["Имя пользователя", store.form.values.fullname],
            ["Телефон пользователя", store.form.values.phone],
            ["Вопрос", store.form.values.text],
            ["Ответ", store.form.values.answer],
            ["Автор ответа", store.form.values.answerFullName],
            ["Дата ответа", store.form.values.answerDate],
          ]}
        />
        <ButtonsContainer>
          <Button onClick={goOnList} variant="secondary">
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(TechSupportAnsweredItemPage);
