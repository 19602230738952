import { observer } from "mobx-react";
import { DeclarationChatStore } from "newApp/stores/common/DeclarationChatStore";
import React, { useEffect } from "react";

import Chat from "../Chat";

interface IProps {
  store: DeclarationChatStore;
}

const DeclarationItemChat: React.FC<IProps> = ({ store }) => {
  useEffect(() => {
    store.onOpenWindow();
    return () => store.onCloseWindow();
  }, [store]);

  return (
    <div>
      <Chat
        messages={store.messages}
        form={store.form}
        sendMessage={store.sendMessage}
        filesStore={store.filesStore}
      />
    </div>
  );
};

export default observer(DeclarationItemChat);
