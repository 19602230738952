import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

export class ProfilePageStore {
  isLoading = false;
  info = defaultInfo;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getProfile();
  };

  reset = () => {
    this.resetInfo();
  };

  resetInfo = () => {
    this.info = defaultInfo;
  };

  getProfile = () => {
    this.isLoading = true;
    return API.access.Users.apiUsersOwnGet()
      .then((res: any) => {
        runInAction(() => {
          this.info.email = res.data.email || null;
          this.info.firstName = res.data.firstName || null;
          this.info.isDeactivated = res.data.isDeactivated || null;
          this.info.lastName = res.data.lastName || null;
          this.info.login = res.data.login || null;
          this.info.otherPhones = res.data.otherPhones || null;
          this.info.phone = res.data.phone || null;
          this.info.surName = res.data.surName || null;
        });
      })
      .catch(this.resetInfo)
      .finally(() => {
        this.isLoading = false;
      });
  };

  onLogout = () => {};
}

const defaultInfo = {
  firstName: null,
  surName: null,
  lastName: null,
  email: null,
  isDeactivated: false,
  login: null,
  otherPhones: null,
  phone: null,
};
