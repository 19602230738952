import React, { FC } from "react";
import ReactCropper from "react-cropper";
import H3 from "../../H3";
import FlexRow from "../../FlexRow";
import Button from "../../Button";
import "cropperjs/dist/cropper.css";
import { ICropperViewProps } from "../index.types";

const CropperView: FC<ICropperViewProps> = ({
  onCropButtonClick,
  onCancelButtonClick,
  onRotateButtonClick,
  ...props
}) => {
  return (
    <>
      <ReactCropper
        className="cropper__crop"
        autoCropArea={0.9}
        dragMode="move"
        viewMode={1}
        {...props}
      />
      <div className="cropper__actions">
        <Button onClick={onRotateButtonClick} variant="link">
          Повернуть
        </Button>
      </div>
      <H3>Подсказки</H3>
      <ol className="cropper__hints">
        <li className="cropper__hints-item">
          Двойной щелчок ЛКМ включит/выключит режим перетаскивания фотографии
        </li>
        <li className="cropper__hints-item">
          Прокрутка колёсика увеличивает/уменьшает размер фотографии
        </li>
      </ol>
      <FlexRow>
        <Button onClick={onCropButtonClick}>Обрезать</Button>
        <Button variant="secondary" onClick={onCancelButtonClick}>
          Отмена
        </Button>
      </FlexRow>
    </>
  );
};

export default CropperView;
