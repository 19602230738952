import { observer } from "mobx-react";
import AdministrationNavigator from "newApp/components/AdministrationNavigator";
import MainLayout from "newApp/components/layouts/MainLayout";
import OrganizationsTable from "newApp/components/OrganizationsTable";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import { URL_ADMINISTRATION_ORGANIZATIONS_CREATE } from "newApp/routing/urls";
import { OrganizationsPageStore } from "newApp/stores/pages/administrations/organizations/OrganizationsPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new OrganizationsPageStore();

const OrganizationsPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Администрирование - Организации</H1>
      <FlexColumn>
        <AdministrationNavigator />
        <Button onClick={() => push(URL_ADMINISTRATION_ORGANIZATIONS_CREATE)}>
          Добавить организацию
        </Button>
        {store.isLoadings.getOrganizations ? (
          <LoaderSpinner />
        ) : (
          <OrganizationsTable {...store} />
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(OrganizationsPage);
