import { observer } from "mobx-react";
import React, { useEffect } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import MainPageModules from "../../components/MainPageModules";
import H1 from "../../kit/components/H1";
import H3 from "../../kit/components/H3";
import Select from "../../kit/components/Select";
import { MainPageStore } from "../../stores/pages/MainPageStore";

const store = new MainPageStore();

const MainPage: React.FC = () => {
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout isLoading={store.isLoading}>
      <H1>Выбор модуля</H1>
      <H3>Организация</H3>
      <Select
        {...store.organization.form.adapters.organizationId}
        placeholder="Организация"
        options={store.organization.options.organizations}
        onChange={store.onChangeOrganization}
      />

      <H3>Доступные модули</H3>
      <MainPageModules modules={store.modules.modules} />
    </MainLayout>
  );
};

export default observer(MainPage);
