/* tslint:disable */
/* eslint-disable */
/**
 * News API
 * Модуль новостей
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DocumentForm
 */
export interface DocumentForm {
    /**
     * 
     * @type {string}
     * @memberof DocumentForm
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface DocumentModel
 */
export interface DocumentModel {
    /**
     * 
     * @type {string}
     * @memberof DocumentModel
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentWithIdModel
 */
export interface DocumentWithIdModel {
    /**
     * 
     * @type {string}
     * @memberof DocumentWithIdModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentWithIdModel
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface EmailTemplateForm
 */
export interface EmailTemplateForm {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateForm
     */
    'templateTitle': string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateForm
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateForm
     */
    'buttonAvailable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateForm
     */
    'showImage': boolean;
}
/**
 * 
 * @export
 * @interface EmailTemplateModel
 */
export interface EmailTemplateModel {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateModel
     */
    'templateTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateModel
     */
    'content'?: string | null;
    /**
     * 
     * @type {NotificationsSettingsType}
     * @memberof EmailTemplateModel
     */
    'templateType'?: NotificationsSettingsType;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateModel
     */
    'showImage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailTemplateModel
     */
    'buttonAvailable'?: boolean;
    /**
     * 
     * @type {Array<TemplateParamsItem>}
     * @memberof EmailTemplateModel
     */
    'templateParams'?: Array<TemplateParamsItem> | null;
}
/**
 * 
 * @export
 * @interface FAQCreateForm
 */
export interface FAQCreateForm {
    /**
     * 
     * @type {string}
     * @memberof FAQCreateForm
     */
    'rubricId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQCreateForm
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQCreateForm
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof FAQCreateForm
     */
    'answer': string;
    /**
     * 
     * @type {FAQStates}
     * @memberof FAQCreateForm
     */
    'state'?: FAQStates;
}
/**
 * 
 * @export
 * @interface FAQGetFAQFile
 */
export interface FAQGetFAQFile {
    /**
     * 
     * @type {string}
     * @memberof FAQGetFAQFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQGetFAQFile
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface FAQGetFAQItem
 */
export interface FAQGetFAQItem {
    /**
     * 
     * @type {string}
     * @memberof FAQGetFAQItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQGetFAQItem
     */
    'question'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQGetFAQItem
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQGetFAQItem
     */
    'rubricId'?: string;
    /**
     * 
     * @type {FAQStates}
     * @memberof FAQGetFAQItem
     */
    'state'?: FAQStates;
    /**
     * 
     * @type {string}
     * @memberof FAQGetFAQItem
     */
    'answer'?: string | null;
    /**
     * 
     * @type {Array<FAQGetFAQFile>}
     * @memberof FAQGetFAQItem
     */
    'files'?: Array<FAQGetFAQFile> | null;
}
/**
 * 
 * @export
 * @interface FAQGetListItemResult
 */
export interface FAQGetListItemResult {
    /**
     * 
     * @type {string}
     * @memberof FAQGetListItemResult
     */
    'id'?: string;
    /**
     * 
     * @type {FAQStates}
     * @memberof FAQGetListItemResult
     */
    'state'?: FAQStates;
    /**
     * 
     * @type {string}
     * @memberof FAQGetListItemResult
     */
    'question'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQGetListItemResult
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQGetListItemResult
     */
    'rubricId'?: string;
}
/**
 * 
 * @export
 * @interface FAQGetListResponse
 */
export interface FAQGetListResponse {
    /**
     * 
     * @type {number}
     * @memberof FAQGetListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FAQGetListItemResult>}
     * @memberof FAQGetListResponse
     */
    'items'?: Array<FAQGetListItemResult> | null;
}
/**
 * 
 * @export
 * @interface FAQRubricCreateForm
 */
export interface FAQRubricCreateForm {
    /**
     * 
     * @type {string}
     * @memberof FAQRubricCreateForm
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface FAQRubricGetList
 */
export interface FAQRubricGetList {
    /**
     * 
     * @type {string}
     * @memberof FAQRubricGetList
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQRubricGetList
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface FAQRubricSetForm
 */
export interface FAQRubricSetForm {
    /**
     * 
     * @type {string}
     * @memberof FAQRubricSetForm
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface FAQSetFAQResponse
 */
export interface FAQSetFAQResponse {
    /**
     * 
     * @type {string}
     * @memberof FAQSetFAQResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface FAQSetFileResponse
 */
export interface FAQSetFileResponse {
    /**
     * 
     * @type {string}
     * @memberof FAQSetFileResponse
     */
    'fileId'?: string;
}
/**
 * 
 * @export
 * @interface FAQSetForm
 */
export interface FAQSetForm {
    /**
     * 
     * @type {string}
     * @memberof FAQSetForm
     */
    'rubricId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQSetForm
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQSetForm
     */
    'question'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQSetForm
     */
    'answer'?: string | null;
    /**
     * 
     * @type {FAQStates}
     * @memberof FAQSetForm
     */
    'state'?: FAQStates;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FAQStates = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type FAQStates = typeof FAQStates[keyof typeof FAQStates];


/**
 * 
 * @export
 * @interface GetProjectDescriptionResponse
 */
export interface GetProjectDescriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDescriptionResponse
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface MobileProjectForm
 */
export interface MobileProjectForm {
    /**
     * 
     * @type {string}
     * @memberof MobileProjectForm
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MobileProjectForm
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface MobileProjectModel
 */
export interface MobileProjectModel {
    /**
     * 
     * @type {string}
     * @memberof MobileProjectModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileProjectModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MobileProjectModel
     */
    'content'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MobileProjectModel
     */
    'hasImage'?: boolean;
}
/**
 * 
 * @export
 * @interface NewsCreateForm
 */
export interface NewsCreateForm {
    /**
     * 
     * @type {string}
     * @memberof NewsCreateForm
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateForm
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateForm
     */
    'content': string;
    /**
     * 
     * @type {NewsState}
     * @memberof NewsCreateForm
     */
    'state'?: NewsState;
    /**
     * 
     * @type {NewsTypes}
     * @memberof NewsCreateForm
     */
    'type'?: NewsTypes;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewsCreateForm
     */
    'rubricsIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateForm
     */
    'imageId': string;
}
/**
 * 
 * @export
 * @interface NewsGetListItemResult
 */
export interface NewsGetListItemResult {
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'id'?: string;
    /**
     * 
     * @type {NewsState}
     * @memberof NewsGetListItemResult
     */
    'state'?: NewsState;
    /**
     * 
     * @type {NewsTypes}
     * @memberof NewsGetListItemResult
     */
    'type'?: NewsTypes;
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsGetListItemResult
     */
    'hasImage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'previewImageId'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsGetListResponse
 */
export interface NewsGetListResponse {
    /**
     * 
     * @type {number}
     * @memberof NewsGetListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<NewsGetListItemResult>}
     * @memberof NewsGetListResponse
     */
    'items'?: Array<NewsGetListItemResult> | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetListResponse
     */
    'mainNewsId'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsGetNewsFile
 */
export interface NewsGetNewsFile {
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsFile
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsGetNewsItem
 */
export interface NewsGetNewsItem {
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'date'?: string;
    /**
     * 
     * @type {NewsTypes}
     * @memberof NewsGetNewsItem
     */
    'type'?: NewsTypes;
    /**
     * 
     * @type {NewsState}
     * @memberof NewsGetNewsItem
     */
    'state'?: NewsState;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'mainNewsTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'content'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsGetNewsItem
     */
    'hasImage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'previewImageId'?: string | null;
    /**
     * 
     * @type {Array<NewsGetNewsFile>}
     * @memberof NewsGetNewsItem
     */
    'files'?: Array<NewsGetNewsFile> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewsGetNewsItem
     */
    'rubricsIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<NewsGetNewsFile>}
     * @memberof NewsGetNewsItem
     */
    'photos'?: Array<NewsGetNewsFile> | null;
}
/**
 * 
 * @export
 * @interface NewsRubricsCreateForm
 */
export interface NewsRubricsCreateForm {
    /**
     * 
     * @type {string}
     * @memberof NewsRubricsCreateForm
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsRubricsCreateForm
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface NewsRubricsGetList
 */
export interface NewsRubricsGetList {
    /**
     * 
     * @type {string}
     * @memberof NewsRubricsGetList
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsRubricsGetList
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsRubricsGetList
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsRubricsGetList
     */
    'isDeletable'?: boolean;
}
/**
 * 
 * @export
 * @interface NewsRubricsSetForm
 */
export interface NewsRubricsSetForm {
    /**
     * 
     * @type {string}
     * @memberof NewsRubricsSetForm
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsRubricsSetForm
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface NewsSetFileResponse
 */
export interface NewsSetFileResponse {
    /**
     * 
     * @type {string}
     * @memberof NewsSetFileResponse
     */
    'fileId'?: string;
}
/**
 * 
 * @export
 * @interface NewsSetForm
 */
export interface NewsSetForm {
    /**
     * 
     * @type {string}
     * @memberof NewsSetForm
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSetForm
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSetForm
     */
    'content'?: string | null;
    /**
     * 
     * @type {NewsState}
     * @memberof NewsSetForm
     */
    'state'?: NewsState;
    /**
     * 
     * @type {NewsTypes}
     * @memberof NewsSetForm
     */
    'type'?: NewsTypes;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewsSetForm
     */
    'rubricsIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof NewsSetForm
     */
    'imageId'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsSetNewsResponse
 */
export interface NewsSetNewsResponse {
    /**
     * 
     * @type {string}
     * @memberof NewsSetNewsResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NewsState = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type NewsState = typeof NewsState[keyof typeof NewsState];


/**
 * 
 * @export
 * @interface NewsTemplateParams
 */
export interface NewsTemplateParams {
    /**
     * 
     * @type {string}
     * @memberof NewsTemplateParams
     */
    'newsTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsTemplateParams
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsTemplateParams
     */
    'customerSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsTemplateParams
     */
    'customerPatronymic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsTemplateParams
     */
    'category'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsTemplateParams
     */
    'buttonAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsTemplateParams
     */
    'showImage'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NewsTypes = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type NewsTypes = typeof NewsTypes[keyof typeof NewsTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationsSettingsType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type NotificationsSettingsType = typeof NotificationsSettingsType[keyof typeof NotificationsSettingsType];


/**
 * 
 * @export
 * @interface SetProjectDescriptionContentForm
 */
export interface SetProjectDescriptionContentForm {
    /**
     * 
     * @type {string}
     * @memberof SetProjectDescriptionContentForm
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface TemplateParamsItem
 */
export interface TemplateParamsItem {
    /**
     * 
     * @type {string}
     * @memberof TemplateParamsItem
     */
    'paramName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateParamsItem
     */
    'paramDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface TemplateTestModel
 */
export interface TemplateTestModel {
    /**
     * 
     * @type {string}
     * @memberof TemplateTestModel
     */
    'sourceText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateTestModel
     */
    'replacedText'?: string | null;
    /**
     * 
     * @type {NewsTemplateParams}
     * @memberof TemplateTestModel
     */
    'parameters'?: NewsTemplateParams;
}
/**
 * 
 * @export
 * @interface TemplateType
 */
export interface TemplateType {
    /**
     * 
     * @type {string}
     * @memberof TemplateType
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TemplateType
     */
    'enumNumber'?: number;
}

/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение политики персональных данных
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPersonalDataPolicyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/personalDataPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary создать/отредактировать документ Политика обработки персональных данных
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPersonalDataPolicyPost: async (documentForm?: DocumentForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/personalDataPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение условий участия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsTermsOfParticipationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/termsOfParticipation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary создать/отредактировать документ Условия участия
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsTermsOfParticipationPost: async (documentForm?: DocumentForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/termsOfParticipation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение пользовательского соглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUserAgreementGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/userAgreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary создать/отредактировать документ Пользовательское соглашение
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUserAgreementPost: async (documentForm?: DocumentForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/userAgreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение политики персональных данных
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsPersonalDataPolicyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsPersonalDataPolicyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary создать/отредактировать документ Политика обработки персональных данных
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsPersonalDataPolicyPost(documentForm?: DocumentForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentWithIdModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsPersonalDataPolicyPost(documentForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение условий участия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsTermsOfParticipationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsTermsOfParticipationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary создать/отредактировать документ Условия участия
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsTermsOfParticipationPost(documentForm?: DocumentForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentWithIdModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsTermsOfParticipationPost(documentForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение пользовательского соглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsUserAgreementGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsUserAgreementGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary создать/отредактировать документ Пользовательское соглашение
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsUserAgreementPost(documentForm?: DocumentForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentWithIdModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsUserAgreementPost(documentForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary получение политики персональных данных
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPersonalDataPolicyGet(options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.documentsPersonalDataPolicyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary создать/отредактировать документ Политика обработки персональных данных
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPersonalDataPolicyPost(documentForm?: DocumentForm, options?: any): AxiosPromise<DocumentWithIdModel> {
            return localVarFp.documentsPersonalDataPolicyPost(documentForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение условий участия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsTermsOfParticipationGet(options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.documentsTermsOfParticipationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary создать/отредактировать документ Условия участия
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsTermsOfParticipationPost(documentForm?: DocumentForm, options?: any): AxiosPromise<DocumentWithIdModel> {
            return localVarFp.documentsTermsOfParticipationPost(documentForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение пользовательского соглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUserAgreementGet(options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.documentsUserAgreementGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary создать/отредактировать документ Пользовательское соглашение
         * @param {DocumentForm} [documentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUserAgreementPost(documentForm?: DocumentForm, options?: any): AxiosPromise<DocumentWithIdModel> {
            return localVarFp.documentsUserAgreementPost(documentForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - interface
 * @export
 * @interface DocumentsApi
 */
export interface DocumentsApiInterface {
    /**
     * 
     * @summary получение политики персональных данных
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsPersonalDataPolicyGet(options?: AxiosRequestConfig): AxiosPromise<DocumentModel>;

    /**
     * 
     * @summary создать/отредактировать документ Политика обработки персональных данных
     * @param {DocumentForm} [documentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsPersonalDataPolicyPost(documentForm?: DocumentForm, options?: AxiosRequestConfig): AxiosPromise<DocumentWithIdModel>;

    /**
     * 
     * @summary Получение условий участия
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsTermsOfParticipationGet(options?: AxiosRequestConfig): AxiosPromise<DocumentModel>;

    /**
     * 
     * @summary создать/отредактировать документ Условия участия
     * @param {DocumentForm} [documentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsTermsOfParticipationPost(documentForm?: DocumentForm, options?: AxiosRequestConfig): AxiosPromise<DocumentWithIdModel>;

    /**
     * 
     * @summary получение пользовательского соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsUserAgreementGet(options?: AxiosRequestConfig): AxiosPromise<DocumentModel>;

    /**
     * 
     * @summary создать/отредактировать документ Пользовательское соглашение
     * @param {DocumentForm} [documentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsUserAgreementPost(documentForm?: DocumentForm, options?: AxiosRequestConfig): AxiosPromise<DocumentWithIdModel>;

}

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI implements DocumentsApiInterface {
    /**
     * 
     * @summary получение политики персональных данных
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsPersonalDataPolicyGet(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsPersonalDataPolicyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary создать/отредактировать документ Политика обработки персональных данных
     * @param {DocumentForm} [documentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsPersonalDataPolicyPost(documentForm?: DocumentForm, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsPersonalDataPolicyPost(documentForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение условий участия
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsTermsOfParticipationGet(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsTermsOfParticipationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary создать/отредактировать документ Условия участия
     * @param {DocumentForm} [documentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsTermsOfParticipationPost(documentForm?: DocumentForm, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsTermsOfParticipationPost(documentForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение пользовательского соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsUserAgreementGet(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsUserAgreementGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary создать/отредактировать документ Пользовательское соглашение
     * @param {DocumentForm} [documentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsUserAgreementPost(documentForm?: DocumentForm, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsUserAgreementPost(documentForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailTemplatesApi - axios parameter creator
 * @export
 */
export const EmailTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение всех заполненных шаблонов писем
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emailTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary установка шаблона письма
         * @param {NotificationsSettingsType} [templateType] тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
         * @param {EmailTemplateForm} [emailTemplateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesPost: async (templateType?: NotificationsSettingsType, emailTemplateForm?: EmailTemplateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emailTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (templateType !== undefined) {
                localVarQueryParameter['templateType'] = templateType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение шаблона письма по типу шаблона
         * @param {NotificationsSettingsType} templateType тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeGet: async (templateType: NotificationsSettingsType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateType' is not null or undefined
            assertParamExists('emailTemplatesTemplateTypeGet', 'templateType', templateType)
            const localVarPath = `/emailTemplates/{templateType}`
                .replace(`{${"templateType"}}`, encodeURIComponent(String(templateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary тестовый метод, не используется
         * @param {TemplateTestModel} [templateTestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTestPostPost: async (templateTestModel?: TemplateTestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emailTemplates/testPost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateTestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение списка возможных типов шаблона и их номеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTypesListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emailTemplates/typesList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailTemplatesApi - functional programming interface
 * @export
 */
export const EmailTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение всех заполненных шаблонов писем
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmailTemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary установка шаблона письма
         * @param {NotificationsSettingsType} [templateType] тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
         * @param {EmailTemplateForm} [emailTemplateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesPost(templateType?: NotificationsSettingsType, emailTemplateForm?: EmailTemplateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesPost(templateType, emailTemplateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение шаблона письма по типу шаблона
         * @param {NotificationsSettingsType} templateType тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateTypeGet(templateType: NotificationsSettingsType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateTypeGet(templateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary тестовый метод, не используется
         * @param {TemplateTestModel} [templateTestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTestPostPost(templateTestModel?: TemplateTestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTestPostPost(templateTestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение списка возможных типов шаблона и их номеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTypesListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTypesListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailTemplatesApi - factory interface
 * @export
 */
export const EmailTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary получение всех заполненных шаблонов писем
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesGet(options?: any): AxiosPromise<Array<EmailTemplateModel>> {
            return localVarFp.emailTemplatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary установка шаблона письма
         * @param {NotificationsSettingsType} [templateType] тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
         * @param {EmailTemplateForm} [emailTemplateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesPost(templateType?: NotificationsSettingsType, emailTemplateForm?: EmailTemplateForm, options?: any): AxiosPromise<EmailTemplateModel> {
            return localVarFp.emailTemplatesPost(templateType, emailTemplateForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение шаблона письма по типу шаблона
         * @param {NotificationsSettingsType} templateType тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateTypeGet(templateType: NotificationsSettingsType, options?: any): AxiosPromise<EmailTemplateModel> {
            return localVarFp.emailTemplatesTemplateTypeGet(templateType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary тестовый метод, не используется
         * @param {TemplateTestModel} [templateTestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTestPostPost(templateTestModel?: TemplateTestModel, options?: any): AxiosPromise<void> {
            return localVarFp.emailTemplatesTestPostPost(templateTestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение списка возможных типов шаблона и их номеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTypesListGet(options?: any): AxiosPromise<Array<TemplateType>> {
            return localVarFp.emailTemplatesTypesListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailTemplatesApi - interface
 * @export
 * @interface EmailTemplatesApi
 */
export interface EmailTemplatesApiInterface {
    /**
     * 
     * @summary получение всех заполненных шаблонов писем
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApiInterface
     */
    emailTemplatesGet(options?: AxiosRequestConfig): AxiosPromise<Array<EmailTemplateModel>>;

    /**
     * 
     * @summary установка шаблона письма
     * @param {NotificationsSettingsType} [templateType] тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
     * @param {EmailTemplateForm} [emailTemplateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApiInterface
     */
    emailTemplatesPost(templateType?: NotificationsSettingsType, emailTemplateForm?: EmailTemplateForm, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateModel>;

    /**
     * 
     * @summary получение шаблона письма по типу шаблона
     * @param {NotificationsSettingsType} templateType тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApiInterface
     */
    emailTemplatesTemplateTypeGet(templateType: NotificationsSettingsType, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateModel>;

    /**
     * 
     * @summary тестовый метод, не используется
     * @param {TemplateTestModel} [templateTestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApiInterface
     */
    emailTemplatesTestPostPost(templateTestModel?: TemplateTestModel, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение списка возможных типов шаблона и их номеров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApiInterface
     */
    emailTemplatesTypesListGet(options?: AxiosRequestConfig): AxiosPromise<Array<TemplateType>>;

}

/**
 * EmailTemplatesApi - object-oriented interface
 * @export
 * @class EmailTemplatesApi
 * @extends {BaseAPI}
 */
export class EmailTemplatesApi extends BaseAPI implements EmailTemplatesApiInterface {
    /**
     * 
     * @summary получение всех заполненных шаблонов писем
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public emailTemplatesGet(options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).emailTemplatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary установка шаблона письма
     * @param {NotificationsSettingsType} [templateType] тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
     * @param {EmailTemplateForm} [emailTemplateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public emailTemplatesPost(templateType?: NotificationsSettingsType, emailTemplateForm?: EmailTemplateForm, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).emailTemplatesPost(templateType, emailTemplateForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение шаблона письма по типу шаблона
     * @param {NotificationsSettingsType} templateType тип шаблона&lt;br /&gt;              News&#x3D;0&lt;br /&gt;              Violations&#x3D;1&lt;br /&gt;              Votes&#x3D;2&lt;br /&gt;              PointsAddedForVoting&#x3D;3&lt;br /&gt;              PointsSpentOnProduct&#x3D;4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public emailTemplatesTemplateTypeGet(templateType: NotificationsSettingsType, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).emailTemplatesTemplateTypeGet(templateType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary тестовый метод, не используется
     * @param {TemplateTestModel} [templateTestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public emailTemplatesTestPostPost(templateTestModel?: TemplateTestModel, options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).emailTemplatesTestPostPost(templateTestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение списка возможных типов шаблона и их номеров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailTemplatesApi
     */
    public emailTemplatesTypesListGet(options?: AxiosRequestConfig) {
        return EmailTemplatesApiFp(this.configuration).emailTemplatesTypesListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FAQApi - axios parameter creator
 * @export
 */
export const FAQApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение файла faq
         * @param {string} faqId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFaqIdFilePost: async (faqId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqId' is not null or undefined
            assertParamExists('faqFaqIdFilePost', 'faqId', faqId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('faqFaqIdFilePost', 'file', file)
            const localVarPath = `/faq/{faqId}/file`
                .replace(`{${"faqId"}}`, encodeURIComponent(String(faqId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary удаление файла faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFileIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqFileIdDelete', 'id', id)
            const localVarPath = `/faq/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary метод получения файла прикрепленного к faq
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqFileIdGet', 'id', id)
            const localVarPath = `/faq/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение списка faq упорядоченных от новых к поздним
         * @param {FAQStates} [state] состояние faq&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqGet: async (state?: FAQStates, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary удаление faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqIdDelete', 'id', id)
            const localVarPath = `/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqIdGet', 'id', id)
            const localVarPath = `/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary изменение faq
         * @param {string} id 
         * @param {FAQSetForm} [fAQSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdPost: async (id: string, fAQSetForm?: FAQSetForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqIdPost', 'id', id)
            const localVarPath = `/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fAQSetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary создание faq
         * @param {FAQCreateForm} [fAQCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqPost: async (fAQCreateForm?: FAQCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fAQCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FAQApi - functional programming interface
 * @export
 */
export const FAQApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FAQApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение файла faq
         * @param {string} faqId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqFaqIdFilePost(faqId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQSetFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqFaqIdFilePost(faqId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary удаление файла faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqFileIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqFileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary метод получения файла прикрепленного к faq
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqFileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение списка faq упорядоченных от новых к поздним
         * @param {FAQStates} [state] состояние faq&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqGet(state?: FAQStates, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQGetListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqGet(state, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary удаление faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQGetFAQItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary изменение faq
         * @param {string} id 
         * @param {FAQSetForm} [fAQSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqIdPost(id: string, fAQSetForm?: FAQSetForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQSetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqIdPost(id, fAQSetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary создание faq
         * @param {FAQCreateForm} [fAQCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqPost(fAQCreateForm?: FAQCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQSetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqPost(fAQCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FAQApi - factory interface
 * @export
 */
export const FAQApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FAQApiFp(configuration)
    return {
        /**
         * 
         * @summary получение файла faq
         * @param {string} faqId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFaqIdFilePost(faqId: string, file: any, options?: any): AxiosPromise<FAQSetFileResponse> {
            return localVarFp.faqFaqIdFilePost(faqId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary удаление файла faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFileIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.faqFileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary метод получения файла прикрепленного к faq
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFileIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.faqFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение списка faq упорядоченных от новых к поздним
         * @param {FAQStates} [state] состояние faq&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqGet(state?: FAQStates, skip?: number, take?: number, options?: any): AxiosPromise<FAQGetListResponse> {
            return localVarFp.faqGet(state, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary удаление faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.faqIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение faq
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdGet(id: string, options?: any): AxiosPromise<FAQGetFAQItem> {
            return localVarFp.faqIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary изменение faq
         * @param {string} id 
         * @param {FAQSetForm} [fAQSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdPost(id: string, fAQSetForm?: FAQSetForm, options?: any): AxiosPromise<FAQSetFAQResponse> {
            return localVarFp.faqIdPost(id, fAQSetForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary создание faq
         * @param {FAQCreateForm} [fAQCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqPost(fAQCreateForm?: FAQCreateForm, options?: any): AxiosPromise<FAQSetFAQResponse> {
            return localVarFp.faqPost(fAQCreateForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FAQApi - interface
 * @export
 * @interface FAQApi
 */
export interface FAQApiInterface {
    /**
     * 
     * @summary получение файла faq
     * @param {string} faqId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqFaqIdFilePost(faqId: string, file: any, options?: AxiosRequestConfig): AxiosPromise<FAQSetFileResponse>;

    /**
     * 
     * @summary удаление файла faq
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqFileIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary метод получения файла прикрепленного к faq
     * @param {string} id id файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqFileIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение списка faq упорядоченных от новых к поздним
     * @param {FAQStates} [state] состояние faq&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqGet(state?: FAQStates, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<FAQGetListResponse>;

    /**
     * 
     * @summary удаление faq
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение faq
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<FAQGetFAQItem>;

    /**
     * 
     * @summary изменение faq
     * @param {string} id 
     * @param {FAQSetForm} [fAQSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqIdPost(id: string, fAQSetForm?: FAQSetForm, options?: AxiosRequestConfig): AxiosPromise<FAQSetFAQResponse>;

    /**
     * 
     * @summary создание faq
     * @param {FAQCreateForm} [fAQCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApiInterface
     */
    faqPost(fAQCreateForm?: FAQCreateForm, options?: AxiosRequestConfig): AxiosPromise<FAQSetFAQResponse>;

}

/**
 * FAQApi - object-oriented interface
 * @export
 * @class FAQApi
 * @extends {BaseAPI}
 */
export class FAQApi extends BaseAPI implements FAQApiInterface {
    /**
     * 
     * @summary получение файла faq
     * @param {string} faqId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqFaqIdFilePost(faqId: string, file: any, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqFaqIdFilePost(faqId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary удаление файла faq
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqFileIdDelete(id: string, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqFileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary метод получения файла прикрепленного к faq
     * @param {string} id id файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqFileIdGet(id: string, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение списка faq упорядоченных от новых к поздним
     * @param {FAQStates} [state] состояние faq&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqGet(state?: FAQStates, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqGet(state, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary удаление faq
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqIdDelete(id: string, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение faq
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqIdGet(id: string, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary изменение faq
     * @param {string} id 
     * @param {FAQSetForm} [fAQSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqIdPost(id: string, fAQSetForm?: FAQSetForm, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqIdPost(id, fAQSetForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary создание faq
     * @param {FAQCreateForm} [fAQCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQApi
     */
    public faqPost(fAQCreateForm?: FAQCreateForm, options?: AxiosRequestConfig) {
        return FAQApiFp(this.configuration).faqPost(fAQCreateForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FAQRubricsApi - axios parameter creator
 * @export
 */
export const FAQRubricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary изменение рубрики
         * @param {string} id 
         * @param {FAQRubricSetForm} [fAQRubricSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricIdPost: async (id: string, fAQRubricSetForm?: FAQRubricSetForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqRubricIdPost', 'id', id)
            const localVarPath = `/faq/rubric/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fAQRubricSetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricListGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq/rubric/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary создание рубрики
         * @param {FAQRubricCreateForm} [fAQRubricCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricPost: async (fAQRubricCreateForm?: FAQRubricCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq/rubric`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fAQRubricCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FAQRubricsApi - functional programming interface
 * @export
 */
export const FAQRubricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FAQRubricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary изменение рубрики
         * @param {string} id 
         * @param {FAQRubricSetForm} [fAQRubricSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqRubricIdPost(id: string, fAQRubricSetForm?: FAQRubricSetForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQSetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqRubricIdPost(id, fAQRubricSetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqRubricListGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FAQRubricGetList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqRubricListGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary создание рубрики
         * @param {FAQRubricCreateForm} [fAQRubricCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqRubricPost(fAQRubricCreateForm?: FAQRubricCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQSetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqRubricPost(fAQRubricCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FAQRubricsApi - factory interface
 * @export
 */
export const FAQRubricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FAQRubricsApiFp(configuration)
    return {
        /**
         * 
         * @summary изменение рубрики
         * @param {string} id 
         * @param {FAQRubricSetForm} [fAQRubricSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricIdPost(id: string, fAQRubricSetForm?: FAQRubricSetForm, options?: any): AxiosPromise<FAQSetFAQResponse> {
            return localVarFp.faqRubricIdPost(id, fAQRubricSetForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricListGet(id?: string, options?: any): AxiosPromise<Array<FAQRubricGetList>> {
            return localVarFp.faqRubricListGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary создание рубрики
         * @param {FAQRubricCreateForm} [fAQRubricCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricPost(fAQRubricCreateForm?: FAQRubricCreateForm, options?: any): AxiosPromise<FAQSetFAQResponse> {
            return localVarFp.faqRubricPost(fAQRubricCreateForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FAQRubricsApi - interface
 * @export
 * @interface FAQRubricsApi
 */
export interface FAQRubricsApiInterface {
    /**
     * 
     * @summary изменение рубрики
     * @param {string} id 
     * @param {FAQRubricSetForm} [fAQRubricSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApiInterface
     */
    faqRubricIdPost(id: string, fAQRubricSetForm?: FAQRubricSetForm, options?: AxiosRequestConfig): AxiosPromise<FAQSetFAQResponse>;

    /**
     * 
     * @summary получение списка рубрик, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApiInterface
     */
    faqRubricListGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<FAQRubricGetList>>;

    /**
     * 
     * @summary создание рубрики
     * @param {FAQRubricCreateForm} [fAQRubricCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApiInterface
     */
    faqRubricPost(fAQRubricCreateForm?: FAQRubricCreateForm, options?: AxiosRequestConfig): AxiosPromise<FAQSetFAQResponse>;

}

/**
 * FAQRubricsApi - object-oriented interface
 * @export
 * @class FAQRubricsApi
 * @extends {BaseAPI}
 */
export class FAQRubricsApi extends BaseAPI implements FAQRubricsApiInterface {
    /**
     * 
     * @summary изменение рубрики
     * @param {string} id 
     * @param {FAQRubricSetForm} [fAQRubricSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApi
     */
    public faqRubricIdPost(id: string, fAQRubricSetForm?: FAQRubricSetForm, options?: AxiosRequestConfig) {
        return FAQRubricsApiFp(this.configuration).faqRubricIdPost(id, fAQRubricSetForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение списка рубрик, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApi
     */
    public faqRubricListGet(id?: string, options?: AxiosRequestConfig) {
        return FAQRubricsApiFp(this.configuration).faqRubricListGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary создание рубрики
     * @param {FAQRubricCreateForm} [fAQRubricCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApi
     */
    public faqRubricPost(fAQRubricCreateForm?: FAQRubricCreateForm, options?: AxiosRequestConfig) {
        return FAQRubricsApiFp(this.configuration).faqRubricPost(fAQRubricCreateForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileProjectApi - axios parameter creator
 * @export
 */
export const MobileProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение мобильного проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mobileproject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получить файл проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectImageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mobileproject/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary устновить файл проекта
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectImagePost: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('mobileprojectImagePost', 'image', image)
            const localVarPath = `/mobileproject/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавить проект
         * @param {MobileProjectForm} [mobileProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectPost: async (mobileProjectForm?: MobileProjectForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mobileproject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mobileProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileProjectApi - functional programming interface
 * @export
 */
export const MobileProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение мобильного проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileprojectGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileProjectModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileprojectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получить файл проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileprojectImageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileprojectImageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary устновить файл проекта
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileprojectImagePost(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileprojectImagePost(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавить проект
         * @param {MobileProjectForm} [mobileProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileprojectPost(mobileProjectForm?: MobileProjectForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileprojectPost(mobileProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileProjectApi - factory interface
 * @export
 */
export const MobileProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary получение мобильного проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectGet(options?: any): AxiosPromise<MobileProjectModel> {
            return localVarFp.mobileprojectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получить файл проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectImageGet(options?: any): AxiosPromise<void> {
            return localVarFp.mobileprojectImageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary устновить файл проекта
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectImagePost(image: any, options?: any): AxiosPromise<void> {
            return localVarFp.mobileprojectImagePost(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавить проект
         * @param {MobileProjectForm} [mobileProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectPost(mobileProjectForm?: MobileProjectForm, options?: any): AxiosPromise<void> {
            return localVarFp.mobileprojectPost(mobileProjectForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileProjectApi - interface
 * @export
 * @interface MobileProjectApi
 */
export interface MobileProjectApiInterface {
    /**
     * 
     * @summary получение мобильного проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApiInterface
     */
    mobileprojectGet(options?: AxiosRequestConfig): AxiosPromise<MobileProjectModel>;

    /**
     * 
     * @summary получить файл проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApiInterface
     */
    mobileprojectImageGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary устновить файл проекта
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApiInterface
     */
    mobileprojectImagePost(image: any, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary добавить проект
     * @param {MobileProjectForm} [mobileProjectForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApiInterface
     */
    mobileprojectPost(mobileProjectForm?: MobileProjectForm, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * MobileProjectApi - object-oriented interface
 * @export
 * @class MobileProjectApi
 * @extends {BaseAPI}
 */
export class MobileProjectApi extends BaseAPI implements MobileProjectApiInterface {
    /**
     * 
     * @summary получение мобильного проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApi
     */
    public mobileprojectGet(options?: AxiosRequestConfig) {
        return MobileProjectApiFp(this.configuration).mobileprojectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получить файл проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApi
     */
    public mobileprojectImageGet(options?: AxiosRequestConfig) {
        return MobileProjectApiFp(this.configuration).mobileprojectImageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary устновить файл проекта
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApi
     */
    public mobileprojectImagePost(image: any, options?: AxiosRequestConfig) {
        return MobileProjectApiFp(this.configuration).mobileprojectImagePost(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавить проект
     * @param {MobileProjectForm} [mobileProjectForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApi
     */
    public mobileprojectPost(mobileProjectForm?: MobileProjectForm, options?: AxiosRequestConfig) {
        return MobileProjectApiFp(this.configuration).mobileprojectPost(mobileProjectForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary удаление файла новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFileIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsFileIdDelete', 'id', id)
            const localVarPath = `/news/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary метод получения файла прикрепленного к новости
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsFileIdGet', 'id', id)
            const localVarPath = `/news/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение списка новостей упорядоченных от новых к поздним
         * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;              2 - главная новость&lt;br /&gt;
         * @param {NewsState} [state] состояние новости&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsGet: async (type?: NewsTypes, state?: NewsState, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary удаление новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdDelete', 'id', id)
            const localVarPath = `/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdGet', 'id', id)
            const localVarPath = `/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary изменение новости
         * @param {string} id 
         * @param {NewsSetForm} [newsSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdPost: async (id: string, newsSetForm?: NewsSetForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdPost', 'id', id)
            const localVarPath = `/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsSetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавление файла
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsImagePost: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('newsImagePost', 'image', image)
            const localVarPath = `/news/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение обрезанной картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdCroppedimageGet: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdCroppedimageGet', 'newsId', newsId)
            const localVarPath = `/news/{newsId}/croppedimage`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавление обрезанного изображения к новости
         * @param {string} newsId 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdCroppedimagePost: async (newsId: string, image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdCroppedimagePost', 'newsId', newsId)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('newsNewsIdCroppedimagePost', 'image', image)
            const localVarPath = `/news/{newsId}/croppedimage`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавление файла к новости
         * @param {string} newsId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdFilePost: async (newsId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdFilePost', 'newsId', newsId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('newsNewsIdFilePost', 'file', file)
            const localVarPath = `/news/{newsId}/file`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdImageGet: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdImageGet', 'newsId', newsId)
            const localVarPath = `/news/{newsId}/image`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавление фото новости
         * @param {string} newsId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPhotoPost: async (newsId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdPhotoPost', 'newsId', newsId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('newsNewsIdPhotoPost', 'file', file)
            const localVarPath = `/news/{newsId}/photo`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение превью картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPreviewimageGet: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdPreviewimageGet', 'newsId', newsId)
            const localVarPath = `/news/{newsId}/previewimage`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавление превью картинки к новости
         * @param {string} newsId 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPreviewimagePost: async (newsId: string, image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdPreviewimagePost', 'newsId', newsId)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('newsNewsIdPreviewimagePost', 'image', image)
            const localVarPath = `/news/{newsId}/previewimage`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary удаление фото, прикрепленного к новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPhotoIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPhotoIdDelete', 'id', id)
            const localVarPath = `/news/photo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary метод получения фото прикрепленного к новости
         * @param {string} id id фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPhotoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPhotoIdGet', 'id', id)
            const localVarPath = `/news/photo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary создание новости
         * @param {NewsCreateForm} [newsCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPost: async (newsCreateForm?: NewsCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary удаление файла новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsFileIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsFileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary метод получения файла прикрепленного к новости
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsFileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение списка новостей упорядоченных от новых к поздним
         * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;              2 - главная новость&lt;br /&gt;
         * @param {NewsState} [state] состояние новости&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsGet(type?: NewsTypes, state?: NewsState, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsGetListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsGet(type, state, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary удаление новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsGetNewsItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary изменение новости
         * @param {string} id 
         * @param {NewsSetForm} [newsSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdPost(id: string, newsSetForm?: NewsSetForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSetNewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdPost(id, newsSetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавление файла
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsImagePost(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsImagePost(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение обрезанной картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdCroppedimageGet(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdCroppedimageGet(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавление обрезанного изображения к новости
         * @param {string} newsId 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdCroppedimagePost(newsId: string, image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdCroppedimagePost(newsId, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавление файла к новости
         * @param {string} newsId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdFilePost(newsId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSetFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdFilePost(newsId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdImageGet(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdImageGet(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавление фото новости
         * @param {string} newsId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdPhotoPost(newsId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSetFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdPhotoPost(newsId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение превью картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdPreviewimageGet(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdPreviewimageGet(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавление превью картинки к новости
         * @param {string} newsId 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdPreviewimagePost(newsId: string, image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdPreviewimagePost(newsId, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary удаление фото, прикрепленного к новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPhotoIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPhotoIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary метод получения фото прикрепленного к новости
         * @param {string} id id фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPhotoIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPhotoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary создание новости
         * @param {NewsCreateForm} [newsCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPost(newsCreateForm?: NewsCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSetNewsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPost(newsCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @summary удаление файла новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFileIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsFileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary метод получения файла прикрепленного к новости
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFileIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение списка новостей упорядоченных от новых к поздним
         * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;              2 - главная новость&lt;br /&gt;
         * @param {NewsState} [state] состояние новости&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsGet(type?: NewsTypes, state?: NewsState, skip?: number, take?: number, options?: any): AxiosPromise<NewsGetListResponse> {
            return localVarFp.newsGet(type, state, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary удаление новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdGet(id: string, options?: any): AxiosPromise<NewsGetNewsItem> {
            return localVarFp.newsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary изменение новости
         * @param {string} id 
         * @param {NewsSetForm} [newsSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdPost(id: string, newsSetForm?: NewsSetForm, options?: any): AxiosPromise<NewsSetNewsResponse> {
            return localVarFp.newsIdPost(id, newsSetForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавление файла
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsImagePost(image: any, options?: any): AxiosPromise<string> {
            return localVarFp.newsImagePost(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение обрезанной картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdCroppedimageGet(newsId: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsNewsIdCroppedimageGet(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавление обрезанного изображения к новости
         * @param {string} newsId 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdCroppedimagePost(newsId: string, image: any, options?: any): AxiosPromise<void> {
            return localVarFp.newsNewsIdCroppedimagePost(newsId, image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавление файла к новости
         * @param {string} newsId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdFilePost(newsId: string, file: any, options?: any): AxiosPromise<NewsSetFileResponse> {
            return localVarFp.newsNewsIdFilePost(newsId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdImageGet(newsId: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsNewsIdImageGet(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавление фото новости
         * @param {string} newsId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPhotoPost(newsId: string, file: any, options?: any): AxiosPromise<NewsSetFileResponse> {
            return localVarFp.newsNewsIdPhotoPost(newsId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение превью картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPreviewimageGet(newsId: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsNewsIdPreviewimageGet(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавление превью картинки к новости
         * @param {string} newsId 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPreviewimagePost(newsId: string, image: any, options?: any): AxiosPromise<void> {
            return localVarFp.newsNewsIdPreviewimagePost(newsId, image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary удаление фото, прикрепленного к новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPhotoIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsPhotoIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary метод получения фото прикрепленного к новости
         * @param {string} id id фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPhotoIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsPhotoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary создание новости
         * @param {NewsCreateForm} [newsCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPost(newsCreateForm?: NewsCreateForm, options?: any): AxiosPromise<NewsSetNewsResponse> {
            return localVarFp.newsPost(newsCreateForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - interface
 * @export
 * @interface NewsApi
 */
export interface NewsApiInterface {
    /**
     * 
     * @summary удаление файла новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsFileIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary метод получения файла прикрепленного к новости
     * @param {string} id id файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsFileIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение списка новостей упорядоченных от новых к поздним
     * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;              2 - главная новость&lt;br /&gt;
     * @param {NewsState} [state] состояние новости&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsGet(type?: NewsTypes, state?: NewsState, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<NewsGetListResponse>;

    /**
     * 
     * @summary удаление новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<NewsGetNewsItem>;

    /**
     * 
     * @summary изменение новости
     * @param {string} id 
     * @param {NewsSetForm} [newsSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsIdPost(id: string, newsSetForm?: NewsSetForm, options?: AxiosRequestConfig): AxiosPromise<NewsSetNewsResponse>;

    /**
     * 
     * @summary добавление файла
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsImagePost(image: any, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary получение обрезанной картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdCroppedimageGet(newsId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary добавление обрезанного изображения к новости
     * @param {string} newsId 
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdCroppedimagePost(newsId: string, image: any, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary добавление файла к новости
     * @param {string} newsId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdFilePost(newsId: string, file: any, options?: AxiosRequestConfig): AxiosPromise<NewsSetFileResponse>;

    /**
     * 
     * @summary получение картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdImageGet(newsId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary добавление фото новости
     * @param {string} newsId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdPhotoPost(newsId: string, file: any, options?: AxiosRequestConfig): AxiosPromise<NewsSetFileResponse>;

    /**
     * 
     * @summary получение превью картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdPreviewimageGet(newsId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary добавление превью картинки к новости
     * @param {string} newsId 
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdPreviewimagePost(newsId: string, image: any, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary удаление фото, прикрепленного к новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsPhotoIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary метод получения фото прикрепленного к новости
     * @param {string} id id фото
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsPhotoIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary создание новости
     * @param {NewsCreateForm} [newsCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsPost(newsCreateForm?: NewsCreateForm, options?: AxiosRequestConfig): AxiosPromise<NewsSetNewsResponse>;

}

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI implements NewsApiInterface {
    /**
     * 
     * @summary удаление файла новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsFileIdDelete(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsFileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary метод получения файла прикрепленного к новости
     * @param {string} id id файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsFileIdGet(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение списка новостей упорядоченных от новых к поздним
     * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;              2 - главная новость&lt;br /&gt;
     * @param {NewsState} [state] состояние новости&lt;br /&gt;              0 - скрытые&lt;br /&gt;              1 - опубликованные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsGet(type?: NewsTypes, state?: NewsState, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsGet(type, state, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary удаление новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdDelete(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdGet(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary изменение новости
     * @param {string} id 
     * @param {NewsSetForm} [newsSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdPost(id: string, newsSetForm?: NewsSetForm, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdPost(id, newsSetForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавление файла
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsImagePost(image: any, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsImagePost(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение обрезанной картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdCroppedimageGet(newsId: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdCroppedimageGet(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавление обрезанного изображения к новости
     * @param {string} newsId 
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdCroppedimagePost(newsId: string, image: any, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdCroppedimagePost(newsId, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавление файла к новости
     * @param {string} newsId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdFilePost(newsId: string, file: any, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdFilePost(newsId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdImageGet(newsId: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdImageGet(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавление фото новости
     * @param {string} newsId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdPhotoPost(newsId: string, file: any, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdPhotoPost(newsId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение превью картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdPreviewimageGet(newsId: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdPreviewimageGet(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавление превью картинки к новости
     * @param {string} newsId 
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdPreviewimagePost(newsId: string, image: any, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdPreviewimagePost(newsId, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary удаление фото, прикрепленного к новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsPhotoIdDelete(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsPhotoIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary метод получения фото прикрепленного к новости
     * @param {string} id id фото
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsPhotoIdGet(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsPhotoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary создание новости
     * @param {NewsCreateForm} [newsCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsPost(newsCreateForm?: NewsCreateForm, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsPost(newsCreateForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsRubricsApi - axios parameter creator
 * @export
 */
export const NewsRubricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary удаление рубрики, если нет использований
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricIdDeletePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsRubricIdDeletePost', 'id', id)
            const localVarPath = `/news/rubric/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary изменение рубрики новостей
         * @param {string} id 
         * @param {NewsRubricsSetForm} [newsRubricsSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricIdPost: async (id: string, newsRubricsSetForm?: NewsRubricsSetForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsRubricIdPost', 'id', id)
            const localVarPath = `/news/rubric/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsRubricsSetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получения списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricListGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/rubric/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary создание рубрики новостей
         * @param {NewsRubricsCreateForm} [newsRubricsCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricPost: async (newsRubricsCreateForm?: NewsRubricsCreateForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/rubric`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsRubricsCreateForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsRubricsApi - functional programming interface
 * @export
 */
export const NewsRubricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsRubricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary удаление рубрики, если нет использований
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsRubricIdDeletePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsRubricIdDeletePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary изменение рубрики новостей
         * @param {string} id 
         * @param {NewsRubricsSetForm} [newsRubricsSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsRubricIdPost(id: string, newsRubricsSetForm?: NewsRubricsSetForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQSetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsRubricIdPost(id, newsRubricsSetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получения списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsRubricListGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsRubricsGetList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsRubricListGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary создание рубрики новостей
         * @param {NewsRubricsCreateForm} [newsRubricsCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsRubricPost(newsRubricsCreateForm?: NewsRubricsCreateForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQSetFAQResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsRubricPost(newsRubricsCreateForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsRubricsApi - factory interface
 * @export
 */
export const NewsRubricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsRubricsApiFp(configuration)
    return {
        /**
         * 
         * @summary удаление рубрики, если нет использований
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricIdDeletePost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsRubricIdDeletePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary изменение рубрики новостей
         * @param {string} id 
         * @param {NewsRubricsSetForm} [newsRubricsSetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricIdPost(id: string, newsRubricsSetForm?: NewsRubricsSetForm, options?: any): AxiosPromise<FAQSetFAQResponse> {
            return localVarFp.newsRubricIdPost(id, newsRubricsSetForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получения списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricListGet(id?: string, options?: any): AxiosPromise<Array<NewsRubricsGetList>> {
            return localVarFp.newsRubricListGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary создание рубрики новостей
         * @param {NewsRubricsCreateForm} [newsRubricsCreateForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricPost(newsRubricsCreateForm?: NewsRubricsCreateForm, options?: any): AxiosPromise<FAQSetFAQResponse> {
            return localVarFp.newsRubricPost(newsRubricsCreateForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsRubricsApi - interface
 * @export
 * @interface NewsRubricsApi
 */
export interface NewsRubricsApiInterface {
    /**
     * 
     * @summary удаление рубрики, если нет использований
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApiInterface
     */
    newsRubricIdDeletePost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary изменение рубрики новостей
     * @param {string} id 
     * @param {NewsRubricsSetForm} [newsRubricsSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApiInterface
     */
    newsRubricIdPost(id: string, newsRubricsSetForm?: NewsRubricsSetForm, options?: AxiosRequestConfig): AxiosPromise<FAQSetFAQResponse>;

    /**
     * 
     * @summary получения списка рубрик, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApiInterface
     */
    newsRubricListGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<NewsRubricsGetList>>;

    /**
     * 
     * @summary создание рубрики новостей
     * @param {NewsRubricsCreateForm} [newsRubricsCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApiInterface
     */
    newsRubricPost(newsRubricsCreateForm?: NewsRubricsCreateForm, options?: AxiosRequestConfig): AxiosPromise<FAQSetFAQResponse>;

}

/**
 * NewsRubricsApi - object-oriented interface
 * @export
 * @class NewsRubricsApi
 * @extends {BaseAPI}
 */
export class NewsRubricsApi extends BaseAPI implements NewsRubricsApiInterface {
    /**
     * 
     * @summary удаление рубрики, если нет использований
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApi
     */
    public newsRubricIdDeletePost(id: string, options?: AxiosRequestConfig) {
        return NewsRubricsApiFp(this.configuration).newsRubricIdDeletePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary изменение рубрики новостей
     * @param {string} id 
     * @param {NewsRubricsSetForm} [newsRubricsSetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApi
     */
    public newsRubricIdPost(id: string, newsRubricsSetForm?: NewsRubricsSetForm, options?: AxiosRequestConfig) {
        return NewsRubricsApiFp(this.configuration).newsRubricIdPost(id, newsRubricsSetForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получения списка рубрик, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApi
     */
    public newsRubricListGet(id?: string, options?: AxiosRequestConfig) {
        return NewsRubricsApiFp(this.configuration).newsRubricListGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary создание рубрики новостей
     * @param {NewsRubricsCreateForm} [newsRubricsCreateForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApi
     */
    public newsRubricPost(newsRubricsCreateForm?: NewsRubricsCreateForm, options?: AxiosRequestConfig) {
        return NewsRubricsApiFp(this.configuration).newsRubricPost(newsRubricsCreateForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectDescriptionApi - axios parameter creator
 * @export
 */
export const ProjectDescriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение описания проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectdescriptionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projectdescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary добавление описание проекта
         * @param {SetProjectDescriptionContentForm} [setProjectDescriptionContentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectdescriptionPost: async (setProjectDescriptionContentForm?: SetProjectDescriptionContentForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projectdescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setProjectDescriptionContentForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectDescriptionApi - functional programming interface
 * @export
 */
export const ProjectDescriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectDescriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение описания проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectdescriptionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectDescriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectdescriptionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary добавление описание проекта
         * @param {SetProjectDescriptionContentForm} [setProjectDescriptionContentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectdescriptionPost(setProjectDescriptionContentForm?: SetProjectDescriptionContentForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectdescriptionPost(setProjectDescriptionContentForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectDescriptionApi - factory interface
 * @export
 */
export const ProjectDescriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectDescriptionApiFp(configuration)
    return {
        /**
         * 
         * @summary получение описания проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectdescriptionGet(options?: any): AxiosPromise<GetProjectDescriptionResponse> {
            return localVarFp.projectdescriptionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary добавление описание проекта
         * @param {SetProjectDescriptionContentForm} [setProjectDescriptionContentForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectdescriptionPost(setProjectDescriptionContentForm?: SetProjectDescriptionContentForm, options?: any): AxiosPromise<void> {
            return localVarFp.projectdescriptionPost(setProjectDescriptionContentForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectDescriptionApi - interface
 * @export
 * @interface ProjectDescriptionApi
 */
export interface ProjectDescriptionApiInterface {
    /**
     * 
     * @summary получение описания проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDescriptionApiInterface
     */
    projectdescriptionGet(options?: AxiosRequestConfig): AxiosPromise<GetProjectDescriptionResponse>;

    /**
     * 
     * @summary добавление описание проекта
     * @param {SetProjectDescriptionContentForm} [setProjectDescriptionContentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDescriptionApiInterface
     */
    projectdescriptionPost(setProjectDescriptionContentForm?: SetProjectDescriptionContentForm, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProjectDescriptionApi - object-oriented interface
 * @export
 * @class ProjectDescriptionApi
 * @extends {BaseAPI}
 */
export class ProjectDescriptionApi extends BaseAPI implements ProjectDescriptionApiInterface {
    /**
     * 
     * @summary получение описания проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDescriptionApi
     */
    public projectdescriptionGet(options?: AxiosRequestConfig) {
        return ProjectDescriptionApiFp(this.configuration).projectdescriptionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary добавление описание проекта
     * @param {SetProjectDescriptionContentForm} [setProjectDescriptionContentForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDescriptionApi
     */
    public projectdescriptionPost(setProjectDescriptionContentForm?: SetProjectDescriptionContentForm, options?: AxiosRequestConfig) {
        return ProjectDescriptionApiFp(this.configuration).projectdescriptionPost(setProjectDescriptionContentForm, options).then((request) => request(this.axios, this.basePath));
    }
}


