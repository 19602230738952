import React from "react";
import DatePicker from "../DatePicker";
import { IDatePickerProps } from "../DatePicker/index.types";

interface IDateTimePickerProps extends IDatePickerProps {}

const DateTimePicker: React.FC<IDateTimePickerProps> = (props) => {
  return (
    <DatePicker
      {...props}
      showTimeInput
      dateFormat="dd.MM.yyyy HH:mm"
      dateMaskOptions={{ mask: "00.00.0000 00:00" }}
    />
  );
};

export default DateTimePicker;
