import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import NewsNavigator from "newApp/components/NewsNavigator";
import RubricsList from "newApp/components/RubricsList";
import SiteNavigator from "newApp/components/SiteNavigator";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import { URL_SITE_NEWS_RUBRICS_CREATE } from "newApp/routing/urls";
import { RubricsListPageStore } from "newApp/stores/pages/site/rubrics/RubricsListPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const store = new RubricsListPageStore();

const RubricsListPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Рубрики новостей</H1>
      <FlexColumn>
        <SiteNavigator />
        <NewsNavigator />
        <Button onClick={() => push(URL_SITE_NEWS_RUBRICS_CREATE)}>
          Добавить рубрику
        </Button>
        {store.isLoadings.getRubrics ? (
          <LoaderSpinner />
        ) : (
          <RubricsList {...store} />
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(RubricsListPage);
