import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { RubricFormStore } from "newApp/stores/common/RubricFormStore";

export class RubricsCreatePageStore {
  isLoadings = { createRubric: false };

  rubricForm = new RubricFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {
    this.rubricForm.reset();
  };

  onClickCreate = () => {
    if (this.rubricForm.form.isValid) {
    }
  };

  createRubric = () => {
    this.isLoadings.createRubric = true;
    return API.administration.NewsRubrics.newsRubricPost({
      title: String(this.rubricForm.form.values.title),
      isActive: true,
    }).finally(() => {
      runInAction(() => {
        this.isLoadings.createRubric = false;
      });
    });
  };

  get isValid() {
    return this.rubricForm.form.isValid;
  }

  validate = () => {
    this.rubricForm.validate();
  };
}

export default new RubricsCreatePageStore();
