import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import {
  TCheckboxeOption,
  TCheckboxesValue,
  TValues,
} from "newApp/kit/components/Checkboxes/index.types";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";

export class AnalyticsAcceptedFilterStore {
  isLoadings = { getCategories: false };

  form = new FormStore({
    period: null,
    beginDate: null,
    endDate: null,
  });
  categoryIds: string[] = [];

  options: {
    period: ISelectOption[];
    category: TCheckboxeOption[];
  } = {
    period: [
      {
        text: "Все время",
        value: null,
      },
      {
        text: "Текущая неделя",
        value: "week",
      },
      {
        text: "Текущий месяц",
        value: "month",
      },
      {
        text: "Текущий год",
        value: "year",
      },
      {
        text: "Выбрать период",
        value: "custom",
      },
    ],
    category: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getCategories();
  };

  reset = () => {
    this.form.reset();
    this.categoryIds = [];
  };

  getCategories = () => {
    this.isLoadings.getCategories = true;
    return API.admin.ViolationAnalytics.apiReportsListRootCategoriesGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.category = (data as any).map((i) => ({
            text: i.name,
            value: i.id,
          }));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getCategories = false;
        });
      });
  };

  get dates(): {
    beginDate?: string;
    endDate?: string;
  } {
    switch (this.form.values.period) {
      case null:
        return {
          beginDate: undefined,
          endDate: undefined,
        };
      case "week":
        const beginDateWeek = new Date();
        beginDateWeek.setDate(beginDateWeek.getDate() - 7);
        return {
          beginDate: new Date(beginDateWeek).toISOString(),
          endDate: new Date().toISOString(),
        };
      case "month":
        const beginDateMonth = new Date();
        beginDateMonth.setDate(beginDateMonth.getDate() - 30);
        return {
          beginDate: new Date(beginDateMonth).toISOString(),
          endDate: new Date().toISOString(),
        };
      case "year":
        const beginYear = new Date();
        beginYear.setDate(beginYear.getDate() - 365);
        return {
          beginDate: new Date(beginYear).toISOString(),
          endDate: new Date().toISOString(),
        };
      case "custom":
        return {
          beginDate: new Date(this.form.values.beginDate).toISOString(),
          endDate: new Date(this.form.values.endDate).toISOString(),
        };
      default:
        return {
          beginDate: undefined,
          endDate: undefined,
        };
    }
  }

  onChangeCheckboxes = (
    value: TCheckboxesValue,
    name?: string,
    checkedValues?: TValues[]
  ) => {
    this.categoryIds = checkedValues as any;
  };
}
