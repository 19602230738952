import {
  ICascadeCheckboxesOptionRecursive,
  ICascadeCheckboxesOption,
} from "../index.types";

export const createCascade = <V>(
  options: ICascadeCheckboxesOption<V>[]
): ICascadeCheckboxesOptionRecursive<V>[] => {
  let results: ICascadeCheckboxesOptionRecursive<V>[] = [];
  options.forEach((option) => {
    if (!option.parentId)
      results.push({
        id: option.id,
        text: option.text,
        parentId: null,
        options: [],
      });
  });

  const parser = (results: ICascadeCheckboxesOptionRecursive<V>[]) => {
    results.forEach((result) => {
      options.forEach((option) => {
        if (
          typeof option.parentId === typeof result.id &&
          String(option.parentId) === String(result.id)
        ) {
          result.options?.push({
            id: option.id,
            text: option.text,
            parentId: option.parentId,
            options: [],
          });
        }
      });
      if (Array.isArray(result.options)) parser(result.options);
    });
  };

  parser(results);

  return results;
};
