import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { FormStore } from "newApp/kit/stores/FormStore";
import { DeclarationChatStore } from "../../../common/DeclarationChatStore";

import { DeclarationHistoryStore } from "../../../common/DeclarationHistoryStore";
import { IDeclarationStep, TDeclarationInfo } from "./index.types";
import { TGetDeclarationResponseData } from "./responseExample";

const defaultInfo: TDeclarationInfo = {
  address: null,
  dateOfCreation: null,
  daysLeft: null,
  email: null,
  firstName: null,
  isOnAddControl: null,
  isPublished: null,
  lastName: null,
  mobile: null,
  number: null,
  organizationTitle: null,
  subCategories: null,
  status: null,
  latitude: null,
  longitude: null,
  id: null,
  category: null,
};

export class DeclarationModerationItemPageStore {
  isLoadings = {
    getDeclaration: false,
  };
  id: string = "";

  info: TDeclarationInfo = defaultInfo;
  photoIds: string[] = [];
  form = new FormStore({
    rejectReason: null,
  });

  history = new DeclarationHistoryStore();

  steps: IDeclarationStep[] = [];

  chat = new DeclarationChatStore();

  files: IFilesPreviewFile[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.history.init(id);
    this.chat.init(id);
    this.getDeclaration();
    this.makeDeclarationChecked();
  };

  reset = () => {
    this.id = "";
    this.chat.reset();
    this.history.reset();
  };

  resetInfo = () => {
    this.info = defaultInfo;
  };

  getDeclaration = () => {
    this.isLoadings.getDeclaration = true;
    return API.admin.ViolationExecution.apiViolationExecutionViolationsIdGet(
      this.id
    )
      .then((res) => {
        runInAction(() => {
          try {
            const data = res.data as TGetDeclarationResponseData;

            this.info.id = data.id;
            this.info.category = data.category;
            this.info.address = data.address || null;
            this.info.dateOfCreation = data.dateOfCreation
              ? new Date(data.dateOfCreation).toLocaleDateString()
              : null;
            this.info.daysLeft = data.daysLeft || null;
            this.info.email = data.author?.email || null;
            this.info.firstName = data.author?.firstName || null;
            this.info.isOnAddControl = data.isOnAddControl || null;
            this.info.isPublished = data.isPublished || null;
            this.info.lastName = data.author?.lastName || null;
            this.info.mobile = data.author?.mobile || null;
            this.info.number = data.number || null;
            this.info.organizationTitle = data.organizationTitle || null;
            this.info.status = data.status || null;
            this.info.subCategories = data.subCategories
              ? data.subCategories.join(", ")
              : null;
            this.info.latitude = data.coordinates.latitude || null;
            this.info.longitude = data.coordinates.longitude || null;

            this.files = [];
            data.photoIds.forEach((id) => {
              this.files.push({
                reference: URL_API_ADMIN + "/api/violations/file?fileId=" + id,
                type: "Image",
                id: id,
              });
            });
            data.videoIds.forEach((id) => {
              this.files.push({
                reference: URL_API_ADMIN + "/api/violations/file?fileId=" + id,
                type: "Video",
                id: id,
              });
            });
            data.docIds.forEach((id) => {
              this.files.push({
                reference: URL_API_ADMIN + "/api/violations/file?fileId=" + id,
                id: id,
              });
            });

            this.steps = data.steps;
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch(() => {
        this.resetInfo();
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getDeclaration = false;
        });
      });
  };

  onClickModerate = () => {
    const newStatus = "Moderate";
    return API.admin.Violations.apiViolationsIdChangestatusPost(
      this.id,
      newStatus
    ).finally(() => {
      setTimeout(() => {
        this.getDeclaration();
      }, 1000);
    });
  };

  onClickPublish = () => {
    return API.admin.Violations.apiViolationsIdPublishPost(this.id).finally(
      () => this.getDeclaration()
    );
  };

  onClickAdditionalControl = () => {
    return API.admin.Violations.apiViolationsAdditionalControlPost(
      this.id
    ).finally(() => this.getDeclaration());
  };

  makeDeclarationChecked = () => {
    return API.admin.ViolationExecution.apiViolationExecutionIdCheckPost(
      this.id
    );
  };

  onReject = () => {
    return API.admin.Violations.apiViolationsIdRejectPost(
      String(this.id),
      String(this.form.values.rejectReason)
    )
      .then(() => true)
      .catch(() => false);
  };
}
