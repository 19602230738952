import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";
import Tabs from "../../kit/components/Tabs";
import { URL_ADMISSION_ROLES, URL_ADMISSION_USERS } from "../../routing/urls";
import { useLocation } from "react-router-dom";

const AdmissionTabs: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const tabs = [
    {
      title: "Пользователи",
      onClick: () => push(URL_ADMISSION_USERS),
      isActive: pathname === URL_ADMISSION_USERS,
    },
    {
      title: "Роли",
      onClick: () => push(URL_ADMISSION_ROLES),
      isActive: pathname === URL_ADMISSION_ROLES,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(AdmissionTabs);
