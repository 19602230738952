import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../../../api";

export class UserForm {
  isLoadings = {
    activate: false,
    deactivate: false,
    getUser: false,
  };

  data: TUserData = {
    lastName: null,
    firstName: null,
    middleName: null,
    email: null,
    phone: null,
    otherPhones: null,
    login: null,
    password: null,
    passwordConfirm: null,
    isDeactivated: null,
  };

  organizations: TUserOrganization[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.getUser(id);
  };

  reset = () => {
    Object.keys(this.data).forEach((key) => {
      this.data = { ...this.data, [key]: null };
    });
    this.organizations = [];
  };

  getUser = (id: string) => {
    this.isLoadings.getUser = true;
    return API.access.Users.apiUsersIdGet(id)
      .then((res: any) => {
        runInAction(() => {
          try {
            this.data = (res.data! as TData)?.data;
            this.organizations =
              (res.data! as TData)?.credentials?.organizations || [];
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch(() => {
        this.reset();
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getUser = false;
        });
      });
  };

  get fullName(): string {
    let fullName = "";
    if (this.data.lastName) fullName += this.data.lastName;
    if (this.data.firstName) fullName += ` ${this.data.firstName}`;
    if (this.data.middleName) fullName += ` ${this.data.middleName}`;
    return fullName;
  }

  activate = (id: string) => {
    this.isLoadings.activate = true;
    return API.access.Users.apiUsersActivateGet(id)
      .then(() => {
        this.getUser(id);
      })
      .catch()
      .finally(() => {
        runInAction(() => {
          this.isLoadings.activate = false;
        });
      });
  };

  deactivate = (id: string) => {
    this.isLoadings.deactivate = true;
    return API.access.Users.apiUsersDeactivateGet(id)
      .then(() => {
        this.getUser(id);
      })
      .catch()
      .finally(() => {
        runInAction(() => {
          this.isLoadings.deactivate = false;
        });
      });
  };
}

export type TUserData = {
  lastName: string | null;
  firstName: string | null;
  middleName: string | null;
  email: string | null;
  phone: string | null;
  otherPhones: string | null;
  login: string | null;
  password: string | null;
  passwordConfirm: string | null;
  isDeactivated: boolean | null;
};

export type TData = {
  id: string;
  data: TUserData;
  credentials: {
    organizations: TUserOrganization[];
  };
};

export type TUserOrganization = {
  id: string;
  organizationTitle: string;
  roles: TUserRole[];
};

export type TUserRole = {
  id: string;
  roleTitle: string;
};
