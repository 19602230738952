import jwtDecode from "jwt-decode";
import { makeAutoObservable, runInAction } from "mobx";

import { API_AUTH_WITHOUT_INTERCEPTOR } from "../../../api/auth";
import { LocalStorage } from "../LocalStorage";

export type TRefreshToken = { token: string; expirationDate: number };

class TokenStore {
  private storage = new LocalStorage();
  private isNowTokenRefresh: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get accessToken(): string | null {
    const token = this.storage.accessToken;
    return token || null;
  }

  set accessToken(token: string | null) {
    this.storage.accessToken = String(token);
  }

  get refreshToken(): TRefreshToken | null {
    const token = this.storage.accessToken;
    const expirationDate = this.storage.refreshTokenExpiration;
    if (token) {
      return {
        token: String(token),
        expirationDate: Number(expirationDate),
      };
    } else {
      return null;
    }
  }

  set refreshToken(token: { token: string; expirationDate: number } | null) {
    if (token) {
      this.storage.refreshToken = String(token.token);
      this.storage.refreshTokenExpiration = Number(token?.expirationDate);
    }
  }

  refreshTokens = () => {
    this.isNowTokenRefresh = true;
    const token = String(this.storage.refreshToken);
    return API_AUTH_WITHOUT_INTERCEPTOR.apiAisaeRefreshPost(token)
      .then((res) => {
        runInAction(() => {
          this.accessToken = (res.data as any).accessToken;
          this.refreshToken = (res.data as any).refreshToken;
        });
      })
      .catch(() => {
        this.clearTokens();
      })
      .finally(() => {
        this.isNowTokenRefresh = false;
      });
  };

  get tokenIsExpired(): boolean {
    const decodedToken = jwtDecode(this.storage.accessToken);
    const tokenExpiration: number | null = Number(decodedToken["exp"]) || null;
    return Date.now() + 30000 > tokenExpiration * 1000;
  }

  checkAndRefreshTokens = () => {
    if (this.isRefreshExpired) return this.clearTokens();
    const sleep = (ms: number = 1000) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    if (this.refreshToken && this.tokenIsExpired) {
      if (this.isNowTokenRefresh) return sleep(3000);
      return this.refreshTokens();
    }
  };

  get isRefreshExpired() {
    const currentStamp = Date.now();
    const expiration = Number(this.storage.refreshTokenExpiration) * 1000;
    return currentStamp > expiration;
  }

  clearTokens = () => {
    this.storage.accessToken = null;
    this.storage.refreshToken = null;
    this.storage.refreshTokenExpiration = null;
  };
}

export default new TokenStore();
