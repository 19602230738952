import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";
import { VotingsListItem } from "./VotingsListItem";

export class VotingsPageStore {
  pagination = new PaginationStore(undefined, () => this.getVotings());
  isLoading = false;
  mode: TVotesPageMode = "announcement";

  mainVote: null | {
    id: string;
    title: string;
  } = null;

  constructor() {
    makeAutoObservable(this);
  }

  votings: VotingsListItem[] = [];

  init = (mode: TVotesPageMode) => {
    this.pagination.reset();
    this.mode = mode;
    this.getVotings();
  };

  reset = () => {
    this.resetVotings();
  };

  resetVotings = () => {
    this.votings = [];
  };

  getVotings = () => {
    this.resetVotings();
    if (this.mode === "announcement") return this.getVotingsAnnouncement();
    if (this.mode === "active") return this.getVotingsAvailable();
    if (this.mode === "completed") return this.getVotingsCompleted();
  };

  private getVotingsAvailable = () => {
    this.isLoading = true;
    return API.admin.Vote.apiVotesAvailableGet(
      this.pagination.currentPage,
      this.pagination.pageSize
    )
      .then(({ data }) => {
        runInAction(() => {
          this.setResponseData(data);
        });
      })
      .catch(this.resetVotings)
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  private getVotingsCompleted = () => {
    this.isLoading = true;
    return API.admin.Vote.apiVotesCompletedGet(
      this.pagination.currentPage,
      this.pagination.pageSize
    )
      .then(({ data }) => {
        runInAction(() => {
          this.setResponseData(data);
        });
      })
      .catch(this.resetVotings)
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  private getVotingsAnnouncement = () => {
    this.isLoading = true;
    return API.admin.Vote.apiVotesAnnouncementGet(
      this.pagination.currentPage,
      this.pagination.pageSize
    )
      .then(({ data }) => {
        runInAction(() => {
          this.setResponseData(data);
        });
      })
      .catch(this.resetVotings)
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  private setResponseData = (data: any) => {
    runInAction(() => {
      try {
        this.votings = data["votes"].map(
          (i) =>
            new VotingsListItem(
              {
                id: String(i["id"]),
                title: String(i["title"]),
                isBanner: !!i["isBanner"],
                isMain: !!i["isImportant"],
                isPublish: !!i["isActive"],
                startDate: new Date(i["startDateTime"]),
                finishDate: new Date(i["endDateTime"]),
              },
              this
            )
        );
        this.pagination.pages = Math.ceil(
          Number(data?.count) / Number(this.pagination.pageSize)
        );
        this.mainVote = {
          id: String(data?.mainVoteId),
          title:
            data?.votes?.find((i) => i.id === String(data?.mainVoteId))
              ?.title || "",
        };
      } catch (error: any) {
        console.error(error);
      }
    });
  };
}

export type TVotesPageMode = "announcement" | "active" | "completed";
