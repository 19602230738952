import { observer } from "mobx-react";
import Divider from "newApp/kit/components/Divider";
import Popup from "newApp/kit/components/Popup";
import TextArea from "newApp/kit/components/TextArea";
import { URL_MODERATION_CREATED } from "newApp/routing/urls";
import { DeclarationModerationItemPageStore } from "newApp/stores/pages/moderation/DeclarationModerationItemPageStore";
import React, { useState } from "react";
import { useHistory } from "react-router";

import Button from "../../kit/components/Button";
import ButtonsContainer from "../../kit/components/ButtonsContainer";
import FlexColumn from "../../kit/components/FlexColumn";
import DeclarationInfo from "../DeclarationInfo";
import FilesPreview from "../FilesPreview";
import StepsContainer from "../StepsContainer";
import { IStepContainerItem } from "../StepsContainer/index.types";

interface IProps extends DeclarationModerationItemPageStore {}

const DeclarationItemInfo: React.FC<IProps> = (props) => {
  const [isShowRejectPopup, setIsShowRejectPopup] = useState(false);

  const { push } = useHistory();

  const steps: IStepContainerItem[] = [
    {
      name: "Данные заявителя",
      body: (
        <FlexColumn>
          <DeclarationInfo info={props.info} />
          <FilesPreview files={props.files} />
        </FlexColumn>
      ),
      number: " ",
      isComplete: true,
    },
  ];
  props.steps.forEach((step, idx) => {
    steps.push({ name: step.name, isComplete: !!step.isDone, number: idx + 1 });
  });

  const onClickReject = async () => {
    if (await props.onReject()) push(URL_MODERATION_CREATED);
  };

  return (
    <div>
      <FlexColumn>
        <ButtonsContainer>
          {props.info.status === "created" && (
            <>
              <Button onClick={props.onClickModerate}>Промодерировать</Button>
              <Button onClick={() => setIsShowRejectPopup(true)}>
                Отказать
              </Button>
            </>
          )}
          <Button onClick={props.onClickPublish}>
            {props.info.isPublished ? "Снять с публикации" : "Опубликовать"}
          </Button>

          <Button onClick={props.onClickAdditionalControl}>
            {props.info.isOnAddControl
              ? "Снять с доп. контроля"
              : "На доп. контроль"}
          </Button>
        </ButtonsContainer>
        <Divider color="grey" />
        <StepsContainer steps={steps} />
      </FlexColumn>

      <Popup
        isOpen={isShowRejectPopup}
        onClose={() => setIsShowRejectPopup(false)}
        title="Укажите причину отказа"
      >
        <FlexColumn>
          <TextArea
            placeholder="Причина отказа"
            minRows={6}
            {...props.form.adapters.rejectReason}
          />
          <ButtonsContainer>
            <Button onClick={onClickReject} variant="danger">
              Отказать
            </Button>
            <Button
              onClick={() => setIsShowRejectPopup(false)}
              variant="secondary"
            >
              Отмена
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      </Popup>
    </div>
  );
};

export default observer(DeclarationItemInfo);
