import { observer } from "mobx-react";
import DatePicker from "newApp/kit/components/DatePicker";
import Dropzone from "newApp/kit/components/Dropzone";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import Select from "newApp/kit/components/Select";
import TextField from "newApp/kit/components/TextField";
import { FaqFormStore } from "newApp/stores/common/FaqFormStore";
import React from "react";
import FilesPreview from "../FilesPreview";
import TextEditor from "../TextEditor";

export interface IFaqFormProps extends Omit<FaqFormStore, "isValid"> {}

const FaqForm: React.FC<IFaqFormProps> = (props) => {
  return (
    <FlexColumn>
      <TextField placeholder="Вопрос" {...props.form.adapters.question} />
      <Select
        placeholder="Рубрика"
        {...props.form.adapters.rubricId}
        options={props.options.rubrics}
      />
      <DatePicker placeholder="Дата" {...props.form.adapters.date} />
      <div>
        <H3>Ответ</H3>
        <TextEditor
          onChange={(value) => props.form.changeValueByKey(value, "answer")}
          value={props.form.values.answer}
          errorMessage={props.form.errors.answer}
        />
      </div>
      <H3>Приложения</H3>
      <FilesPreview
        files={props.filesPreviews}
        onClickRemove={props.onClickRemove}
      />
      <Dropzone onAddFiles={props.onAddFiles} maxFileSize={15728640} />
    </FlexColumn>
  );
};

export default observer(FaqForm);
