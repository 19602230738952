import React from "react";
import "./styles.scss";

interface IProps {
  children: React.ReactNode;
}

const H2: React.FC<IProps> = ({ children }) => {
  return <h2 className="h2">{children}</h2>;
};

export default H2;
