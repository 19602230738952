import { observer } from "mobx-react";
import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";

interface IProps {
  to: string;
}

const Link: React.FC<IProps> = (props) => {
  return (
    <NavLink className="link" to={props.to} activeClassName="link--active">
      {props.children}
    </NavLink>
  );
};

export default observer(Link);
