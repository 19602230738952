import { makeAutoObservable } from "mobx";
import { ModerationDeclarationListStore } from "newApp/stores/pages/moderation/ModerationListCreatedPageStore/ModerationDeclarationListStore";

export class ModerationListCreatedPageStore {
  declarations = new ModerationDeclarationListStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.declarations.init();
  };

  reset = () => {
    this.declarations.reset();
  };
}
