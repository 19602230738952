import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FormStore } from "newApp/kit/stores/FormStore";
import { ProductFormMainPhotoStore } from "./ProductFormMainPhotoStore";
import formValidationSchema from "./formValidationSchema";

import { IRadioButtonOption } from "newApp/kit/components/RadioButtons/index.types";
import {
  TCheckboxeOption,
  TCheckboxesValue,
  TValues,
} from "newApp/kit/components/Checkboxes/index.types";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
// import _debounce from "lodash.debounce";

export class ProductFormStore {
  isLoadings = {
    getCategoriesOptions: false,
    coordsIsLoading: false,
  };

  form = new FormStore(
    {
      title: null,
      description: null,
      price: null,
      quantity: 1,
      //   wayToGet: null,
      date: null,
      categories: null,
      //   type: null,
      organizationId: null,
    },
    formValidationSchema
  );

  currentType: string = "Goods";

  options: {
    type: IRadioButtonOption[];
    categories: TCheckboxeOption[];
    organizations: (ISelectOption & {
      coords: ProductFormStore["orgCoords"];
    })[];
  } = {
    type: [
      {
        text: "Товар",
        value: "Goods",
      },
      {
        text: "Услуга",
        value: "Service",
      },
    ],
    categories: [],
    organizations: [],
  };

  arrays: { categories: TCheckboxesValue[] } = {
    categories: [],
  };

  orgCoords = {
    latitude: 56.8339325,
    longitude: 60.6231973,
  };

  mainPhoto = new ProductFormMainPhotoStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getCategoriesOptions();
    this.getOrganizations();
    // this.form.changeValueByKey(new Date(), "date");
    // this.form.changeValueByKey("Goods", "type");
  };

  reset = () => {
    this.form.reset();
    this.mainPhoto.reset();
    this.resetCategories();
    this.currentType = "Goods";
    this.orgCoords = {
      latitude: 56.8339325,
      longitude: 60.6231973,
    };
  };

  resetCategories = () => {
    this.options.categories = [];
    this.arrays.categories = [];
  };

  getCategoriesOptions = () => {
    this.isLoadings.getCategoriesOptions = true;

    API.shop.Categories.apiCategoriesGet().then(({ data }) => {
      runInAction(() => {
        this.options.categories = data["items"]
          .filter((item) => item.isActive === true)
          .map((item) => ({
            text: item.title || null,
            value: item.id || null,
          }));
      });
    });
  };

  onChangeCheckboxes = (_: any, __: any, checkedValues?: TValues[]) => {
    this.arrays.categories = checkedValues;
  };

  onChangeTypes = (value: string) => {
    this.currentType = value;
    // this.form.changeValueByKey(value, "type");
  };

  onChangeOrganization = (value: string, name: string) => {
    this.form.adapters.organizationId.onChange(value, name);
    this.setCoords(
      this.options.organizations.find((org) => org.value === value).coords
    );
  };

  validate = async () => {
    await this.form.validateAll();
  };

  get isValid() {
    return this.form.isValid;
  }

  getOrganizations = async () => {
    try {
      const { data } = await API.shop.Departments.apiDepartmentsGet();
      runInAction(() => {
        this.options.organizations = data.map((org) => ({
          text: org.fullName || org.shortName,
          value: org.id,
          coords: org.coordinates as Required<typeof org.coordinates>,
        }));

        this.setCoords(
          data.find((org) => org.id === this.form.values.organizationId)
            ?.coordinates
        );
      });
    } catch (error) {
      runInAction(() => (this.options.organizations = []));
    }
  };

  setCoords = (coords?: { latitude?: number; longitude?: number }) => {
    if (coords && coords.latitude && coords.longitude) {
      this.orgCoords = {
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
    }
  };
}
