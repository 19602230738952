import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Map from "../Map";
import { IMapMarker } from "../Map/index.types";
import { IDeclarationsMapProps } from "./index.types";
import "./styles.scss";

const DeclarationsMap: React.FC<IDeclarationsMapProps> = (props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const markers: IMapMarker[] = props?.declarations?.map((d) => ({
    id: d.id,
    position: d.location,
    popup: (
      <div className="map-popup" onClick={() => push(pathname + `/${d.id}`)}>
        <div>
          <span>Номер: </span>
          <span>{d.number}</span>
        </div>
        <div>
          <span>Дата: </span>
          <span>{d.date}</span>
        </div>
        <div>
          <span>Категория: </span>
          <span>{d.category}</span>
        </div>
        <div>
          <span>Подкатегория: </span>
          <span>{d.subCategory}</span>
        </div>
        <div>
          <span>Дней осталось: </span>
          <span>{d.daysLeft}</span>
        </div>
      </div>
    ),
  }));

  return (
    <div className="declarations-map">
      <Map markers={markers} />
    </div>
  );
};

export default observer(DeclarationsMap);
