import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router";

import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";

import { ICategory } from "newApp/stores/pages/shop/categories/CategoriesPageStore";

import { URL_SHOP_CATEGORIES } from "newApp/routing/urls";

import DashedButton from "../DashedButton";
import "./styles.scss";

export interface ICategoriesListProps {
  categoriesList: ICategory[];
  onClickDelete: (id: string) => void;
  toggleIsActive: (isActive: boolean, id: string) => void;
}

const CategoriesList: React.FC<ICategoriesListProps> = (props) => {
  const { push } = useHistory();

  return (
    <div className="categories-list">
      {props.categoriesList.map((category) => (
        <div
          className={[
            "categories-list__item",
            category.isActive ? "categories-list__item--active" : "",
          ].join(" ")}
          key={category.id}
        >
          <FlexColumn>
            <div className="categories-list__item-header">
              <div className="categories-list__item-header-title">
                {category.title}
              </div>
              <div className="categories-list__item-header-buttons">
                <DashedButton
                  onClick={() =>
                    push(URL_SHOP_CATEGORIES + `/${category.id}/edit`)
                  }
                >
                  Редактировать
                </DashedButton>
                <DashedButton
                  color="red"
                  onClick={() => {
                    window.confirm(
                      `Вы точно хотите удалить данную категорию?`
                    ) && props.onClickDelete(category.id);
                  }}
                >
                  Удалить
                </DashedButton>
              </div>
            </div>
            <Divider color="grey" />
            <div className="categorys-list__item-controls">
              <Checkbox
                value={category.isActive}
                onChange={() =>
                  props.toggleIsActive(category.isActive, category.id)
                }
              >
                {category.isActive ? "Активно" : "Не активно"}
              </Checkbox>
            </div>
          </FlexColumn>
        </div>
      ))}
    </div>
  );
};

export default observer(CategoriesList);
