import * as yup from "yup";
import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "newApp/constants/validation/validators";

export default yup.object().shape({
  fileId: VALIDATOR_ANY,
  hasPhoto: VALIDATOR_ANY,
  id: VALIDATOR_ANY,
  text: VALIDATOR_STRING_REQUIRED,
  files: VALIDATOR_ANY,
});
