import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Link from "../Link";
import "./styles.scss";

interface IProps {
  links: { text: string; to: string }[];
}

const HeaderNavigationMenu: React.FC<IProps> = (props) => {
  useEffect(() => {}, []);

  return (
    <div className="navigation-menu">
      {props.links.map((link) => (
        <Link to={link.to} key={link.text}>
          {link.text}
        </Link>
      ))}
    </div>
  );
};

export default observer(HeaderNavigationMenu);
