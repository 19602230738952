import { observer } from "mobx-react";
import React from "react";
import ListModeTabs from "../ListModeTabs";
import ModerationTabs from "../ModerationTabs";
import NewMessagesCountBadge from "../NewMessagesCountBadge";
import "./styles.scss";

export interface IModerationNavigatorProps {
  newCreateMessageCount?: number | null;
  isHasNewMessagesInCreated?: boolean;
  isHasNewMessagesInWork?: boolean;
}

const ModerationNavigator: React.FC<IModerationNavigatorProps> = (props) => {
  return (
    <div className="moderation-navigator">
      <ListModeTabs />
      <ModerationTabs
        newMessage={
          !!props.newCreateMessageCount && (
            <NewMessagesCountBadge count={props.newCreateMessageCount} />
          )
        }
        isHasNewMessagesInCreated={props.isHasNewMessagesInCreated}
        isHasNewMessagesInWork={props.isHasNewMessagesInWork}
      />
    </div>
  );
};

export default observer(ModerationNavigator);
