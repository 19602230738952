import "./styles.scss";

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import LoaderSpinner from "../../../kit/components/LoaderSpinner";
import {
  URL_ADMINISTRATION_STEPS,
  URL_ADMISSION_USERS,
  URL_ANALYTICS_INWORK,
  URL_EXECUTION_ACCEPTED,
  URL_LOGIN,
  URL_MODERATION_CREATED,
  URL_SHOP_PRODUCTS,
  URL_SITE_NEWS,
  URL_VOTING_ACTIVE,
} from "../../../routing/urls";
import AuthStore from "../../../stores/common/AuthStore";
import ProfileStore from "../../../stores/common/ProfileStore";
import Header from "../../Header";

interface IProps {
  isLoading?: boolean;
  isNotScrollOnTop?: boolean;
}

const MainLayout: React.FC<IProps> = (props) => {
  const { push } = useHistory();
  const links = [
    { text: "Модерирование", to: URL_MODERATION_CREATED },
    { text: "Исполнение", to: URL_EXECUTION_ACCEPTED },
    { text: "Голосования", to: URL_VOTING_ACTIVE },
    { text: "Сайт", to: URL_SITE_NEWS },
    { text: "Администрирование", to: URL_ADMINISTRATION_STEPS },
    { text: "Доступ", to: URL_ADMISSION_USERS },
    { text: "Аналитика", to: URL_ANALYTICS_INWORK },
    { text: "Магазин поощрений", to: URL_SHOP_PRODUCTS },
  ];

  const onLogout = () => {
    AuthStore.logOut();
    push(URL_LOGIN);
  };

  useEffect(() => {
    if (!props.isNotScrollOnTop)
      window.scrollTo({ behavior: "smooth", top: 0 });
  }, [props.isNotScrollOnTop]);

  useEffect(() => {
    AuthStore.checkAuth();
    if (!AuthStore.isAuth) push(URL_LOGIN);
    ProfileStore.checkProfile();
  }, [push]);

  return (
    <div className="main-layout">
      <Header
        links={links}
        isAuth={AuthStore.isAuth}
        userName={ProfileStore.userName}
        onLogout={onLogout}
        headerTitle="Активный Екатеринбург - Панель администрирования"
      />
      {props.isLoading ? (
        <LoaderSpinner />
      ) : (
        <div className="container">{props.children}</div>
      )}
    </div>
  );
};

export default observer(MainLayout);
