export const getRuDate = (date?: string): string | null => {
  if (!date) return null;

  const today = new Date();
  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday = new Date(yesterday);

  if (today.toLocaleDateString() === new Date(date).toLocaleDateString()) {
    return "Сегодня";
  }
  if (yesterday.toLocaleDateString() === new Date(date).toLocaleDateString()) {
    return "Вчера";
  }

  return new Date(date).toLocaleDateString();
};
