import React from "react";

export interface IIconPhotoProps {}

const IconPhoto: React.FC<IIconPhotoProps> = (props) => {
  return (
    <svg
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.6692 4.98551C37.8707 4.13527 36.7681 3.63285 35.5133 3.63285H29.2015V3.55556C29.2015 2.58937 28.8213 1.66184 28.1749 1.04348C27.5285 0.386473 26.654 0 25.7034 0H14.2966C13.308 0 12.4335 0.386473 11.7871 1.04348C11.1407 1.70048 10.7605 2.58937 10.7605 3.55556V3.63285H4.48669C3.23194 3.63285 2.12928 4.13527 1.3308 4.98551C0.532319 5.7971 0 6.95652 0 8.19324V27.4396C0 28.715 0.494297 29.8357 1.3308 30.6473C2.12928 31.4589 3.26996 32 4.48669 32H35.5133C36.7681 32 37.8707 31.4976 38.6692 30.6473C39.4677 29.8357 40 28.6763 40 27.4396V8.19324C40 6.91787 39.5057 5.7971 38.6692 4.98551ZM38.0228 27.4396H37.9848C37.9848 28.1353 37.7186 28.7536 37.2624 29.2174C36.8061 29.6812 36.1977 29.9517 35.5133 29.9517H4.48669C3.80228 29.9517 3.19392 29.6812 2.73764 29.2174C2.28137 28.7536 2.01521 28.1353 2.01521 27.4396V8.19324C2.01521 7.49758 2.28137 6.87923 2.73764 6.41546C3.19392 5.95169 3.80228 5.68116 4.48669 5.68116H11.8251C12.3954 5.68116 12.8517 5.21739 12.8517 4.63768V3.51691C12.8517 3.09179 13.0038 2.70531 13.27 2.43478C13.5361 2.16425 13.9164 2.00966 14.3346 2.00966H25.7034C26.1217 2.00966 26.5019 2.16425 26.7681 2.43478C27.0342 2.70531 27.1863 3.09179 27.1863 3.51691V4.63768C27.1863 5.21739 27.6426 5.68116 28.2129 5.68116H35.5513C36.2357 5.68116 36.8441 5.95169 37.3004 6.41546C37.7567 6.87923 38.0228 7.49758 38.0228 8.19324V27.4396Z"
        fill="white"
      />
      <path
        d="M20 8.30918C17.4144 8.30918 15.057 9.3913 13.384 11.0918C11.673 12.8309 10.6464 15.1884 10.6464 17.8164C10.6464 20.4444 11.711 22.8406 13.384 24.5411C15.0951 26.2802 17.4144 27.3237 20 27.3237C22.5856 27.3237 24.943 26.2415 26.616 24.5411C28.327 22.8019 29.3536 20.4444 29.3536 17.8164C29.3536 15.1884 28.289 12.7923 26.616 11.0918C24.943 9.3913 22.5856 8.30918 20 8.30918ZM25.1711 23.1111C23.8403 24.4251 22.0152 25.2754 20 25.2754C17.9848 25.2754 16.1597 24.4251 14.8289 23.1111C13.4981 21.7585 12.6996 19.9034 12.6996 17.8551C12.6996 15.8068 13.5361 13.9517 14.8289 12.599C16.1597 11.2464 17.9848 10.4348 20 10.4348C22.0152 10.4348 23.8403 11.285 25.1711 12.599C26.5019 13.9517 27.3004 15.8068 27.3004 17.8551C27.3384 19.9034 26.5019 21.7585 25.1711 23.1111Z"
        fill="white"
      />
      <path
        d="M33.5361 12.0579C34.5651 12.0579 35.3992 11.2101 35.3992 10.1642C35.3992 9.11834 34.5651 8.27049 33.5361 8.27049C32.5071 8.27049 31.673 9.11834 31.673 10.1642C31.673 11.2101 32.5071 12.0579 33.5361 12.0579Z"
        fill="white"
      />
    </svg>
  );
};

export default IconPhoto;
