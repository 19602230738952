import React from "react";
import { ICheckboxProps } from "./index.types";
import "./styles.scss";

const Checkbox: React.FC<ICheckboxProps> = ({
  onChange,
  disabled,
  value,
  name,
  title,
  children,
}) => {
  return (
    <label className={"checkbox"} title={title}>
      <input
        name={name}
        className="checkbox__input"
        type="checkbox"
        disabled={!!disabled}
        checked={!!value}
        onChange={() => onChange(!value, name)}
      />
      <span className="checkbox__mark" />
      <span className="checkbox__text">{children}</span>
    </label>
  );
};

export default Checkbox;
