import { observer } from "mobx-react";
import React from "react";
import { IExecutionStepProps } from "..";

const StepEmpty: React.FC<IExecutionStepProps> = (props) => {
  return (
    <div title={props.stepTypeId}>
      <div>Действия на этом шаге неизвестны</div>
      <div>{props.stepTypeId}</div>
    </div>
  );
};

export default observer(StepEmpty);
