import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router";

import ListModeSwitcher from "../ListModeSwitcher";

export const MODE = "mode";
export const MODE_LIST = "list";
export const MODE_MAP = "map";

const ListModeTabs: React.FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get(MODE);

  const tabs = [
    {
      title: "Список",
      onClick: () => push({ search: `${MODE}=${MODE_LIST}` }),
      isActive: mode === MODE_LIST || !mode,
    },
    {
      title: "Карта",
      onClick: () => push({ search: `${MODE}=${MODE_MAP}` }),
      isActive: mode === MODE_MAP,
    },
  ];

  return <ListModeSwitcher items={tabs} />;
};

export default observer(ListModeTabs);
