import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import Checkboxes from "newApp/kit/components/Checkboxes";
import DatePicker from "newApp/kit/components/DatePicker";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import TextField from "newApp/kit/components/TextField";
import { NewsFormStore } from "newApp/stores/common/NewsFormStore";
import React from "react";

import TextEditor from "../TextEditor";
import NewsFormFiles from "./NewsFormFiles";
import NewsFormMainPhoto from "./NewsFormMainPhoto";
import NewsFormPhotos from "./NewsFormPhotos";

export interface INewsFormProps extends Omit<NewsFormStore, "isValid"> {}

const NewsForm: React.FC<INewsFormProps> = (props) => {
  return (
    <FlexColumn>
      <TextField placeholder="Заголовок" {...props.form.adapters.title} />
      <DatePicker
        placeholder="Дата создания новости"
        {...props.form.adapters.date}
      />
      <Checkbox {...props.form.adapters.isBanner}>
        Разместить в баннере
      </Checkbox>
      <Checkbox {...props.form.adapters.isMain}>Главная новость</Checkbox>
      <NewsFormMainPhoto {...props.mainPhoto} />
      <H3>Текст новости</H3>
      <TextEditor {...props.form.adapters.content} />
      <div>
        <H3>Текст новости</H3>
        <Checkboxes
          onChange={props.onChangeCheckboxes}
          options={props.options.rubrics}
          values={props.arrays.rubrics}
        />
      </div>
      <NewsFormFiles {...props.files} />
      <NewsFormPhotos {...props.photos} />
    </FlexColumn>
  );
};

export default observer(NewsForm);
