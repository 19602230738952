import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import { history } from "./utils/history";
import NewApp from "./newApp/components/App";

ReactDOM.render(
  <Router history={history}>
    <NewApp />
  </Router>,
  document.getElementById("root")
);
