import { makeAutoObservable } from "mobx";

export class RubricListItemStore {
  form: IForm = {};

  constructor(form: IForm) {
    Object.entries(form).forEach(([key, value]) => {
      this.form[key] = value;
    });
    makeAutoObservable(this);
  }
}

interface IForm {
  id?: string;
  title?: string;
}
