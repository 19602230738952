import { observer } from "mobx-react";
import React, { useState } from "react";
import DashedButton from "../DashedButton";
import IconOk from "../icons/IconOk";
import "./styles.scss";

export interface ICategorySelectOption {
  text: React.ReactNode;
  value: any;
  level?: number;
}

export interface ICategorySelectProps {
  placeholder?: string;
  options?: ICategorySelectOption[];
  value: any;
  name?: string;
  onChange: (value: any, name?: string) => void;
}

const CategorySelect: React.FC<ICategorySelectProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption =
    props.options.find((i) => i.value === props.value) || null;

  return (
    <div className="category-select">
      {props.placeholder && (
        <div className="category-select__placeholder">{props.placeholder}</div>
      )}
      {isOpen ? (
        <div className="category-select__open">
          {!props?.options?.length && <div>Вариантов нет</div>}
          <div className="category-select__variants">
            {props.options?.map((o, idx) => {
              const isSelected = o.value === props.value;

              return (
                <div
                  className={[
                    "category-select__variants-item",
                    isSelected
                      ? "category-select__variants-item--selected"
                      : "",
                  ].join(" ")}
                  key={idx}
                  style={{ paddingLeft: `${o.level * 30}px` }}
                  onClick={() => {
                    props.onChange(o.value, props.name);
                    setIsOpen(false);
                  }}
                >
                  {isSelected && <IconOk />}
                  <div>{o.text}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="category-select__close">
          <div className="category-select__close-title">
            {selectedOption?.text || "Не выбрано"}
          </div>
          <div className="category-select__close-divider">—</div>
          <DashedButton onClick={() => setIsOpen(true)}>Изменить</DashedButton>
        </div>
      )}
    </div>
  );
};

export default observer(CategorySelect);
