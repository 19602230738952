import "./styles.scss";

import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import { URL_ADMINISTRATION_CATEGORIES } from "newApp/routing/urls";
import { ICategory } from "newApp/stores/pages/administrations/categories/CategoriesPageStore";
import React from "react";
import { useHistory } from "react-router";

import DashedButton from "../DashedButton";
import DividerVertical from "../DividerVertical";

export interface IOrganizationsTableProps {
  categories: ICategory[];
  changeItemActive: (id: string, state: number) => void;
  onDeleteItem: (id: string) => void;
}

const CategoriesTable: React.FC<IOrganizationsTableProps> = (props) => {
  const { push } = useHistory();

  return (
    <table className="categories-table">
      <thead className="categories-table__header">
        <tr className="categories-table__header-row">
          <td className="categories-table__header-cell">Наименование</td>
          <td className="categories-table__header-cell">Публикация</td>
        </tr>
      </thead>
      <tbody className="categories-table__body">
        {props.categories.map((i, idx) => (
          <tr
            className={[
              "categories-table__body-row",
              !!i.level ? "categories-table__body-row--bordered" : "",
            ].join(" ")}
            key={idx}
          >
            <td
              className={[
                "categories-table__body-cell",
                `categories-table__body-cell--level-${i.level}`,
                `categories-table__body-cell--clickable`,
              ].join(" ")}
              style={{ paddingLeft: `${i.level * 40}px` }}
              onClick={() =>
                push(URL_ADMINISTRATION_CATEGORIES + `/${i.id}/edit`)
              }
            >
              {i.name}{" "}
              {i.state === 2 ? (
                <span className="categories-table__body-cell--deactivated">
                  (Деактивировано)
                </span>
              ) : null}
            </td>
            <td className={"categories-table__body-cell"}>
              <Checkbox
                value={i.state === 1}
                onChange={() => props.changeItemActive(i.id, i.state)}
              >
                Активно
              </Checkbox>
            </td>
            <td
              className={[
                "categories-table__body-cell",
                "categories-table__body-cell--inline",
              ].join(" ")}
            >
              <DashedButton
                onClick={() =>
                  push(URL_ADMINISTRATION_CATEGORIES + `/${i.id}/edit`)
                }
              >
                Редактировать
              </DashedButton>
              <DividerVertical />
              <DashedButton
                onClick={() => props.onDeleteItem(i.id)}
                color="red"
                title={[
                  "Удалить категорию.",
                  "Если категория используется, она будет деактивирована.",
                ].join("\n")}
              >
                Удалить
              </DashedButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(CategoriesTable);
