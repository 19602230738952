import { observer } from "mobx-react";
import AdministrationNavigator from "newApp/components/AdministrationNavigator";
import MainLayout from "newApp/components/layouts/MainLayout";
import StepsTable from "newApp/components/StepsTable";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Pagination from "newApp/kit/components/Pagination";
import { URL_ADMINISTRATION_STEPS_CREATE } from "newApp/routing/urls";
import { StepsPageStore } from "newApp/stores/pages/administrations/steps/StepsPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new StepsPageStore();

const StepsPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Администрирование - Конструктор шагов</H1>
      <FlexColumn>
        <AdministrationNavigator />
        <Button onClick={() => push(URL_ADMINISTRATION_STEPS_CREATE)}>
          Добавить правило
        </Button>
        {store.isLoadings.getTemplates ? (
          <LoaderSpinner />
        ) : (
          <StepsTable {...store} />
        )}
        <Pagination {...store.pagination} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(StepsPage);
