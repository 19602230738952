import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";

import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import ProductForm from "newApp/components/ProductForm";
import ButtonBack from "newApp/components/ButtonBack";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";

import { ProductsEditPageStore } from "newApp/stores/pages/shop/products/ProductsEditPageStore";

import { URL_SHOP_PRODUCTS } from "newApp/routing/urls";
import { scrollToError } from "newApp/kit/utils/scrollToError";

const store = new ProductsEditPageStore();

const ProductEditPage: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  const moveToProductHandler = () => push(URL_SHOP_PRODUCTS + "/" + id);

  const saveProductHandler = async () => {
    const res = await store.onClickSaveProduct();
    if (!res) {
      scrollToError();
      return;
    }
    moveToProductHandler();
  };

  const disapproveProductHandler = () => {
    store.disapproveProduct().then(moveToProductHandler);
  };

  const isButtonsDisabled =
    store.isLoadingImage || store.isLoadings.disapproveProduct;

  return (
    <MainLayout>
      <H1>Редактирование товара/услуги</H1>
      {store.isLoading ? (
        <LoaderSpinner />
      ) : (
        <FlexColumn>
          <ButtonBack onClick={moveToProductHandler}>Назад к товару</ButtonBack>
          <ProductForm {...store.productForm} />
          <ButtonsContainer>
            {store.isEsk ? (
              <>
                <Button
                  onClick={saveProductHandler}
                  disabled={isButtonsDisabled}
                >
                  Утвержден
                </Button>
                <Button
                  onClick={disapproveProductHandler}
                  disabled={isButtonsDisabled}
                >
                  Не утверждён
                </Button>
              </>
            ) : (
              <Button onClick={saveProductHandler} disabled={isButtonsDisabled}>
                {store.isLoadingImage ? "Загрузка.." : "Сохранить"}
              </Button>
            )}
            <Button onClick={moveToProductHandler} variant="secondary">
              Отмена
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      )}
    </MainLayout>
  );
};

export default observer(ProductEditPage);
