import { observer } from "mobx-react";
import React from "react";
import { NavLink } from "react-router-dom";
import LogoIcon from "../../assets/images/png/logo-36.png";
import { URL_MAIN } from "../../routing/urls";
import "./styles.scss";

interface IProps {}

const HeaderLogo: React.FC<IProps> = () => {
  return (
    <NavLink className="header-logo" to={URL_MAIN}>
      <img className="header-logo__img" src={LogoIcon} alt="Logo" />
    </NavLink>
  );
};

export default observer(HeaderLogo);
