import React, { FC, useEffect, useState } from "react";
import CropperJS from "cropperjs";
import { getDataURL } from "../../utils/getDataURL";
import { compressImage } from "../../utils/compressImage";
import Modal from "../Modal";
import LoaderSpinner from "../LoaderSpinner";
import ResultView from "./ResultView";
import CropperView from "./CropperView";
import { ICropperProps } from "./index.types";
import { getCropBase64 } from "./getCropBase64";
import "./styles.scss";

const Cropper: FC<ICropperProps> = ({
  isOpen = true,
  sourceImage,
  onCropped,
  onClose,
  steps,
  cropperProps,
  keepFileType = true,
}) => {
  const [cropper, setCropper] = useState<CropperJS>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [sourceDataUrl, setSourceDataUrl] = useState<string>();
  const [results, setResults] = useState<string[]>([]);

  // load image as dataUrl
  useEffect(() => {
    if (sourceImage) {
      setIsLoading(true);
      compressImage(sourceImage)
        .then((compressed) => getDataURL(compressed))
        .then((dataUrl) => {
          setSourceDataUrl(dataUrl);
        })
        .finally(() => setIsLoading(false));
    }
  }, [sourceImage]);

  if (steps.length === 0) {
    return null;
  }

  const handleCropButtonClick = async () => {
    if (cropper) {
      try {
        const base64 = await getCropBase64(
          cropper,
          steps[currentStep].width,
          keepFileType ? sourceImage.type : undefined
        );
        setResults((prev) => [
          ...prev.slice(0, currentStep),
          base64,
          ...prev.slice(currentStep, -1),
        ]);
      } catch (error) {}
    }

    setIsPreviewMode(true);
  };

  const handleConfirmButtonClick = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
      setIsPreviewMode(false);
    } else {
      onCropped(results);
    }
  };

  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleRotateButtonClick = () => {
    cropper?.rotate(90);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="cropper"
      title={
        isPreviewMode
          ? steps[currentStep].title + " - Результат"
          : steps[currentStep].title
      }
    >
      {isLoading ? (
        <LoaderSpinner />
      ) : isPreviewMode ? (
        <ResultView
          previewSrc={results[currentStep]}
          onConfirm={handleConfirmButtonClick}
          onBack={() => setIsPreviewMode(false)}
        />
      ) : (
        <CropperView
          src={sourceDataUrl}
          aspectRatio={steps[currentStep].width / steps[currentStep].height}
          onInitialized={setCropper}
          onCropButtonClick={handleCropButtonClick}
          onCancelButtonClick={handleCancelButtonClick}
          onRotateButtonClick={handleRotateButtonClick}
          {...cropperProps}
        />
      )}
    </Modal>
  );
};

export default Cropper;
