import React from "react";

import RolesListPage from "../pages/admission/roles";
import RoleCreatePage from "../pages/admission/roles/create";
import RoleItemPage from "../pages/admission/roles/id";
import RoleEditPage from "../pages/admission/roles/id/edit";
import UsersListPage from "../pages/admission/users";
import UserCreatePage from "../pages/admission/users/create";
import UsersItemPage from "../pages/admission/users/id";
import UserItemEditPage from "../pages/admission/users/id/edit";
import ExecutionListPage from "../pages/execution";
import ExecutionItemPage from "../pages/execution/id";
import MainPage from "../pages/MainPage";
import ModerationListCreatedPage from "../pages/moderation";
import DeclarationModerationItemPage from "../pages/moderation/id";
import ProfilePage from "../pages/profile";
import VotingsPage from "../pages/voting";
import VoteItemPage from "../pages/voting/id";
import VoteCreatePage from "../pages/voting/create";
import VoteEditPage from "../pages/voting/id/edit";
import {
  URL_ADMISSION_ROLES,
  URL_ADMISSION_ROLES_CREATE,
  URL_ADMISSION_ROLES_ID,
  URL_ADMISSION_ROLES_ID_EDIT,
  URL_ADMISSION_USERS,
  URL_ADMISSION_USERS_CREATE,
  URL_ADMISSION_USERS_ID,
  URL_ADMISSION_USERS_ID_EDIT,
  URL_EXECUTION_ACCEPTED,
  URL_EXECUTION_ACCEPTED_ID,
  URL_EXECUTION_CLOSED,
  URL_EXECUTION_CLOSED_ID,
  URL_EXECUTION_INWORK,
  URL_EXECUTION_INWORK_ID,
  URL_LOGIN,
  URL_MAIN,
  URL_MODERATION_ACCEPTED,
  URL_MODERATION_ACCEPTED_ID,
  URL_MODERATION_CLOSED,
  URL_MODERATION_CLOSED_ID,
  URL_MODERATION_CREATED,
  URL_MODERATION_CREATED_ID,
  URL_MODERATION_REJECTED,
  URL_MODERATION_REJECTED_ID,
  URL_PROFILE,
  URL_VOTING_ACTIVE,
  URL_VOTING_ACTIVE_ID,
  URL_VOTING_ADD,
  URL_VOTING_ANNOUNCEMENT,
  URL_VOTING_ANNOUNCEMENT_ID,
  URL_VOTING_COMPLETED,
  URL_VOTING_COMPLETED_ID,
  URL_VOTING_EDIT_ID,
} from "./urls";
import siteRouting from "./site";
import shopRouting from "./shop";
import administrationRouting from "./administration";
import analyticsRouting from "./analytics";
import LoginPage from "newApp/pages/login";

const routing: { path: string; component: React.ReactNode }[] = [
  {
    path: URL_MAIN,
    component: <MainPage />,
  },
  {
    path: URL_ADMISSION_USERS,
    component: <UsersListPage />,
  },
  {
    path: URL_ADMISSION_USERS_CREATE,
    component: <UserCreatePage />,
  },
  {
    path: URL_ADMISSION_USERS_ID,
    component: <UsersItemPage />,
  },
  {
    path: URL_ADMISSION_USERS_ID_EDIT,
    component: <UserItemEditPage />,
  },
  {
    path: URL_ADMISSION_ROLES,
    component: <RolesListPage />,
  },
  {
    path: URL_ADMISSION_ROLES_CREATE,
    component: <RoleCreatePage />,
  },
  {
    path: URL_ADMISSION_ROLES_ID_EDIT,
    component: <RoleEditPage />,
  },
  {
    path: URL_ADMISSION_ROLES_ID,
    component: <RoleItemPage />,
  },
  {
    path: URL_PROFILE,
    component: <ProfilePage />,
  },
  {
    path: URL_MODERATION_CREATED,
    component: <ModerationListCreatedPage />,
  },
  {
    path: URL_MODERATION_ACCEPTED,
    component: <ModerationListCreatedPage />,
  },
  {
    path: URL_MODERATION_REJECTED,
    component: <ModerationListCreatedPage />,
  },
  {
    path: URL_MODERATION_CLOSED,
    component: <ModerationListCreatedPage />,
  },
  {
    path: URL_MODERATION_CREATED_ID,
    component: <DeclarationModerationItemPage />,
  },
  {
    path: URL_MODERATION_ACCEPTED_ID,
    component: <DeclarationModerationItemPage />,
  },
  {
    path: URL_MODERATION_REJECTED_ID,
    component: <DeclarationModerationItemPage />,
  },
  {
    path: URL_MODERATION_CLOSED_ID,
    component: <DeclarationModerationItemPage />,
  },
  {
    path: URL_EXECUTION_ACCEPTED,
    component: <ExecutionListPage />,
  },
  {
    path: URL_EXECUTION_INWORK,
    component: <ExecutionListPage />,
  },
  {
    path: URL_EXECUTION_CLOSED,
    component: <ExecutionListPage />,
  },
  {
    path: URL_EXECUTION_ACCEPTED_ID,
    component: <ExecutionItemPage />,
  },
  {
    path: URL_EXECUTION_INWORK_ID,
    component: <ExecutionItemPage />,
  },
  {
    path: URL_EXECUTION_CLOSED_ID,
    component: <ExecutionItemPage />,
  },
  {
    path: URL_VOTING_ANNOUNCEMENT,
    component: <VotingsPage />,
  },
  {
    path: URL_VOTING_ACTIVE,
    component: <VotingsPage />,
  },
  {
    path: URL_VOTING_COMPLETED,
    component: <VotingsPage />,
  },
  {
    path: URL_VOTING_ANNOUNCEMENT_ID,
    component: <VoteItemPage />,
  },
  {
    path: URL_VOTING_ACTIVE_ID,
    component: <VoteItemPage />,
  },
  {
    path: URL_VOTING_COMPLETED_ID,
    component: <VoteItemPage />,
  },
  {
    path: URL_VOTING_ADD,
    component: <VoteCreatePage />,
  },
  {
    path: URL_VOTING_EDIT_ID,
    component: <VoteEditPage />,
  },
  {
    path: URL_LOGIN,
    component: <LoginPage />,
  },

  ...siteRouting,
  ...shopRouting,
  ...administrationRouting,
  ...analyticsRouting,
];

export default routing;
