import React from 'react';

const IconSend = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none">
      <path
        d="M20 7.46667L12.2222 0V4.26667C4.44444 5.33333 1.11111 10.6667 0 16C2.77778 12.2667 6.66667 10.56 12.2222 10.56V14.9333L20 7.46667Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export default IconSend;
