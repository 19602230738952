import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import FilesPreview from "newApp/components/FilesPreview";
import MainLayout from "newApp/components/layouts/MainLayout";
import NewsPreview from "newApp/components/NewsPreview";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import H3 from "newApp/kit/components/H3";
import InfoTable from "newApp/kit/components/InfoTable";
import { URL_SITE_FAQ } from "newApp/routing/urls";
import { FaqItemPageStore } from "newApp/stores/pages/site/faq/FaqItemPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new FaqItemPageStore();

const FaqItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  return (
    <MainLayout isLoading={store.isLoadings.getFaq}>
      <H1>{store.form.question}</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_FAQ}>Назад к списку</ButtonBack>
        <ButtonsContainer>
          <Button onClick={() => push(URL_SITE_FAQ + `/${id}/edit`)}>
            Редактировать
          </Button>
          <Button
            onClick={() => store.changePublish()}
            variant="secondary"
            disabled={store.isLoadings.changePublish}
          >
            {!!store.form.state ? "Снять с публикации" : "Опубликовать"}
          </Button>
        </ButtonsContainer>
        <InfoTable
          content={[
            ["Вопрос", store.form.question],
            ["Дата", store.dateTime],
            ["Рубрика", store.rubricDescription],
          ]}
        />
        <div>
          <H3>Приложения</H3>
          <FilesPreview files={store.files} />
        </div>
        <div>
          <H3>Ответ</H3>
          <NewsPreview content={store.form.answer} />
        </div>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(FaqItemPage);
