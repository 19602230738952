import { observer } from "mobx-react";
import FlexColumn from "newApp/kit/components/FlexColumn";
import TextField from "newApp/kit/components/TextField";
import { FaqRubricFormStore } from "newApp/stores/common/FaqRubricFormStore";
import React from "react";

export interface IFaqRubricFormProps {
  faqRubricForm: FaqRubricFormStore;
}

const FaqRubricForm: React.FC<IFaqRubricFormProps> = (props) => {
  return (
    <FlexColumn>
      <TextField
        placeholder="Название"
        {...props.faqRubricForm.form.adapters.title}
      />
    </FlexColumn>
  );
};

export default observer(FaqRubricForm);
