import React from "react";
import "./styles.scss";

export interface IWarningCardProps {
  type?: "red" | "green" | "yellow";
  onCloseClick?: () => void;
  header?: string;
}

const WarningCard: React.FC<IWarningCardProps> = ({
  type = "yellow",
  header = "Внимание!",
  onCloseClick,
  children,
}) => {
  return (
    <div
      className={[
        "warning-card",
        type === "yellow" ? "warning-card--yellow" : "",
        type === "red" ? "warning-card--red" : "",
        type === "green" ? "warning-card--green" : "",
      ].join(" ")}
      data-testid="warning-card"
    >
      <div className="warning-card__left">
        <div className="warning-card__icon" />
      </div>
      <div className="warning-card__body">
        <div className="warning-card__header">{header}</div>
        <div className="warning-card__text">{children}</div>
      </div>
      <div className="warning-card__right">
        <div
          className="warning-card__close-icon"
          data-testid="close-btn"
          onClick={onCloseClick}
        />
      </div>
    </div>
  );
};

export default WarningCard;
