import "./styles.scss";

import { observer } from "mobx-react";
import { URL_ADMINISTRATION_STEPS } from "newApp/routing/urls";
import React from "react";
import { useHistory } from "react-router";

import DashedButton from "../DashedButton";
import { IStepsTableProps } from "./index.types";

const StepsTable: React.FC<IStepsTableProps> = (props) => {
  const { push } = useHistory();

  return (
    <table className="organizations-table">
      <thead className="organizations-table__header">
        <tr className="organizations-table__header-row">
          <td className="organizations-table__header-cell">Наименование</td>
        </tr>
      </thead>
      <tbody className="organizations-table__body">
        {props.templates.map((o, idx) => {
          const goOnEdit = () =>
            push(URL_ADMINISTRATION_STEPS + `/${o.id}/edit`);
          return (
            <tr
              className={[
                "organizations-table__body-row",
                !!o.level ? "organizations-table__body-row--bordered" : "",
              ].join(" ")}
              key={idx}
            >
              <td
                className={[
                  "organizations-table__body-cell",
                  `organizations-table__body-cell--level-${o.level}`,
                  `organizations-table__body-cell--clickable`,
                ].join(" ")}
                style={{ paddingLeft: `${o.level * 40}px` }}
                onClick={goOnEdit}
              >
                {o.title}
              </td>
              <td className={["organizations-table__body-cell"].join(" ")}>
                <DashedButton onClick={goOnEdit}>Редактировать</DashedButton>
              </td>
              <td className={["organizations-table__body-cell"].join(" ")}>
                <DashedButton
                  onClick={() => {
                    window.confirm(
                      `Вы уверены что хотите удалить ${o.title}?`
                    ) && props.removeItem(o.id);
                  }}
                  color="red"
                >
                  Удалить
                </DashedButton>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default observer(StepsTable);
