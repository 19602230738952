import { observer } from "mobx-react";
import React from "react";
import ExecutionTabs from "../ExecutionTabs";
import ListModeTabs from "../ListModeTabs";

import "./styles.scss";

export interface IExecutionNavigatorProps {}

const ExecutionNavigator: React.FC<IExecutionNavigatorProps> = (props) => {
  return (
    <div className="execution-navigator">
      <ListModeTabs />
      <ExecutionTabs />
    </div>
  );
};

export default observer(ExecutionNavigator);
