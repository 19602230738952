import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { NewsFormStore } from "../../../../common/NewsFormStore";

export class NewsCreatePageStore {
  isLoadings = {
    createNews: false,
    onClickCreate: false,
  };

  form = new NewsFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.form.init();
  };

  reset = () => {
    this.form.reset();
  };

  validate = () => {
    this.form.validate();
  };

  get isValid() {
    return this.form.isValid;
  }

  createNews = () => {
    this.isLoadings.createNews = true;
    return API.administration.News.newsPost({
      imageId: this.form.mainPhoto.mainImageId,
      content: this.form.form.values.content,
      date: this.form.form.values.date,
      state: this.form.form.values.state || 0,
      type: this.form.form.values.type || 0,
      title: this.form.form.values.title,
      rubricsIds: this.form.arrays.rubrics.map((i) => String(i)),
    })
      .then(({ data }) => data.id)
      .catch(() => false)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createNews = false;
        });
      });
  };

  onClickCreate = async () => {
    runInAction(() => {
      this.isLoadings.onClickCreate = true;
    });
    this.validate();
    if (this.isValid) {
      const id = await this.createNews();

      if (id && typeof id === "string") {
        await this.form.mainPhoto.saveCroppedImage(id);
        await this.form.mainPhoto.savePreviewImage(id);
        await this.form.files.uploadFiles(id);
        await this.form.photos.uploadPhotos(id);

        runInAction(() => {
          this.isLoadings.onClickCreate = false;
        });
        return id;
      }
    }
    runInAction(() => {
      this.isLoadings.onClickCreate = false;
    });
    return false;
  };
}
