import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

interface IProps {
  count?: React.ReactNode;
}

const NewMessagesCountBadge: React.FC<IProps> = (props) => {
  if (!props.count) return null;

  return <span className="new-messages-badge">{props.count}</span>;
};

export default observer(NewMessagesCountBadge);
