import React, { useEffect } from "react";
import { observer } from "mobx-react";

import MainLayout from "newApp/components/layouts/MainLayout";
import H1 from "newApp/kit/components/H1";
import FlexColumn from "newApp/kit/components/FlexColumn";
import ShopNavigator from "newApp/components/ShopNavigator";
import ShopSettings from "newApp/components/ShopSettings";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";

import { SettingsPageStore } from "newApp/stores/pages/shop/settings";

const store = new SettingsPageStore();

const ShopSettingsPage: React.FC = () => {
  useEffect(() => {
    store.init();

    return () => store.reset();
  }, []);
  return (
    <MainLayout>
      <H1>Настройки магазина поощрений</H1>
      {store.isLoading ? (
        <LoaderSpinner />
      ) : (
        <FlexColumn>
          <ShopNavigator />
          <ShopSettings {...store.form} />
        </FlexColumn>
      )}
    </MainLayout>
  );
};

export default observer(ShopSettingsPage);
