import { observer } from "mobx-react";
import React from "react";
import CascadeCheckboxes from "../../kit/components/CascadeCheckboxes";
import { ICascadeCheckboxesOption } from "../../kit/components/CascadeCheckboxes/index.types";
import H3 from "../../kit/components/H3";

interface IProps {
  values: string[];
  options: ICascadeCheckboxesOption<string>[];
  onChange: (value: string, name?: string, values?: string[]) => void;
}

const UserEditCreateRoles: React.FC<IProps> = (props) => {
  return (
    <div>
      <H3>Организации и роли</H3>
      <div>
        <CascadeCheckboxes
          onChange={props.onChange}
          values={props.values}
          options={props.options}
        />
      </div>
    </div>
  );
};

export default observer(UserEditCreateRoles);
