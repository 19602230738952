import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import FaqRubricForm from "newApp/components/FaqRubricForm";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_FAQ_RUBRICS } from "newApp/routing/urls";
import store from "newApp/stores/pages/site/faq/rubrics/FaqRubricsEditPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const FaqRubricsEditPage: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    store.init(id);
    return store.reset();
  }, [id]);

  const onClickCreate = async () => {
    const id = await store.onCreate();
    if (id) push(URL_SITE_FAQ_RUBRICS);
  };

  return (
    <MainLayout isLoading={store.isLoadings.getRubric}>
      <H1>Редактирование рубрики часто задаваемого вопроса</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_FAQ_RUBRICS}>Назад к списку</ButtonBack>
        <FaqRubricForm faqRubricForm={store.faqRubricForm} />

        <ButtonsContainer>
          <Button
            onClick={onClickCreate}
            disabled={store.isLoadings.saveRubric}
          >
            Сохранить изменения
          </Button>
          <Button
            onClick={() => push(URL_SITE_FAQ_RUBRICS)}
            variant="secondary"
          >
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(FaqRubricsEditPage);
