import React, { FC } from "react";
import Button from "../../Button";
import FlexRow from "../../FlexRow";
import { IResultViewProps } from "../index.types";

const ResultView: FC<IResultViewProps> = ({
  previewSrc,
  onConfirm,
  onBack,
}) => {
  return (
    <div className="cropper__preview">
      <div className="cropper__preview-image-wrapper">
        <img
          src={previewSrc}
          className="cropper__preview-image"
          alt="Результат"
        />
      </div>

      <FlexRow>
        <Button onClick={onConfirm}>Подтвердить</Button>
        <Button onClick={onBack} variant="secondary">
          Назад
        </Button>
      </FlexRow>
    </div>
  );
};

export default ResultView;
