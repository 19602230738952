import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "newApp/constants/validation/validators";
import * as yup from "yup";

export default yup.object().shape({
  content: VALIDATOR_STRING_REQUIRED,
  hasImage: VALIDATOR_ANY,
  id: VALIDATOR_ANY,
  title: VALIDATOR_STRING_REQUIRED,
});
