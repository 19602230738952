import "./styles.scss";

import React from "react";

export interface IShowHideIconProps {
  reverse?: boolean;
}

const ShowHideIcon: React.FC<IShowHideIconProps> = (props) => {
  return (
    <div
      className={[
        "show-hide-icon",
        props.reverse ? "show-hide-icon--reverse" : "",
      ].join(" ")}
    />
  );
};

export default ShowHideIcon;
