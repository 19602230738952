import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

class BallsStore {
  haveESK: string | null = null;
  haveNoESK: string | null = null;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getBallsInfo();
  };

  getBallsInfo = () => {
    this.isLoading = true;
    API.shop.PointsInfo.apiPointsinfoHasESKGet()
      .then(({ data }) => {
        runInAction(() => {
          this.haveESK = data.content;
        });
      })
      .finally(() => {
        runInAction(() => (this.isLoading = false));
      });

    API.shop.PointsInfo.apiPointsinfoWithoutESKGet().then(({ data }) => {
      runInAction(() => {
        this.haveNoESK = data.content;
      });
    });
  };
}

export default new BallsStore();
