import React from "react";
import { observer } from "mobx-react";
import Checkboxes from "newApp/kit/components/Checkboxes";
import FlexColumn from "newApp/kit/components/FlexColumn";
import DatePicker from "newApp/kit/components/DatePicker";
import FlexRow from "newApp/kit/components/FlexRow";
import H3 from "newApp/kit/components/H3";
import TextField from "newApp/kit/components/TextField";
import ProductFormMainPhoto from "./ProductFormMainPhoto";
import Divider from "newApp/kit/components/Divider";

import { ProductFormStore } from "newApp/stores/common/ProductFormStore";

import LoaderSpinner from "components/Loader";
import ProductMap from "../ProductMap";
import TextEditor from "newApp/components/TextEditor";
import Select from "newApp/kit/components/Select";

export interface IProductFormProps extends Omit<ProductFormStore, "isValid"> {}

const ProductForm: React.FC<IProductFormProps> = (props) => {
  return (
    <FlexColumn>
      <TextField
        placeholder="Наименование товара/услуги"
        {...props.form.adapters.title}
      />
      <div>
        <H3>Описание товара/услуги</H3>
        <TextEditor {...props.form.adapters.description} />
      </div>
      <DatePicker
        placeholder="Дата создания товара/услуги"
        {...props.form.adapters.date}
      />
      <ProductFormMainPhoto {...props.mainPhoto} />
      {/* <H3>Тип</H3>
      <RadioButtons
        {...props.form.adapters.type}
        options={props.options.type}
        value={props.currentType}
        onChange={props.onChangeTypes}
        isHorizontal
      /> */}
      <Divider />
      <FlexRow>
        <TextField
          placeholder="Стоимость"
          type="number"
          {...props.form.adapters.price}
        />
        <TextField
          placeholder="Количество"
          type="number"
          {...props.form.adapters.quantity}
        />
      </FlexRow>
      {/* <TextArea
        placeholder="Способы получения "
        {...props.form.adapters.wayToGet}
      /> */}
      <Select
        placeholder="Наименование организации"
        {...props.form.adapters.organizationId}
        onChange={props.onChangeOrganization}
        options={props.options.organizations}
      />
      {/* <TextField
        placeholder="Адрес организации"
        {...props.form.adapters.organizationAddress}
        onChange={props.changeOrgAddress}
      /> */}
      {!props.isLoadings.coordsIsLoading ? (
        <ProductMap
          coords={[
            Number(props.orgCoords?.latitude),
            Number(props.orgCoords?.longitude),
          ]}
          zoom={11}
          center={[
            Number(props.orgCoords?.latitude),
            Number(props.orgCoords?.longitude),
          ]}
          // onClick={props.getCoordsFromMap}
        />
      ) : (
        <LoaderSpinner />
      )}
      <div>
        <H3>Рубрики</H3>
        <Checkboxes
          onChange={props.onChangeCheckboxes}
          options={props.options.categories}
          values={props.arrays.categories}
        />
      </div>
    </FlexColumn>
  );
};

export default observer(ProductForm);
