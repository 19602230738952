import { observer } from "mobx-react";
import AdministrationNavigator from "newApp/components/AdministrationNavigator";
import MainLayout from "newApp/components/layouts/MainLayout";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import React from "react";

const AdministrationPage: React.FC = () => {
  return (
    <MainLayout>
      <H1>Администрирование - Конструктор шагов</H1>
      <FlexColumn>
        <AdministrationNavigator />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(AdministrationPage);
