import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import React from "react";
import { IExecutionStepProps } from "..";

const StepExecute: React.FC<IExecutionStepProps> = (props) => {
  return (
    <>
      {!props.isDone && (
        <ButtonsContainer>
          <Button onClick={props.executeStep}>Исполнено</Button>
        </ButtonsContainer>
      )}
    </>
  );
};

export default observer(StepExecute);
