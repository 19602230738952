import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FormStore } from "newApp/kit/stores/FormStore";
import validationSchema from "./validationSchema";

export class TechSupportNewItemPageStore {
  isLoadings = {
    getQuestion: false,
    sendAnswer: false,
  };

  id = "";

  form = new FormStore(
    {
      issueDate: null,
      fullname: null,
      phone: null,
      text: null,
      answer: null,
      answerFullName: null,
      answerDate: null,
    },
    validationSchema
  );

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getQuestion();
  };

  reset = () => {
    this.form.reset();
    this.id = "";
  };

  getQuestion = () => {
    this.isLoadings.getQuestion = true;
    return API.admin.Question.apiQuestionsIdGet(this.id)
      .then(({ data }) => {
        runInAction(() => {
          this.form.changeValueByKey(data["issueDate"], "issueDate");
          this.form.changeValueByKey(data["text"], "text");
          this.form.changeValueByKey(
            data["userFullname"]["fullname"],
            "fullname"
          );
          this.form.changeValueByKey(data["userFullname"]["phone"], "phone");
          this.form.changeValueByKey(
            data["answer"]["fullname"],
            "answerFullName"
          );
          this.form.changeValueByKey(data["answer"]["date"], "answerDate");
          this.form.values.answer = data["answer"]["text"];
        });
      })
      .catch(() => {
        runInAction(() => {});
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getQuestion = false;
        });
      });
  };

  onAnswer = async () => {
    await this.form.validate();
    if (this.form.isValid) {
      return await this.sendAnswer();
    }
  };

  sendAnswer = () => {
    this.isLoadings.sendAnswer = true;
    return API.admin.Question.apiQuestionsAnswerPost(
      this.id,
      this.form.values.answer
    )
      .then(() => true)
      .catch((e) => {
        console.error(e);
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.sendAnswer = false;
        });
      });
  };
}
