import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";

export class FaqItemPageStore {
  isLoadings = {
    getFaq: false,
    getRubrics: false,
    changePublish: false,
  };
  id = "";

  form = {
    answer: null,
    date: null,
    id: null,
    question: null,
    rubricId: null,
    state: null,
  };

  rubrics: { id: string; title: string }[] = [];
  files: IFilesPreviewFile[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getFaq();
    this.getRubrics();
  };

  reset = () => {
    this.id = "";
    this.resetRubrics();
    this.files = [];
  };

  resetRubrics = () => {
    this.rubrics = [];
  };

  getFaq = () => {
    this.isLoadings.getFaq = true;
    return API.administration.FAQ.faqIdGet(this.id)
      .then(({ data }) => {
        runInAction(() => {
          Object.entries(data).forEach(([key, value]) => {
            this.form[key] = value;
          });
          this.files = data.files?.map((f) => ({
            reference: URL_API_ADMINISTRATION + "/faq/file/" + f.id,
            title: f.name,
          }));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getFaq = false;
        });
      });
  };

  get dateTime() {
    if (this.form.date)
      return (
        new Date(this.form.date).toLocaleDateString() +
        " " +
        new Date(this.form.date).toLocaleTimeString()
      );
    return "Неизвестно";
  }

  get rubricDescription() {
    return (
      this.rubrics?.find((i) => i.id === this.form?.rubricId)?.title ||
      "Неизвестно"
    );
  }

  getRubrics = () => {
    this.isLoadings.getRubrics = true;
    return API.administration.FAQRubrics.faqRubricListGet()
      .then(({ data }) => {
        runInAction(() => {
          this.rubrics = data.map((i) => ({
            id: String(i.id),
            title: String(i.title),
          }));
        });
      })
      .catch(this.resetRubrics)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubrics = false;
        });
      });
  };

  changePublish = () => {
    this.isLoadings.changePublish = true;
    return API.administration.FAQ.faqIdPost(this.id, {
      ...this.form,
      state: this.form.state === 0 ? 1 : 0,
    })
      .then(() => {
        this.getFaq();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changePublish = false;
        });
      });
  };
}
