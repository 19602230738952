import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { ICategorySelectOption } from "newApp/components/CategorySelect";
import { CategoryTagsStore } from "newApp/components/CategoryTags/CategoryTagsStore";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";
import formValidationSchema from "./formValidationSchema";

export class CategoryFormStore {
  isLoadings = {
    getRootCategories: false,
    getOrganizations: false,
  };

  form = new FormStore(
    {
      parentId: null,
      name: null,
      isActive: null,
      organizationId: null,
      daysLimit: null,
    },
    formValidationSchema
  );

  options: {
    categories: ICategorySelectOption[];
    organizations: ISelectOption[];
  } = {
    categories: [],
    organizations: [],
  };

  categoryTags = new CategoryTagsStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getRootCategories();
    this.getOrganizations();
  };

  reset = () => {
    this.resetOptions();
    this.form.reset();
    this.categoryTags.form.reset();
  };

  resetOptions = () => {
    Object.keys(this.options).forEach((key) => {
      this.options[key] = [];
    });
  };

  getRootCategories = () => {
    this.isLoadings.getRootCategories = true;
    this.options.categories = [];
    return API.admin.Category.apiObjectsGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.categories = [
            ...this.options.categories,
            {
              text: "Сделать новой категорией",
              value: null,
              level: 0,
            },
          ];
          data.forEach((i) => this.categoryParser(i as any));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRootCategories = false;
        });
      });
  };

  categoryParser = (c: ICategory, level = 0) => {
    this.options.categories = [
      ...this.options.categories,
      {
        text: c.name,
        value: c.id,
        level,
      },
    ];
    if (c.children?.length) {
      c.children?.forEach((o) => this.categoryParser(o, level + 1));
    }
  };

  getOrganizations = () => {
    this.isLoadings.getOrganizations = true;
    this.options.organizations = [];
    return API.admin.Organization.apiOrganizationsGet()
      .then(({ data }) => {
        runInAction(() => {
          data.forEach((i) => this.organizationParser(i as any));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getOrganizations = false;
        });
      });
  };

  organizationParser = (organization: IOrganization, level = 0) => {
    this.options.organizations = [
      ...this.options.organizations,
      {
        text: organization.title,
        value: organization.id,
      },
    ];
    if (organization.children?.length) {
      organization.children?.forEach((o) =>
        this.organizationParser(o, level + 1)
      );
    }
  };
}

export interface ICategory {
  daysLimit: number;
  level: number;
  id: string;
  name: string;
  organizationId: string;
  parentId: string | null;
  state: number;
  children?: ICategory[];
}

export interface IOrganization {
  active: boolean;
  children: IOrganization[];
  id: string;
  parentId: string | null;
  title: string;
  titleShort: string;
}
