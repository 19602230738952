import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { ShopCategoryFormStore } from "newApp/stores/common/ShopCategoryFormStore";

export class CategoriesCreatePageStore {
  isLoadings = { createCategory: false };

  categoryForm = new ShopCategoryFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }

  reset = () => {
    this.categoryForm.reset();
  };

  validateAndCreate = () => {
    this.categoryForm.form.validate();
    if (this.categoryForm.form.isValid) return this.createCategory();
  };

  createCategory = () => {
    this.isLoadings.createCategory = true;
    return API.shop.Categories.apiCategoriesCreatePost({
      title: this.categoryForm.form.values.title,
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.createCategory = false;
        });
      });
  };
}
