import "./styles.scss";

import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import React from "react";

import { IExecutionStepProps } from "..";
import Dropzone from "newApp/kit/components/Dropzone";
import Divider from "newApp/kit/components/Divider";
import IconPhoto from "../../IconPhoto";
import DashedButton from "newApp/components/DashedButton";
import cn from "classnames";
import FilesPreview from "newApp/components/FilesPreview";
import { toJS } from "mobx";

const StepWasNow: React.FC<IExecutionStepProps> = (props) => {
  console.log("props IExecutionStepProps", toJS(props));

  return (
    <div title={props.stepTypeId} className="step-was-now">
      <FlexColumn>
        <div className="step-was-now__container">
          <div className="step-was-now__was">
            <div className="step-was-now__title">Было</div>
            <Divider color="blue" />
            {!props.isDone && (
              <div>
                <div className="file-selector">
                  {!!props.declarationImages.length && (
                    <p className="file-selector__title">
                      Выберите одно фото из заявки
                    </p>
                  )}
                  {props?.declarationSelectedImage?.reference ? (
                    <div className="file-selector__files">
                      {props.declarationImages.map((image, idx) => {
                        const isActive =
                          props?.declarationSelectedImage?.id === image.id;
                        return (
                          <div
                            key={image.id || idx}
                            className={cn(
                              "file-selector__container",
                              isActive && "file-selector__container--selected"
                            )}
                          >
                            <FilesPreview files={[image]} />
                            <div className="file-selector__file-footer">
                              <DashedButton
                                onClick={() =>
                                  !isActive &&
                                  props.onChangeDeclarationImage(image)
                                }
                                children={isActive ? "Выбрано" : " Выбрать"}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className={"file-selector__container"}>
                      <div className="file-selector__no-photo">
                        <IconPhoto />
                      </div>
                      <div className="file-selector__file-footer">
                        <p>Нет файлов</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="step-was-now__now">
            <div className="step-was-now__title">Стало</div>
            <Divider color="blue" />
            {!props.isDone && (
              <div className="file-selector">
                {props.nowFile && props.declarationSelectedPreview ? (
                  <div className={"file-selector__container"}>
                    <FilesPreview
                      files={[
                        {
                          type: "Image",
                          reference: props.declarationSelectedPreview,
                        },
                      ]}
                    />
                    <div className="file-selector__file-footer">
                      <DashedButton
                        color="red"
                        onClick={props.removeDeclarationSelectedPreview}
                        children="Удалить"
                      />
                    </div>
                  </div>
                ) : (
                  <Dropzone
                    maxFiles={1}
                    acceptFileTypes={["image/*"]}
                    onAddFiles={props.onAddFilesNow}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {!props.isDone && (
          <ButtonsContainer>
            <Button onClick={props.executeStep}>Опубликовать</Button>
          </ButtonsContainer>
        )}
      </FlexColumn>
    </div>
  );
};

export default observer(StepWasNow);
