import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

export interface IListModeSwitcherProps {
  items: {
    title: React.ReactNode;
    isActive?: boolean;
    onClick?: () => void;
  }[];
}

const ListModeSwitcher: React.FC<IListModeSwitcherProps> = (props) => {
  return (
    <div className="list-mode-switcher">
      {props.items.map((item, idx) => (
        <div
          className={[
            "list-mode-switcher__item",
            item.isActive ? "list-mode-switcher__item--active" : "",
          ].join(" ")}
          onClick={item.onClick}
          key={idx}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default observer(ListModeSwitcher);
