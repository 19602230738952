import { observer } from "mobx-react";
import React from "react";
import { IExecutionStepProps } from "..";

const StepKafka: React.FC<IExecutionStepProps> = (props) => {
  return (
    <div title={props.stepTypeId}>
      <div>Ожидайте автоматического завершения этого шага</div>
    </div>
  );
};

export default observer(StepKafka);
