import "./styles.scss";

import { observer } from "mobx-react";
import H3 from "newApp/kit/components/H3";
import Modal from "newApp/kit/components/Modal";
import React, { useState } from "react";

import DashedButton from "../DashedButton";
import IconExpand from "../IconExpand";
import IconFile from "../IconFile";
import IconVideo from "../IconVideo";

export interface IFilesPreviewFile {
  id?: string;
  reference?: string;
  type?: "Image" | "Video" | string;
  title?: string;
  file?: File;
}
export interface IFilesPreviewProps {
  files: IFilesPreviewFile[];
  removeFile?: (idx: number) => void;
  onClickRemove?: (idx: number, file?: IFilesPreviewFile) => void;
}

const FilesPreview: React.FC<IFilesPreviewProps> = (props) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [currentFile, setCurrentFile] = useState<null | IFilesPreviewFile>(
    null
  );

  return (
    <div className="files-preview">
      {props.files.map((file, idx) => (
        <div key={file.id || idx}>
          <div
            className="files-preview__item"
            title={file.title || file.reference}
            onClick={() => {
              setIsShowPopup(true);
              setCurrentFile(file);
            }}
          >
            <div className="files-preview__item-icon">
              <IconExpand />
            </div>
            {String(file.type).toLowerCase() === "image" && (
              <img
                className="files-preview__item-img"
                alt="Превью файла"
                src={file.reference}
              />
            )}
            {String(file.type).toLowerCase() === "video" && <IconVideo />}
            {String(file.type).toLowerCase() !== "image" &&
              String(file.type).toLowerCase() !== "video" && <IconFile />}
          </div>
          {props.onClickRemove && (
            <DashedButton onClick={() => props.onClickRemove(idx, file)}>
              Удалить
            </DashedButton>
          )}
        </div>
      ))}

      <Modal
        isOpen={isShowPopup}
        onClose={() => {
          setIsShowPopup(false);
          setCurrentFile(null);
        }}
        title={
          <span>
            {currentFile?.title && <div>{currentFile?.title}</div>}
            <a target="_blank" href={currentFile?.reference} rel="noreferrer">
              Скачать фаил
            </a>
          </span>
        }
      >
        {String(currentFile?.type).toLowerCase() === "image" && (
          <img
            className="popup__img"
            alt="Превью файла"
            src={currentFile.reference}
          />
        )}
        {String(currentFile?.type).toLowerCase() === "video" && (
          <video controls src={currentFile?.reference}></video>
        )}
        {String(currentFile?.type).toLowerCase() !== "image" &&
          String(currentFile?.type).toLowerCase() !== "video" && (
            <H3>Превью недоступно</H3>
          )}
      </Modal>
    </div>
  );
};

export default observer(FilesPreview);
