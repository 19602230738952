import * as yup from "yup";
import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "../../../constants/validation/validators";

export default yup.object().shape({
  parentId: VALIDATOR_ANY,
  title: VALIDATOR_STRING_REQUIRED,
  titleShort: VALIDATOR_STRING_REQUIRED,
});
