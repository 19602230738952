import "./styles.scss";

import {
  URL_SITE_DESCRIPTION,
  URL_SITE_DOCS_AGREEMENTS,
  URL_SITE_DOCS_POLICY,
  URL_SITE_DOCS_TERMS,
  URL_SITE_FAQ,
  URL_SITE_FAQ_RUBRICS,
  URL_SITE_NEWS,
  URL_SITE_NEWS_RUBRICS,
  URL_SITE_TECHSUPPORT_ANSWERED,
  URL_SITE_TECHSUPPORT_NEW,
  URL_SITE_EMAILTEMPLATES,
} from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";

const VotingsModeSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const links: { paths: string[]; text: string }[] = [
    { paths: [URL_SITE_NEWS, URL_SITE_NEWS_RUBRICS], text: "Новости" },
    {
      paths: [URL_SITE_FAQ, URL_SITE_FAQ_RUBRICS],
      text: "Часто задаваемые вопросы",
    },
    { paths: [URL_SITE_DESCRIPTION], text: "Описание проекта" },
    {
      paths: [URL_SITE_TECHSUPPORT_NEW, URL_SITE_TECHSUPPORT_ANSWERED],
      text: "Вопросы личного кабинета",
    },
    {
      paths: [
        URL_SITE_DOCS_TERMS,
        URL_SITE_DOCS_POLICY,
        URL_SITE_DOCS_AGREEMENTS,
      ],
      text: "Документы",
    },
    { paths: [URL_SITE_EMAILTEMPLATES], text: "Шаблоны" },
  ];

  return (
    <div className="votings-mode-switcher">
      {links.map((link, indx) => {
        return (
          <div
            className={[
              "votings-mode-switcher__item",
              link.paths.includes(pathname)
                ? "votings-mode-switcher__item--active"
                : "",
            ].join(" ")}
            key={indx}
            onClick={() => push(link.paths[0])}
          >
            {link.text}
          </div>
        );
      })}
    </div>
  );
};

export default VotingsModeSwitcher;
