import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

export interface IAnalyticsTable {
  titles: ITitle[];
  rows: IRow[];
}

interface IRow {
  title: string;
  values: number[][];
  children: IRow[];
}

interface ITitle {
  title: string;
  subtitles: ITitle[] | null;
}

export interface IAnalyticsInWorkTableProps {
  table?: IAnalyticsTable;
}

interface IHeaderCell {
  text: string | number | null;
  colSpan: number;
}

const AnalyticsTable: React.FC<IAnalyticsInWorkTableProps> = (props) => {
  const header: IHeaderCell[][] = [];
  const firstHeaderRow: IHeaderCell[] = props.table?.titles?.map((t) => ({
    text: t?.title,
    colSpan: t?.subtitles?.length || 1,
  }));
  if (firstHeaderRow) header.push(firstHeaderRow);
  const secondHeaderRow: IHeaderCell[] = [];
  const content: {
    level: number;
    items: React.ReactNode[];
  }[] = [];

  props.table?.titles?.forEach((t) => {
    if (!t.subtitles) secondHeaderRow.push({ text: null, colSpan: 1 });
    t.subtitles?.forEach((st) => {
      secondHeaderRow.push({ text: st.title, colSpan: 1 });
    });
  });
  if (secondHeaderRow) header.push(secondHeaderRow);

  const parseChildren = (row: IRow, level = 0) => {
    try {
      const items = [];
      row?.values?.forEach((i) => {
        if (Array.isArray(i)) {
          i?.forEach((j) => {
            items.push(j);
          });
        } else {
          items.push(i);
        }
      });
      content.push({
        items: [row.title, ...items],
        level,
      });
      row.children?.forEach((i) => parseChildren(i, level + 1));
    } catch (e) {
      console.error(e);
    }
  };

  props.table?.rows?.forEach((i) => {
    parseChildren(i);
  });

  return (
    <table className="analytics-table">
      <thead className="analytics-table__header">
        {header.map((row, rowIdx) => (
          <tr className="analytics-table__row" key={rowIdx}>
            {row.map((cell, cellIdx) => (
              <th
                className={[
                  "analytics-table__header-cell",
                  cell.colSpan > 1
                    ? "analytics-table__header-cell--bordered"
                    : "",
                ].join(" ")}
                key={cellIdx}
                colSpan={cell.colSpan}
              >
                {cell.text}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="analytics-table__body">
        {content.map((row, rowIdx) => (
          <tr className="analytics-table__body-row" key={rowIdx}>
            {row.items.map((cell, cellIdx) => {
              const paddingLeft = cellIdx ? 0 : row.level * 20;
              return (
                <td
                  className="analytics-table__body-cell"
                  key={cellIdx}
                  style={{ paddingLeft: `${paddingLeft}px` }}
                >
                  {cell}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(AnalyticsTable);
