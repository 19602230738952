import React from "react";
import "./styles.scss";

export interface ITableProps {
  headers?: React.ReactNode[];
  content: React.ReactNode[][];
  className?: string;
}

const Table: React.FC<ITableProps> = (props) => {
  return (
    <div className="table__container">
      <table className={`table ${props.className}`}>
        {props.headers && (
          <thead className="table__header">
            <tr className="table__row">
              {props.headers.map((header, idx) => (
                <th className="table__cell" key={idx}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="table__body">
          {props.content.map((row, idx) => (
            <tr className="table__row" key={idx}>
              {row.map((cell, indx) => (
                <td className="table__cell" key={indx}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
