import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router";

import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import ProductForm from "newApp/components/ProductForm";
import ButtonBack from "newApp/components/ButtonBack";

import { ProductsCreatePageStore } from "newApp/stores/pages/shop/products/ProductsCreatePageStore";

import { URL_SHOP_PRODUCTS } from "newApp/routing/urls";
import { scrollToError } from "newApp/kit/utils/scrollToError";

const store = new ProductsCreatePageStore();

const ProductCreatePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const onCreateHandler = async () => {
    const id = await store.onClickCreateProduct();
    if (!id) {
      scrollToError();
      return;
    }
    push(URL_SHOP_PRODUCTS + `/${id}`);
  };

  const moveToProductsListHandler = () => push(URL_SHOP_PRODUCTS);

  return (
    <MainLayout>
      <H1>Добавление товара/услуги</H1>
      <FlexColumn>
        <ButtonBack url={URL_SHOP_PRODUCTS}>Назад к списку</ButtonBack>
        <ProductForm {...store.productForm} />
        <ButtonsContainer>
          <Button
            onClick={onCreateHandler}
            disabled={store.isLoadings.createProduct || store.isLoadingImage}
          >
            {store.isLoadings.createProduct || store.isLoadingImage
              ? "Загрузка.."
              : "Создать товар/услугу"}
          </Button>
          <Button onClick={moveToProductsListHandler} variant="secondary">
            Отмена
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ProductCreatePage);
