import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import IconMessage from "../../components/IconMessage";
import cn from "classnames";
import { IDeclarationsListProps } from "./index.types";
import "./styles.scss";

const DeclarationsList: React.FC<IDeclarationsListProps> = (props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <table className="declaration-list">
      <thead className="declaration-list__header">
        <tr className="declaration-list__row">
          <th className="declaration-list__cell">№</th>
          <th className="declaration-list__cell">Дата</th>
          <th className="declaration-list__cell">Категория</th>
          <th className="declaration-list__cell">Подкатегория</th>
          <th className="declaration-list__cell">Ведомство</th>
          <th className="declaration-list__cell">Осталось дней</th>
        </tr>
      </thead>

      <tbody className="declaration-list__body">
        {props.declarations.map((declaration) => (
          <tr
            className={[
              "declaration-list__row",
              declaration.isNotChecked
                ? "declaration-list__row--not-checked"
                : "",
              declaration.isOnControl
                ? "declaration-list__row--on-control"
                : "",
            ].join(" ")}
            key={declaration.id}
            onClick={() => push(pathname + `/${declaration.id}`)}
            title={
              declaration.isOnControl
                ? "Сообщение находится на дополнительном контроле"
                : declaration.isNotChecked
                ? "Новое сообщение"
                : undefined
            }
          >
            <td
              className={cn(
                "declaration-list__cell",
                declaration.isHasNewMessage && "declaration-list__cell-message"
              )}
            >
              {declaration.number}{" "}
              {declaration.isHasNewMessage && <IconMessage />}
            </td>
            <td className="declaration-list__cell">{declaration.date}</td>
            <td className="declaration-list__cell">{declaration.category}</td>
            <td className="declaration-list__cell">
              {declaration.subCategory}
            </td>
            <td className="declaration-list__cell">
              {declaration.organization}
            </td>
            <td
              className={[
                "declaration-list__cell",
                "declaration-list__item-days-left",
                Number(declaration.daysLeft) < 1
                  ? "declaration-list__item-days-left--over"
                  : "",
              ].join(" ")}
            >
              {declaration.daysLeft}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(DeclarationsList);
