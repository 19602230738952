import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import { URL_SITE_NEWS_RUBRICS } from "newApp/routing/urls";
import { RubricsListItemStore } from "newApp/stores/pages/site/rubrics/RubricsListPageStore/RubricsListItemStore";
import React from "react";
import { useHistory } from "react-router";
import DashedButton from "../DashedButton";
import "./styles.scss";

export interface IRubricsListProps {
  rubricsList: RubricsListItemStore[];
}

const RubricsList: React.FC<IRubricsListProps> = (props) => {
  const { push } = useHistory();
  return (
    <div className="rubrics-list">
      {props.rubricsList.map((rubric) => (
        <div
          className={[
            "rubrics-list__item",
            rubric.form.isActive ? "rubrics-list__item--active" : "",
          ].join(" ")}
          key={rubric.form.id}
        >
          <FlexColumn>
            <div className="rubrics-list__item-header">
              <div className="rubrics-list__item-header-title">
                {rubric.form.title}
              </div>
              <div className="rubrics-list__item-header-buttons">
                <DashedButton
                  onClick={() =>
                    push(URL_SITE_NEWS_RUBRICS + `/${rubric.form.id}/edit`)
                  }
                >
                  Редактировать
                </DashedButton>
                <DashedButton color="red" onClick={rubric.onClickDelete}>
                  Удалить
                </DashedButton>
              </div>
            </div>
            <Divider color="grey" />
            <div className="rubrics-list__item-controls">
              <Checkbox
                value={!!rubric.form.isActive}
                onChange={rubric.onChangeActive}
                disabled={rubric.isLoadings.postRubric}
              >
                Активно
              </Checkbox>
            </div>
          </FlexColumn>
        </div>
      ))}
    </div>
  );
};

export default observer(RubricsList);
