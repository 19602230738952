import React from "react";
import "./styles.scss";

interface IFlexColumnProps {
  gap?: number;
  className?: string;
}

const FlexColumn: React.FC<IFlexColumnProps> = ({
  gap = 30,
  children,
  className,
}) => {
  return (
    <div
      className={["flex-column", className ? className : ""].join(" ")}
      style={{ gap: `${gap}px` }}
    >
      {children}
    </div>
  );
};

export default FlexColumn;
