import React, { useEffect } from "react";
import { observer } from "mobx-react";

import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import CategoryForm from "newApp/components/ShopCategoryForm";
import H1 from "newApp/kit/components/H1";
import { URL_SHOP_CATEGORIES } from "newApp/routing/urls";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import { useHistory } from "react-router";

import { CategoriesCreatePageStore } from "newApp/stores/pages/shop/categories/CategoriesCreatePageStore";

const store = new CategoriesCreatePageStore();

const CategoriesCreatePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    return () => store.reset();
  }, []);

  const createCategoryHandler = () => {
    store.validateAndCreate().then(() => push(URL_SHOP_CATEGORIES));
  };

  return (
    <MainLayout>
      <H1>Добавление рубрики</H1>
      {store.isLoading ? (
        <LoaderSpinner />
      ) : (
        <FlexColumn>
          <ButtonBack url={URL_SHOP_CATEGORIES}>
            Назад к списку рубрик
          </ButtonBack>
          <CategoryForm {...store.categoryForm} />
          <ButtonsContainer>
            <Button onClick={createCategoryHandler}>Создать</Button>
            <Button
              onClick={() => push(URL_SHOP_CATEGORIES)}
              variant="secondary"
            >
              Отменить
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      )}
    </MainLayout>
  );
};

export default observer(CategoriesCreatePage);
