import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";
import { FaqListItemStore } from "./FaqListItemStore";

export class FaqListPageStore {
  isLoadings = {
    getFaqs: false,
    getRubrics: false,
  };

  faqList: FaqListItemStore[] = [];
  rubrics: IRubric[] = [];
  pagination = new PaginationStore(undefined, () => this.getFaqs());

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getFaqs();
    this.getRubrics();
  };

  reset = () => {};

  getFaqs = () => {
    this.isLoadings.getFaqs = true;
    const state = undefined;
    const skip = (this.pagination.currentPage - 1) * this.pagination.pageSize;
    const number = this.pagination.pageSize;
    return API.administration.FAQ.faqGet(state, skip, number)
      .then(({ data }) => {
        try {
          runInAction(() => {
            this.faqList =
              data.items?.map((i) => new FaqListItemStore(i, this)) || [];

            this.pagination.currentPage = Math.ceil(
              Number(data.count) / this.pagination.pageSize
            );
          });
        } catch (error: any) {
          console.error(error);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getFaqs = false;
        });
      });
  };

  getRubrics = () => {
    this.isLoadings.getRubrics = true;
    return API.administration.FAQRubrics.faqRubricListGet()
      .then(({ data }) => {
        runInAction(() => {
          this.rubrics = data.map((i) => ({
            id: String(i.id),
            title: String(i.title),
          }));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubrics = false;
        });
      });
  };
}

export interface IRubric {
  id: string;
  title: string;
}
