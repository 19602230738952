import { observer } from "mobx-react";
import FilesPreview from "newApp/components/FilesPreview";
import Cropper from "newApp/kit/components/Cropper";
import Dropzone from "newApp/kit/components/Dropzone";
import H3 from "newApp/kit/components/H3";
import { NewsFormMainPhotoStore } from "newApp/stores/common/NewsFormStore/NewsFormMainPhotoStore";
import React from "react";

export interface INewsFormMainPhotoProps extends NewsFormMainPhotoStore {}

const NewsFormMainPhoto: React.FC<INewsFormMainPhotoProps> = (props) => {
  return (
    <div>
      <H3>Главное изображение новости</H3>
      {props.croppedImage ? (
        <FilesPreview
          files={[props.croppedImage, props.previewImage]}
          onClickRemove={props.onRemoveMainPhoto}
        />
      ) : (
        <Dropzone
          onAddFiles={props.onAddMainPhoto}
          multiple={false}
          acceptFileTypes={["image/*"]}
        />
      )}
      {props.isShow.mainPhotoCropper && (
        <Cropper
          sourceImage={props.mainImageFile}
          steps={[
            {
              title: "Получить обрезанное изображение",
              width: 1920,
              height: 1080,
            },
            { title: "Получить превью", width: 1920, height: 640 },
          ]}
          onCropped={props.onCroppedMainPhoto}
          onClose={props.closeMainPhotoCropper}
        />
      )}
    </div>
  );
};

export default observer(NewsFormMainPhoto);
