import { config, axiosInstance } from "../axios";
import { URL_API_ADMINISTRATION } from "../urls";
import * as api from "./openapi";

export const apiAdministration = {
  FAQ: new api.FAQApi(config, URL_API_ADMINISTRATION, axiosInstance),
  FAQRubrics: new api.FAQRubricsApi(
    config,
    URL_API_ADMINISTRATION,
    axiosInstance
  ),
  MobileProject: new api.MobileProjectApi(
    config,
    URL_API_ADMINISTRATION,
    axiosInstance
  ),
  News: new api.NewsApi(config, URL_API_ADMINISTRATION, axiosInstance),
  NewsRubrics: new api.NewsRubricsApi(
    config,
    URL_API_ADMINISTRATION,
    axiosInstance
  ),
  ProjectDescription: new api.ProjectDescriptionApi(
    config,
    URL_API_ADMINISTRATION,
    axiosInstance
  ),
  Documents: new api.DocumentsApi(
    config,
    URL_API_ADMINISTRATION,
    axiosInstance
  ),
  EmailTemplates: new api.EmailTemplatesApi(
    config,
    URL_API_ADMINISTRATION,
    axiosInstance
  ),
};
