import React from "react";
import classNames from "classnames";
import { ITooltipProps } from "./index.types";
import "./styles.scss";

const Tooltip: React.FC<ITooltipProps> = ({
  title,
  text,
  onClose,
  className,
}) => {
  const handleClose = () => onClose && onClose();

  return (
    <div className={classNames("tooltip", className)} role="tooltip">
      <div className="tooltip__inner">
        <div className="tooltip__content">
          {title && <div className="tooltip__title">{title}</div>}
          {text && <div className="tooltip__text">{text}</div>}
        </div>
        <button
          className="tooltip__close-button"
          onClick={handleClose}
          aria-label="закрыть подсказку"
          tabIndex={-1}
        />
      </div>
    </div>
  );
};

export default Tooltip;
