import { observer } from "mobx-react";
import { URL_ADMINISTRATION_STEPS } from "newApp/routing/urls";
import React from "react";
import { NavLink } from "react-router-dom";
import WarningCard from "../../kit/components/WarningCard";

export interface IStepsCreateErrorProps {
  error: null | {
    id?: string;
    text?: string;
  };
  onCloseClick?: () => void;
}

const StepsCreateError: React.FC<IStepsCreateErrorProps> = (props) => {
  if (!props.error) return null;
  return (
    <WarningCard type="red" onCloseClick={props.onCloseClick}>
      {props.error?.text && <div>{props.error.text}</div>}
      {props.error?.id && (
        <div>
          Вы можете посмотреть его{" "}
          <NavLink to={URL_ADMINISTRATION_STEPS + `/${props.error.id}/edit`}>
            здесь
          </NavLink>
        </div>
      )}
    </WarningCard>
  );
};

export default observer(StepsCreateError);
