import React from "react";
import "./styles.scss";

interface IProps {
  justifyRight?: boolean;
}

const ButtonsContainer: React.FC<IProps> = (props) => {
  return (
    <div
      className={[
        "button-container",
        props.justifyRight ? "button-container--justify-right" : "",
      ].join(" ")}
    >
      {props.children}
    </div>
  );
};

export default ButtonsContainer;
