import "./styles.scss";

import React from "react";

import Button from "../../kit/components/Button";
import Divider from "../../kit/components/Divider";
import FlexColumn from "../../kit/components/FlexColumn";
import TextField from "../../kit/components/TextField";
import WarningCard from "../../kit/components/WarningCard";
import { ILoginFormProps } from "./index.types";
import { observer } from "mobx-react-lite";

const LoginForm: React.FC<ILoginFormProps> = (props) => {
  return (
    <div className="login-form">
      <FlexColumn>
        <div className="login-form__header">Авторизация</div>
        <div className="login-form__sub-header">
          Чтобы войти в систему, введите логин и пароль
        </div>
        {props.warningCardErrorText && (
          <WarningCard onCloseClick={props.onClosewarningCardErrorText}>
            {props.warningCardErrorText}
          </WarningCard>
        )}
        <TextField
          value={props.values.login}
          name="login"
          errorMessage={props.errors.login}
          onChange={props.onChange}
          placeholder="Логин"
          isDisabled={!!props.isLoading}
        />
        <TextField
          name="password"
          value={props.values.password}
          errorMessage={props.errors.password}
          onChange={props.onChange}
          placeholder="Пароль"
          type="password"
          id="password"
          isDisabled={!!props.isLoading}
        />
        <Divider />
        <Button onClick={props.onLogin} disabled={!!props.isLoading}>
          Войти
        </Button>
      </FlexColumn>
    </div>
  );
};

export default observer(LoginForm);
