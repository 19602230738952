import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { IVotingListItem } from "newApp/components/VotingList";

export class VotingsListItem implements IVotingListItem {
  title: string;
  isBanner: boolean;
  isMain: boolean;
  isPublish: boolean;
  startDate?: Date | null;
  finishDate?: Date | null;
  id: string;

  rootStore: IRootStore;

  isLoadings = {
    changeBanner: false,
    changePublish: false,
    changeMain: false,
    remove: false,
  };

  constructor(form: IVotingsListItemForm, rootStore: IRootStore) {
    Object.entries(form).forEach(([key, value]) => {
      this[key] = value;
    });
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  onChangeBanner = () => {
    this.isLoadings.changeBanner = true;
    return API.admin.Vote.apiVotesIdChangetypePost(this.id, {
      IsBanner: !this.isBanner,
    })
      .then(() => {
        this.rootStore.getVotings();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeBanner = false;
        });
      });
  };

  onChangeMain = () => {
    this.isLoadings.changeMain = true;
    return API.admin.Vote.apiVotesIdChangetypePost(this.id, {
      IsImportant: !this.isMain,
    })
      .then(() => {
        this.rootStore.getVotings();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeMain = false;
        });
      });
  };

  onChangePublish = () => {
    if (this.isPublish) {
      this.deactivate();
    } else {
      this.activate();
    }
  };

  activate = () => {
    this.isLoadings.changePublish = true;
    return API.admin.Vote.apiVotesActivateIdPost(this.id)
      .then(() => {
        this.rootStore.getVotings();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changePublish = false;
        });
      });
  };

  deactivate = () => {
    this.isLoadings.changePublish = true;
    return API.admin.Vote.apiVotesDeactivateIdPost(this.id)
      .then(() => {
        this.rootStore.getVotings();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changePublish = false;
        });
      });
  };

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }

  remove = () => {
    this.isLoadings.remove = true;
    return API.admin.Vote.apiVotesDeleteIdPost(this.id)
      .then(() => {
        this.rootStore.getVotings();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.remove = false;
        });
      });
  };
}

export interface IVotingsListItemForm {
  title: string;
  isBanner: boolean;
  isMain: boolean;
  isPublish: boolean;
  startDate?: Date | null;
  finishDate?: Date | null;
  id: string;
}

export interface IRootStore {
  getVotings: () => void;
}
