import React from "react";
import "./styles.scss";

interface IFlexRowProps {
  className?: string;
}

const FlexRow: React.FC<IFlexRowProps> = (props) => {
  return (
    <div className={["flex-row", props.className].join(" ")}>
      {props.children}
    </div>
  );
};

export default FlexRow;
