import * as yup from "yup";
import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "../../../constants/validation/validators";

export const formValidationSchema = yup.object().shape({
  lastName: VALIDATOR_STRING_REQUIRED,
  firstName: VALIDATOR_STRING_REQUIRED,
  middleName: VALIDATOR_ANY,
  email: VALIDATOR_STRING_REQUIRED,
  phone: VALIDATOR_ANY,
  otherPhones: VALIDATOR_ANY,
  login: VALIDATOR_STRING_REQUIRED,
  password: VALIDATOR_ANY,
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password")], "Пароли не совпадают")
    .nullable(),
  isDeactivated: VALIDATOR_ANY,
});
