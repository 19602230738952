import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { RubricsListItemStore } from "./RubricsListItemStore";

export class RubricsListPageStore {
  isLoadings = { getRubrics: false };

  rubricsList: RubricsListItemStore[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getRubrics();
  };

  reset = () => {
    this.resetRubrics();
  };

  resetRubrics = () => {
    this.rubricsList = [];
  };

  getRubrics = () => {
    this.isLoadings.getRubrics = true;
    return API.administration.NewsRubrics.newsRubricListGet()
      .then(({ data }) => {
        runInAction(() => {
          this.rubricsList = data.map((i) => new RubricsListItemStore(i, this));
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubrics = false;
        });
      });
  };
}
