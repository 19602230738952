/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding
 * Геокодирование
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';


/**
 * GeoCodingApi - axios parameter creator
 * @export
 */
export const GeoCodingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение адреса (улицы/улицы и дома) по координатам
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGet: async (latitude?: string, longitude?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение координат по адресу
         * @param {string} [address] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coordsGet: async (address?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeoCodingApi - functional programming interface
 * @export
 */
export const GeoCodingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeoCodingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение адреса (улицы/улицы и дома) по координатам
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressGet(latitude?: string, longitude?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressGet(latitude, longitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение координат по адресу
         * @param {string} [address] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coordsGet(address?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coordsGet(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeoCodingApi - factory interface
 * @export
 */
export const GeoCodingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeoCodingApiFp(configuration)
    return {
        /**
         * 
         * @summary получение адреса (улицы/улицы и дома) по координатам
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGet(latitude?: string, longitude?: string, options?: any): AxiosPromise<string> {
            return localVarFp.addressGet(latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение координат по адресу
         * @param {string} [address] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coordsGet(address?: string, options?: any): AxiosPromise<void> {
            return localVarFp.coordsGet(address, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeoCodingApi - interface
 * @export
 * @interface GeoCodingApi
 */
export interface GeoCodingApiInterface {
    /**
     * 
     * @summary получение адреса (улицы/улицы и дома) по координатам
     * @param {string} [latitude] 
     * @param {string} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoCodingApiInterface
     */
    addressGet(latitude?: string, longitude?: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary получение координат по адресу
     * @param {string} [address] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoCodingApiInterface
     */
    coordsGet(address?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * GeoCodingApi - object-oriented interface
 * @export
 * @class GeoCodingApi
 * @extends {BaseAPI}
 */
export class GeoCodingApi extends BaseAPI implements GeoCodingApiInterface {
    /**
     * 
     * @summary получение адреса (улицы/улицы и дома) по координатам
     * @param {string} [latitude] 
     * @param {string} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoCodingApi
     */
    public addressGet(latitude?: string, longitude?: string, options?: AxiosRequestConfig) {
        return GeoCodingApiFp(this.configuration).addressGet(latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение координат по адресу
     * @param {string} [address] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoCodingApi
     */
    public coordsGet(address?: string, options?: AxiosRequestConfig) {
        return GeoCodingApiFp(this.configuration).coordsGet(address, options).then((request) => request(this.axios, this.basePath));
    }
}


