import React from "react";

const IconFilter: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" width="2" height="17" fill="#565656"></rect>
      <rect x="7" width="2" height="17" fill="#565656"></rect>
      <rect x="12" width="2" height="17" fill="#565656"></rect>
      <rect
        x="10"
        y="5"
        width="2"
        height="6"
        transform="rotate(-90 10 5)"
        fill="#565656"
      ></rect>
      <rect
        y="13"
        width="2"
        height="6"
        transform="rotate(-90 0 13)"
        fill="#565656"
      ></rect>
    </svg>
  );
};

export default IconFilter;
