import { makeAutoObservable } from "mobx";

import { FormStore } from "../../../../../../kit/stores/FormStore";
import { formValidationSchema } from "./formValidationSchema";
import { IRoleStore } from "./index.types";
import { RoleModulesStore } from "./RoleModulesStore";

export class RolesFormsStore {
  form = new FormStore<IRoleStore>(
    {
      roleTitle: null,
      level: null,
    },
    formValidationSchema
  );

  modules = new RoleModulesStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.modules.init();
  };

  reset = () => {
    this.form.reset();
    this.modules.reset();
  };
}
