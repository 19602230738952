import React, {
  ChangeEvent,
  FocusEvent,
  MouseEventHandler,
  useRef,
  useState,
} from "react";
import Tooltip from "../Tooltip";
import MaskedInput from "./MaskedInput";
import "./styles.scss";
import { ITextFieldProps } from "./index.types";
import classNames from "classnames";

const TextField: React.FC<ITextFieldProps> = ({
  placeholder,
  className,
  type = "text",
  errorTitle,
  errorMessage,
  requiredLabel,
  mask,
  isRequired,
  isDisabled,
  onChange,
  onBlur,
  onFocus,
  value,
  ...props
}) => {
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);

  const openErrorTooltip = () => setShowErrorTooltip(true);
  const closeErrorTooltip = () => setShowErrorTooltip(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const toggleErrorTooltip = () => {
    setShowErrorTooltip((prev) => !prev);
  };

  const handleErrorButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    toggleErrorTooltip();
    inputRef.current?.focus();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, e.target.name);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    closeErrorTooltip();
    onBlur && onBlur(e);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    openErrorTooltip();
    onFocus && onFocus(e);
  };

  return (
    <div
      className={classNames(
        "text-field",
        errorMessage && "text-field--error",
        className
      )}
      data-testid="TextField"
    >
      {mask ? (
        <MaskedInput
          {...props}
          type={type}
          className={"text-field__input"}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          mask={mask}
          ref={inputRef}
          required={isRequired}
          disabled={isDisabled}
          onChange={onChange}
          value={value || ""}
        />
      ) : (
        <input
          {...props}
          type={type}
          className={"text-field__input"}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          required={isRequired}
          disabled={isDisabled}
          onChange={handleChange}
          value={value || ""}
        />
      )}

      {placeholder && (
        <label className={"text-field__label"} htmlFor={props.id}>
          {placeholder}
        </label>
      )}

      {errorMessage && (
        <button
          className={"text-field__error-button"}
          aria-label="Информация об ошибке"
          onMouseDown={handleErrorButtonClick}
          tabIndex={-1}
        />
      )}

      {errorMessage && showErrorTooltip && (
        <Tooltip
          onClose={closeErrorTooltip}
          title={errorTitle}
          text={errorMessage}
          className="text-field__tooltip"
        />
      )}

      {isRequired && (
        <div className="text-field__required-label">
          {requiredLabel ? requiredLabel : "Обязательно"}
        </div>
      )}
    </div>
  );
};

export default TextField;
