import { observer } from "mobx-react";
import React from "react";
import HeaderLogo from "../HeaderLogo";
import HeaderNavigation from "../HeaderNavigation";
import HeaderProfile from "../HeaderProfile";
import "./styles.scss";

interface IProps {
  links: {
    text: string;
    to: string;
  }[];
  isAuth: boolean;
  userName?: string | null;
  onLogout: () => void;
  headerTitle: string;
}

const Header: React.FC<IProps> = (props) => {
  return (
    <header className="header">
      <div className="header__bottom">
        <div className="container">
          <div className="header__bottom-body">
            <HeaderLogo />
            <div className="header__bottom-body-central">
              <HeaderNavigation links={props.links} />
            </div>
            <div className="header__bottom-body-divider" />
            <HeaderProfile
              userName={props.userName}
              isAuth={props.isAuth}
              onLogout={props.onLogout}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default observer(Header);
