/* tslint:disable */
/* eslint-disable */
/**
 * Access API
 * Модуль распределения доступа
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface OkResult
 */
export interface OkResult {
    /**
     * 
     * @type {number}
     * @memberof OkResult
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderingOrder = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type OrderingOrder = typeof OrderingOrder[keyof typeof OrderingOrder];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProfileEditOwnForm
 */
export interface ProfileEditOwnForm {
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'surName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'otherPhones'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileEditOwnForm
     */
    'passwordConfirm'?: string | null;
}
/**
 * 
 * @export
 * @interface ProfileUserModel
 */
export interface ProfileUserModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUserModel
     */
    'surName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUserModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUserModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUserModel
     */
    'otherPhones'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileUserModel
     */
    'login'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileUserModel
     */
    'isDeactivated'?: boolean;
}
/**
 * 
 * @export
 * @interface RoleForm
 */
export interface RoleForm {
    /**
     * 
     * @type {string}
     * @memberof RoleForm
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof RoleForm
     */
    'level': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleForm
     */
    'availableInModules'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RoleForm
     */
    'permissions'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface RoleFormHolder
 */
export interface RoleFormHolder {
    /**
     * 
     * @type {RoleForm}
     * @memberof RoleFormHolder
     */
    'form'?: RoleForm;
}
/**
 * 
 * @export
 * @interface SelectListGroup
 */
export interface SelectListGroup {
    /**
     * 
     * @type {boolean}
     * @memberof SelectListGroup
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectListGroup
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface SelectListItem
 */
export interface SelectListItem {
    /**
     * 
     * @type {boolean}
     * @memberof SelectListItem
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {SelectListGroup}
     * @memberof SelectListItem
     */
    'group'?: SelectListGroup;
    /**
     * 
     * @type {boolean}
     * @memberof SelectListItem
     */
    'selected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectListItem
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectListItem
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCredentialsForm
 */
export interface UserCredentialsForm {
    /**
     * 
     * @type {Array<UserCredentialsOrganizationForm>}
     * @memberof UserCredentialsForm
     */
    'organizations'?: Array<UserCredentialsOrganizationForm> | null;
}
/**
 * 
 * @export
 * @interface UserCredentialsOrganizationForm
 */
export interface UserCredentialsOrganizationForm {
    /**
     * 
     * @type {string}
     * @memberof UserCredentialsOrganizationForm
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredentialsOrganizationForm
     */
    'organizationTitle'?: string | null;
    /**
     * 
     * @type {Array<UserRoleForm>}
     * @memberof UserCredentialsOrganizationForm
     */
    'roles'?: Array<UserRoleForm> | null;
}
/**
 * 
 * @export
 * @interface UserDataForm
 */
export interface UserDataForm {
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'otherPhones'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'login': string;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDataForm
     */
    'passwordConfirm'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDataForm
     */
    'isDeactivated'?: boolean;
}
/**
 * 
 * @export
 * @interface UserForm
 */
export interface UserForm {
    /**
     * 
     * @type {UserDataForm}
     * @memberof UserForm
     */
    'data'?: UserDataForm;
    /**
     * 
     * @type {UserCredentialsForm}
     * @memberof UserForm
     */
    'credentials'?: UserCredentialsForm;
}
/**
 * 
 * @export
 * @interface UserFormHolder
 */
export interface UserFormHolder {
    /**
     * 
     * @type {UserForm}
     * @memberof UserFormHolder
     */
    'form'?: UserForm;
}
/**
 * 
 * @export
 * @interface UserItemModel
 */
export interface UserItemModel {
    /**
     * 
     * @type {string}
     * @memberof UserItemModel
     */
    'id'?: string;
    /**
     * 
     * @type {UserDataForm}
     * @memberof UserItemModel
     */
    'data'?: UserDataForm;
    /**
     * 
     * @type {UserCredentialsForm}
     * @memberof UserItemModel
     */
    'credentials'?: UserCredentialsForm;
}
/**
 * 
 * @export
 * @interface UserListFilterForm
 */
export interface UserListFilterForm {
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'fio'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'roleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'surName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListFilterForm
     */
    'adLogin'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListFilterForm
     */
    'deactivated'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserListFilterForm
     */
    'organizationIds'?: Array<string> | null;
    /**
     * 
     * @type {UserListOrdering}
     * @memberof UserListFilterForm
     */
    'orderColumn'?: UserListOrdering;
    /**
     * 
     * @type {OrderingOrder}
     * @memberof UserListFilterForm
     */
    'order'?: OrderingOrder;
    /**
     * 
     * @type {number}
     * @memberof UserListFilterForm
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface UserListItemModel
 */
export interface UserListItemModel {
    /**
     * 
     * @type {string}
     * @memberof UserListItemModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListItemModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListItemModel
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListItemModel
     */
    'adLogin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListItemModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListItemModel
     */
    'notInThisSystem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserListItemModel
     */
    'isDeactivated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserListItemModel
     */
    'isOnline'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserListItemModel
     */
    'lastActionPeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListItemModel
     */
    'lastActionDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserListItemModel
     */
    'organizations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserListItemModel
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserListModel
 */
export interface UserListModel {
    /**
     * 
     * @type {UserListFilterForm}
     * @memberof UserListModel
     */
    'form'?: UserListFilterForm;
    /**
     * 
     * @type {Array<SelectListItem>}
     * @memberof UserListModel
     */
    'organizations'?: Array<SelectListItem> | null;
    /**
     * 
     * @type {Array<SelectListItem>}
     * @memberof UserListModel
     */
    'roles'?: Array<SelectListItem> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListModel
     */
    'canCreate'?: boolean;
    /**
     * 
     * @type {Array<UserListItemModel>}
     * @memberof UserListModel
     */
    'users'?: Array<UserListItemModel> | null;
    /**
     * 
     * @type {Array<SelectListItem>}
     * @memberof UserListModel
     */
    'orderColumns'?: Array<SelectListItem> | null;
    /**
     * 
     * @type {Array<SelectListItem>}
     * @memberof UserListModel
     */
    'orders'?: Array<SelectListItem> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserListOrdering = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type UserListOrdering = typeof UserListOrdering[keyof typeof UserListOrdering];


/**
 * 
 * @export
 * @interface UserRoleForm
 */
export interface UserRoleForm {
    /**
     * 
     * @type {string}
     * @memberof UserRoleForm
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleForm
     */
    'roleTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface UsersSetPhotoResponse
 */
export interface UsersSetPhotoResponse {
    /**
     * 
     * @type {string}
     * @memberof UsersSetPhotoResponse
     */
    'fileId'?: string;
}

/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Метод для получения операций и правил к ним
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonOperationRulesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/common/operationRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Метод для получения операций и правил к ним
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommonOperationRulesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommonOperationRulesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary Метод для получения операций и правил к ним
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonOperationRulesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCommonOperationRulesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - interface
 * @export
 * @interface CommonApi
 */
export interface CommonApiInterface {
    /**
     * 
     * @summary Метод для получения операций и правил к ним
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApiInterface
     */
    apiCommonOperationRulesGet(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI implements CommonApiInterface {
    /**
     * 
     * @summary Метод для получения операций и правил к ним
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public apiCommonOperationRulesGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).apiCommonOperationRulesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание роли
         * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesCreatePost: async (roleFormHolder?: RoleFormHolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleFormHolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование роли
         * @param {string} roleId Идентификатор роли
         * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesEditRoleIdPost: async (roleId: string, roleFormHolder?: RoleFormHolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiRolesEditRoleIdPost', 'roleId', roleId)
            const localVarPath = `/api/roles/edit/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleFormHolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список ролей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка модулей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesModulesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles/modules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Информация о роли
         * @param {string} roleId Идентификатор роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesRoleIdGet: async (roleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiRolesRoleIdGet', 'roleId', roleId)
            const localVarPath = `/api/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание роли
         * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesCreatePost(roleFormHolder?: RoleFormHolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesCreatePost(roleFormHolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование роли
         * @param {string} roleId Идентификатор роли
         * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesEditRoleIdPost(roleId: string, roleFormHolder?: RoleFormHolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesEditRoleIdPost(roleId, roleFormHolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список ролей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка модулей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesModulesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesModulesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Информация о роли
         * @param {string} roleId Идентификатор роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesRoleIdGet(roleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesRoleIdGet(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание роли
         * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesCreatePost(roleFormHolder?: RoleFormHolder, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesCreatePost(roleFormHolder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование роли
         * @param {string} roleId Идентификатор роли
         * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesEditRoleIdPost(roleId: string, roleFormHolder?: RoleFormHolder, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesEditRoleIdPost(roleId, roleFormHolder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список ролей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка модулей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesModulesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesModulesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Информация о роли
         * @param {string} roleId Идентификатор роли
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesRoleIdGet(roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesRoleIdGet(roleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - interface
 * @export
 * @interface RolesApi
 */
export interface RolesApiInterface {
    /**
     * 
     * @summary Создание роли
     * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApiInterface
     */
    apiRolesCreatePost(roleFormHolder?: RoleFormHolder, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование роли
     * @param {string} roleId Идентификатор роли
     * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApiInterface
     */
    apiRolesEditRoleIdPost(roleId: string, roleFormHolder?: RoleFormHolder, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Список ролей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApiInterface
     */
    apiRolesGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка модулей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApiInterface
     */
    apiRolesModulesGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Информация о роли
     * @param {string} roleId Идентификатор роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApiInterface
     */
    apiRolesRoleIdGet(roleId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI implements RolesApiInterface {
    /**
     * 
     * @summary Создание роли
     * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesCreatePost(roleFormHolder?: RoleFormHolder, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesCreatePost(roleFormHolder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование роли
     * @param {string} roleId Идентификатор роли
     * @param {RoleFormHolder} [roleFormHolder] Форма для создания роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesEditRoleIdPost(roleId: string, roleFormHolder?: RoleFormHolder, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesEditRoleIdPost(roleId, roleFormHolder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список ролей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesGet(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка модулей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesModulesGet(options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesModulesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Информация о роли
     * @param {string} roleId Идентификатор роли
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesRoleIdGet(roleId: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesRoleIdGet(roleId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Метод активации пользователя
         * @param {string} [userId] Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersActivateGet: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод для создания пользователя
         * @param {UserFormHolder} [userFormHolder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersCreatePost: async (userFormHolder?: UserFormHolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFormHolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод деактивации пользователя
         * @param {string} [userId] Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDeactivateGet: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/deactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод редактирования пользователя
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {UserFormHolder} [userFormHolder] Форма редактирования пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersEditUserIdPost: async (userId: string, userFormHolder?: UserFormHolder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUsersEditUserIdPost', 'userId', userId)
            const localVarPath = `/api/users/edit/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userFormHolder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Карточка пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdGet', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод редактирования данных текущего пользователя
         * @param {ProfileEditOwnForm} [profileEditOwnForm] Форма редактирования пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersOwnEditPost: async (profileEditOwnForm?: ProfileEditOwnForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/own/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileEditOwnForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод получения данных текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersOwnGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод получения фото по id
         * @param {string} fileId Уникальный идентификатор фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPhotoFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiUsersPhotoFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/users/photo/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод удаления фотографии пользователя
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPhotoUserIdDelete: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUsersPhotoUserIdDelete', 'userId', userId)
            const localVarPath = `/api/users/photo/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Метод редактирования фото
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {any} file Файл
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUserIdPhotoPost: async (userId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUsersUserIdPhotoPost', 'userId', userId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiUsersUserIdPhotoPost', 'file', file)
            const localVarPath = `/api/users/{userId}/photo`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Метод активации пользователя
         * @param {string} [userId] Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersActivateGet(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersActivateGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод для создания пользователя
         * @param {UserFormHolder} [userFormHolder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersCreatePost(userFormHolder?: UserFormHolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersCreatePost(userFormHolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод деактивации пользователя
         * @param {string} [userId] Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersDeactivateGet(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersDeactivateGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод редактирования пользователя
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {UserFormHolder} [userFormHolder] Форма редактирования пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersEditUserIdPost(userId: string, userFormHolder?: UserFormHolder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersEditUserIdPost(userId, userFormHolder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Карточка пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод редактирования данных текущего пользователя
         * @param {ProfileEditOwnForm} [profileEditOwnForm] Форма редактирования пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersOwnEditPost(profileEditOwnForm?: ProfileEditOwnForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersOwnEditPost(profileEditOwnForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод получения данных текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersOwnGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersOwnGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод получения фото по id
         * @param {string} fileId Уникальный идентификатор фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPhotoFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPhotoFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод удаления фотографии пользователя
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPhotoUserIdDelete(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPhotoUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Метод редактирования фото
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {any} file Файл
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersUserIdPhotoPost(userId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersSetPhotoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUserIdPhotoPost(userId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Метод активации пользователя
         * @param {string} [userId] Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersActivateGet(userId?: string, options?: any): AxiosPromise<OkResult> {
            return localVarFp.apiUsersActivateGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод для создания пользователя
         * @param {UserFormHolder} [userFormHolder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersCreatePost(userFormHolder?: UserFormHolder, options?: any): AxiosPromise<string> {
            return localVarFp.apiUsersCreatePost(userFormHolder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод деактивации пользователя
         * @param {string} [userId] Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersDeactivateGet(userId?: string, options?: any): AxiosPromise<OkResult> {
            return localVarFp.apiUsersDeactivateGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод редактирования пользователя
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {UserFormHolder} [userFormHolder] Форма редактирования пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersEditUserIdPost(userId: string, userFormHolder?: UserFormHolder, options?: any): AxiosPromise<OkResult> {
            return localVarFp.apiUsersEditUserIdPost(userId, userFormHolder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Список пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(options?: any): AxiosPromise<UserListModel> {
            return localVarFp.apiUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Карточка пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet(id: string, options?: any): AxiosPromise<UserItemModel> {
            return localVarFp.apiUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод редактирования данных текущего пользователя
         * @param {ProfileEditOwnForm} [profileEditOwnForm] Форма редактирования пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersOwnEditPost(profileEditOwnForm?: ProfileEditOwnForm, options?: any): AxiosPromise<OkResult> {
            return localVarFp.apiUsersOwnEditPost(profileEditOwnForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод получения данных текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersOwnGet(options?: any): AxiosPromise<ProfileUserModel> {
            return localVarFp.apiUsersOwnGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод получения фото по id
         * @param {string} fileId Уникальный идентификатор фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPhotoFileIdGet(fileId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiUsersPhotoFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод удаления фотографии пользователя
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPhotoUserIdDelete(userId: string, options?: any): AxiosPromise<OkResult> {
            return localVarFp.apiUsersPhotoUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Метод редактирования фото
         * @param {string} userId Уникальный идентификатор пользователя
         * @param {any} file Файл
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUserIdPhotoPost(userId: string, file: any, options?: any): AxiosPromise<UsersSetPhotoResponse> {
            return localVarFp.apiUsersUserIdPhotoPost(userId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @summary Метод активации пользователя
     * @param {string} [userId] Уникальный идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersActivateGet(userId?: string, options?: AxiosRequestConfig): AxiosPromise<OkResult>;

    /**
     * 
     * @summary Метод для создания пользователя
     * @param {UserFormHolder} [userFormHolder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersCreatePost(userFormHolder?: UserFormHolder, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Метод деактивации пользователя
     * @param {string} [userId] Уникальный идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersDeactivateGet(userId?: string, options?: AxiosRequestConfig): AxiosPromise<OkResult>;

    /**
     * 
     * @summary Метод редактирования пользователя
     * @param {string} userId Уникальный идентификатор пользователя
     * @param {UserFormHolder} [userFormHolder] Форма редактирования пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersEditUserIdPost(userId: string, userFormHolder?: UserFormHolder, options?: AxiosRequestConfig): AxiosPromise<OkResult>;

    /**
     * 
     * @summary Список пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersGet(options?: AxiosRequestConfig): AxiosPromise<UserListModel>;

    /**
     * 
     * @summary Карточка пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<UserItemModel>;

    /**
     * 
     * @summary Метод редактирования данных текущего пользователя
     * @param {ProfileEditOwnForm} [profileEditOwnForm] Форма редактирования пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersOwnEditPost(profileEditOwnForm?: ProfileEditOwnForm, options?: AxiosRequestConfig): AxiosPromise<OkResult>;

    /**
     * 
     * @summary Метод получения данных текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersOwnGet(options?: AxiosRequestConfig): AxiosPromise<ProfileUserModel>;

    /**
     * 
     * @summary Метод получения фото по id
     * @param {string} fileId Уникальный идентификатор фото
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersPhotoFileIdGet(fileId: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * 
     * @summary Метод удаления фотографии пользователя
     * @param {string} userId Уникальный идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersPhotoUserIdDelete(userId: string, options?: AxiosRequestConfig): AxiosPromise<OkResult>;

    /**
     * 
     * @summary Метод редактирования фото
     * @param {string} userId Уникальный идентификатор пользователя
     * @param {any} file Файл
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersUserIdPhotoPost(userId: string, file: any, options?: AxiosRequestConfig): AxiosPromise<UsersSetPhotoResponse>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @summary Метод активации пользователя
     * @param {string} [userId] Уникальный идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersActivateGet(userId?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersActivateGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод для создания пользователя
     * @param {UserFormHolder} [userFormHolder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersCreatePost(userFormHolder?: UserFormHolder, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersCreatePost(userFormHolder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод деактивации пользователя
     * @param {string} [userId] Уникальный идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersDeactivateGet(userId?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersDeactivateGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод редактирования пользователя
     * @param {string} userId Уникальный идентификатор пользователя
     * @param {UserFormHolder} [userFormHolder] Форма редактирования пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersEditUserIdPost(userId: string, userFormHolder?: UserFormHolder, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersEditUserIdPost(userId, userFormHolder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Список пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Карточка пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод редактирования данных текущего пользователя
     * @param {ProfileEditOwnForm} [profileEditOwnForm] Форма редактирования пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersOwnEditPost(profileEditOwnForm?: ProfileEditOwnForm, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersOwnEditPost(profileEditOwnForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод получения данных текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersOwnGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersOwnGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод получения фото по id
     * @param {string} fileId Уникальный идентификатор фото
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPhotoFileIdGet(fileId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersPhotoFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод удаления фотографии пользователя
     * @param {string} userId Уникальный идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPhotoUserIdDelete(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersPhotoUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Метод редактирования фото
     * @param {string} userId Уникальный идентификатор пользователя
     * @param {any} file Файл
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUserIdPhotoPost(userId: string, file: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersUserIdPhotoPost(userId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


