import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FormStore } from "newApp/kit/stores/FormStore";
import validationSchema from "./validationSchema";

class ProjectDescriptionEditPageStore {
  isLoadings = {
    getDescription: false,
    saveDescription: false,
  };

  form = new FormStore(
    {
      description: null,
    },
    validationSchema
  );

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getDescription();
  };

  reset = () => {
    this.form.reset();
    this.isLoadings.getDescription = false;
    this.isLoadings.saveDescription = false;
  };

  getDescription = () => {
    this.isLoadings.getDescription = true;
    return API.administration.ProjectDescription.projectdescriptionGet()
      .then(({ data }) => {
        runInAction(() => {
          this.form.changeValueByKey(data.content, "description");
        });
      })
      .catch(() => {
        runInAction(() => {});
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getDescription = false;
        });
      });
  };

  onSave = async () => {
    await this.form.validate();
    if (this.form.isValid) {
      return await this.saveDescription();
    }
  };

  saveDescription = () => {
    this.isLoadings.saveDescription = true;
    return API.administration.ProjectDescription.projectdescriptionPost({
      content: this.form.values.description,
    })
      .then(() => true)
      .catch((e) => {
        console.error(e);
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveDescription = false;
        });
      });
  };
}

export default new ProjectDescriptionEditPageStore();
