import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../../api";
import { ICascadeCheckboxesOption } from "../../../../kit/components/CascadeCheckboxes/index.types";
import { TUserOrganization } from "../index.types";

export class RolesStore {
  oraganizations: { text: string; value: string }[] = [];
  roles: { text: string; value: string }[] = [];
  private _values: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getRolesAndOrganizations();
  };

  reset = () => {
    this.oraganizations = [];
    this.roles = [];
    this._values = [];
  };

  get values() {
    return this._values;
  }

  generateId = (values: string[]) => {
    return values.join("__");
  };

  setValues(organizations: TUserOrganization[]) {
    const values: string[] = [];
    organizations.forEach((org) => {
      values.push(org.id);
      org.roles.forEach((role) => {
        values.push(this.generateId([org.id, role.id]));
      });
    });
    this._values = values;
  }

  /** Возвращает список для CascadeCheckboxes */
  get rolesOptions(): ICascadeCheckboxesOption<string>[] {
    const roles: ICascadeCheckboxesOption<string>[] = [];
    this.oraganizations.forEach((org) => {
      roles.push({
        id: org.value,
        text: org.text,
      });

      this.roles.forEach((role) => {
        roles.push({
          id: this.generateId([org.value, role.value]),
          text: role.text,
          parentId: org.value,
        });
      });
    });
    return roles;
  }

  onChange = (value: string, name?: string, values?: string[]) => {
    if (values) this._values = values;
  };

  getRolesAndOrganizations = () => {
    return API.access.Users.apiUsersGet()
      .then(({ data }) => {
        runInAction(() => {
          try {
            this.oraganizations = (data as any)?.organizations?.map(
              (o: { text: string; value: string }) => ({
                text: o.text || "",
                value: o.value || "",
              })
            );
            this.roles = (data as any)?.roles?.map(
              (o: { text: string; value: string }) => ({
                text: o.text || "",
                value: o.value || "",
              })
            );
          } catch (error) {
            console.error("Error in RolesStore.getRolesAndOrganizations");
          }
        });
      })
      .catch(() => {
        runInAction(() => {
          this.oraganizations = [];
          this.roles = [];
        });
      });
  };

  get organizationsForSave() {
    type TOrganization = {
      id: string;
      organizationTitle: string | null;
      roles: { id: string; roleTitle: string | null }[];
    };
    const organizations: TOrganization[] = [];
    this.oraganizations.forEach((org) => {
      if (this.values.includes(org.value)) {
        const organization: TOrganization = {
          id: org.value,
          organizationTitle: org.text,
          roles: [],
        };
        this.roles.forEach((role) => {
          this.values.forEach((value) => {
            if (value === this.generateId([org.value, role.value])) {
              organization.roles.push({
                id: role.value,
                roleTitle: role.text,
              });
            }
          });
        });
        organizations.push(organization);
      }
    });
    return organizations;
  }
}
