import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { DeclarationChatStore } from "newApp/stores/common/DeclarationChatStore";

import { DeclarationHistoryStore } from "../../../common/DeclarationHistoryStore";
import { ExecutionStepStore } from "./ExecutionStepStore";
import {
  TExecutionDeclarationInfo,
  TGetExecutionResponseData,
} from "./index.types";

export class ExecutionItemPageStore {
  isLoadings = {
    getDeclaration: false,
  };
  info: TExecutionDeclarationInfo = defaultInfo;
  id: string = "";
  history = new DeclarationHistoryStore();
  chat = new DeclarationChatStore();
  files: IFilesPreviewFile[] = [];
  private allSteps: ExecutionStepStore[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getDeclaration();
    this.history.init(this.id);
    this.chat.init(id);
    this.makeDeclarationChecked();
  };

  reset = () => {
    this.id = "";
    this.resetInfo();
    this.history.reset();
    this.chat.reset();
  };

  resetInfo = () => {
    this.info = defaultInfo;
  };

  getDeclaration = () => {
    this.isLoadings.getDeclaration = true;
    return API.admin.ViolationExecution.apiViolationExecutionViolationsIdGet(
      this.id
    )
      .then((res) => {
        const data = res.data as TGetExecutionResponseData;
        runInAction(() => {
          this.info.id = data.id;
          this.info.category = data.category;
          this.info.address = data.address || null;
          this.info.dateOfCreation = data.dateOfCreation
            ? new Date(data.dateOfCreation).toLocaleDateString()
            : null;
          this.info.daysLeft = data.daysLeft || null;
          this.info.email = data.author?.email || null;
          this.info.firstName = data.author?.firstName || null;
          this.info.isOnAddControl = data.isOnAddControl || null;
          this.info.isPublished = data.isPublished || null;
          this.info.lastName = data.author?.lastName || null;
          this.info.mobile = data.author?.mobile || null;
          this.info.number = data.number || null;
          this.info.organizationTitle = data.organizationTitle || null;
          this.info.status = data.status || null;
          this.info.subCategories = data.subCategories
            ? data.subCategories.join(", ")
            : null;
          this.info.latitude = data.coordinates.latitude || null;
          this.info.longitude = data.coordinates.longitude || null;

          this.files = [];
          data.photoIds.forEach((id) => {
            this.files.push({
              reference: URL_API_ADMIN + "/api/violations/file?fileId=" + id,
              type: "Image",
              id: id,
            });
          });
          data.videoIds.forEach((id) => {
            this.files.push({
              reference: URL_API_ADMIN + "/api/violations/file?fileId=" + id,
              type: "Video",
              id: id,
            });
          });
          data.docIds.forEach((id) => {
            this.files.push({
              reference: URL_API_ADMIN + "/api/violations/file?fileId=" + id,
              id: id,
            });
          });

          this.allSteps = [];
          data?.steps?.forEach((step) => {
            console.log("step", step);

            this.allSteps.push(
              new ExecutionStepStore({
                ...step,
                getDeclaration: () => this.getDeclaration(),
                declarationId: this.id,
                declarationImages: this.files.filter((i) => i.type === "Image"),
              })
            );
          });
        });
      })
      .catch(this.resetInfo)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getDeclaration = false;
        });
      });
  };

  onClickPublish = () => {
    return API.admin.Violations.apiViolationsIdPublishPost(this.id).finally(
      () => this.getDeclaration()
    );
  };

  onClickAdditionalControl = () => {
    return API.admin.Violations.apiViolationsAdditionalControlPost(
      this.id
    ).finally(() => this.getDeclaration());
  };

  makeDeclarationChecked = () => {
    return API.admin.ViolationExecution.apiViolationExecutionIdCheckPost(
      this.id
    );
  };

  get steps(): ExecutionStepStore[] {
    const comletedSteps = this.allSteps.filter((s) => !!s.isDone);
    const currentStep = this.allSteps[comletedSteps.length];
    const steps = [...comletedSteps];
    if (currentStep) steps.push(currentStep);
    return steps;
  }
}

const defaultInfo: TExecutionDeclarationInfo = {
  address: null,
  dateOfCreation: null,
  daysLeft: null,
  email: null,
  firstName: null,
  isOnAddControl: null,
  isPublished: null,
  lastName: null,
  mobile: null,
  number: null,
  organizationTitle: null,
  subCategories: null,
  status: null,
  latitude: null,
  longitude: null,
  id: null,
  category: null,
};
