import { observer } from "mobx-react";
import React from "react";
import FlexColumn from "../../kit/components/FlexColumn";
import TextField from "../../kit/components/TextField";
import { FormStore } from "../../kit/stores/FormStore";
import { IRoleStore } from "../../stores/pages/admission/roles/RoleItemEditPage/RolesFormsStore/index.types";

interface IProps {
  form: FormStore<IRoleStore>;
}

const RoleEditCreateForm: React.FC<IProps> = (props) => {
  return (
    <FlexColumn>
      <TextField placeholder="Название" {...props.form.adapters.roleTitle} />
      <TextField placeholder="Уровень доступа" {...props.form.adapters.level} />
    </FlexColumn>
  );
};

export default observer(RoleEditCreateForm);
