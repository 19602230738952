import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { getDataURL } from "newApp/kit/utils/getDataURL";

export class NewsFormFilesStore {
  constructor() {
    makeAutoObservable(this);
  }

  filesPreviews: IFilesPreviewFile[] = [];
  files: { id?: string; name?: string }[] = [];
  filesForDeleteIds: string[] = [];

  init = () => {};

  reset = () => {
    this.resetFiles();
    this.filesForDeleteIds = [];
  };

  resetFiles = () => {
    this.filesPreviews = [];
  };

  onClickRemoveFiles = (idx: number, file?: IFilesPreviewFile) => {
    this.filesForDeleteIds.push(file.id);
    this.filesPreviews = this.filesPreviews.filter((i) => i !== file);
  };

  onAddFiles = (files: File[]) => {
    files.forEach((file) => {
      getDataURL(file)
        .then((reference) => {
          runInAction(() => {
            this.filesPreviews.push({
              reference,
              type: file.type.split("/")[0],
              title: file.name,
              file,
            });
          });
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  setFiles = (files: { id?: string; name?: string }[]) => {
    this.resetFiles();
    this.files = files;
    this.filesPreviews = files.map((f) => ({
      reference: URL_API_ADMINISTRATION + `/news/file/${f.id}`,
      title: f.name,
      id: f.id,
    }));
  };

  changeFilesOnServer = async (newsId: string) => {
    await this.removeFiles();
    await this.uploadFiles(newsId);
  };

  uploadFiles = async (newsId: string) => {
    for await (const i of this.filesPreviews.filter((i) => !!i.file)) {
      await API.administration.News.newsNewsIdFilePost(newsId, i.file);
    }
  };

  removeFiles = async () => {
    for await (const id of this.filesForDeleteIds) {
      await API.administration.News.newsFileIdDelete(id);
    }
    runInAction(() => {
      this.filesForDeleteIds = [];
    });
  };
}
