import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import Checkboxes from "newApp/kit/components/Checkboxes";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import Select from "newApp/kit/components/Select";
import { StepsFormStore } from "newApp/stores/common/StepsFormStore";
import React from "react";
import CategorySelect from "../CategorySelect";
import DashedButton from "../DashedButton";
import StepsContainer from "../StepsContainer";
import "./styles.scss";

export interface IStepsFormProps extends Omit<StepsFormStore, "isValid"> {}

const StepsForm: React.FC<IStepsFormProps> = (props) => {
  return (
    <>
      <StepsContainer
        steps={[
          {
            name: "Категория, для которой необходимо создать шаги",
            number: " ",
            body: (
              <FlexColumn>
                <CategorySelect
                  options={props.options.categories}
                  {...props.form.adapters.categoryId}
                />
                <Divider />
              </FlexColumn>
            ),
          },
          ...(!!props.form.values.categoryId
            ? [
                ...props.steps.map((step, idx) => {
                  return {
                    name: (
                      <div className="step-header">
                        <div>{step.stepName}</div>
                        <DashedButton
                          onClick={() => props.removeStep(idx)}
                          color="red"
                        >
                          Удалить
                        </DashedButton>
                      </div>
                    ),
                    number: String(idx + 1),
                    body: (
                      <FlexColumn>
                        <Select
                          placeholder="Тип шага"
                          options={step.options.stepType}
                          {...step.form.adapters.stepType}
                          onChange={step.onChangeStepTypes}
                        />
                        {!!step.options.fields.length && (
                          <Checkboxes
                            label="Поля заполняемы на этом шаге"
                            onChange={step.onChangeFields}
                            options={step.options.fields}
                            values={step.fields}
                            name={"fields"}
                          />
                        )}
                        <div />
                      </FlexColumn>
                    ),
                  };
                }),
                {
                  name: "Добавить шаг",
                  number: " ",
                  body: (
                    <div>
                      <Button onClick={() => props.addStep()} size="small">
                        Добавить шаг
                      </Button>
                    </div>
                  ),
                },
              ]
            : []),
        ]}
      />
    </>
  );
};

export default observer(StepsForm);
