interface ICoords {
  latitude: number;
  longitude: number;
}

export const compareCoords = (
  coords1: ICoords,
  coords2: ICoords,
  /** Точность сравнения координат, количество знаков после запятой */
  accuracy = 3
) => {
  return (
    Number(coords1.latitude).toFixed(accuracy) ===
      Number(coords2.latitude).toFixed(accuracy) &&
    Number(coords1.longitude).toFixed(accuracy) ===
      Number(coords2.longitude).toFixed(accuracy)
  );
};
