import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_SHOP } from "newApp/api/urls";
import { SettingsPageStore } from "newApp/stores/pages/shop/settings";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";

export class ProductsPageStore {
  productsList: IProductsListItem[] = [];
  mode: boolean | null = null;

  settings = new SettingsPageStore();
  pagination = new PaginationStore({}, () => this.getProducts(this.mode));

  isLoadings = {
    getProducts: false,
    changeActive: false,
    deleteProduct: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = (mode: boolean) => {
    this.mode = mode;
    this.getProducts(mode);
    this.settings.init();
  };

  getProducts = (isActive: boolean) => {
    this.isLoadings.getProducts = true;

    const skip = (this.pagination.currentPage - 1) * this.pagination.pageSize;
    const take = this.pagination.pageSize;

    return API.shop.Products.apiProductsGet(isActive, skip, take)
      .then(({ data }) => {
        runInAction(() => {
          this.productsList = data["items"].map((item: IProductsListItem) => ({
            id: item.id || null,
            title: item.title || null,
            creationDate: item.creationDate
              ? new Date(item.creationDate).toLocaleDateString()
              : null,
            imageId: item.imageId
              ? `${URL_API_SHOP}/api/products/image/${item.imageId}`
              : null,
            isActive: item.isActive || false,
          }));

          this.pagination.pages = Math.ceil(
            data["count"] / this.pagination.pageSize
          );
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getProducts = false;
          window.scrollTo({ behavior: "smooth", top: 0 });
        });
      });
  };

  onClickDelete = (id: string) => {
    this.isLoadings.deleteProduct = true;

    return API.shop.Products.apiProductsDeactivateIdPost(id)
      .then(() => {
        runInAction(() => {
          this.getProducts(this.mode);
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.deleteProduct = false;
        });
      });
  };

  toggleIsActive = (isActive: boolean, id: string) => {
    isActive
      ? this.deactivateProduct(id).then(() => this.getProducts(this.mode))
      : this.activateProduct(id).then(() => this.getProducts(this.mode));
  };

  activateProduct = (id: string) => {
    this.isLoadings.changeActive = true;

    return API.shop.Products.apiProductsActivateIdPost(id)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeActive = false;
        });
      });
  };
  deactivateProduct = (id: string) => {
    this.isLoadings.changeActive = true;

    return API.shop.Products.apiProductsDeactivateIdPost(id)
      .then(() => {
        runInAction(() => {
          this.getProducts(this.mode);
        });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeActive = false;
        });
      });
  };

  reset = () => {
    this.productsList = [];
    this.pagination.reset();
    this.mode = null;
  };

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }
}

export interface IProductsListItem {
  id: string | null;
  title: string | null;
  creationDate: Date | string | null;
  imageId: string | null;
  isActive: boolean | null;
}
