import { FC } from "react";

interface IIconPros {
  className?: string;
}

const IconError: FC<IIconPros> = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="#CE0A24"
      />
      <path d="M15 7H3V11H15V7Z" fill="white" />
    </svg>
  );
};

export default IconError;
