import React from "react";
import Checkbox from "../../CheckBox";
import { ICheckboxProps } from "../../CheckBox/index.types";

interface IProps extends ICheckboxProps {
  text: React.ReactNode;
  isShowBody?: boolean;
}

const CascadeCheckboxesItem: React.FC<IProps> = ({
  isShowBody = false,
  text,
  ...props
}) => {
  return (
    <div className="cascade-checkbox-item">
      <Checkbox {...props}>{text}</Checkbox>
      {(!!props.value || !!isShowBody) && (
        <div className="cascade-checkbox-item__body">{props.children}</div>
      )}
    </div>
  );
};

export default CascadeCheckboxesItem;
