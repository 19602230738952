import { toJS } from "mobx";
import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import { ExecutionStepStore } from "newApp/stores/pages/execution/ExecutionItemPageStore/ExecutionStepStore";
import { TExecutionDeclarationInfo } from "newApp/stores/pages/execution/ExecutionItemPageStore/index.types";
import React from "react";

import DeclarationInfo from "../DeclarationInfo";
import ExecutionStep from "../ExecutionStep";
import FilesPreview, { IFilesPreviewFile } from "../FilesPreview";
import StepsContainer from "../StepsContainer";
import { IStepContainerItem } from "../StepsContainer/index.types";

export interface IDeclarationItemExecutionProps {
  info: TExecutionDeclarationInfo;
  onClickPublish: () => void;
  onClickAdditionalControl: () => void;
  files: IFilesPreviewFile[];
  steps: ExecutionStepStore[];
}

const DeclarationItemExecution: React.FC<IDeclarationItemExecutionProps> = (
  props
) => {
  console.log("props", toJS(props));

  const steps: IStepContainerItem[] = [
    {
      name: "Данные заявителя",
      body: (
        <FlexColumn>
          <DeclarationInfo info={props.info} />
          <FilesPreview files={props.files} />
        </FlexColumn>
      ),
      number: " ",
      isComplete: true,
    },
    ...props.steps.map((step) => {
      console.log("step in props", toJS(step));

      return {
        name: step.name,
        isComplete: !!step.isDone,
        body: <ExecutionStep {...step} />,
        files: step.files,
      };
    }),
  ];

  console.log("steps", toJS(steps));

  return (
    <div>
      <FlexColumn>
        <ButtonsContainer>
          <Button onClick={props.onClickPublish}>
            {props.info.isPublished ? "Снять с публикации" : "Опубликовать"}
          </Button>

          <Button onClick={props.onClickAdditionalControl}>
            {props.info.isOnAddControl
              ? "Снять с доп. контроля"
              : "На доп. контроль"}
          </Button>
        </ButtonsContainer>
        <Divider color="grey" />
        <StepsContainer steps={steps} />
      </FlexColumn>
    </div>
  );
};

export default observer(DeclarationItemExecution);
