import { makeAutoObservable } from "mobx";
import { LocalStorage } from "newApp/stores/common/LocalStorage";
import { MainPageModulesStore } from "./MainPageModulesStore";
import { MainPageOrganizationStore } from "./MainPageOrganizationStore";

export class MainPageStore {
  organization = new MainPageOrganizationStore();
  modules = new MainPageModulesStore();
  storage = new LocalStorage();

  constructor() {
    makeAutoObservable(this);
  }

  init = async () => {
    await this.organization.init();
    const id = this.organization.form.values.organizationId;
    if (id) this.modules.init(id);
  };

  reset = () => {
    this.organization.reset();
    this.modules.reset();
  };

  onChangeOrganization = (value: string | null, name?: string) => {
    this.organization.onChangeOrganization(value, name).finally(() => {
      const id = this.organization.form.values.organizationId;
      if (id) {
        this.modules.getModules(id);
        this.storage.organizationId = id;
      }
    });
  };

  get isLoading() {
    return this.modules.isLoading || this.organization.isLoading;
  }
}
