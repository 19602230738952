import React from "react";

const IconModeration = () => {
  return (
    <svg width="56" height="60" viewBox="0 0 87 94">
      <path
        fill="#5CC4CA"
        d="M43.5 4c2.4 0 4.7.4 6.3 1.1.1 0 6.4 2.9 13.4 7 6.9 4.1 12.6 8.3 12.6 8.3 3 2.2 5.9 7.5 6.3 11.2 0 .1.7 7.1.7 15.3s-.7 15.3-.7 15.3c-.4 3.8-3.4 9-6.3 11.2-.1 0-5.7 4.2-12.6 8.3-6.9 4.1-13.3 7-13.4 7-1.6.7-3.9 1.1-6.3 1.1s-4.7-.4-6.3-1.1c-.1 0-6.4-2.9-13.4-7-6.9-4.1-12.6-8.3-12.6-8.3-3-2.2-5.9-7.5-6.3-11.2-.2 0-.9-7.1-.9-15.2s.7-15.3.7-15.3c.4-3.8 3.4-9 6.3-11.2.1 0 5.7-4.2 12.6-8.3s13.3-7 13.4-7c1.8-.8 4.1-1.2 6.5-1.2m0-4c-2.9 0-5.8.5-7.9 1.5 0 0-6.5 2.9-13.8 7.2-7.2 4.3-13 8.5-13 8.5-3.9 2.9-7.4 9.2-7.9 14 0 0-.8 7.2-.8 15.8 0 8.5.8 15.8.8 15.8.5 4.8 4.1 11.1 7.9 14 0 0 5.8 4.3 13 8.6s13.8 7.2 13.8 7.2c2.2 1 5.1 1.5 7.9 1.5s5.8-.5 7.9-1.5c0 0 6.5-2.9 13.8-7.2 7.2-4.3 13-8.6 13-8.6 3.9-2.9 7.4-9.2 7.9-14 0 0 .8-7.2.8-15.8 0-8.5-.8-15.8-.8-15.8-.5-4.8-4.1-11.1-7.9-14 0 0-5.8-4.3-13-8.5C58 4.4 51.4 1.5 51.4 1.5c-2.2-1-5-1.5-7.9-1.5z"
      ></path>
      <path
        fill="none"
        stroke="#5CC4CA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M33.5 34h19M33.5 42h19M33.5 50.9h14M33.5 59.9h13M60.6 38V26H25.9v41.9h35v-16"
      ></path>
      <path
        fill="#5CC4CA"
        d="M64.9 36.1l4 4L53 55.9l-6 2 2-6 15.9-15.8m0-4c-1 0-2 .4-2.8 1.2L46.2 49.1c-.4.4-.8 1-1 1.6l-2 6c-.5 1.4-.1 3 1 4.1.8.8 1.8 1.2 2.8 1.2.4 0 .9-.1 1.3-.2l6-2c.6-.2 1.1-.5 1.6-1l16-15.8c1.6-1.6 1.6-4.1 0-5.7l-4-4c-.9-.8-1.9-1.2-3-1.2z"
      ></path>
    </svg>
  );
};

export default IconModeration;
