import { observer } from "mobx-react";
import routing from "../../routing";
import React from "react";
import { Route, Switch } from "react-router-dom";
import "../../kit/styles/global.scss";
import "./styles.scss";

export const newAppId = "newApp";

const App: React.FC = () => {
  return (
    <div id={newAppId}>
      <Switch>
        {routing.map((route) => (
          <Route path={route.path} exact={true} key={route.path}>
            {route.component}
          </Route>
        ))}
      </Switch>
    </div>
  );
};

export default observer(App);
