import { observer } from "mobx-react";
import FlexColumn from "newApp/kit/components/FlexColumn";
import VoteQuestionsForm from "newApp/components/VoteQuestionsForm";
import React from "react";
import VoteDetailsForm from "../VoteDetailsForm";
import { VoteFormStore } from "newApp/stores/common/VoteFormStore";

export interface IVoteFormProps {
  voteForm: VoteFormStore;
}

const VoteForm: React.FC<IVoteFormProps> = (props) => {
  return (
    <FlexColumn>
      <VoteDetailsForm store={props.voteForm.details} />
      <VoteQuestionsForm {...props.voteForm.questions} />
    </FlexColumn>
  );
};

export default observer(VoteForm);
