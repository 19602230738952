import imageCompression from "browser-image-compression";
import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { getDataURL } from "newApp/kit/utils/getDataURL";

export class VoteDetailsFileFormStore {
  isLoadings = { uploadFile: false };

  file: File | null = null;
  filesPreviews: IFilesPreviewFile[] = [];
  uploadedFileIds: string[] = [];

  mainFileId: string | null = null;
  previewFileId: string | null = null;
  croppedFileId: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {
    this.mainFileId = null;
    this.previewFileId = null;
    this.filesPreviews = [];
  };

  onCropped = async (results: string[]) => {
    const mainFilePreview = await getDataURL(this.file);
    this.filesPreviews = [
      {
        reference: mainFilePreview,
        file: this.file,
        title: "Оригинальное изображение",
        type: "Image",
      },
      {
        reference: results[0],
        title: "Обрезанное изображение 1",
        type: "Image",
      },
      {
        reference: results[1],
        title: "Обрезанное изображение 2",
        type: "Image",
      },
    ];
    this.file = null;
  };

  onClose = () => {
    this.filesPreviews = [];
  };

  setFiles = (form: {
    fileId?: string;
    croppedFileId?: string;
    previewFileId?: string;
  }) => {
    this.filesPreviews = [];
    if (form.fileId) {
      this.mainFileId = form.fileId;
      this.filesPreviews.push({
        reference: URL_API_ADMIN + `/api/files/${form.fileId}`,
        file: this.file,
        title: "Оригинальное изображение",
        type: "Image",
      });
    }
    if (form.croppedFileId) {
      this.croppedFileId = form.croppedFileId;
      this.filesPreviews.push({
        reference: URL_API_ADMIN + `/api/files/${form.croppedFileId}`,
        file: this.file,
        title: "Обрезанное изображение",
        type: "Image",
      });
    }
    if (form.previewFileId) {
      this.previewFileId = form.previewFileId;
      this.filesPreviews.push({
        reference: URL_API_ADMIN + `/api/files/${form.previewFileId}`,
        file: this.file,
        title: "Превью изображения",
        type: "Image",
      });
    }
  };

  onAddFiles = (files: File[]) => {
    this.file = files[0];
  };

  onClickRemove = () => {
    this.filesPreviews = [];
  };

  uploadFiles = async () => {
    if (this.filesPreviews.filter((i) => !!i.file).length) {
      await this.uploadPreviewImage();
      await this.uploadCroppedImage();
      await this.uploadMainFile();
    }
  };

  uploadFile = (file: File) => {
    this.isLoadings.uploadFile = true;
    return API.admin.File.apiFilesV2UploadPost([file])
      .then(({ data }) => data)
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.uploadFile = false;
        });
      });
  };

  uploadMainFile = async () => {
    const file = this.filesPreviews[0].file;
    return await this.uploadFile(file)
      .then((data) => {
        runInAction(() => {
          this.mainFileId = data[0]["id"];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.mainFileId = null;
        });
      });
  };

  uploadPreviewImage = async () => {
    const blob = await imageCompression.getFilefromDataUrl(
      this.filesPreviews[2]?.reference,
      "preview"
    );
    return await this.uploadFile(blob)
      .then((data) => {
        runInAction(() => {
          this.previewFileId = data[0]["id"];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.previewFileId = null;
        });
      });
  };

  uploadCroppedImage = async () => {
    const blob = await imageCompression.getFilefromDataUrl(
      this.filesPreviews[1]?.reference,
      "preview"
    );
    return await this.uploadFile(blob)
      .then((data) => {
        runInAction(() => {
          this.croppedFileId = data[0]["id"];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.croppedFileId = null;
        });
      });
  };
}
