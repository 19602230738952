import { makeAutoObservable } from "mobx";
import { FormStore } from "../../../../kit/stores/FormStore";
import { schema } from "./schema";

export class LoginFormStore {
  form = new FormStore<{ login: null | string; password: null | string }>(
    {
      login: null,
      password: null,
    },
    schema
  );

  warningCardErrorText: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  clearwarningCardErrorText = () => {
    this.warningCardErrorText = null;
  };
}
