import { observer } from "mobx-react";
import React from "react";
import { URL_LOGIN, URL_PROFILE } from "../../routing/urls";
import IconExit from "../IconExit";
import Link from "../Link";
import "./styles.scss";

interface IProps {
  userName?: string | null;
  isAuth: boolean;
  onLogout: () => void;
}

const HeaderProfile: React.FC<IProps> = (props) => {
  return (
    <div className="header-profile">
      {props.isAuth ? (
        <>
          <Link to={URL_PROFILE}>{props.userName}</Link>
          <button
            className="header-profile__exit-icon"
            onClick={props.onLogout}
          >
            <IconExit />
          </button>
        </>
      ) : (
        <Link to={URL_LOGIN}>Войти</Link>
      )}
    </div>
  );
};

export default observer(HeaderProfile);
