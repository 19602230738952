import { observer } from "mobx-react";
import FlexColumn from "newApp/kit/components/FlexColumn";
import TextField from "newApp/kit/components/TextField";
import { RubricFormStore } from "newApp/stores/common/RubricFormStore";
import React from "react";

export interface IRubricFormProps extends RubricFormStore {}

const RubricForm: React.FC<IRubricFormProps> = (props) => {
  return (
    <FlexColumn>
      <TextField
        placeholder="Название рубрики"
        {...props.form.adapters.title}
      />
    </FlexColumn>
  );
};

export default observer(RubricForm);
