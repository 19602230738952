import React from "react";
import "./styles.scss";

interface IProps {
  children: React.ReactNode;
}

const H1: React.FC<IProps> = ({ children }) => {
  return <h1 className="h1">{children}</h1>;
};

export default H1;
