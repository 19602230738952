import { observer } from "mobx-react";
import AdministrationNavigator from "newApp/components/AdministrationNavigator";
import FilesPreview from "newApp/components/FilesPreview";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import H3 from "newApp/kit/components/H3";
import InfoTable from "newApp/kit/components/InfoTable";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import { URL_ADMINISTRATION_MOBILE } from "newApp/routing/urls";
import { MobilePageStore } from "newApp/stores/pages/site/mobile/MobilePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new MobilePageStore();

const MobilePage: React.FC = () => {
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const { push } = useHistory();

  return (
    <MainLayout>
      <H1>Мобильное приложение</H1>
      <FlexColumn>
        <AdministrationNavigator />
        <ButtonsContainer>
          <Button onClick={() => push(URL_ADMINISTRATION_MOBILE + `/edit`)}>
            Редактировать
          </Button>
        </ButtonsContainer>
        {store.isLoadings.getMobile ? (
          <LoaderSpinner />
        ) : (
          <>
            <InfoTable
              content={[
                ["Заголовок", store.form.title],
                ["Описание", store.form.content],
                ["Изображение", store.form.hasImage ? "Да" : "Нет"],
              ]}
            />
            <H3>Изображение</H3>
            <FilesPreview files={store.filesPreviews} />
          </>
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(MobilePage);
