import { makeAutoObservable } from "mobx";
import { FormStore } from "newApp/kit/stores/FormStore";

export class CategoryTagsStore {
  form = new FormStore({
    newTag: null,
  });

  tags: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {};

  createTag = () => {
    if (this.form.values.newTag) {
      this.tags.push(this.form.values.newTag);
      this.form.reset("newTag");
    }
  };

  deleteTag = (idx: number) => {
    this.tags.splice(idx, 1);
  };
}
