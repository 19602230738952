import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../api";

export interface IUserInfo {
  lastName?: string | null;
  firstName?: string | null;
  surName?: string | null;
  email?: string | null;
  phone?: string | null;
  otherPhones?: string | null;
  login?: string | null;
  isDeactivated?: boolean | null;
}

export class ProfileStore {
  private userInfo: IUserInfo = {
    lastName: null,
    firstName: null,
    surName: null,
    email: null,
    phone: null,
    otherPhones: null,
    login: null,
    isDeactivated: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  checkProfile = () => {
    if (!this.userInfo.firstName) {
      this.getProfile();
    }
  };

  private getProfile = () => {
    return API.access.Users.apiUsersOwnGet()
      .then(({ data }) => {
        runInAction(() => {
          this.userInfo = data;
        });
      })
      .catch(() => {
        this.reset();
      });
  };

  get userName() {
    let userName = "";
    if (this.userInfo.lastName) userName += this.userInfo.lastName;
    if (this.userInfo.firstName) userName += ` ${this.userInfo.firstName[0]}.`;
    if (this.userInfo.surName) userName += ` ${this.userInfo.surName[0]}.`;
    return userName;
  }

  reset = () => {
    Object.keys(this.userInfo).forEach((key) => {
      this.userInfo = { ...this.userInfo, [key]: null };
    });
  };
}

export default new ProfileStore();
