import { observer } from "mobx-react";
import React from "react";

import Checkbox from "../../kit/components/CheckBox";
import FlexColumn from "../../kit/components/FlexColumn";
import H3 from "../../kit/components/H3";
import Select from "../../kit/components/Select";
import { IRoleEditModulesProps } from "./index.types";

const RoleEditModules: React.FC<IRoleEditModulesProps> = (props) => {
  return (
    <FlexColumn>
      <H3>Доступна в модулях</H3>
      {props.modules.map((module) => (
        <FlexColumn key={module.id}>
          <Checkbox
            value={module.isSelected}
            onChange={() => props.onChangeModule(module.id)}
          >
            {module.name}
          </Checkbox>
          {module.isSelected && (
            <FlexColumn>
              {module.operations?.map((op) => (
                <Select
                  placeholder={op.name}
                  onChange={(value) =>
                    props.onChangeOperation(module.id, op.id, value)
                  }
                  value={op.value}
                  options={op.options}
                  key={op.id}
                />
              ))}
            </FlexColumn>
          )}
        </FlexColumn>
      ))}
    </FlexColumn>
  );
};

export default observer(RoleEditModules);
