import { observer } from "mobx-react";
import React from "react";
import Table from "../Table";
import "./styles.scss";

interface IProps {
  header?: React.ReactNode;
  content: [React.ReactNode, React.ReactNode][];
}

const InfoTable: React.FC<IProps> = (props) => {
  return (
    <div className="info-table">
      {props.header && <div className="info-table__header">{props.header}</div>}
      <Table content={props.content.map((row) => [row[0], row[1] || "—"])} />
      <div className="info-table__divider"></div>
    </div>
  );
};

export default observer(InfoTable);
