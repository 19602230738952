import { observer } from "mobx-react";
import FilesPreview from "newApp/components/FilesPreview";
import Dropzone from "newApp/kit/components/Dropzone";
import H3 from "newApp/kit/components/H3";
import { NewsFormFilesStore } from "newApp/stores/common/NewsFormStore/NewsFormFilesStore";
import React from "react";

export interface INewsFormFilesProps extends NewsFormFilesStore {}

const NewsFormFiles: React.FC<INewsFormFilesProps> = (props) => {
  return (
    <div>
      <H3>Приложения ({props.filesPreviews.length})</H3>
      <FilesPreview
        files={props.filesPreviews}
        onClickRemove={props.onClickRemoveFiles}
      />
      <Dropzone onAddFiles={props.onAddFiles} maxFileSize={15728640} />
    </div>
  );
};

export default observer(NewsFormFiles);
