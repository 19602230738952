import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

export class OrganizationsPageStore {
  isLoadings = {
    getOrganizations: false,
    changeItemActive: false,
  };

  organizations: IOrganization[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getOrganizations();
  };

  reset = () => {
    this.resetOrganizations();
  };

  resetOrganizations = () => {
    this.organizations = [];
  };

  getOrganizations = () => {
    this.isLoadings.getOrganizations = true;
    this.resetOrganizations();
    return API.admin.Organization.apiOrganizationsGet()
      .then(({ data }) => {
        runInAction(() => {
          data.forEach((i) => this.parser(i as any));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getOrganizations = false;
        });
      });
  };

  parser = (organization: IOrganization, level = 0) => {
    this.organizations.push({
      active: organization.active,
      id: organization.id,
      parentId: organization.parentId,
      level: level,
      title: organization.title,
      titleShort: organization.titleShort,
    });
    if (organization.children?.length) {
      organization.children?.forEach((o) => {
        this.parser(o, level + 1);
      });
    }
  };

  changeItemActive = (id: string, isActive: boolean) => {
    this.isLoadings.changeItemActive = true;
    return API.admin.Organization.apiOrganizationsSetIdPost(id, {
      Active: !isActive,
    })
      .then(() => this.getOrganizations())
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeItemActive = false;
        });
      });
  };
}

export interface IOrganization {
  active: boolean;
  id: string;
  parentId: string;
  title: string;
  titleShort: string;
  level: number;
  children?: IOrganization[];
}
