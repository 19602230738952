import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FormStore } from "newApp/kit/stores/FormStore";

export class ShopSettingsFormStore {
  isLoadings = {
    getSettings: false,
    setSettings: false,
  };

  form = new FormStore({
    isVisibleInMainMenu: false,
    isVisibleInProfile: true,
    isVisibleInMobile: false,
  });

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getProjectSettings();
  };

  getProjectSettings = () => {
    this.isLoadings.getSettings = true;
    API.shop.ProjectSettings.apiProjectsettingsGet()
      .then(({ data }) => {
        this.form.values.isVisibleInMainMenu = data.isVisibleInMainMenu;
        this.form.values.isVisibleInProfile = data.isVisibleInProfile;
        this.form.values.isVisibleInMobile = data.isVisibleInMobile;
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getSettings = false;
        });
      });
  };

  setProjectSettings = () => {
    this.isLoadings.setSettings = true;

    API.shop.ProjectSettings.apiProjectsettingsPost({
      isVisibleInMainMenu: this.form.values.isVisibleInMainMenu,
      isVisibleInProfile: this.form.values.isVisibleInProfile,
      isVisibleInMobile: this.form.values.isVisibleInMobile,
    })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.setSettings = false;
        });
      });
  };

  onClickVisibleInMainMenu = () => {
    this.form.values.isVisibleInMainMenu =
      !this.form.values.isVisibleInMainMenu;
    this.setProjectSettings();
  };

  onClickVisibleInProfile = () => {
    this.form.values.isVisibleInProfile = !this.form.values.isVisibleInProfile;
    this.setProjectSettings();
  };

  onClickVisibleInMobile = () => {
    this.form.values.isVisibleInMobile = !this.form.values.isVisibleInMobile;
    this.setProjectSettings();
  };

  reset = () => {
    this.form.reset();
  };
}
