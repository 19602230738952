import { config, axiosInstance } from "../axios";
import { URL_API_AUTH } from "../urls";
import * as api from "./openapi";

export const apiAuth = {
  Auth: new api.AuthApi(config, URL_API_AUTH, axiosInstance),
  Module: new api.ModuleApi(config, URL_API_AUTH, axiosInstance),
  Organization: new api.OrganizationApi(config, URL_API_AUTH, axiosInstance),
};

export const API_AUTH_WITHOUT_INTERCEPTOR = new api.AuthApi(
  config,
  URL_API_AUTH
);
