import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import VoteItemDates from "newApp/components/VoteItemDates";
import VoteItemDetails from "newApp/components/VoteItemDetails";
import VoteItemModeSwitcher, {
  VOTE_ITEM_MODE,
  VOTE_ITEM_MODE_RESULTS,
} from "newApp/components/VoteItemModeSwitcher";
import VoteItemResults from "newApp/components/VoteItemResults";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import FlexRow from "newApp/kit/components/FlexRow";
import H1 from "newApp/kit/components/H1";
import { VoteItemPageStore } from "newApp/stores/pages/votings/VoteItemPageStore";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import "./styles.scss";

const store = new VoteItemPageStore();

const VoteItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get(VOTE_ITEM_MODE);

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  return (
    <MainLayout>
      <H1>{store.details.info.title}</H1>
      <FlexColumn>
        <VoteItemDates
          dateStart={store.details.info.startDateTime}
          dateFinish={store.details.info.endDateTime}
        />
        <div>
          <FlexRow className="tabs-download">
            <VoteItemModeSwitcher />
            <Button onClick={() => store.downloadVoteStat(id)} size="small">
              Скачать результаты голосования
            </Button>
          </FlexRow>
        </div>
        {mode === VOTE_ITEM_MODE_RESULTS ? (
          <VoteItemResults {...store.results} />
        ) : (
          <VoteItemDetails {...store.details} />
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(VoteItemPage);
