import { config, axiosInstance } from "../axios";
import { URL_API_ADMIN } from "../urls";
import * as api from "./openapi";

export const apiAdmin = {
  Analytics: new api.AnalyticsApi(config, URL_API_ADMIN, axiosInstance),
  Category: new api.CategoryApi(config, URL_API_ADMIN, axiosInstance),
  Chat: new api.ChatApi(config, URL_API_ADMIN, axiosInstance),
  FieldTypes: new api.FieldTypesApi(config, URL_API_ADMIN, axiosInstance),
  File: new api.FileApi(config, URL_API_ADMIN, axiosInstance),
  //   KafkaTest: new api.KafkaTestApi(config, URL_API_ADMIN, axiosInstance),
  Organization: new api.OrganizationApi(config, URL_API_ADMIN, axiosInstance),
  OrganizationType: new api.OrganizationTypeApi(
    config,
    URL_API_ADMIN,
    axiosInstance
  ),
  Question: new api.QuestionApi(config, URL_API_ADMIN, axiosInstance),
  Sections: new api.SectionsApi(config, URL_API_ADMIN, axiosInstance),
  Steps: new api.StepsApi(config, URL_API_ADMIN, axiosInstance),
  SubObjects: new api.SubObjectsApi(config, URL_API_ADMIN, axiosInstance),
  ViolationAnalytics: new api.ViolationAnalyticsApi(
    config,
    URL_API_ADMIN,
    axiosInstance
  ),
  ViolationExecution: new api.ViolationExecutionApi(
    config,
    URL_API_ADMIN,
    axiosInstance
  ),
  ViolationNumber: new api.ViolationNumberApi(
    config,
    URL_API_ADMIN,
    axiosInstance
  ),
  Violations: new api.ViolationsApi(config, URL_API_ADMIN, axiosInstance),
  ViolationTemplates: new api.ViolationTemplatesApi(
    config,
    URL_API_ADMIN,
    axiosInstance
  ),
  Vote: new api.VoteApi(config, URL_API_ADMIN, axiosInstance),
  Files: new api.VotesFilesApi(config, URL_API_ADMIN, axiosInstance),
};
