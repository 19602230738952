import { makeAutoObservable } from "mobx";
import { FormStore } from "../../../kit/stores/FormStore";
import { formValidationSchema } from "./formValidationSchema";
import { TUserData } from "./index.types";
import { RolesStore } from "./RolesStore";

export class UserEditCreateFormStore {
  form = new FormStore<TUserData>(
    {
      lastName: null,
      firstName: null,
      middleName: null,
      email: null,
      phone: null,
      otherPhones: null,
      login: null,
      password: null,
      passwordConfirm: null,
      isDeactivated: null,
    },
    formValidationSchema
  );

  roles = new RolesStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.roles.init();
  };

  reset = () => {
    this.form.reset();
    this.roles.reset();
  };

  validate = () => {
    this.form.validate();
  };

  get isValid() {
    return this.form.isValid;
  }
}
