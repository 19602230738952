import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import Button from "../Button";

const CalendarHeader = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div className="react-datepicker__nav">
      <Button
        onClick={decreaseMonth}
        size="small"
        disabled={prevMonthButtonDisabled}
        aria-label="Предыдущий месяц"
      >
        {"<"}
      </Button>
      <span className="react-datepicker__date-title">
        {monthDate.toLocaleString("ru", {
          month: "long",
          year: "numeric",
        })}
      </span>
      <Button
        onClick={increaseMonth}
        size="small"
        disabled={nextMonthButtonDisabled}
        aria-label="Следующий месяц"
      >
        {">"}
      </Button>
    </div>
  );
};

export default CalendarHeader;
