import { makeAutoObservable, runInAction } from "mobx";
import { LocalStorage } from "newApp/stores/common/LocalStorage";
import { API } from "../../../../api";
import { ISelectOption } from "../../../../kit/components/Select/index.types";
import { FormStore } from "../../../../kit/stores/FormStore";
import TokenStore from "../../../common/TokenStore";
import { TData } from "./index.types";

export class MainPageOrganizationStore {
  form = new FormStore<{ organizationId: string | null }>({
    organizationId: null,
  });

  isLoading = false;

  options: {
    organizations: ISelectOption[];
  } = {
    organizations: [],
  };

  storage = new LocalStorage();

  constructor() {
    makeAutoObservable(this);
  }

  init = async () => {
    await this.getOrganizations();
    await this.setDefaultOrganization();
  };

  reset = () => {
    this.options.organizations = [];
    this.isLoading = false;
  };

  getOrganizations = () => {
    this.isLoading = true;
    return API.auth.Organization.apiOrganizationsGet()
      .then((res: any) => {
        runInAction(() => {
          try {
            this.options.organizations = (res.data! as TData).organizations.map(
              (i) => ({
                text: i.organizationTitle || "Неизвестно",
                value: i.organizationId || "",
              })
            );
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch(() => {
        runInAction(() => {
          this.options.organizations = [];
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  setDefaultOrganization = () => {
    const id = this.options.organizations[0].value;
    if (id) {
      this.form.changeValueByKey(id, "organizationId");
      this.storage.organizationId = id;
    }
    this.setOrganization();
  };

  setOrganization = () => {
    return API.auth.Module.apiModulesSetOrganizationPost({
      organizationId: String(this.form.values.organizationId),
    })
      .then((res: any) => {
        const token = (res.data! as { token: string }).token;
        if (token) TokenStore.accessToken = token;
      })
      .catch(() => {
        console.error("Ошибка в MainPageOrganizationStore.setOrganization");
      });
  };

  onChangeOrganization = (value: string | null, name?: string) => {
    this.form.changeValue(value, name);
    return this.setOrganization();
  };
}
