import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { getDataURL } from "newApp/kit/utils/getDataURL";

export class NewsFormPhotosStore {
  photosPreviews: IFilesPreviewFile[] = [];
  photosForDeleteIds: string[] = [];

  isLoadings = {
    uploadPhotos: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {
    this.resetPhotos();
  };

  resetPhotos = () => {
    this.photosPreviews = [];
  };

  onAddPhotos = (photos: File[]) => {
    photos.forEach((file) => {
      getDataURL(file)
        .then((reference) => {
          runInAction(() => {
            this.photosPreviews.push({
              reference,
              type: file.type.split("/")[0],
              title: file.name,
              file,
            });
          });
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  setPhotos = (photos: { id?: string; name?: string }[]) => {
    this.resetPhotos();
    this.photosPreviews = photos.map((f) => ({
      reference: URL_API_ADMINISTRATION + `/news/photo/${f.id}`,
      title: f.name,
      type: "image",
      id: f.id,
    }));
  };

  onClickRemovePhoto = (idx: number, file?: IFilesPreviewFile) => {
    this.photosForDeleteIds.push(file.id);
    this.photosPreviews = this.photosPreviews.filter((i) => i !== file);
  };

  changePhotosOnServer = async (newsId: string) => {
    await this.removePhotos();
    await this.uploadPhotos(newsId);
  };

  uploadPhotos = async (newsId: string) => {
    for await (const i of this.photosPreviews.filter((i) => !!i.file)) {
      try {
        await API.administration.News.newsNewsIdPhotoPost(newsId, i.file);
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  removePhotos = async () => {
    for await (const id of this.photosForDeleteIds) {
      await API.administration.News.newsPhotoIdDelete(id).catch((e) =>
        console.error(e)
      );
    }
    runInAction(() => {
      this.photosForDeleteIds = [];
    });
  };
}
