import { observer } from "mobx-react";
import React from "react";
import TabsItem, { ITabsItemProps } from "./TabsItem";
import "./styles.scss";

interface IProps {
  tabs: ITabsItemProps[];
  title?: React.ReactNode;
}

const Tabs: React.FC<IProps> = (props) => {
  return (
    <div className="tabs">
      {props.title && <div className="tabs__title">{props.title}</div>}
      <div className="tabs__items">
        {props.tabs.map((tab, idx) => (
          <TabsItem key={idx} {...tab} />
        ))}
      </div>
    </div>
  );
};

export default observer(Tabs);
