import React from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router";
import cn from "classnames";

import "./styles.scss";

export const PRODUCT_LIST_MODE = "mode";
export const PRODUCT_LIST_MODE_ACTIVE = "active";
export const PRODUCT_LIST_MODE_DEACTIVATED = "deactivated";

const ProductListNavigator: React.FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const mode = new URLSearchParams(search).get(PRODUCT_LIST_MODE);

  const setQuery = (mode: string) => {
    push({ search: `${PRODUCT_LIST_MODE}=${mode}` });
  };

  const links: { isActive: boolean; text: string; onClick: () => void }[] = [
    {
      isActive: !mode || mode === PRODUCT_LIST_MODE_ACTIVE,
      text: "Активные",
      onClick: () => setQuery(PRODUCT_LIST_MODE_ACTIVE),
    },
    {
      isActive: mode === PRODUCT_LIST_MODE_DEACTIVATED,
      text: "Деактивированные",
      onClick: () => setQuery(PRODUCT_LIST_MODE_DEACTIVATED),
    },
  ];

  return (
    <div className="product-list-navigator">
      {links.map((link, idx) => (
        <div
          key={idx}
          className={cn(
            "product-list-navigator__item",
            link.isActive && "product-list-navigator__item--active"
          )}
          onClick={link.onClick}
        >
          {link.text}
        </div>
      ))}
    </div>
  );
};

export default observer(ProductListNavigator);
