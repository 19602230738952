import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";

export class NewMessagesStore {
  newMessagesCount: null | number = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.checkNewMessages();
  };

  reset = () => {
    this.newMessagesCount = null;
  };

  checkNewMessages = () => {
    return API.admin.Violations.apiViolationsNewGet(0)
      .then(({ data }) => {
        runInAction(() => {
          this.newMessagesCount = Number(data);
        });
      })
      .catch(() => {
        this.reset();
      });
  };
}
