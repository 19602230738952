import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

import MainLayout from "newApp/components/layouts/MainLayout";
import H1 from "newApp/kit/components/H1";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import ShopNavigator from "newApp/components/ShopNavigator";
import ProductListNavigator from "newApp/components/ProductListNavigator";
import ProductsList from "newApp/components/ProductsList";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Pagination from "newApp/kit/components/Pagination";

import { ProductsPageStore } from "newApp/stores/pages/shop/products/ProductsPageStore";

import {
  PRODUCT_LIST_MODE_ACTIVE,
  PRODUCT_LIST_MODE_DEACTIVATED,
} from "newApp/components/ProductListNavigator";
import { URL_SHOP_PRODUCTS_CREATE } from "newApp/routing/urls";

const store = new ProductsPageStore();

const ShopPage: React.FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get("mode");

  useEffect(() => {
    if (mode === PRODUCT_LIST_MODE_ACTIVE || !mode) store.init(true);
    if (mode === PRODUCT_LIST_MODE_DEACTIVATED) store.init(false);

    return () => store.reset();
  }, [mode]);

  return (
    <MainLayout>
      <H1>Магазин</H1>
      {/* <ShopSettings {...store.settings} /> */}
      <FlexColumn>
        <ShopNavigator />
        <Button onClick={() => push(URL_SHOP_PRODUCTS_CREATE)}>
          Добавить товар/услугу
        </Button>
        <ProductListNavigator />
        {store.isLoading ? <LoaderSpinner /> : <ProductsList {...store} />}
        <Pagination {...store.pagination} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ShopPage);
