import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";

import MainLayout from "newApp/components/layouts/MainLayout";
import FlexColumn from "newApp/kit/components/FlexColumn";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import ButtonBack from "newApp/components/ButtonBack";
import Button from "newApp/kit/components/Button";
import H1 from "newApp/kit/components/H1";
import FilesPreview from "newApp/components/FilesPreview";
import H3 from "newApp/kit/components/H3";
import InfoTable from "newApp/kit/components/InfoTable";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Map from "newApp/components/Map";

import { ProductsItemPageStore } from "newApp/stores/pages/shop/products/ProductsItemPageStore";

import { URL_SHOP_PRODUCTS } from "newApp/routing/urls";

const store = new ProductsItemPageStore();

const VoteItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  return (
    <MainLayout>
      {store.isLoading ? (
        <LoaderSpinner />
      ) : (
        <FlexColumn>
          <H1>{store.product.title}</H1>
          <ButtonBack url={URL_SHOP_PRODUCTS}>Назад к списку</ButtonBack>
          <ButtonsContainer>
            <Button onClick={() => push(URL_SHOP_PRODUCTS + `/${id}/edit`)}>
              Редактировать
            </Button>
            {store.product.isActive ? (
              <Button
                onClick={() => {
                  window.confirm(
                    `Вы точно хотите удалить данный товар/услугу?`
                  ) && store.deleteProduct(id);
                }}
                variant="danger"
              >
                Удалить
              </Button>
            ) : (
              <Button
                onClick={() => {
                  store.activateProduct(id);
                }}
                variant="secondary"
              >
                Активировать
              </Button>
            )}
          </ButtonsContainer>
          <InfoTable
            header="Информация о товаре/услуге"
            content={[
              //   ["Тип", store.product.type === "Goods" ? "Товар" : "Услуга"],
              ["Стоимость", store.product.price],
              //   ["Количество", store.product.quantity],
              ["Дата создания", store.product.creationDate],
              [
                "Описание",
                <div
                  className="html-raw-content"
                  dangerouslySetInnerHTML={{
                    __html: store.product.description,
                  }}
                />,
              ],
              //   ["Способы получения", store.product.wayToGet],
              ["Наименование организации", store.product.organizationName],
              ["Адрес организации", store.product.organizationAddress],
            ]}
          />
          {!!store.categoriesList.length && (
            <>
              <H3>Рубрики</H3>
              {store.categoriesList.map((cat, idx) => {
                return <span key={idx}>{cat}</span>;
              })}
            </>
          )}
          {!!store.imagesPreviews.length && (
            <>
              <H3>Изображение товара/услуги</H3>
              <FilesPreview files={store.imagesPreviews} />
            </>
          )}
          {store.product.organizationCoordinates && (
            <Map
              center={[
                store.product.organizationCoordinates.latitude,
                store.product.organizationCoordinates.longitude,
              ]}
              markers={[
                {
                  position: [
                    store.product.organizationCoordinates.latitude,
                    store.product.organizationCoordinates.longitude,
                  ],
                },
              ]}
            />
          )}
        </FlexColumn>
      )}
    </MainLayout>
  );
};

export default observer(VoteItemPage);
