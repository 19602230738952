import * as yup from "yup";
import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "../../../constants/validation/validators";

export default yup.object().shape({
  title: VALIDATOR_STRING_REQUIRED,
  croppedImageId: VALIDATOR_ANY,
  previewImageId: VALIDATOR_ANY,
  content: VALIDATOR_STRING_REQUIRED,
  date: VALIDATOR_STRING_REQUIRED,
  state: VALIDATOR_ANY,
  type: VALIDATOR_ANY,
  isBanner: VALIDATOR_ANY,
  isMain: VALIDATOR_ANY,
});
