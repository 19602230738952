import "./styles.scss";

import { observer } from "mobx-react";
import MainLayout from "newApp/components/layouts/MainLayout";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import React, { useEffect } from "react";
import SiteNavigator from "newApp/components/SiteNavigator";

import { EmailTemplatesStore } from "./store";
import { useHistory } from "react-router";
import Button from "newApp/kit/components/Button";
import {
  URL_SITE_EMAILTEMPLATES_CREATE,
  URL_SITE_EMAILTEMPLATES,
} from "newApp/routing/urls";
import H3 from "newApp/kit/components/H3";

const store = new EmailTemplatesStore();

const EmailTemplatesPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();

    return () => {
      store.reset();
    };
  }, []);

  const onTemplateHandleClick = (id: number) => {
    push(URL_SITE_EMAILTEMPLATES + `/${id}`);
  };

  return (
    <div className="main-templates">
      <MainLayout isLoading={store.isLoading.page}>
        <H1>Шаблоны писем</H1>
        <FlexColumn>
          <SiteNavigator />
          <Button onClick={() => push(URL_SITE_EMAILTEMPLATES_CREATE)}>
            Добавить шаблон
          </Button>
          <div className="main-templates__list">
            <H3>Список шаблонов писем</H3>
            {store.emailTemplates.map((item, idx) => {
              return (
                <p
                  className="main-templates__list--item"
                  key={idx}
                  onClick={() => {
                    onTemplateHandleClick(item.templateType);
                  }}
                >
                  {item.templateTitle}
                </p>
              );
            })}
          </div>
        </FlexColumn>
      </MainLayout>
    </div>
  );
};

export default observer(EmailTemplatesPage);
