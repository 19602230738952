import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IAnalyticsTable } from "newApp/components/AnalyticsTable";

import { AnalyticsAcceptedFilterStore } from "./AnalyticsAcceptedFilterStore";

export class AnalyticsAcceptedPageStore {
  isLoadings = { getReport: false };

  table: null | IAnalyticsTable = null;
  filter = new AnalyticsAcceptedFilterStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getReport();
    this.filter.init();
  };

  reset = () => {};

  getReport = () => {
    this.isLoadings.getReport = true;
    return API.admin.ViolationAnalytics.apiReportsReceivedViolationGet(
      this.filter.dates.beginDate,
      this.filter.dates.endDate,
      this.filter.categoryIds.length ? this.filter.categoryIds : undefined
    )
      .then(({ data }) => {
        runInAction(() => {
          this.table = data as any;
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getReport = false;
        });
      });
  };

  get downloadUrl() {
    let url = URL_API_ADMIN + `/api/reports/receivedViolation/download?`;
    if (this.filter.dates.beginDate) {
      url += `BeginDate=${this.filter.dates.beginDate}&`;
    }
    if (this.filter.dates.endDate) {
      url += `EndDate=${this.filter.dates.endDate}&`;
    }
    if (this.filter.categoryIds.length) {
      this.filter.categoryIds.forEach((id) => {
        url += `CategoryIds=${id}&`;
      });
    }
    return url;
  }
}
