import { makeAutoObservable, runInAction } from "mobx";

import { API } from "../../../../../api";
import { IRoleInfo } from "../RolesItemPageStore/RolesInfoStore/index.types";
import { RolesFormsStore } from "./RolesFormsStore";

export class RoleItemEditPageStore {
  isLoadings = { getRole: false, postRole: false };

  forms = new RolesFormsStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.forms.init();
    this.getRole(id);
  };

  reset = () => {
    this.forms.reset();
    this.isLoadings = { getRole: false, postRole: false };
  };

  private getRole = (id: string) => {
    this.isLoadings.getRole = true;
    return API.access.Roles.apiRolesRoleIdGet(id)
      .then((res: any) => {
        runInAction(() => {
          try {
            const data = res.data! as IRoleInfo;
            /** set role form */
            this.forms.form.changeValueByKey(data.roleTitle, "roleTitle");
            this.forms.form.changeValueByKey(
              String(data.level) || null,
              "level"
            );

            /** set selected modules */
            this.forms.modules.setModules(data.modulesDetailes);
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRole = false;
        });
      });
  };

  validate = () => {
    this.forms.form.validate();
  };

  get isValid() {
    return this.forms.form.isValid;
  }

  postEditedRole = (id: string) => {
    this.isLoadings.postRole = true;
    return API.access.Roles.apiRolesEditRoleIdPost(id, {
      form: {
        level: Number(this.forms.form.values.level),
        title: String(this.forms.form.values.roleTitle),
        availableInModules: this.forms.modules.selectedModules,
        permissions: this.forms.modules.permissions,
      },
    }).finally(() => {
      runInAction(() => {
        this.isLoadings.postRole = false;
      });
    });
  };
}
