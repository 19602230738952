import { observer } from "mobx-react";
import React from "react";

interface IProps {
  info: {
    address: null | string;
    dateOfCreation: null | string;
    daysLeft: null | number;
    email: null | string;
    firstName: null | string;
    isOnAddControl: null | boolean;
    isPublished: null | boolean;
    lastName: null | string;
    mobile: null | string;
    number: null | number | string;
    organizationTitle: null | string;
    subCategories: null | string;
    status: null | string;
    category: null | string;
  };
  onClick?: () => void;
}

const MapMarkerPopup: React.FC<IProps> = (props) => {
  return (
    <div className="map-marker-popup" onClick={props.onClick}>
      <div>
        <span>Номер: </span>
        <span>{props.info.number}</span>
      </div>
      <div>
        <span>Дата: </span>
        <span>{props.info.dateOfCreation}</span>
      </div>
      <div>
        <span>Адрес: </span>
        <span>{props.info.address}</span>
      </div>
      <div>
        <span>Категория: </span>
        <span>{props.info.category}</span>
      </div>
      <div>
        <span>Подкатегория: </span>
        <span>{props.info.subCategories}</span>
      </div>
      <div>
        <span>Дней осталось: </span>
        <span>{props.info.daysLeft}</span>
      </div>
    </div>
  );
};

export default observer(MapMarkerPopup);
