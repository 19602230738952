import { observer } from "mobx-react";
import AdministrationNavigator from "newApp/components/AdministrationNavigator";
import CategoriesTable from "newApp/components/CategoriesTable";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import { URL_ADMINISTRATION_CATEGORIES_CREATE } from "newApp/routing/urls";
import { CategoriesPageStore } from "newApp/stores/pages/administrations/categories/CategoriesPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new CategoriesPageStore();

const CategoriesPage: React.FC = () => {
  const { push } = useHistory();
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Администрирование - Категории</H1>
      <FlexColumn>
        <AdministrationNavigator />
        <Button onClick={() => push(URL_ADMINISTRATION_CATEGORIES_CREATE)}>
          Добавить категорию
        </Button>
        {store.isLoadings.getCategories ? (
          <LoaderSpinner />
        ) : (
          <CategoriesTable {...store} />
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(CategoriesPage);
