import { makeAutoObservable } from "mobx";
import { RolesListStore } from "./RolesListStore";

export class RolesListPageStore {
  rolesList = new RolesListStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.rolesList.init();
  };

  reset = () => {
    this.rolesList.reset();
  };
}
