import { observer } from "mobx-react";
import DaysLeftCount from "newApp/components/DaysLeftCount";
import DeclarationItemBody from "newApp/components/DeclarationItemBody";
import DeclarationItemMap from "newApp/components/DeclarationItemMap";
import DeclarationModeSwitcher, {
  DECLARATION_MODE,
  DECLARATION_MODE_MAP,
} from "newApp/components/DeclarationModeSwitcher";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router";

import LayoutMain from "../../../components/layouts/MainLayout";
import { DeclarationModerationItemPageStore } from "../../../stores/pages/moderation/DeclarationModerationItemPageStore";

const store = new DeclarationModerationItemPageStore();

const DeclarationModerationItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get(DECLARATION_MODE);

  useEffect(() => {
    if (id) store.init(id);
    return () => store.reset();
  }, [id]);

  return (
    <LayoutMain>
      {store.isLoadings.getDeclaration ? (
        <LoaderSpinner />
      ) : (
        <>
          <FlexColumn>
            <H1>Сообщение № {store.info.number}</H1>
            <DaysLeftCount daysLeft={store.info.daysLeft} />
            <DeclarationModeSwitcher />

            {mode === DECLARATION_MODE_MAP ? (
              <DeclarationItemMap info={store.info} />
            ) : (
              <DeclarationItemBody {...store} />
            )}
          </FlexColumn>
        </>
      )}
    </LayoutMain>
  );
};

export default observer(DeclarationModerationItemPage);
