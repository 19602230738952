import "./styles.scss";
import "react-leaflet-markercluster/dist/styles.min.css";
import { observer } from "mobx-react";
import React from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import { IMapProps } from "./index.types";
import { LeafletCustomIcon } from "newApp/components/LeafletCustomIcon";
import { CRS_YANDEX, MAP_TILES_URL } from "newApp/constants/map";

const Map: React.FC<IMapProps> = ({
  icon,
  zoom = 13,
  defaultCenter,
  markers,
  center = [56.8339325, 60.6231973],
  ...props
}) => {
  const OnClickInterceptor = () => {
    useMapEvents({
      click: (e) => {
        props.onClick({
          latitude: Number(e["latlng"]["lat"]),
          longitude: Number(e["latlng"]["lng"]),
        });
      },
    });
    return null;
  };

  return (
    <MapContainer {...props} center={center} zoom={zoom} crs={CRS_YANDEX}>
      <TileLayer url={MAP_TILES_URL} />
      <OnClickInterceptor />

      <MarkerClusterGroup>
        {markers?.map((marker, idx) => {
          return (
            <Marker
              position={marker?.position}
              key={marker.id || idx}
              icon={LeafletCustomIcon}
            >
              {marker.popup && <Popup>{marker?.popup}</Popup>}
            </Marker>
          );
        })}
      </MarkerClusterGroup>

      {props?.polygons?.map((polygon, idx) => (
        <Polygon
          positions={polygon.map((p) => [p.latitude, p.longitude])}
          pathOptions={{ color: "purple" }}
          key={idx}
        />
      ))}

      {props.children}
    </MapContainer>
  );
};

export default observer(Map);
