import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import FaqForm from "newApp/components/FaqForm";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_FAQ } from "newApp/routing/urls";
import { FaqEditPageStore } from "newApp/stores/pages/site/faq/FaqEditPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new FaqEditPageStore();

const FaqEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  const onClickEdit = async () => {
    await store.onSave();
    push(URL_SITE_FAQ + `/${id}`);
  };

  return (
    <MainLayout isLoading={store.isLoadings.getFaq}>
      <H1>Редактирование часто задаваемого вопроса</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_FAQ + `/${id}`}>Назад к вопросу</ButtonBack>
        <FaqForm {...store.faqForm} />
        <ButtonsContainer>
          <Button onClick={onClickEdit} disabled={store.isLoadings.onSave}>
            Сохранить изменения
          </Button>
          <Button
            onClick={() => push(URL_SITE_FAQ + `/${id}`)}
            variant="secondary"
          >
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(FaqEditPage);
