import { observer } from "mobx-react";
import Tabs from "newApp/kit/components/Tabs";
import { URL_SITE_NEWS, URL_SITE_NEWS_RUBRICS } from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";

const NewsNavigator: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const tabs = [
    {
      title: "Новости",
      onClick: () => push(URL_SITE_NEWS),
      isActive: pathname === URL_SITE_NEWS,
    },
    {
      title: "Рубрики",
      onClick: () => push(URL_SITE_NEWS_RUBRICS),
      isActive: pathname === URL_SITE_NEWS_RUBRICS,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(NewsNavigator);
