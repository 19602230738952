import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import {
  EmailTemplateModel,
  NotificationsSettingsType,
  TemplateParamsItem,
  TemplateType,
} from "newApp/api/administration/openapi";
import { FormStore } from "newApp/kit/stores/FormStore";
import { templateSchema } from "./validationsSchema";

export class EmailTemplatesStore {
  emailTemplates: EmailTemplateModel[] = [];
  isLoading = {
    page: false,
    templates: false,
  };

  type: TemplateType[] = [];

  form = new FormStore(
    {
      templateType: null,
      content: null,
      templateTitle: null,
      buttonAvailable: false,
      showImage: false,
    },
    templateSchema
  );
  templateParams: TemplateParamsItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = (id?: number) => {
    this.getEmailTemplates();
    this.getTypesList();

    if (id > -1) {
      this.getEmailTemplate(id);
    }
  };

  getTypesList = () => {
    API.administration.EmailTemplates.emailTemplatesTypesListGet().then(
      (res) => {
        runInAction(() => {
          this.type = res.data.map((item) => {
            const obj: { text: string; value: number | null } = {
              text: "",
              value: null,
            };
            obj.text = item.name;
            obj.value = item.enumNumber;

            return obj as TemplateType;
          });
        });
      }
    );
  };

  getEmailTemplates = () => {
    runInAction(() => {
      this.isLoading.page = true;
    });
    API.administration.EmailTemplates.emailTemplatesGet()
      .then((res) => {
        if (res.status === 200) {
          runInAction(() => {
            this.emailTemplates = res.data;
          });
        }
      })
      .catch((error) => {
        throw new Error(error.response);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading.page = false;
        });
      });
  };

  getEmailTemplate = (id: number) => {
    runInAction(() => {
      this.isLoading.templates = true;
    });
    this.form.changeValue(id, "templateType");
    API.administration.EmailTemplates.emailTemplatesTemplateTypeGet(
      id as NotificationsSettingsType
    )
      .then((res) => {
        const {
          content,
          showImage,
          buttonAvailable,
          templateParams,
          templateType,
          templateTitle,
        } = res.data;
        runInAction(() => {
          this.templateParams = templateParams;
          this.form.changeValue(content, "content");
          this.form.changeValue(buttonAvailable, "buttonAvailable");
          this.form.changeValue(showImage, "showImage");
          this.form.changeValue(templateType, "templateType");
          this.form.changeValue(templateTitle, "templateTitle");
        });
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading.templates = false;
        });
      });
  };

  saveEmailTemplate = async () => {
    const form = {
      content: this.form.values.content,
      buttonAvailable: this.form.values.buttonAvailable,
      showImage: this.form.values.showImage,
      templateTitle: this.form.values.templateTitle,
    };
    API.administration.EmailTemplates.emailTemplatesPost(
      this.form.values.templateType as NotificationsSettingsType,
      form
    ).then((res) => {
      console.log("res", res);
      return res;
    });
  };

  reset = () => {
    this.emailTemplates = [];
    this.form.reset();
    this.isLoading = { page: false, templates: false };
  };
}

export default new EmailTemplatesStore();
