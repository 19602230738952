import { observer } from "mobx-react";
import DeclarationsListFilter from "newApp/components/DeclarationsListFilter";
import MainLayout from "newApp/components/layouts/MainLayout";
import ListCounter from "newApp/components/ListCounter";
import ListOrMap from "newApp/components/ListOrMap";
import ModerationNavigator from "newApp/components/ModerationNavigator";
import Divider from "newApp/kit/components/Divider";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Pagination from "newApp/kit/components/Pagination";
import {
  URL_MODERATION_ACCEPTED,
  URL_MODERATION_CLOSED,
  URL_MODERATION_CREATED,
  URL_MODERATION_REJECTED,
} from "newApp/routing/urls";
import { ModerationListCreatedPageStore } from "newApp/stores/pages/moderation/ModerationListCreatedPageStore";
import React, { useEffect } from "react";
import { useLocation } from "react-router";

const store = new ModerationListCreatedPageStore();

const ModerationListCreatedPage: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    store.init();
    if (pathname === URL_MODERATION_CREATED) store.declarations.getCreated();
    if (pathname === URL_MODERATION_ACCEPTED) store.declarations.getAccepted();
    if (pathname === URL_MODERATION_REJECTED) store.declarations.getRejected();
    if (pathname === URL_MODERATION_CLOSED) store.declarations.getClosed();
    return () => store.reset();
  }, [pathname]);

  return (
    <MainLayout>
      <H1>Модерирование сообщений</H1>
      <FlexColumn>
        <ModerationNavigator
          newCreateMessageCount={
            store.declarations.newmessages.newMessagesCount
          }
          isHasNewMessagesInCreated={
            store.declarations.isHasNewMessageInCreated
          }
          isHasNewMessagesInWork={store.declarations.isHasNewMessagesInWork}
        />
        {store.declarations.isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <DeclarationsListFilter
              form={store.declarations.filter.form}
              isOpen={!!store.declarations.filter.isShowModal}
              onClose={store.declarations.filter.closeModal}
              options={store.declarations.filter.options}
              getList={store.declarations.getDeclarations}
            />
            <Divider color="grey" />
            <ListCounter countOnPage={store.declarations.declarations.length} />
            <ListOrMap declarations={store.declarations.declarations} />
            <Pagination {...store.declarations.pagination} />
          </>
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ModerationListCreatedPage);
