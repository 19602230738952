import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../../api";
import { moduleVariants } from "./moduleVariants";
import { TModule, TModuleId } from "./index.types";

export class MainPageModulesStore {
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  private moduleList: TModuleId[] = [];

  init = (organizationId: string) => {
    this.getModules(organizationId);
  };

  reset = () => {
    this.moduleList = [];
    this.isLoading = false;
  };

  getModules = (organizationId: string) => {
    this.isLoading = true;
    return API.auth.Module.apiModulesGet(organizationId)
      .then((res: any) => {
        runInAction(() => {
          try {
            this.moduleList = res.data! as TModuleId[];
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch(() => {
        runInAction(() => {
          this.moduleList = [];
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  get modules(): TModule[] {
    return moduleVariants.filter((module) =>
      this.moduleList.includes(module.id)
    );
  }
}

export default new MainPageModulesStore();
