import { observer } from "mobx-react";
import DocsNavigator from "newApp/components/DocsNavigator";
import MainLayout from "newApp/components/layouts/MainLayout";
import NewsPreview from "newApp/components/NewsPreview";
import SiteNavigator from "newApp/components/SiteNavigator";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_DOCS_AGREEMENTS } from "newApp/routing/urls";
import { DocsEditStore } from "./store";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";

const store = new DocsEditStore();

const DocsTermsPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return store.reset();
  }, []);

  return (
    <MainLayout isLoading={store.isLoading}>
      <H1>Пользовательское соглашение</H1>
      <FlexColumn>
        <SiteNavigator />
        <DocsNavigator />
        {store.isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <ButtonsContainer>
              <Button onClick={() => push(URL_SITE_DOCS_AGREEMENTS + "/edit")}>
                Редактировать
              </Button>
            </ButtonsContainer>
            <NewsPreview content={store.terms} />
          </>
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(DocsTermsPage);
