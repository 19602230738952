import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FaqRubricFormStore } from "newApp/stores/common/FaqRubricFormStore";

class FaqRubricsEditPageStore {
  isLoadings = {
    getRubric: false,
    saveRubric: false,
  };
  id = "";

  faqRubricForm = new FaqRubricFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getRubric();
    this.faqRubricForm.init();
  };

  reset = () => {
    this.faqRubricForm.reset();
  };

  onCreate = async () => {
    await this.faqRubricForm.form.validate();
    if (this.faqRubricForm.form.isValid) return await this.saveRubric();
    return false;
  };

  getRubric = () => {
    this.isLoadings.getRubric = true;
    return API.administration.FAQRubrics.faqRubricListGet(this.id)
      .then(({ data }) => {
        runInAction(() => {
          this.faqRubricForm.form.changeValueByKey(data[0].title, "title");
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRubric = false;
        });
      });
  };

  saveRubric = () => {
    this.isLoadings.saveRubric = true;
    return API.administration.FAQRubrics.faqRubricIdPost(this.id, {
      title: this.faqRubricForm.form.values.title,
    })
      .then(() => true)
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveRubric = false;
        });
      });
  };
}

export default new FaqRubricsEditPageStore();
