import { observer } from "mobx-react";
import FlexColumn from "newApp/kit/components/FlexColumn";
import { IVoteItemQuestionAnswer } from "newApp/stores/pages/votings/VoteItemPageStore/VoteItemPageInfoStore/index.types";
import React from "react";
import FilesPreview from "../FilesPreview";
import "./styles.scss";

export interface IVoteItemQuestionAnswersListProps {
  answers: IVoteItemQuestionAnswer[];
}

const VoteItemQuestionAnswersList: React.FC<IVoteItemQuestionAnswersListProps> =
  (props) => {
    return (
      <div className="answers-list">
        <FlexColumn>
          {props.answers.map((answer, answerIdx) => (
            <div className="answers-list__item" key={answer.id}>
              <div className="answers-list__item-number">{answerIdx + 1}</div>
              <div className="answers-list__item-text">{answer.text}</div>
              {answer.files.length !== 0 && (
                <FilesPreview files={answer.files} />
              )}
            </div>
          ))}
        </FlexColumn>
      </div>
    );
  };

export default observer(VoteItemQuestionAnswersList);
