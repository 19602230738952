import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { NewsSetForm } from "newApp/api/administration/openapi";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { PaginationStore } from "newApp/kit/components/Pagination/PaginationStore";

export class NewsPageStore {
  isLoadings = {
    getNews: false,
    deleteNews: false,
    changeNews: false,
  };

  newsList: INewsListItem[] = [];

  pagination = new PaginationStore({}, () => this.getNews());

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getNews();
  };

  reset = () => {
    this.newsList = [];
    this.pagination.reset();
  };

  getNews = () => {
    this.isLoadings.getNews = true;
    const type = undefined;
    const state = undefined;
    const skip = (this.pagination.currentPage - 1) * this.pagination.pageSize;
    const take = this.pagination.pageSize;
    return API.administration.News.newsGet(type, state, skip, take)
      .then(({ data }) => {
        runInAction(() => {
          this.newsList =
            data.items?.map((i) => ({
              croppedImageId: i.croppedImageId || null,
              image: i.croppedImageId
                ? URL_API_ADMINISTRATION + "/news/" + i.id + "/croppedimage"
                : null,
              date: i.date ? new Date(i.date).toLocaleDateString() : null,
              hasImage: !!i.hasImage,
              id: i.id || null,
              previewImageId: i.previewImageId || null,
              state: i.state || null,
              title: i.title || null,
              type: Number(i.type),
              isBanner: i.type === 1,
              isMain: i.id === data.mainNewsId,
            })) || [];

          this.pagination.pages = Math.ceil(
            data.count / this.pagination.pageSize
          );
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getNews = false;
        });
      });
  };

  onClickDelete = (id: string) => {
    this.isLoadings.deleteNews = true;
    return API.administration.News.newsIdDelete(id)
      .then(this.getNews)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.deleteNews = false;
        });
      });
  };

  onClickBanner = (id: string) => {
    const currentType = this.newsList.find((i) => i.id === id).type;
    const type = currentType === 0 ? 1 : 0;
    return this.changeNews(id, { type });
  };

  onClickMain = (id: string) => {
    const currentType = this.newsList.find((i) => i.id === id).type;
    const type = currentType === 2 ? 0 : 2;
    return this.changeNews(id, { type });
  };

  onClickActive = (id: string) => {
    const currentState = this.newsList.find((i) => i.id === id).state;
    const state = currentState === 1 ? 0 : 1;
    return this.changeNews(id, { state });
  };

  changeNews = (id: string, options: NewsSetForm) => {
    this.isLoadings.changeNews = true;
    // type = 0 - обычные новости 1 - новости баннера 2 - главная новость
    // state =  0 - скрытые 1 - опубликованные
    return API.administration.News.newsIdPost(id, options)
      .then(this.getNews)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.changeNews = false;
        });
      });
  };

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }
}

export interface INewsListItem {
  croppedImageId: string | null;
  image: string | null;
  date: string | null;
  hasImage: boolean | null;
  id: string | null;
  previewImageId: string | null;
  state: number | null;
  title: string | null;
  type: number | null;
  isBanner: boolean;
  isMain: boolean;
}
