import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../../../../api";
import { IRoleInfo } from "./index.types";

export class RolesInfoStore {
  isLoading = false;

  private roleInfo: IRoleInfo | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.getInfo(id);
  };

  reset = () => {
    this.isLoading = false;
    this.roleInfo = null;
  };

  private getInfo = (id: string) => {
    this.isLoading = true;
    return API.access.Roles.apiRolesRoleIdGet(id)
      .then((res: any) => {
        runInAction(() => {
          const data = res.data! as IRoleInfo;
          this.roleInfo = data;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.roleInfo = null;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  get fullName(): string | null {
    return this.roleInfo?.roleTitle || null;
  }

  get infoTable() {
    return {
      name: this.roleInfo?.roleTitle,
      id: this.roleInfo?.id,
      level: String(this.roleInfo?.level) || null,
      isRoleAvailableForModule: this.roleInfo?.isRoleAvailableForModule
        ? "Да"
        : "Нет",
    };
  }

  get rightsTable() {
    const rights: string[][] = [];
    this.roleInfo?.modulesDetailes.forEach((module) => {
      module.entities.forEach((entity) => {
        entity.operations.forEach((operation) => {
          rights.push([
            module.moduleTitle,
            entity.entityTitle,
            operation.title,
            operation.rules,
          ]);
        });
      });
    });

    return rights;
  }
}
