import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "newApp/constants/validation/validators";
import * as yup from "yup";

export const formValidationSchema = yup.object().shape({
  title: VALIDATOR_STRING_REQUIRED,
  isActive: VALIDATOR_ANY,
});
