import { makeAutoObservable, runInAction } from "mobx";

import { API } from "../../../../../../../api";
import {
  IModuleItem,
  IModuleItemOperation,
} from "../../../../../../../components/RoleEditModules/index.types";
import { IModulesDetail } from "../../../RolesItemPageStore/RolesInfoStore/index.types";
import {
  IModule,
  IOperation,
  IResponseData,
  IRule,
  IRulesResponseData,
} from "./index.types";

export class RoleModulesStore {
  isLoadings = { getRules: false, getModules: false };

  private modulesOptions: IModule[] = [];
  private operations: IOperation[] = [];
  private rules: IRule[] = [];

  selectedModules: string[] = [];
  selectedRules: {
    [key: string]: {
      [key: string]: string | null;
    };
  } = {};

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getModules();
    this.getRules();
  };

  reset = () => {
    this.modulesOptions = [];
    this.operations = [];
    this.rules = [];
    this.selectedModules = [];
    this.selectedRules = {};
  };

  private getModules = () => {
    this.isLoadings.getModules = true;
    return API.access.Roles.apiRolesModulesGet()
      .then((res: any) => {
        runInAction(() => {
          this.modulesOptions = (res.data! as IResponseData).modules;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.modulesOptions = [];
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getModules = false;
        });
      });
  };

  private getRules = () => {
    return API.access.Common.apiCommonOperationRulesGet()
      .then((res: any) => {
        runInAction(() => {
          this.rules = (res.data! as IRulesResponseData).rules;
          this.operations = (res.data! as IRulesResponseData).operations;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.rules = [];
          this.operations = [];
        });
      });
  };

  get modules(): IModuleItem[] {
    const modules: IModuleItem[] = [];
    this.modulesOptions.forEach((module) => {
      const operations: IModuleItemOperation[] = [];
      this.operations.forEach((operation) => {
        if (operation.moduleId === module.value) {
          const operationId = this.selectedRules[module.value];
          const value = operationId ? operationId[String(operation.id)] : null;
          operations.push({
            name: operation.title,
            id: operation.id,
            value,
            options: this.rules.map((rule) => ({
              text: rule.title,
              value: rule.id,
            })),
          });
        }
      });
      modules.push({
        name: module.text,
        id: module.value,
        isSelected: this.selectedModules.includes(module.value),
        operations,
      });
    });
    return modules;
  }

  onChangeModule = (id: string) => {
    if (this.selectedModules.includes(id)) {
      this.selectedModules = this.selectedModules.filter(
        (moduleId) => moduleId !== id
      );
      /** reset inner values */
      this.selectedRules[id] = {};
    } else {
      this.selectedModules = [...this.selectedModules, id];

      /** set inner values by default */
      if (
        !this.selectedRules[id] ||
        !Object.keys(this.selectedRules[id]).length
      ) {
        let values = {};
        this.operations.forEach((operation) => {
          if (operation.moduleId === id) {
            const defaultValue = this.rules[0].id;
            values = { ...values, [operation.id]: defaultValue };
          }
        });
        this.selectedRules[id] = values;
      }
    }
  };

  onChangeOperation = (
    moduleId: string,
    operationId: string,
    ruleId: string
  ) => {
    this.selectedRules = {
      ...this.selectedRules,
      [moduleId]: {
        ...this.selectedRules[moduleId],
        [operationId]: ruleId,
      },
    };
  };

  setModules = (modules: IModulesDetail[]) => {
    modules.forEach((module) => {
      this.selectedModules = [...this.selectedModules, module.id];
      module.entities.forEach((entity) => {
        entity.operations.forEach((operation) => {
          this.onChangeOperation(
            module.id,
            operation.operationId,
            operation.ruleId
          );
        });
      });
    });
  };

  /** {operation:rule} */
  get permissions(): { [key: string]: string } {
    let permissions = {};
    Object.keys(this.selectedRules).forEach((moduleKey) => {
      Object.entries(this.selectedRules[moduleKey]).forEach(
        ([operationKey, value]) => {
          permissions = { ...permissions, [operationKey]: value };
        }
      );
    });
    return permissions;
  }
}
