import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { FormStore } from "newApp/kit/stores/FormStore";
import { getDataURL } from "newApp/kit/utils/getDataURL";
import validationSchema from "./validationSchema";

export class AnswerStore {
  form = new FormStore<IAnswerStoreForm>(
    {
      fileId: null,
      hasPhoto: null,
      id: null,
      text: null,
      files: [],
    },
    validationSchema
  );

  files: IFilesPreviewFile[] = [];
  fileIds: string[] = [];

  isLoadings = {
    uploadFile: false,
  };

  constructor(form?: IAnswerStoreForm) {
    if (form) this.setForm(form);
    makeAutoObservable(this);
  }

  setForm = (form: IAnswerStoreForm) => {
    this.form.setValues({
      ...form,
      hasPhoto: !!form.fileId,
    });
    if (form.files.length) {
      form.files.forEach((item) => this.fileIds.push(item.id));
      this.files = form.files.map((item) => ({
        reference: URL_API_ADMIN + `/api/answers/files/${item.id}`,
        type:
          item.name.split(".")[1] === "png"
            ? "image"
            : item.name.split(".")[1] === "jpg"
            ? "image"
            : item.name.split(".")[1] === "jpeg"
            ? "image"
            : item.name.split(".")[1],
        id: item.id,
        title: item.name,
      }));
    }
  };

  resetFiles = () => {
    this.files = [];
  };

  removeFile = (idx: number) => {
    const id = this.fileIds[idx];
    API.admin.Files.apiAnswersFilesFileIdDeletePost(id).then(() => {
      this.files.splice(idx, 1);
      this.fileIds.splice(idx, 1);
    });
  };

  setFile = (params, file: File) => {
    this.files.push({
      type: file.type.split("/")[0],
      reference: params.reference || URL_API_ADMIN + "/api/files/" + params.id,
      file: file,
    });
  };

  onAddFiles = (files: File[]) => {
    runInAction(() => {
      files.forEach((f) => {
        getDataURL(f)
          .then((reference) => {
            runInAction(() => {
              this.setFile({ reference }, f);
            });
          })
          .catch(() => {
            console.error("Ошибка в создании превью");
          });
      });
      files.forEach((file) => {
        this.uploadFile(file)
          .then((data) => {
            this.form.changeValueByKey(data[0]["id"], "fileId");
          })
          .catch(() => {
            this.form.reset("fileId");
          });
      });
    });
  };

  uploadFile = (file: File) => {
    this.isLoadings.uploadFile = true;
    return API.admin.Files.apiAnswersFilesSetPost(file)
      .then(({ data }) => this.fileIds.push(data.Id))
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.uploadFile = false;
        });
      });
  };

  validate = () => {
    this.form.validate();
  };

  get isValid() {
    return this.form.isValid;
  }
}

export interface IAnswerStoreForm {
  hasPhoto: null | boolean;
  text: null | string;
  id: null | string;
  fileId: null | string;
  files: any[];
}
