import { formatISO } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { VoteFormStore } from "newApp/stores/common/VoteFormStore";
import { TGetVoteResponse } from "./responseExample";

export class VoteEditPageStore {
  id: string = "";

  voteForm = new VoteFormStore();

  isLoadings = {
    getVote: false,
    saveVote: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.voteForm.init();
    this.getVote();
  };

  reset = () => {
    this.voteForm.reset();
  };

  getVote = () => {
    this.isLoadings.getVote = true;
    return API.admin.Vote.apiVotesIdGet(this.id)
      .then((res) => {
        try {
          runInAction(() => {
            const data = res.data as TGetVoteResponse;
            this.voteForm.details.setForm({
              ...data.voteModel,
              mainVoteTitle: data.mainVoteTitle,
            });
            this.voteForm.questions.setQuestions(
              data.voteModel.questions as any
            );
          });
        } catch (error: any) {
          console.error(error);
        }
      })
      .catch(this.reset)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getVote = false;
        });
      });
  };

  onSave = async () => {
    this.voteForm.validate();
    if (this.voteForm.isValid) {
      await this.voteForm.details.fileForm.uploadFiles();
      return await this.saveVote();
    }
    return false;
  };

  saveVote = () => {
    this.isLoadings.saveVote = true;
    return API.admin.Vote.apiVotesEditIdPost(this.id, {
      Title: this.voteForm.details.form.values.title,
      Annotation: this.voteForm.details.form.values.annotation,
      Description: this.voteForm.details.form.values.description,
      IsDigitalResult: !!this.voteForm.details.form.values.isDigitalResult,
      NumberOfPoints: this.voteForm.details.form.values.numberOfPoints
        ? Number(this.voteForm.details.form.values.numberOfPoints)
        : null,
      EndDateTime: formatISO(this.voteForm.details.form.values.endDateTime),
      StartDateTime: formatISO(this.voteForm.details.form.values.startDateTime),
      IsImportant: !!this.voteForm.details.form.values.isImportant,
      IsBanner: !!this.voteForm.details.form.values.isBanner,
      Gender: this.voteForm.details.form.values.isGenderLimit
        ? this.voteForm.details.form.values.gender
        : null,
      FileId: this.voteForm.details.fileForm.mainFileId || undefined,
      FilesIds: this.voteForm.details.filesIds || [],
      CroppedFileId: this.voteForm.details.fileForm.croppedFileId || undefined,
      PreviewFileId: this.voteForm.details.fileForm.previewFileId || undefined,
      FromAge: this.voteForm.details.form.values.fromAge,
      ToAge: this.voteForm.details.form.values.toAge,

      Coordinates: this.voteForm?.details?.coordinates?.length
        ? this.voteForm.details.coordinates.map((i) => ({
            Latitude: i.latitude,
            Longitude: i.longitude,
          }))
        : null,
      Place: this.voteForm.details.form.values.place,
      Position: this.voteForm.details.form.values.position,
      Idp: 0,
      Type: "Interview",
      Questions: this.voteForm.questions.questions.map((q) => ({
        Text: q.form.values.text || undefined,
        IsRequired: !!q.form.values.isRequired || undefined,
        Type: q.form.values.type || undefined,
        HasOtherOption: !!q.form.values.hasOtherOption || undefined,
        Id: q.form.values.id || undefined,
        FilesIds: q.fileIds || undefined,

        //TODO: здесь тоже говно
        Answers: q.answers.map((a) => ({
          Id: a.form.values.id,
          Text: a.form.values.text,
          FilesIds: a.fileIds,
        })),
      })),
    })
      .then(() => true)
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveVote = false;
        });
      });
  };
}
