import * as yup from "yup";
import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "../../../constants/validation/validators";

export default yup.object().shape({
  answer: VALIDATOR_STRING_REQUIRED,
  date: VALIDATOR_STRING_REQUIRED,
  id: VALIDATOR_ANY,
  question: VALIDATOR_STRING_REQUIRED,
  rubricId: VALIDATOR_STRING_REQUIRED,
  state: VALIDATOR_ANY,
});
