import React from "react";

const IconFile: React.FC = () => {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      version="1.1"
      id="svg6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 11.99998,4.00002 H 16 v 1.99996 h 4 V 0 H 0 V 5.99998 H 4.00002 V 4.00002 H 8.00004 V 24 H 4.00002 v 4 h 11.998 v -4 h -3.99804 z"
        fill="#689857"
        id="path2"
      />
      <path
        d="m 16,10.00004 v 5.99998 h 4 v -1.99996 h 2 V 24 h -2 v 4 h 8 V 24 H 26 V 13.99998 h 2 v 1.99998 h 4 V 9.99998 Z"
        fill="#689857"
        id="path4"
      />
    </svg>
  );
};

export default IconFile;
