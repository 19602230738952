import "./styles.scss";

import { observer } from "mobx-react";
import Checkbox from "newApp/kit/components/CheckBox";
import { URL_ADMINISTRATION_ORGANIZATIONS } from "newApp/routing/urls";
import { IOrganization } from "newApp/stores/pages/administrations/organizations/OrganizationsPageStore";
import React from "react";
import { useHistory } from "react-router";

import DashedButton from "../DashedButton";

export interface IOrganizationsTableProps {
  organizations: IOrganization[];
  changeItemActive: (id: string, isActive: boolean) => void;
}

const OrganizationsTable: React.FC<IOrganizationsTableProps> = (props) => {
  const { push } = useHistory();

  return (
    <table className="organizations-table">
      <thead className="organizations-table__header">
        <tr className="organizations-table__header-row">
          <td className="organizations-table__header-cell">Наименование</td>
          <td className="organizations-table__header-cell">Публикация</td>
        </tr>
      </thead>
      <tbody className="organizations-table__body">
        {props.organizations.map((o, idx) => (
          <tr
            className={[
              "organizations-table__body-row",
              !!o.level ? "organizations-table__body-row--bordered" : "",
            ].join(" ")}
            key={idx}
          >
            <td
              className={[
                "organizations-table__body-cell",
                `organizations-table__body-cell--level-${o.level}`,
                `organizations-table__body-cell--clickable`,
              ].join(" ")}
              style={{ paddingLeft: `${o.level * 40}px` }}
              onClick={() =>
                push(URL_ADMINISTRATION_ORGANIZATIONS + `/${o.id}/edit`)
              }
            >
              {o.title}
            </td>
            <td className={"organizations-table__body-cell"}>
              <Checkbox
                value={o.active}
                onChange={() => props.changeItemActive(o.id, o.active)}
              >
                Активно
              </Checkbox>
            </td>
            <td
              className={[
                "organizations-table__body-cell",
                "organizations-table__body-cell--inline",
              ].join(" ")}
            >
              <DashedButton
                onClick={() =>
                  push(URL_ADMINISTRATION_ORGANIZATIONS + `/${o.id}/edit`)
                }
              >
                Редактировать
              </DashedButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(OrganizationsTable);
