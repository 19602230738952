import "./styles.scss";

import {
  URL_ANALYTICS_ACCEPTED,
  URL_ANALYTICS_INWORK,
} from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";

const AnalyticsNavigator: React.FC = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const links: { paths: string[]; text: string }[] = [
    { paths: [URL_ANALYTICS_INWORK], text: "В работе" },
    { paths: [URL_ANALYTICS_ACCEPTED], text: "Поступившие" },
  ];

  return (
    <div className="votings-mode-switcher">
      {links.map((link, indx) => (
        <div
          className={[
            "votings-mode-switcher__item",
            link.paths.includes(pathname)
              ? "votings-mode-switcher__item--active"
              : "",
          ].join(" ")}
          key={indx}
          onClick={() => push(link.paths[0])}
        >
          {link.text}
        </div>
      ))}
    </div>
  );
};

export default AnalyticsNavigator;
