import React, { useState } from "react";
import ReactSelect from "react-select";
import IconError from "../IconError";
import Tooltip from "../Tooltip";
import { ISelectProps } from "./index.types";
import "./styles.scss";
import { observer } from "mobx-react";

const Select: React.FC<ISelectProps> = (props) => {
  const options = props.options.map((option) => ({
    value: option.value,
    label: option.text,
  }));
  const selectedOption = options.find((option) => option.value === props.value);

  const ishasError: boolean = !!props.errorMessage || !!props.errorTitle;
  const [isOpenError, setIsOpenError] = useState(false);

  const errorIcon = ishasError
    ? () => (
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
            setIsOpenError(!isOpenError);
          }}
        >
          <IconError />
        </div>
      )
    : undefined;

  return (
    <div className={["select", ishasError ? "select--error" : ""].join(" ")}>
      <ReactSelect
        {...props}
        className="react-select"
        classNamePrefix="react-select"
        options={options}
        value={selectedOption}
        components={{
          IndicatorSeparator: errorIcon,
        }}
        onChange={(newOption) => props.onChange(newOption?.value, props.name)}
        placeholder={props.placeholder}
        onBlur={() => setIsOpenError(false)}
        menuIsOpen={props.menuIsOpen}
      />
      {props.placeholder && !!selectedOption && (
        <div className="select__placeholder">{props.placeholder}</div>
      )}
      {ishasError && isOpenError && (
        <Tooltip
          title={props.errorTitle}
          text={props.errorMessage}
          onClose={() => setIsOpenError(false)}
        />
      )}
    </div>
  );
};

export default observer(Select);
