import { observer } from "mobx-react";
import React from "react";

import Map from "../Map";
import { IMapMarker } from "../Map/index.types";
import "./styles.scss";

interface IProps {
  coords: [number, number];
  zoom: number;
  center: [number, number];
  onClick?: (coords: { latitude: number; longitude: number }) => void;
}

const ProductItemMap: React.FC<IProps> = ({
  coords,
  zoom,
  center,
  onClick,
}) => {
  const markers: IMapMarker[] = [
    {
      position: [coords[0], coords[1]],
    },
  ];

  return (
    <div className="declaration-item-map">
      {
        <Map
          markers={markers}
          zoom={zoom}
          center={center}
          icon
          onClick={onClick}
        />
      }
    </div>
  );
};

export default observer(ProductItemMap);
