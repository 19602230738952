import "./styles.scss";

import { observer } from "mobx-react";
import { TExecutionDeclarationInfo } from "newApp/stores/pages/execution/ExecutionItemPageStore/index.types";
import React from "react";
import { getDeclarationStatus } from "./getDeclarationStatus";

export interface IDeclarationInfoProps {
  info: TExecutionDeclarationInfo;
}

const DeclarationInfo: React.FC<IDeclarationInfoProps> = (props) => {
  return (
    <table className="declaration-info">
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Категория</td>
        <td className="declaration-info__row-value">{props.info.category}</td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Подкатегории</td>
        <td className="declaration-info__row-value">
          {props.info.subCategories}
        </td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Дата создания</td>
        <td className="declaration-info__row-value">
          {props.info.dateOfCreation}
        </td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Осталось дней</td>
        <td className="declaration-info__row-value">{props.info.daysLeft}</td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Адрес</td>
        <td className="declaration-info__row-value">{props.info.address}</td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Заявитель</td>
        <td className="declaration-info__row-value">
          {[props.info.lastName, props.info.firstName].join(" ")}
        </td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">email</td>
        <td className="declaration-info__row-value">
          {props.info.email ? (
            <a href={`mailto:${props.info.email}`}>{props.info.email}</a>
          ) : (
            ""
          )}
        </td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Телефон</td>
        <td className="declaration-info__row-value">
          {props.info.mobile ? (
            <a href={`tel:${props.info.mobile}`}>{props.info.mobile}</a>
          ) : (
            ""
          )}
        </td>
      </tr>
      <tr className="declaration-info__row">
        <td className="declaration-info__row-label">Статус заявления</td>
        <td
          className="declaration-info__row-value"
          title={`Статус заявления: ${props.info.status}`}
        >
          {getDeclarationStatus(props.info.status)}
        </td>
      </tr>
    </table>
  );
};

export default observer(DeclarationInfo);
