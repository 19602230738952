import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import FaqForm from "newApp/components/FaqForm";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_FAQ } from "newApp/routing/urls";
import { FaqCreatePageStore } from "newApp/stores/pages/site/faq/FaqCreatePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new FaqCreatePageStore();

const FaqCreatePage: React.FC = () => {
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const { push } = useHistory();

  const onClickCreate = async () => {
    const id = await store.onCreate();
    if (id) push(URL_SITE_FAQ + `/${id}`);
  };

  return (
    <MainLayout>
      <H1>Создание часто задаваемого вопроса</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_FAQ}>Назад к списку вопросов</ButtonBack>
        <FaqForm {...store.faqForm} />
        <ButtonsContainer>
          <Button onClick={onClickCreate} disabled={store.isLoadings.onCreate}>
            Создать
          </Button>
          <Button onClick={() => push(URL_SITE_FAQ)} variant="secondary">
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(FaqCreatePage);
