import { observer } from "mobx-react";
import React from "react";
import Table from "../../kit/components/Table";
import { URL_ADMISSION_ROLES } from "../../routing/urls";
import Link from "../Link";
import { IRoleProp } from "./index.types";

interface IProps {
  roles: IRoleProp[];
}

const RolesListTable: React.FC<IProps> = (props) => {
  return (
    <Table
      content={props.roles.map((role) => [
        <Link to={URL_ADMISSION_ROLES + `/${role.id}`}>{role.name}</Link>,
        role.accessLevel,
        role.module,
      ])}
      headers={["Название", "Уровень", "Модули"]}
    />
  );
};

export default observer(RolesListTable);
