import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import FilesPreview from "newApp/components/FilesPreview";
import MainLayout from "newApp/components/layouts/MainLayout";
import NewsPreview from "newApp/components/NewsPreview";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import H3 from "newApp/kit/components/H3";
import InfoTable from "newApp/kit/components/InfoTable";
import { URL_SITE_NEWS } from "newApp/routing/urls";
import { NewsItemPageStore } from "newApp/stores/pages/site/news/NewsItemPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new NewsItemPageStore();

const NewsItemPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  return (
    <MainLayout isLoading={store.isLoadings.getNewsItem}>
      <H1>{store.info.title}</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_NEWS}>Назад к списку новостей</ButtonBack>
        <ButtonsContainer>
          <Button onClick={() => push(URL_SITE_NEWS + `/${id}/edit`)}>
            Редактировать
          </Button>
          <Button onClick={store.onClickPublish} variant="secondary">
            {store.info.isActive ? "Снять с публикации" : "Опубликовать"}
          </Button>
        </ButtonsContainer>
        <InfoTable
          header="Информация о новости"
          content={[
            ["Дата создания новости", store.info.date],
            ["Опубликована", store.info.isActive ? "Да" : "Нет"],
            ["Закреплена в баннере", store.info.isBanner ? "Да" : "Нет"],
            ["Главная новость", store.info.isMain ? "Да" : "Нет"],
          ]}
        />
        <div>
          <H3>Главное изображение новости</H3>
          <FilesPreview files={store.mainImages} />
        </div>
        <div>
          <H3>Прикрепленые фотографии</H3>
          <FilesPreview files={store.images} />
        </div>
        <div>
          <H3>Прикрепленые файлы</H3>
          <FilesPreview files={store.files} />
        </div>
        <H3>Текст новости</H3>
        <NewsPreview content={store.info.content} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(NewsItemPage);
