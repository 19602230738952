import React from "react";

const IconVoting = () => {
  return (
    <svg width="56" height="62" viewBox="0 0 56 62" fill="none">
      <path
        d="M22.3051 4.2577C25.9125 2.53927 30.0875 2.53927 33.6949 4.2577C36.2389 5.46956 39.0621 6.87826 41.252 8.13809C43.4311 9.39172 46.052 11.1128 48.3691 12.6959C51.6846 14.9611 53.7871 18.5938 54.0985 22.5968C54.3153 25.3836 54.5 28.4981 54.5 31C54.5 33.5019 54.3153 36.6164 54.0985 39.4032C53.7871 43.4062 51.6846 47.0389 48.3691 49.3041C46.052 50.8872 43.4311 52.6083 41.252 53.8619C39.0621 55.1217 36.2389 56.5304 33.6949 57.7423C30.0875 59.4607 25.9125 59.4607 22.3051 57.7423C19.7611 56.5304 16.9379 55.1217 14.748 53.8619C12.5689 52.6083 9.94796 50.8872 7.63093 49.3041C4.31542 47.0389 2.21292 43.4062 1.90149 39.4032C1.68467 36.6164 1.5 33.5019 1.5 31C1.5 28.4981 1.68467 25.3836 1.90149 22.5968C2.21292 18.5938 4.31542 14.9611 7.63092 12.6959C9.94796 11.1128 12.5689 9.39172 14.748 8.13809C16.9379 6.87826 19.7611 5.46956 22.3051 4.2577Z"
        stroke="#882C80"
        strokeWidth="3"
      ></path>
      <rect
        x="12.5"
        y="29.5"
        width="5"
        height="12"
        stroke="#882C80"
        strokeWidth="3"
      ></rect>
      <rect
        x="21.5"
        y="18.5"
        width="5"
        height="23"
        stroke="#882C80"
        strokeWidth="3"
      ></rect>
      <rect
        x="30.5"
        y="35.5"
        width="5"
        height="6"
        stroke="#882C80"
        strokeWidth="3"
      ></rect>
      <rect
        x="39.5"
        y="25.5"
        width="5"
        height="16"
        stroke="#882C80"
        strokeWidth="3"
      ></rect>
    </svg>
  );
};

export default IconVoting;
