import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import NewsForm from "newApp/components/NewsForm";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_NEWS } from "newApp/routing/urls";
import { NewsCreatePageStore } from "newApp/stores/pages/site/news/NewsCreatePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const store = new NewsCreatePageStore();

const NewsCreatePage: React.FC = () => {
  const { push } = useHistory();
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const goOnNewsList = () => push(URL_SITE_NEWS);

  const onCreate = () => {
    store.onClickCreate().then((id) => {
      if (id) push(URL_SITE_NEWS + `/${id}`);
    });
  };

  const isDisabledButton =
    store.isLoadings.onClickCreate ||
    store.form.mainPhoto.isLoadings.uploadImage;

  return (
    <MainLayout>
      <H1>Создание новости</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_NEWS}>Назад к списку новостей</ButtonBack>
        <NewsForm {...store.form} />
        <ButtonsContainer>
          <Button onClick={onCreate} disabled={isDisabledButton}>
            {store.form.mainPhoto.isLoadings.uploadImage
              ? "Загрузка изображения"
              : "Создать новость"}
          </Button>
          <Button onClick={goOnNewsList} variant="secondary">
            Отмена
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(NewsCreatePage);
