import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMINISTRATION } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import imageCompression from "browser-image-compression";

export class NewsFormMainPhotoStore {
  isLoadings = {
    savePreviewImage: false,
    saveCroppedImage: false,
    uploadImage: false,
  };

  isShow = {
    mainPhotoCropper: false,
  };

  mainImageFile: File | null = null;
  mainImageId: string | null = null;

  croppedImage: IFilesPreviewFile | null = null;
  previewImage: IFilesPreviewFile | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {
    this.mainImageFile = null;
    this.mainImageId = null;
    this.previewImage = null;
    this.croppedImage = null;
  };

  onAddMainPhoto = (files: File[]) => {
    this.mainImageFile = files[0];
    this.isShow.mainPhotoCropper = true;
  };

  onRemoveMainPhoto = () => {
    this.reset();
  };

  closeMainPhotoCropper = () => {
    this.isShow.mainPhotoCropper = false;
  };

  onCroppedMainPhoto = (results: string[]) => {
    this.croppedImage = {
      reference: results[0],
      type: "Image",
    };
    this.previewImage = {
      reference: results[1],
      type: "Image",
    };
    this.uploadImage();
    this.closeMainPhotoCropper();
  };

  uploadImage = () => {
    runInAction(() => {
      this.mainImageId = null;
      this.isLoadings.uploadImage = true;
    });

    return API.administration.News.newsImagePost(this.mainImageFile)
      .then(({ data }) => {
        runInAction(() => {
          this.mainImageId = data;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.mainImageId = null;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.uploadImage = false;
        });
      });
  };

  setMainPhoto = (
    croppedImageId: string,
    previewImageId: string,
    newsId: string
  ) => {
    if (croppedImageId) {
      this.croppedImage = {
        reference: URL_API_ADMINISTRATION + `/news/${newsId}/croppedimage`,
        type: "Image",
      };
    }
    if (previewImageId) {
      this.previewImage = {
        reference: URL_API_ADMINISTRATION + `/news/${newsId}/previewimage`,
        type: "Image",
      };
    }
  };

  savePreviewImage = async (newsId: string) => {
    try {
      this.isLoadings.savePreviewImage = true;
      const blob = await imageCompression.getFilefromDataUrl(
        this.previewImage.reference,
        "preview"
      );
      return API.administration.News.newsNewsIdPreviewimagePost(
        newsId,
        blob
      ).finally(() => {
        runInAction(() => {
          this.isLoadings.savePreviewImage = false;
        });
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  saveCroppedImage = async (newsId: string) => {
    try {
      this.isLoadings.saveCroppedImage = true;

      const blob = await imageCompression.getFilefromDataUrl(
        this.croppedImage.reference,
        "croppedImage"
      );
      return API.administration.News.newsNewsIdCroppedimagePost(
        newsId,
        blob
      ).finally(() => {
        runInAction(() => {
          this.isLoadings.saveCroppedImage = false;
        });
      });
    } catch (error: any) {
      console.error(error);
    }
  };
}
