import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "newApp/constants/validation/validators";
import * as yup from "yup";

export const templateSchema = yup.object({
  content: VALIDATOR_STRING_REQUIRED,
  templateTitle: VALIDATOR_STRING_REQUIRED,
  buttonAvailable: VALIDATOR_ANY,
  showImage: VALIDATOR_ANY,
  templateType: VALIDATOR_ANY,
});
