import React, { MutableRefObject, useEffect, useImperativeHandle } from "react";
import { useIMask } from "react-imask";
import { TMaskedInputProps } from "./index.types";

// todo: в новой версии react-imask должны исправить типизацию
declare module "imask" {
  interface MaskElement {
    input: HTMLInputElement;
  }
}

const MaskedInput = React.forwardRef(
  ({ mask, onChange, ...props }: TMaskedInputProps, ref) => {
    const imask = useIMask(
      {
        mask,
        definitions: {
          C: /[a-zA-Zа-яА-Я]/i,
          s: /[a-z]/,
          S: /[A-Z]/,
          ы: /[а-я]/,
          Ы: /[А-Я]/,
          9: /[0-9]/,
        },
      },
      {
        onAccept: (value, { el }) => {
          onChange(value, el.input.name);
        },
      }
    );

    // synchronize values
    useEffect(() => {
      imask.maskRef.current.value = props.value || "";
      imask.maskRef.current.updateValue();
    }, [imask, props.value]);

    // todo: в новой версии react-imask должны исправить типизацию
    const maskedInputRef = imask.ref as MutableRefObject<HTMLInputElement>;

    // synchronize refs
    useImperativeHandle(
      ref,
      () => {
        return maskedInputRef.current;
      },
      [maskedInputRef]
    );

    return (
      <input
        {...props}
        ref={maskedInputRef}
        value={props.value}
        // we use onAccept instead of onChange
        onChange={() => null}
      />
    );
  }
);

export default MaskedInput;
