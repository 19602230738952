import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

export interface IDaysLeftCountProps {
  daysLeft?: number;
}

const DaysLeftCount: React.FC<IDaysLeftCountProps> = (props) => {
  if (!props.daysLeft) return null;
  return (
    <div className="days-left-count">
      <span className="days-left-count__title">Осталось дней: </span>
      <span
        className={[
          "days-left-count__value",
          Number(props.daysLeft) < 0 ? "days-left-count__value--negative" : "",
        ].join(" ")}
      >
        {props.daysLeft}
      </span>
    </div>
  );
};

export default observer(DaysLeftCount);
