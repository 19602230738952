import React from "react";
import "./styles.scss";

import Loader from "./Loader";

const LoaderSpinner: React.FC = () => {
  return (
    <div className="throbber-container">
      <div className="throbber-svg throbber-window">
        <div>
          <h3 className="line-1">Идёт загрузка</h3>
          <div className="line-2">Это может занять некоторое время</div>
        </div>
        <div className="throbber-big">
          <Loader />
        </div>
      </div>
    </div>
  );
};

export default LoaderSpinner;
