import { observer } from "mobx-react";
import React from "react";

export interface IIconOkProps {
  color?: "string";
}

const IconOk: React.FC<IIconOkProps> = ({ color = "#84BF41" }) => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 7.05301L6.16399 13L18 1.61368L16.3007 0L6.16399 9.74994L1.67572 5.42796L0 7.05301Z"
        fill={color}
      />
    </svg>
  );
};

export default observer(IconOk);
