import React from "react";

const IconSite = () => {
  return (
    <svg width="56" height="62" viewBox="0 0 56 62" fill="none">
      <path
        d="M22.3051 4.2577C25.9125 2.53927 30.0875 2.53927 33.6949 4.2577C36.2389 5.46956 39.0621 6.87826 41.252 8.13809C43.4311 9.39172 46.052 11.1128 48.3691 12.6959C51.6846 14.9611 53.7871 18.5938 54.0985 22.5968C54.3153 25.3836 54.5 28.4981 54.5 31C54.5 33.5019 54.3153 36.6164 54.0985 39.4032C53.7871 43.4062 51.6846 47.0389 48.3691 49.3041C46.052 50.8872 43.4311 52.6083 41.252 53.8619C39.0621 55.1217 36.2389 56.5304 33.6949 57.7423C30.0875 59.4607 25.9125 59.4607 22.3051 57.7423C19.7611 56.5304 16.9379 55.1217 14.748 53.8619C12.5689 52.6083 9.94796 50.8872 7.63093 49.3041C4.31542 47.0389 2.21292 43.4062 1.90149 39.4032C1.68467 36.6164 1.5 33.5019 1.5 31C1.5 28.4981 1.68467 25.3836 1.90149 22.5968C2.21292 18.5938 4.31542 14.9611 7.63092 12.6959C9.94796 11.1128 12.5689 9.39172 14.748 8.13809C16.9379 6.87826 19.7611 5.46956 22.3051 4.2577Z"
        stroke="#E77A2B"
        strokeWidth="3"
      ></path>
      <path
        d="M28.3853 30.9012L31 22.8541L33.6147 30.9012C33.9494 31.9313 34.9092 32.6287 35.9923 32.6287H44.4535L37.6083 37.6021C36.732 38.2387 36.3654 39.3671 36.7001 40.3972L39.3148 48.4443L32.4695 43.4709C31.5932 42.8343 30.4068 42.8343 29.5305 43.4709L22.6852 48.4443L25.2999 40.3972C25.6346 39.3671 25.268 38.2387 24.3917 37.6021L17.5465 32.6287H26.0077C27.0908 32.6287 28.0506 31.9313 28.3853 30.9012Z"
        stroke="#E77A2B"
        strokeWidth="3"
      ></path>
      <path
        d="M19.1814 18.3734L20 15.8541L20.8186 18.3734L22.2451 17.9098L20.8186 18.3734C21.1532 19.4034 22.1131 20.1008 23.1962 20.1008H25.8451L23.7021 21.6578C22.8259 22.2944 22.4592 23.4228 22.7939 24.4529L23.6125 26.9721L21.4695 25.4152C20.5932 24.7785 19.4068 24.7785 18.5305 25.4152L16.3875 26.9721L17.2061 24.4529C17.5408 23.4228 17.1741 22.2944 16.2979 21.6578L14.1549 20.1008H16.8038C17.8869 20.1008 18.8468 19.4034 19.1814 18.3734Z"
        stroke="#E77A2B"
        strokeWidth="3"
      ></path>
    </svg>
  );
};

export default IconSite;
