import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FaqFormStore } from "newApp/stores/common/FaqFormStore";

export class FaqEditPageStore {
  isLoadings = {
    getFaq: false,
    saveFaq: false,
    onSave: false,
  };

  faqForm = new FaqFormStore();

  id = "";

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.id = id;
    this.getFaq();
    this.faqForm.init();
  };

  reset = () => {
    this.faqForm.reset();
  };

  getFaq = () => {
    this.isLoadings.getFaq = true;
    return API.administration.FAQ.faqIdGet(this.id)
      .then(({ data }) => {
        runInAction(() => {
          try {
            this.faqForm.form.changeValueByKey(data.answer, "answer");
            this.faqForm.form.changeValueByKey(data.question, "question");
            this.faqForm.form.changeValueByKey(data.rubricId, "rubricId");
            this.faqForm.form.changeValueByKey(data.state, "state");
            this.faqForm.form.changeValue(new Date(data.date), "date");
            this.faqForm.setFiles(data.files);
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getFaq = false;
        });
      });
  };

  saveFaq = () => {
    this.isLoadings.saveFaq = true;
    return API.administration.FAQ.faqIdPost(this.id, {
      answer: this.faqForm.form.values.answer,
      date: new Date(this.faqForm.form.values.date).toISOString(),
      question: this.faqForm.form.values.question,
      rubricId: this.faqForm.form.values.rubricId,
      state: this.faqForm.form.values.state as any,
    })
      .then(() => {})
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveFaq = false;
        });
      });
  };

  onSave = async () => {
    runInAction(() => (this.isLoadings.onSave = true));
    this.faqForm.validate();
    if (this.faqForm.isValid) {
      await this.faqForm.removeFiles();
      await this.faqForm.uploadFiles(this.id);
      await this.saveFaq();
    }
    runInAction(() => (this.isLoadings.onSave = false));
  };
}
