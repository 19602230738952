import { toJS } from "mobx";
import { observer } from "mobx-react";
import Dropzone from "newApp/kit/components/Dropzone";
import FilesList from "newApp/kit/components/FilesList";
import FlexColumn from "newApp/kit/components/FlexColumn";
import TextArea from "newApp/kit/components/TextArea";
import FilesListStore from "newApp/kit/stores/FilesListStore";
import { FormStore } from "newApp/kit/stores/FormStore";
import { IStepValueItem } from "newApp/stores/pages/execution/ExecutionItemPageStore/ExecutionStepStore";
import React from "react";

export interface IStepValuesProps {
  values: IStepValueItem[];
  form: FormStore<{ textArea: string | null }>;
  filesStore: FilesListStore;
  isDone: boolean;
}

const StepValues: React.FC<IStepValuesProps> = (props) => {
  return (
    <FlexColumn>
      {props.values?.map((value) => {
        console.log("value", toJS(value));

        if (value.id === "7fcb5a4c-055e-4cc8-8712-7fd1c869575f")
          return (
            <TextArea
              placeholder={value.name}
              {...props.form.adapters.textArea}
              value={value.value}
              key={value.id}
              isDisabled
            />
          );
        if (value.id === "11225924-a3ba-4b02-ac22-7ca35b237375")
          return (
            <div key={value.id}>
              <Dropzone {...props.filesStore.dropzoneAdapter} />
              <FilesList {...props.filesStore.filesListAdapter} />
            </div>
          );

        return (
          <div key={value.id}>
            Требуется неизвестное поле {value.id} {value.name}
          </div>
        );
      })}
    </FlexColumn>
  );
};

export default observer(StepValues);
