import { makeAutoObservable, runInAction } from "mobx";

import { API } from "../../../../../api";
import { RolesFormsStore } from "../RoleItemEditPage/RolesFormsStore";

export class RoleCreatePageStore {
  isLoadings = {
    postRole: false,
  };

  forms = new RolesFormsStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.forms.init();
  };

  reset = () => {
    this.forms.reset();
    this.isLoadings = {
      postRole: false,
    };
  };

  validate = () => {
    this.forms.form.validate();
  };

  get isValid() {
    return this.forms.form.isValid;
  }

  postRole = () => {
    this.isLoadings.postRole = true;
    return API.access.Roles.apiRolesCreatePost({
      form: {
        level: Number(this.forms.form.values.level),
        title: String(this.forms.form.values.roleTitle),
        availableInModules: this.forms.modules.selectedModules,
        permissions: this.forms.modules.permissions,
      },
    })
      .then(({ data }) => String(data))
      .catch(() => null)
      .finally(() => {
        runInAction(() => {
          this.isLoadings.postRole = false;
        });
      });
  };
}
