import * as yup from "yup";
import {
  VALIDATOR_ANY,
  VALIDATOR_STRING_REQUIRED,
} from "../../../constants/validation/validators";

export default yup.object().shape({
  parentId: VALIDATOR_ANY,
  name: VALIDATOR_STRING_REQUIRED,
  isActive: VALIDATOR_ANY,
  organizationId: VALIDATOR_ANY,
  daysLimit: VALIDATOR_ANY,
});
