import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import FaqRubricForm from "newApp/components/FaqRubricForm";
import MainLayout from "newApp/components/layouts/MainLayout";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_FAQ_RUBRICS } from "newApp/routing/urls";
import store from "newApp/stores/pages/site/faq/rubrics/FaqRubricsCreatePageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const FaqRubricsCreatePage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return store.reset();
  }, []);

  const onClickCreate = async () => {
    const id = await store.onSave();
    if (id) push(URL_SITE_FAQ_RUBRICS);
  };

  return (
    <MainLayout>
      <H1>Создание рубрики часто задаваемых вопросов</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_FAQ_RUBRICS}>Назад к списку</ButtonBack>
        <FaqRubricForm faqRubricForm={store.faqRubricForm} />

        <ButtonsContainer>
          <Button onClick={onClickCreate}>Создать</Button>
          <Button
            onClick={() => push(URL_SITE_FAQ_RUBRICS)}
            variant="secondary"
          >
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(FaqRubricsCreatePage);
