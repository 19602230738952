import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import MainLayout from "newApp/components/layouts/MainLayout";
import H1 from "newApp/kit/components/H1";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import ShopNavigator from "newApp/components/ShopNavigator";
import CategoriesList from "newApp/components/ShopCategoriesList";
import LoaderSpinner from "newApp/kit/components/LoaderSpinner";
import Pagination from "newApp/kit/components/Pagination";

import { CategoriesPageStore } from "newApp/stores/pages/shop/categories/CategoriesPageStore";

import { URL_SHOP_CATEGORIES_CREATE } from "newApp/routing/urls";

const store = new CategoriesPageStore();

const CategoriesListPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();

    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Рубрики</H1>
      <FlexColumn>
        <ShopNavigator />
        <Button onClick={() => push(URL_SHOP_CATEGORIES_CREATE)}>
          Добавить рубрику
        </Button>
        {store.isLoading ? <LoaderSpinner /> : <CategoriesList {...store} />}
        <Pagination {...store.pagination} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(CategoriesListPage);
