import "./index.styles.scss";

import { observer } from "mobx-react";
import FilesPreview from "newApp/components/FilesPreview";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import Checkbox from "newApp/kit/components/CheckBox";
import Divider from "newApp/kit/components/Divider";
import DropZone from "newApp/kit/components/Dropzone";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H3 from "newApp/kit/components/H3";
import Select from "newApp/kit/components/Select";
import TextArea from "newApp/kit/components/TextArea";
import { QuestionStore } from "newApp/stores/common/VoteFormStore/VoteQuestionsFormStore/QuestionStore";
import React from "react";

import VoteAnswerForm from "./VoteAnswerForm";
import { VOTES_FILE_TYPES } from "newApp/constants/dropzone";

export interface IVoteQuestionFormProps extends Omit<QuestionStore, "isValid"> {
  index: number;
  remove: () => void;
}

const VoteQuestionForm: React.FC<IVoteQuestionFormProps> = (props) => {
  return (
    <div className="vote-question">
      <div className="vote-question__container">
        <FlexColumn>
          <div className="vote-question__title">Вопрос {props.index + 1}</div>
          <div className="vote-question__row">
            <div className="vote-question__row-item">
              <TextArea
                placeholder="Текст вопроса"
                {...props.form.adapters.text}
              />
            </div>
            <div className="vote-question__row-item">
              <Select
                placeholder="Тип вопроса"
                options={props.options.type}
                {...props.form.adapters.type}
                onChange={props.onChangeType}
              />
            </div>
          </div>
          <FilesPreview files={props.files} onClickRemove={props.removeFile} />
          <DropZone
            acceptFileTypes={VOTES_FILE_TYPES}
            multiple={true}
            onAddFiles={props.onAddFiles}
          />
          <Checkbox {...props.form.adapters.isRequired}>
            Обязательный вопрос
          </Checkbox>
          <Checkbox
            {...props.form.adapters.hasOtherOption}
            disabled={props.form.values.type === "TextField"}
          >
            Свободный ответ
          </Checkbox>
        </FlexColumn>
      </div>

      {props.form.values.type !== "TextField" && (
        <>
          <Divider />
          <div className="vote-question__container">
            <FlexColumn>
              <H3>Ответы ({props.answers.length})</H3>

              {props.answers.map((answer, answerIdx) => (
                <VoteAnswerForm
                  {...answer}
                  index={answerIdx}
                  removeAnswer={props.removeAnswer}
                  key={answerIdx}
                />
              ))}

              <Button onClick={() => props.addAnswer()} size="small">
                Добавить вариант ответа
              </Button>
            </FlexColumn>
          </div>
        </>
      )}

      <Divider />
      <div className="vote-question__container">
        <ButtonsContainer>
          <Button onClick={props.remove} variant="secondary">
            Удалить вопрос
          </Button>
        </ButtonsContainer>
      </div>
    </div>
  );
};

export default observer(VoteQuestionForm);
