import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import Select from "newApp/kit/components/Select";
import TextField from "newApp/kit/components/TextField";
import { FormStore } from "newApp/kit/stores/FormStore";
import {
  IModerationListFilterForm,
  IModerationListFilterOptions,
} from "newApp/stores/common/ModerationListFilterStore/index.types";
import React from "react";

import "./styles.scss";

export interface IDeclarationsListFilterProps {
  form: FormStore<IModerationListFilterForm>;
  onClose: () => void;
  isOpen: boolean;
  options: IModerationListFilterOptions;
  getList: () => void;
}

const DeclarationsListFilter: React.FC<IDeclarationsListFilterProps> = (
  props
) => {
  return (
    <div className="declarations-list-filter">
      <TextField
        placeholder="Номер"
        type="number"
        {...props.form.adapters.number}
      />
      <Select
        placeholder="Опубликовано на портале"
        options={props.options.isPublished}
        {...props.form.adapters.isPublished}
      />
      <Select
        placeholder="Район"
        options={props.options.districts}
        {...props.form.adapters.district}
      />
      <Select
        placeholder="Организация"
        options={props.options.organization}
        {...props.form.adapters.organizationId}
      />
      <Select
        placeholder="Сортировать"
        options={props.options.orderByDescending}
        {...props.form.adapters.orderByDescending}
      />
      <Select
        placeholder="Категория"
        options={props.options.category}
        {...props.form.adapters.categoryId}
      />
      <div className="declarations-list-filter__empty"></div>
      <div className="declarations-list-filter__buttons">
        <Button
          onClick={() => {
            props.form.reset();
            props.getList();
          }}
          variant="secondary"
          size="small"
        >
          Сбросить
        </Button>
        <Button onClick={props.getList} size="small">
          Найти
        </Button>
      </div>
    </div>
  );
};

export default observer(DeclarationsListFilter);
