import { observer } from "mobx-react";
import React from "react";
import "./styles.scss";

export interface INewsPreviewProps {
  content: string;
}

const NewsPreview: React.FC<INewsPreviewProps> = (props) => {
  return (
    <div className="news-preview">
      <div
        className="html-raw-content"
        dangerouslySetInnerHTML={{ __html: props.content }}
      ></div>
    </div>
  );
};

export default observer(NewsPreview);
