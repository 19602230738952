import { makeAutoObservable, runInAction } from "mobx";
import { SchemaOf } from "yup";
import { IFormStoreError, IFormStoreValue } from "./index.types";
import { isValidationError } from "./utils";

export class FormStore<Values extends object> {
  private _values: Values;
  private _errors: { [key in keyof Values]: IFormStoreError };
  private validationSchema?: SchemaOf<{ [key in keyof Values]: any }>;

  constructor(
    values: Values,
    validationSchema?: SchemaOf<{ [key in keyof Values]: any }>
  ) {
    this._values = values;

    const errors = JSON.parse(JSON.stringify(values));
    Object.keys(errors).forEach((key) => (errors[key] = null));
    this._errors = errors;

    if (validationSchema) this.validationSchema = validationSchema;
    makeAutoObservable(this);
  }

  /** Изменяет значение value по key
   * - Если в констурктор передана валидационная схема заодно и валидацию запускает
   */
  changeValue = (value: IFormStoreValue, key?: string) => {
    if (key) this.values = { ...this.values, [key]: value };
    if (this.validationSchema) this.validate(key as keyof Values);
  };

  /** Изменяет значение value по key c проверкой типов
   * - Если в констурктор передана валидационная схема заодно и валидацию запускает
   */
  changeValueByKey = <K extends keyof Values>(value: Values[K], key?: K) => {
    if (key) this.values = { ...this.values, [key]: value };
    if (this.validationSchema) this.validate(key as keyof Values);
  };

  /** присваивает всем values и errors null
   * - Если передать key то сбросит только одно значение
   * -  Если ничего не передавать то сбросит все
   */
  reset = (key?: keyof Values) => {
    this.resetValues(key);
    this.resetError(key);
  };

  private changeError = (value: string | null, key: keyof Values) => {
    this._errors = { ...this._errors, [key]: value };
  };

  /** Валидирует всю форму и возвращает её, если она валидна. Если нет - возвращает null  */
  validateAll = async () => {
    this.resetError();

    try {
      return await this.validationSchema?.validate(this.values, {
        abortEarly: false,
      });
    } catch (error) {
      if (isValidationError(error)) {
        error.inner.map((error) =>
          this.changeError(error.message, error.path as keyof Values)
        );
      }

      return null;
    }
  };

  /** Валидация value по схеме
   * - Если передать key, то провалидруется только одно поле
   * - Если не передавать, то все поля values
   */
  validate = (key?: keyof Values) => {
    if (this.validationSchema) {
      if (key) {
        this.validationSchema
          .validateAt(String(key), this.values)
          .then(() => {
            this.changeError(null, key);
          })
          .catch((err: { errors: string[] }) => {
            this.changeError(err?.errors?.join(" ") || "Неверно", key);
          });
      } else {
        Object.keys(this.values).forEach((key) => {
          this.validate(key as keyof Values);
        });
      }
    }
  };

  /** Сброс ошибок на null
   * - Если передать key, то только одно поле key
   * - Если не передавать key, то все поля errors
   */
  resetError = (key?: keyof Values) => {
    if (key) {
      this._errors = { ...this._errors, [key]: null };
    } else {
      Object.keys(this._errors).forEach((errorKey) => {
        this._errors = { ...this._errors, [errorKey]: null };
      });
    }
  };

  /** Сброс values на null
   * - Если передать key, то только одно поле key
   * - Если не передавать key, то все поля errors
   */
  resetValues = (key?: keyof Values) => {
    if (key) {
      this._values = { ...this._values, [key]: null };
    } else {
      Object.keys(this._errors).forEach((valueKey) => {
        this._values = { ...this._values, [valueKey]: null };
      });
    }
  };

  /** true/false Все поля values соответствуют валидацаионной схеме */
  get isValid(): boolean {
    if (this.validationSchema) {
      return this.validationSchema.isValidSync(this.values);
    } else {
      return true;
    }
  }

  get values() {
    return this._values;
  }

  set values(values: Values) {
    runInAction(() => {
      this._values = values;
    });
  }

  /** Рекурсивно разбирает объект и присваивает значения в values */
  setValues = (values: object | { [key in keyof Values]: any }) => {
    Object.entries(values).forEach(([key, value]) => {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        this.setValues(value);
      } else {
        if (["string", "number", "boolean"].includes(typeof value)) {
          this._values = { ...this._values, [key]: value };
        }
      }
    });
  };

  get errors() {
    return this._errors;
  }

  log = () => {
    console.log(
      JSON.parse(
        JSON.stringify({
          values: this._values,
          errors: this._errors,
        })
      )
    );
  };

  /** Применять вот так
   * -  <Input label="Фамилия" isRequired {...store.form.adapters.lastName} />
   */
  get adapters() {
    let adapters = {};
    Object.keys(this._values).forEach((key) => {
      adapters = {
        ...adapters,
        [key]: {
          name: key,
          value: this.values[key as keyof Values],
          onChange: this.changeValue,
          errorMessage: this.errors[key as keyof Values],
        },
      };
    });
    return adapters as {
      [key in keyof Values]: {
        name: string;
        errorMessage: string | null;
        onChange: (value: any, name?: string) => void;
        value: any;
      };
    };
  }

  setError = (value: string | null, key: keyof Values) => {
    this._errors = { ...this._errors, [key]: value };
  };
}
