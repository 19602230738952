import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../../../components/layouts/MainLayout";
import UserEditCreateForm from "../../../../components/UserEditCreateForm";
import UserEditCreateRoles from "../../../../components/UserEditCreateRoles";
import Button from "../../../../kit/components/Button";
import ButtonsContainer from "../../../../kit/components/ButtonsContainer";
import Divider from "../../../../kit/components/Divider";
import FlexColumn from "../../../../kit/components/FlexColumn";
import H1 from "../../../../kit/components/H1";
import { URL_ADMISSION_USERS } from "../../../../routing/urls";
import { UserItemCreatePageStore } from "../../../../stores/pages/admission/UserItemCreatePageStore";

const store = new UserItemCreatePageStore();

const UserCreatePage: React.FC = () => {
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  const { push } = useHistory();

  const onClickCreate = async () => {
    const isSaved = await store.tryCreate();
    if (isSaved) push(URL_ADMISSION_USERS + `/${String(isSaved)}`);
  };

  const onClickCancel = () => {
    push(URL_ADMISSION_USERS);
  };

  return (
    <MainLayout>
      <H1>Создание пользователя</H1>
      <FlexColumn>
        <UserEditCreateForm form={store.forms.form} />
        <UserEditCreateRoles
          values={store.forms.roles.values}
          options={store.forms.roles.rolesOptions}
          onChange={store.forms.roles.onChange}
        />
        <Divider />
        <ButtonsContainer justifyRight>
          <Button onClick={onClickCreate}>Создать</Button>
          <Button variant="secondary" onClick={onClickCancel}>
            Отменить
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(UserCreatePage);
