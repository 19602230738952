import React, { FC } from "react";
import IconError from "./IconError";
import IconSuccess from "./IconSuccess";
import { TFileStatusProps } from "./index.types";

export const FileStatus: FC<TFileStatusProps> = ({
  status,
  loadingProgress,
  preview,
  name,
}) => {
  const statusIcon =
    status === "success" ? (
      <IconSuccess className="files-list__status-icon" />
    ) : status === "error" ? (
      <IconError className="files-list__status-icon" />
    ) : null;

  const loadingProgressNormalized = loadingProgress
    ? Math.min(Math.max(0, loadingProgress), 100)
    : 0;

  return (
    <div className={"files-list__file-status"}>
      <div className="files-list__file-status-inner">
        {preview && (
          <img src={preview} alt={name} className="files-list__file-preview" />
        )}
        {statusIcon}
        {status === "loading" && (
          <div className="files-list__file-progress">
            <div
              className="files-list__file-progress-bar"
              style={{
                transform: `translateX(${loadingProgressNormalized - 100}%)`,
              }}
              role="progressbar"
              aria-valuemin={0}
              aria-valuemax={100}
              aria-valuenow={loadingProgressNormalized}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};
