// export const baseUrl = "http://172.16.100.102:8077";

// export const baseUrl = 'http://localhost:7003';

// Для билда в тест
export const baseUrl = "https://ae-admin.aiwoo.info";

// Тестовый url для магазина поощрений
// export const baseUrl = "https://virtserver.swaggerhub.com/app33/shop/1.0.0";

// export const baseUrl = "http://localhost:9099";

// для билда в продакшен
// export const baseUrl = "http://172.23.199.126";

export function makeApiUrl(url: string, search?: any) {
  return `${baseUrl}/${url
    .split("/")
    .filter((t) => t)
    .join("/")}${search ? `?${new URLSearchParams(search).toString()}` : ""}`;
}

export function makeBasePath(...path: string[]) {
  return (
    "/" +
    path
      .map((p) =>
        p
          .split("/")
          .filter((t) => t)
          .join("/")
      )
      .join("/")
  );
}
