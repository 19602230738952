import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../../api";
import { UserEditCreateFormStore } from "../../../common/UserEditCreateFormStore";
import { TData } from "../UsersItemPageStore/UserForm";

export class UserItemEditPageStore {
  isLoadings = { getUser: false, postUser: false };

  forms = new UserEditCreateFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: string) => {
    this.forms.init();
    this.getUser(id);
  };

  reset = () => {
    this.forms.reset();
  };

  getUser = (id: string) => {
    this.isLoadings.getUser = true;
    return API.access.Users.apiUsersIdGet(id)
      .then((res: any) => {
        runInAction(() => {
          try {
            this.forms.form.values = (res.data! as TData)?.data;
            this.forms.roles.setValues(
              (res.data! as TData)?.credentials?.organizations || []
            );
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch(() => {
        this.reset();
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getUser = false;
        });
      });
  };

  saveEditedUser = async (id: string) => {
    this.forms.validate();
    if (this.forms.isValid) {
      return this.postUserEdit(id);
    } else {
      return null;
    }
  };

  postUserEdit = (id: string) => {
    this.isLoadings.postUser = true;
    return API.access.Users.apiUsersEditUserIdPost(id, {
      form: {
        data: {
          lastName: this.forms.form.values.lastName || "",
          firstName: this.forms.form.values.firstName || "",
          middleName: this.forms.form.values.middleName || null,
          email: this.forms.form.values.email || "",
          phone: this.forms.form.values.phone || null,
          otherPhones: this.forms.form.values.otherPhones || null,
          login: this.forms.form.values.login || "",
          password: this.forms.form.values.password || null,
          passwordConfirm: this.forms.form.values.passwordConfirm || null,
          isDeactivated: this.forms.form.values.isDeactivated || false,
        },
        credentials: { organizations: this.forms.roles.organizationsForSave },
      },
    })
      .then(({ data }) => data)
      .catch(({ response: { data } }) => {
        try {
          if (data) {
            Object.entries(data).forEach(([key, value]) => {
              const errorValue = (value as string[]).join(". ");
              const errorKey: string = String(
                key.split(".").slice(-1).pop()
              ).toLowerCase();
              this.forms.form.setError(errorValue, errorKey as any);
            });
          }
        } catch (error: any) {
          console.error(error);
        }
        return null;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.postUser = false;
        });
      });
  };
}
