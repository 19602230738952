import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { ICategorySelectOption } from "newApp/components/CategorySelect";
import { FormStore } from "newApp/kit/stores/FormStore";
import formValidationSchema from "./formValidationSchema";
import { IStepItemStoreForm, StepItemStore } from "./StepItemStore";

export class StepsFormStore {
  isLoadings = { getRootCategories: false };

  form = new FormStore(
    {
      categoryId: null,
    },
    formValidationSchema
  );

  options: {
    categories: ICategorySelectOption[];
  } = {
    categories: [],
  };

  steps: StepItemStore[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getRootCategories();
  };

  reset = () => {
    this.form.reset();
    this.steps = [];
  };

  getRootCategories = () => {
    this.isLoadings.getRootCategories = true;
    this.options.categories = [];
    return API.admin.Category.apiObjectsGet()
      .then(({ data }) => {
        runInAction(() => {
          data.forEach((i) => this.categoryParser(i as any));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getRootCategories = false;
        });
      });
  };

  categoryParser = (c: ICategory, level = 0) => {
    this.options.categories = [
      ...this.options.categories,
      {
        text: c.name,
        value: c.id,
        level,
      },
    ];
    if (c.children?.length) {
      c.children?.forEach((o) => this.categoryParser(o, level + 1));
    }
  };

  addStep = (form?: IStepItemStoreForm) => {
    this.steps.push(new StepItemStore(form));
  };

  removeStep = (idx: number) => {
    this.steps.splice(idx, 1);
  };

  validate = async () => {
    await this.form.validate();
    for await (let i of this.steps) {
      i.form.validate();
    }
  };

  get isValid() {
    return this.form.isValid && !this.steps.some((i) => !i.form.isValid);
  }
}

export interface ICategory {
  daysLimit: number;
  level: number;
  id: string;
  name: string;
  organizationId: string;
  parentId: string | null;
  state: number;
  children?: ICategory[];
}
