import { observer } from "mobx-react";
import Tabs from "newApp/kit/components/Tabs";
import { URL_SITE_FAQ, URL_SITE_FAQ_RUBRICS } from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";

const FaqNavigator: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const tabs = [
    {
      title: "Вопросы",
      onClick: () => push(URL_SITE_FAQ),
      isActive: pathname === URL_SITE_FAQ,
    },
    {
      title: "Рубрики",
      onClick: () => push(URL_SITE_FAQ_RUBRICS),
      isActive: pathname === URL_SITE_FAQ_RUBRICS,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(FaqNavigator);
