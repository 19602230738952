import { makeAutoObservable, runInAction } from "mobx";

import { API_AUTH_WITHOUT_INTERCEPTOR } from "../../../api/auth";
import { LocalStorage } from "../../common/LocalStorage";
import TokenStore from "../../common/TokenStore";
import { LoginFormStore } from "./loginFormStore";

export class LoginPageStore {
  loginForm = new LoginFormStore();
  storage = new LocalStorage();

  isLoadings = {
    postLogin: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  onLogin = () => {
    this.loginForm.form.validate();
    if (this.loginForm.form.isValid) {
      this.loginForm.clearwarningCardErrorText();
      return this.postLogin();
    }
  };

  postLogin = () => {
    this.isLoadings.postLogin = true;
    return API_AUTH_WITHOUT_INTERCEPTOR.apiAisaeLoginPost({
      login: this.loginForm.form.values.login,
      password: this.loginForm.form.values.password,
    })
      .then((res: { data: any }) => {
        /** ответ не типизирован в свагере */
        try {
          TokenStore.accessToken = (res.data as any).accessToken;
          TokenStore.refreshToken = (res.data as any).refreshToken;
        } catch (error: any) {
          console.error(error);
        }
        return true;
      })
      .catch(() => {
        runInAction(() => {
          this.loginForm.warningCardErrorText =
            "Логин или пароль введены неверно";
        });
        return false;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.postLogin = false;
        });
      });
  };
}
