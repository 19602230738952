import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";
import { IOrganization } from "newApp/stores/pages/administrations/organizations/OrganizationsPageStore";
import formValidationSchema from "./formValidationSchema";

export class OrganizationFormStore {
  isLoadings = { getOrganizations: false };

  form = new FormStore(
    {
      parentId: null,
      title: null,
      titleShort: null,
    },
    formValidationSchema
  );

  options: {
    organizations: ISelectOption[];
  } = {
    organizations: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getOrganizations();
  };

  reset = () => {
    this.form.reset();
    this.resetOrganizations();
  };

  resetOrganizations = () => {
    this.options.organizations = [];
  };

  getOrganizations = () => {
    this.isLoadings.getOrganizations = true;
    this.resetOrganizations();
    return API.admin.Organization.apiOrganizationsGet()
      .then(({ data }) => {
        runInAction(() => {
          this.options.organizations.push({
            text: "Не указано",
            value: null,
          });
          data.forEach((i) => this.parser(i as any));
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getOrganizations = false;
        });
      });
  };

  parser = (organization: IOrganization, level = 0) => {
    runInAction(() => {
      this.options.organizations = [
        ...this.options.organizations,
        { text: organization.title, value: organization.id },
      ];
    });
    if (organization.children?.length) {
      organization.children?.forEach((o) => {
        this.parser(o, level + 1);
      });
    }
  };
}
