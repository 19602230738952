import DatePickerBase from "react-datepicker";
import ru from "date-fns/locale/ru";
import Button from "../Button";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import MaskedInput from "./MaskedInput";
import { IDatePickerProps } from "./index.types";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import Tooltip from "../Tooltip";
import CalendarHeader from "./CalendarHeader";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const DatePicker: React.FC<IDatePickerProps> = ({
  value,
  onChange,
  name,
  dateFormat = "dd.MM.yyyy",
  dateMaskOptions = { mask: Date, overwrite: true },
  timeInputLabel = "Время",
  placeholder,
  id,
  errorMessage,
  errorTitle,
  isRequired,
  requiredLabel = "Обазятельно",
  ...props
}) => {
  const handleChange = (date: Date | null) => {
    onChange(date, name);
  };

  const datepickerRef = useRef<HTMLDivElement>(null);

  const [isPlaceholderShown, setIsPlaceholderShown] = useState(false);

  useEffect(() => {
    if (value === null) {
      setIsPlaceholderShown(true);
    } else {
      setIsPlaceholderShown(false);
    }
  }, [value]);

  const [isErrorTooltipShown, setIsErrorTooltipShown] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleErrorTooltip = () => {
    setIsErrorTooltipShown((prev) => !prev);
  };

  const closeErrorTooltip = () => setIsErrorTooltipShown(false);

  const handleErrorButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    toggleErrorTooltip();
    inputRef.current?.focus();
  };

  const handleClickOutside = useCallback(closeErrorTooltip, []);

  useOnClickOutside(datepickerRef, handleClickOutside);

  return (
    <div
      className={classNames(
        "datepicker",
        (errorMessage || errorTitle) && "datepicker--error"
      )}
      data-testid="DatePicker"
      ref={datepickerRef}
    >
      <DatePickerBase
        selected={value}
        dateFormat={dateFormat}
        timeInputLabel={timeInputLabel}
        fixedHeight
        customInput={
          <MaskedInput
            maskOptions={dateMaskOptions}
            id={id}
            className={"datepicker__input"}
            required={isRequired}
          />
        }
        renderCustomHeader={CalendarHeader}
        todayButton={
          <Button onClick={() => {}} size="small">
            Сегодня
          </Button>
        }
        onChange={handleChange}
        locale={ru}
        {...props}
      />

      {placeholder && (
        <label
          className={classNames(
            "datepicker__label",
            !isPlaceholderShown && "datepicker__label--top"
          )}
          htmlFor={id}
        >
          {placeholder}
        </label>
      )}

      {(errorMessage || errorTitle) && (
        <button
          className={"datepicker__error-button"}
          aria-label="Информация об ошибке"
          onMouseDown={handleErrorButtonClick}
          tabIndex={-1}
        />
      )}

      {(errorMessage || errorTitle) && isErrorTooltipShown && (
        <Tooltip
          onClose={closeErrorTooltip}
          title={errorTitle}
          text={errorMessage}
          className="datepicker__tooltip"
        />
      )}

      {isRequired && (
        <div className="datepicker__required-label">
          {requiredLabel ? requiredLabel : "Обязательно"}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
