import "./styles.scss";

import { observer } from "mobx-react";
import React, { useState } from "react";

import Link from "../Link";

interface IProps {
  links: {
    text: string;
    to: string;
  }[];
}

const HeaderNavigationMore: React.FC<IProps> = (props) => {
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

  if (!props.links || !props.links.length) return null;

  return (
    <button
      onClick={() => setIsShowMenu(!isShowMenu)}
      className="header-navigation-more"
    >
      <div className="header-navigation-more__body">
        <div className="header-navigation-more__text">ЕЩЕ</div>
        <div
          className={[
            "header-navigation-more__icon",
            isShowMenu ? "header-navigation-more__icon--active" : "",
          ].join(" ")}
        />
      </div>
      {isShowMenu && (
        <div className="header-navigation-more__menu">
          <div
            className="header-navigation-more__menu-close-icon"
            onClick={() => setIsShowMenu(false)}
          />
          <div className="header-navigation-more__menu-items">
            {props.links.map((link, idx) => (
              <Link to={link.to} key={idx}>
                {link.text}
              </Link>
            ))}
          </div>
        </div>
      )}
    </button>
  );
};

export default observer(HeaderNavigationMore);
