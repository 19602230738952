import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import DatePicker from "newApp/kit/components/DatePicker";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import Modal from "newApp/kit/components/Modal";
import { AnalyticsFilterStore } from "./store";
import React, { useState } from "react";
import IconFilter from "../../../../components/IconFilter";

export interface IAnalyticsFilterProps {
  filter: AnalyticsFilterStore;
  getList: () => void;
}

const AnalyticsFilter: React.FC<IAnalyticsFilterProps> = (props) => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const onClickSearch = () => {
    props.getList();
    setIsShowPopup(false);
  };
  const onClickCancel = () => {
    props.filter.reset();
    props.getList();
    setIsShowPopup(false);
  };

  return (
    <>
      <Button
        onClick={() => setIsShowPopup(true)}
        variant="secondary"
        size="small"
      >
        <IconFilter />
        <span> Фильтр</span>
      </Button>
      <Modal isOpen={isShowPopup} onClose={() => setIsShowPopup(false)}>
        <H1>Фильтр</H1>
        <FlexColumn>
          <DatePicker
            placeholder="Начальная дата"
            {...props.filter.form.adapters.beginDate}
            maxDate={props.filter.form.adapters.endDate.value}
          />
          <DatePicker
            placeholder="Конечная дата"
            {...props.filter.form.adapters.endDate}
            minDate={props.filter.form.adapters.beginDate.value}
          />

          <ButtonsContainer>
            <Button onClick={onClickSearch}>Искать</Button>
            <Button onClick={onClickCancel} variant="secondary">
              Сбросить
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      </Modal>
    </>
  );
};

export default observer(AnalyticsFilter);
