import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { ShopCategoryFormStore } from "newApp/stores/common/ShopCategoryFormStore";

export class CategoryEditPageStore {
  isLoadings = {
    getCategory: false,
    saveCategory: false,
  };

  categoryForm = new ShopCategoryFormStore();

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return Object.values(this.isLoadings).some((i) => i);
  }

  init = (id: string) => {
    this.getCategory(id);
  };

  getCategory = (id: string) => {
    this.isLoadings.getCategory = true;
    API.shop.Categories.apiCategoriesIdGet(id)
      .then(({ data }) => {
        runInAction(() => {
          this.categoryForm.form.values.title = data.title;
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getCategory = false;
        });
      });
  };

  reset = () => {
    this.categoryForm.reset();
  };

  validateAndSave = (id) => {
    this.categoryForm.form.validate();
    if (this.categoryForm.form.isValid) {
      return this.saveCategory(id);
    }
  };

  saveCategory = (id: string) => {
    this.isLoadings.saveCategory = true;
    return API.shop.Categories.apiCategoriesEditIdPost(id, {
      title: this.categoryForm.form.values.title,
    })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.saveCategory = false;
        });
      });
  };
}
