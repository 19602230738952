import { observer } from "mobx-react";
import ButtonBack from "newApp/components/ButtonBack";
import MainLayout from "newApp/components/layouts/MainLayout";
import NewsForm from "newApp/components/NewsForm";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_NEWS } from "newApp/routing/urls";
import { NewsEditPageStore } from "newApp/stores/pages/site/news/NewsEditPageStore";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

const store = new NewsEditPageStore();

const NewsEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  useEffect(() => {
    store.init(id);
    return () => store.reset();
  }, [id]);

  const goOnNewsItem = () => push(URL_SITE_NEWS + `/${id}`);

  const onSave = () => {
    store.saveNews().then(goOnNewsItem);
  };

  const isDisabledButton =
    store.isLoadings.saveNews || store.form.mainPhoto.isLoadings.uploadImage;

  return (
    <MainLayout
      isLoading={store.isLoadings.getNewsItem || store.isLoadings.saveNews}
    >
      <H1>Редактирование новости</H1>
      <FlexColumn>
        <ButtonBack url={URL_SITE_NEWS + `/${id}`}>Назад к новости</ButtonBack>
        <NewsForm {...store.form} />
        <ButtonsContainer>
          <Button onClick={onSave} disabled={isDisabledButton}>
            {store.form.mainPhoto.isLoadings.uploadImage
              ? "Загрузка изображения"
              : "Сохранить изменения"}
          </Button>
          <Button onClick={goOnNewsItem} variant="secondary">
            Отмена
          </Button>
        </ButtonsContainer>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(NewsEditPage);
