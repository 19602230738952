import React from "react";

const IconExit = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 10C9.63188 10 9.33341 10.2985 9.33341 10.6667V14.6667H1.33335V1.33334H9.33341V5.33334C9.33341 5.70153 9.63188 6 10.0001 6C10.3683 6 10.6667 5.70153 10.6667 5.33334V0.666656C10.6667 0.298469 10.3683 0 10.0001 0H0.666661C0.298471 0 0 0.298469 0 0.666656V15.3333C0 15.7015 0.298471 16 0.666661 16H10.0001C10.3683 16 10.6667 15.7015 10.6667 15.3333V10.6667C10.6667 10.2985 10.3683 10 10.0001 10Z"
        fill="#0063B0"
      />
      <path
        d="M15.849 8.4225C15.8554 8.41475 15.8609 8.40659 15.8668 8.39863C15.8739 8.38919 15.8812 8.37994 15.8878 8.37009C15.8942 8.36059 15.8997 8.35072 15.9055 8.34097C15.9108 8.33206 15.9164 8.32334 15.9213 8.31419C15.9266 8.30425 15.9311 8.294 15.9359 8.28384C15.9405 8.27428 15.9452 8.26487 15.9493 8.25506C15.9534 8.24522 15.9567 8.23516 15.9602 8.22516C15.964 8.21462 15.968 8.20425 15.9713 8.1935C15.9743 8.1835 15.9765 8.17331 15.9791 8.16322C15.9819 8.15225 15.9849 8.14141 15.9871 8.13025C15.9895 8.11853 15.9908 8.10672 15.9925 8.09494C15.9939 8.08522 15.9958 8.07566 15.9968 8.06578C16.0011 8.02203 16.0011 7.97794 15.9968 7.93419C15.9958 7.92434 15.994 7.91478 15.9925 7.90503C15.9909 7.89325 15.9895 7.88141 15.9871 7.86972C15.9849 7.85853 15.9819 7.84772 15.9791 7.83675C15.9765 7.82662 15.9743 7.81647 15.9713 7.80647C15.968 7.79572 15.964 7.78531 15.9602 7.77481C15.9566 7.76481 15.9534 7.75475 15.9493 7.74491C15.9452 7.73509 15.9405 7.72569 15.9359 7.71613C15.9311 7.70597 15.9266 7.69572 15.9213 7.68578C15.9164 7.67663 15.9108 7.66791 15.9055 7.659C15.8997 7.64925 15.8941 7.63938 15.8878 7.62988C15.8812 7.62003 15.8739 7.61078 15.8668 7.60134C15.8609 7.59338 15.8553 7.58522 15.849 7.57747C15.8353 7.56075 15.8208 7.54466 15.8055 7.52934C15.8053 7.52909 15.805 7.52884 15.8048 7.52863L13.1382 4.86197C12.8778 4.60162 12.4557 4.60162 12.1953 4.86197C11.935 5.12231 11.935 5.54444 12.1953 5.80478L13.7239 7.33337H8.00005C7.63186 7.33337 7.33339 7.63184 7.33339 8.00003C7.33339 8.36822 7.63186 8.66669 8.00005 8.66669H13.7239L12.1953 10.1953C11.935 10.4556 11.935 10.8778 12.1953 11.1381C12.4557 11.3984 12.8778 11.3984 13.1382 11.1381L15.8048 8.47144C15.8051 8.47119 15.8053 8.47094 15.8055 8.47072C15.8208 8.45531 15.8353 8.43922 15.849 8.4225Z"
        fill="#0063B0"
      />
    </svg>
  );
};

export default IconExit;
