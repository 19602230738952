import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "../../components/LoginForm";
import { URL_MAIN } from "../../routing/urls";
import { LoginPageStore } from "../../stores/pages/login";

import "./styles.scss";

const store = new LoginPageStore();

const LoginPage: React.FC = () => {
  let { push } = useHistory();
  const onLoginHandler = async () => {
    const isLoginSuccess = await store.onLogin();
    if (isLoginSuccess) push(URL_MAIN);
  };

  return (
    <div className="login-page">
      <LoginForm
        values={store.loginForm.form.values}
        errors={store.loginForm.form.errors}
        onChange={store.loginForm.form.changeValue}
        onLogin={onLoginHandler}
        warningCardErrorText={store.loginForm.warningCardErrorText}
        onClosewarningCardErrorText={store.loginForm.clearwarningCardErrorText}
        isLoading={store.isLoadings.postLogin}
      />
    </div>
  );
};

export default observer(LoginPage);
