import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { FormStore } from "newApp/kit/stores/FormStore";
import validationSchema from "./validationSchema";

export class DocsEditStore {
  terms: string | null = null;
  isLoading = false;
  constructor() {
    makeAutoObservable(this);
  }

  form = new FormStore({ description: null }, validationSchema);

  init = () => {
    runInAction(() => {
      this.isLoading = true;
    });
    API.administration.Documents.documentsUserAgreementGet()
      .then((res) => {
        runInAction(() => {
          this.terms = res.data.content;
          this.form.changeValueByKey(res.data.content, "description");
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  onSave = async () => {
    await this.form.validate();
    if (this.form.isValid) {
      return await this.saveDescription();
    }
  };

  saveDescription = () => {
    return API.administration.Documents.documentsUserAgreementPost({
      content: this.form.values.description,
    })
      .then(() => true)
      .catch((e) => {
        console.error(e);
        return false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  reset = () => {
    this.form.reset();
    this.isLoading = false;
  };
}
