import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AdmissionTabs from "../../../components/AdmissionTabs";
import MainLayout from "../../../components/layouts/MainLayout";
import RolesListTable from "../../../components/RolesListTable";
import Button from "../../../kit/components/Button";
import FlexColumn from "../../../kit/components/FlexColumn";
import H1 from "../../../kit/components/H1";
import { URL_ADMISSION_ROLES_CREATE } from "../../../routing/urls";
import { RolesListPageStore } from "../../../stores/pages/admission/roles/RolesListPageStore";

const store = new RolesListPageStore();

const RolesListPage: React.FC = () => {
  const { push } = useHistory();
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout isLoading={store.rolesList.isLoading}>
      <FlexColumn>
        <H1>Роли пользователей системы</H1>
        <AdmissionTabs />
        <div>
          <Button onClick={() => push(URL_ADMISSION_ROLES_CREATE)}>
            Добавить роль
          </Button>
        </div>
        <RolesListTable roles={store.rolesList.roles} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(RolesListPage);
