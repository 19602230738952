import { makeAutoObservable, runInAction } from "mobx";

import { API } from "newApp/api";
// import { ProductStatisticsModel } from "newApp/api/shop/openapi";
import { AnalyticsFilterStore } from "../../../../pages/shop/analytics/AnalyticsFilter/store";
import { formatISO } from "date-fns";
import FileSaver from "file-saver";
import { ProductStatisticsModel } from "newApp/api/shop/openapi";

class ShopAnalyticsStore {
  isLoading = false;
  filter: AnalyticsFilterStore;

  products: ProductStatisticsModel[] = [];

  constructor() {
    this.filter = new AnalyticsFilterStore();
    makeAutoObservable(this);
  }

  getShopAnalytics = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    return API.shop.Analytics.apiAnalyticsProductStatisticsGet()
      .then((res) => {
        runInAction(() => {
          this.products = res.data;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  downloadFile = () => {
    const beginDate = this.filter.form.values.beginDate
      ? formatISO(this.filter.form.values.beginDate, { representation: "date" })
      : undefined;
    const endDate = this.filter.form.values.endDate
      ? formatISO(this.filter.form.values.endDate, { representation: "date" })
      : undefined;

    API.shop.Analytics.apiAnalyticsProductsRegisterGet(beginDate, endDate, {
      responseType: "blob",
    }).then((res) => {
      const name = "Реестр покупателей.xlsx";
      const fileData = new File([res.data as unknown as BlobPart], name);
      FileSaver.saveAs(fileData, name);
    });
  };

  getShopFilteredAnalytics = async () => {
    runInAction(() => {
      this.isLoading = true;
    });

    const beginDate = this.filter.form.values.beginDate
      ? formatISO(this.filter.form.values.beginDate, { representation: "date" })
      : undefined;
    const endDate = this.filter.form.values.endDate
      ? formatISO(this.filter.form.values.endDate, { representation: "date" })
      : undefined;

    return API.shop.Analytics.apiAnalyticsProductStatisticsGet(
      beginDate,
      endDate
    )
      .then((res) => {
        runInAction(() => {
          this.products = res.data;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  get productInfo() {
    const prodArray: string[][] = [];
    this.products.forEach((item, idx) => {
      prodArray.push([
        (idx + 1) as unknown as string,
        item.productName,
        item.productNumber as unknown as string,
      ]);
    });

    return prodArray;
  }
}

export default new ShopAnalyticsStore();
