import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router";
import "./styles.scss";

export interface IButtonBackProps {
  url?: string;
  onClick?: () => void;
}

const ButtonBack: React.FC<IButtonBackProps> = (props) => {
  const { push, goBack } = useHistory();

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (props.url) {
      push(props.url);
    } else {
      goBack();
    }
  };

  return (
    <div className="button-back" onClick={onClick}>
      <span className="button-back__icon" />
      <span>{props.children || "Назад"}</span>
    </div>
  );
};

export default observer(ButtonBack);
