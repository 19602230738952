import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactModal from "react-modal";
import IconClose from "../IconClose";
import { IModalProps } from "./index.types";
import "./styles.scss";

const Modal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  contentLabel,
  className,
  title,
  children,
  ...reactModalProps
}) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const checkIfScrollable = () => {
    if (
      scrollRef.current &&
      scrollRef.current.scrollHeight > scrollRef.current.clientHeight
    ) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("resize", checkIfScrollable);
    } else {
      setIsScrollable(false);
      setIsScrolling(false);
    }

    return () => {
      if (isOpen) {
        window.removeEventListener("resize", checkIfScrollable);
      }
    };
  }, [isOpen]);

  const modalClasses = useMemo<
    Pick<Required<ReactModal.Props>, "className" | "overlayClassName">
  >(
    () => ({
      overlayClassName: {
        base: classNames(
          "kit-modal",
          isScrollable && "kit-modal--is-scrollable",
          isScrolling && "kit-modal--is-scrolling",
          className
        ),
        afterOpen: "kit-modal--after-open",
        beforeClose: "kit-modal--before-close",
      },
      className: {
        base: "kit-modal__content",
        afterOpen: "kit-modal__content--after-open",
        beforeClose: "kit-modal__content--before-close",
      },
    }),
    [className, isScrolling, isScrollable]
  );

  const renderOverlay = useCallback<
    Required<ReactModal.Props>["overlayElement"]
  >(
    (props, contentElement) => (
      <div
        {...props}
        onScroll={(e) => {
          if (e.currentTarget.scrollTop > 50) {
            setIsScrolling(true);
          } else if (e.currentTarget.scrollTop < 1) {
            setIsScrolling(false);
          }
        }}
        ref={scrollRef}
        data-testid="Modal"
      >
        {contentElement}
      </div>
    ),
    []
  );

  return (
    <ReactModal
      onAfterOpen={checkIfScrollable}
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={onClose}
      className={modalClasses.className}
      overlayClassName={modalClasses.overlayClassName}
      ariaHideApp={false}
      overlayElement={renderOverlay}
      {...reactModalProps}
    >
      <header className="kit-modal__header">
        {typeof title === "string" || typeof title === "number" ? (
          <h2 className="kit-modal__title">{title}</h2>
        ) : (
          title
        )}
        <button
          onClick={onClose}
          className="kit-modal__close-button"
          aria-label="Закрыть окно"
        >
          <IconClose className="kit-modal__close-icon" />
        </button>
      </header>
      <div className="kit-modal__body">{children}</div>
    </ReactModal>
  );
};

export default Modal;
