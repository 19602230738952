import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IFilesPreviewFile } from "newApp/components/FilesPreview";
import { IRadioButtonOption } from "newApp/kit/components/RadioButtons/index.types";
import { ISelectOption } from "newApp/kit/components/Select/index.types";
import { FormStore } from "newApp/kit/stores/FormStore";
import { getDataURL } from "newApp/kit/utils/getDataURL";
import { compareCoords } from "newApp/utils/compareCoords";
import formValidationSchema from "./formValidationSchema";
import { VoteDetailsFileFormStore } from "./VoteDetailsFileFormStore";

export class VoteDetailsFormStore {
  form = new FormStore(
    {
      title: null,
      endDateTime: null,
      startDateTime: null,
      place: null,
      annotation: null,
      numberOfPoints: null,
      position: null,
      fromAge: null,
      toAge: null,
      description: null,
      gender: null,
      isBanner: null,
      isImportant: false,
      isAgeLimit: false,
      isGenderLimit: false,
      isTerritoryLimit: false,
      isDigitalResult: true,
      isReward: null,
      mainVoteTitle: null,
    },
    formValidationSchema
  );

  options: {
    isDigitalResult: IRadioButtonOption[];
    gender: IRadioButtonOption[];
    placeType: ISelectOption[];
    positionTypes: ISelectOption[];
  } = {
    isDigitalResult: [
      {
        text: "Цифры",
        value: true,
      },
      {
        text: "Проценты",
        value: false,
      },
    ],
    gender: [
      {
        text: "Любой",
        value: "Any",
      },
      {
        text: "Мужской",
        value: "Male",
      },
      {
        text: "Женский",
        value: "Female",
      },
    ],
    placeType: [
      { value: "None", text: "Не выбрано" },
      { value: "Registration", text: "Место регистрации" },
      { value: "Living", text: "Место проживания" },
      { value: "All", text: "По месту регистрации или проживания" },
    ],
    positionTypes: [
      { value: "None", text: "Не выбрано" },
      { value: "Place", text: "По месту" },
      { value: "Geolocation", text: "По геопозиции" },
    ],
  };

  coordinates: TCoordinate[] = [];

  fileForm = new VoteDetailsFileFormStore();

  files: IFilesPreviewFile[] = [];
  filesIds: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {};

  reset = () => {
    this.form.reset();
    this.fileForm.reset();
    this.files = [];
    this.files = [];
  };

  setForm = (form: IVoteDetailsForm) => {
    if (form.files.length) {
      form.files.forEach((item) => {
        this.filesIds.push(item.id);
      });
      this.files = form.files.map((item) => ({
        reference: URL_API_ADMIN + `/api/votes/files/${item.id}`,
        type:
          item.name.split(".")[1] === "png"
            ? "image"
            : item.name.split(".")[1] === "jpg"
            ? "image"
            : item.name.split(".")[1] === "jpeg"
            ? "image"
            : item.name.split(".")[1],
        id: item.id,
        title: item.name,
      }));
    }

    this.form.setValues({
      title: form.title,
      isAgeLimit: !!form.fromAge || !!form.toAge,
      isGenderLimit: !!form.gender,
      isDigitalResult: !!form.isDigitalResult,
      isTerritoryLimit: !!form?.coordinates?.length,
      description: form.description,
      annotation: form.annotation,
      fromAge: form.fromAge,
      gender: form.gender,
      isBanner: !!form.isBanner,
      isImportant: !!form.isImportant,
      isReward: !!form["numberOfPoints"],
      numberOfPoints: form["numberOfPoints"] || null,
      toAge: form.toAge,
      place: form.place,
      position: form.position,
      mainVoteTitle: form.mainVoteTitle,
    });
    this.form.changeValueByKey(new Date(form.startDateTime), "startDateTime");
    this.form.changeValueByKey(new Date(form.endDateTime), "endDateTime");
    this.coordinates = form.coordinates;

    this.fileForm.setFiles({ ...form });
  };

  onAddFiles = (files: File[]) => {
    runInAction(() => {
      files.forEach((f, idx) => {
        this.uploadFile(f);
        getDataURL(f)
          .then((reference) => {
            runInAction(() => {
              this.files.push({
                file: f,
                type: f.type.split("/")[0],
                reference,
              });
            });
          })
          .catch(() => {
            console.error("Ошибка в создании превью");
          });
      });
    });
  };

  uploadFile = (file: File) => {
    // this.isLoadings.uploadFile = true;
    return API.admin.Files.apiVotesFilesSetPost(file)
      .then(({ data }) => {
        this.filesIds.push(data.Id);

        return data;
      })
      .catch((e) => console.error(e))
      .finally(() => {
        runInAction(() => {
          //   this.isLoadings.uploadFile = false;
        });
      });
  };

  removeFile = (idx: number) => {
    const id = this.filesIds[idx];

    API.admin.Files.apiVotesFilesFileIdDeletePost(id).then(() => {
      this.files.splice(idx, 1);
      this.filesIds.splice(idx, 1);
    });
  };

  validate = () => {
    this.form.validate();
  };

  get isValid() {
    return this.form.isValid;
  }

  resetCoordinates = () => {
    this.coordinates = [];
  };

  addCoords = (coords: TCoordinate) => {
    if (this.coordinates === null) this.coordinates = [];
    this.coordinates.push(coords);
  };

  removeCoords = (coords: TCoordinate) => {
    this.coordinates = this.coordinates.filter(
      (i) => !compareCoords(i, coords)
    );
  };

  removeNumberOfPoints = (value: boolean, key?: string) => {
    this.form.changeValue(value, "isReward");
    if (!value) this.form.changeValue(null, "numberOfPoints");
  };
}

export interface IVoteDetailsForm {
  title: null | string;
  mainVoteTitle: null | string;
  place: null | string;
  position: null | string;
  fromAge: null | string | number;
  toAge: null | string | number;
  isBanner: null | boolean;
  isImportant: null | boolean;
  isDigitalResult: null | boolean;
  isAgeLimit?: null | boolean;
  coordinates?: TCoordinate[];
  isGenderLimit?: null | boolean;
  isTerritoryLimit?: null | boolean;
  endDateTime: null | string | Date;
  startDateTime: null | string | Date;
  annotation: null | string;
  description: null | string;
  gender: null | string;
  fileId?: null | string;
  previewFileId?: null | string;
  files: { name: string; id: string }[];
}

export type TCoordinate = {
  latitude: number;
  longitude: number;
};
