import { makeAutoObservable, runInAction } from "mobx";
import { API } from "newApp/api";
import { URL_API_ADMIN } from "newApp/api/urls";
import { IChatMessage } from "newApp/components/Chat/index.types";
import FilesListStore from "newApp/kit/stores/FilesListStore";
import { FormStore } from "newApp/kit/stores/FormStore";

export interface IDeclarationModerationItemChatForm {
  newMessage: null | string;
}
export class DeclarationChatStore {
  private declarationId = "";

  private refreshInterval: NodeJS.Timer | null = null;

  messages: IChatMessage[] = [];

  form = new FormStore<IDeclarationModerationItemChatForm>({
    newMessage: null,
  });

  filesStore = new FilesListStore(async () => ({ id: "" }), {
    validation: {
      acceptFileTypes: ["image/*", "video/*", "application/*"],
    },
    isUploadManual: true,
  });

  constructor() {
    makeAutoObservable(this);
  }

  init = (declarationId: string) => {
    this.declarationId = declarationId;
  };

  reset = () => {
    this.stopRefresh();
    this.messages = [];
    this.declarationId = "";
    this.refreshInterval = null;
  };

  private startRefresh = () => {
    if (!this.refreshInterval) {
      this.refreshInterval = setInterval(this.getMessages, 3000);
    }
  };

  private stopRefresh = () => {
    if (this.refreshInterval) clearInterval(this.refreshInterval);
    this.refreshInterval = null;
  };

  onOpenWindow = () => {
    this.getMessages();
    this.startRefresh();
  };

  onCloseWindow = () => {
    this.stopRefresh();
  };

  private getMessages = () => {
    API.admin.Chat.apiChatMessagesViolationIdGet(this.declarationId)
      .then((res) => {
        runInAction(() => {
          const data = res.data as {
            messages: IChatMessage[];
          };
          try {
            this.messages = data.messages.map((m) => ({
              ...m,
              fileReferences: m.fileReferences.map((file) => ({
                ...file,
                reference: URL_API_ADMIN + "/" + file.reference,
              })),
            }));
          } catch (error: any) {
            console.error(error);
          }
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  sendMessage = () => {
    const photos = this.filesStore.files
      .filter((i) => i.file.type.includes("image"))
      .map((i) => i.file);
    const videos = this.filesStore.files
      .filter((i) => i.file.type.includes("video"))
      .map((i) => i.file);
    const audios = this.filesStore.files
      .filter((i) => i.file.type.includes("audio"))
      .map((i) => i.file);
    const docs = this.filesStore.files
      .filter((i) => i.file.type.includes("application"))
      .map((i) => i.file);

    if (
      this.form.values.newMessage ||
      !!photos.length ||
      !!videos.length ||
      !!audios.length ||
      !!docs.length
    ) {
      return API.admin.Chat.apiChatSendPost(
        this.form.values.newMessage || "",
        this.declarationId,
        photos,
        videos,
        audios,
        docs
      )
        .then(() => {
          this.form.reset("newMessage");
          this.filesStore.files = [];
          this.getMessages();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
}
