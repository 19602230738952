import LoaderSpinner from "components/Loader";
import { observer } from "mobx-react";
import FaqNavigator from "newApp/components/FaqNavigator";
import FaqRubricsList from "newApp/components/FaqRubricsList";
import MainLayout from "newApp/components/layouts/MainLayout";
import SiteNavigator from "newApp/components/SiteNavigator";
import Button from "newApp/kit/components/Button";
import FlexColumn from "newApp/kit/components/FlexColumn";
import H1 from "newApp/kit/components/H1";
import { URL_SITE_FAQ_RUBRICS_CREATE } from "newApp/routing/urls";
import store from "newApp/stores/pages/site/faq/rubrics/FaqRubricsListPageStore";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const FaqRubricsListPage: React.FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout>
      <H1>Рубрики часто задаваемых вопросов</H1>
      <FlexColumn>
        <SiteNavigator />
        <FaqNavigator />
        <Button onClick={() => push(URL_SITE_FAQ_RUBRICS_CREATE)}>
          Добавить рубрику
        </Button>
        {store.isLoadings.getRubrics ? (
          <LoaderSpinner />
        ) : (
          <FaqRubricsList {...store} />
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(FaqRubricsListPage);
