import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import MainLayout from "../../../../components/layouts/MainLayout";
import Button from "../../../../kit/components/Button";
import ButtonsContainer from "../../../../kit/components/ButtonsContainer";
import FlexColumn from "../../../../kit/components/FlexColumn";
import H1 from "../../../../kit/components/H1";
import H3 from "../../../../kit/components/H3";
import InfoTable from "../../../../kit/components/InfoTable";
import Table from "../../../../kit/components/Table";
import { URL_ADMISSION_ROLES } from "../../../../routing/urls";
import { RolesItemPageStore } from "../../../../stores/pages/admission/roles/RolesItemPageStore";

const store = new RolesItemPageStore();

const RoleItemPage: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) store.init(id);
    return () => store.reset();
  }, [id]);

  return (
    <MainLayout isLoading={store.info.isLoading}>
      <H1>{store.info.fullName}</H1>
      <ButtonsContainer>
        <Button
          variant="secondary"
          size="small"
          onClick={() => push(URL_ADMISSION_ROLES + `/${id}/edit`)}
        >
          Редактировать
        </Button>
      </ButtonsContainer>
      <FlexColumn>
        <InfoTable
          content={[
            ["Название", store.info.infoTable.name],
            ["id", store.info.infoTable.id],
            ["Доступно", store.info.infoTable.isRoleAvailableForModule],
            ["Уровень", store.info.infoTable.level],
          ]}
        />
        <div>
          <H3>Права</H3>
          <Table
            headers={["Модуль", "Сущность", "Операция", "Правило"]}
            content={store.info.rightsTable}
          />
        </div>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(RoleItemPage);
