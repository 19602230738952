import { observer } from "mobx-react";
import FlexColumn from "newApp/kit/components/FlexColumn";
import { DeclarationModerationItemPageStore } from "newApp/stores/pages/moderation/DeclarationModerationItemPageStore";
import React from "react";
import { useLocation } from "react-router";
import DeclarationItemBodyModeSwitcher, {
  DECLARATION_BODY_MODE,
  DECLARATION_BODY_MODE_CHAT,
  DECLARATION_BODY_MODE_HISTORY,
  DECLARATION_BODY_MODE_INFO,
} from "../DeclarationItemBodyModeSwitcher";
import DeclarationItemChat from "../DeclarationItemChat";
import DeclarationItemHistory from "../DeclarationItemHistory";
import DeclarationItemInfo from "../DeclarationItemInfo";
import "./styles.scss";

interface IProps extends DeclarationModerationItemPageStore {}

const DeclarationItemBody: React.FC<IProps> = (props) => {
  const { search } = useLocation();
  const bodyMode = new URLSearchParams(search).get(DECLARATION_BODY_MODE);

  return (
    <div className="declaration-item-body">
      <FlexColumn>
        <DeclarationItemBodyModeSwitcher />
        {(!bodyMode || bodyMode === DECLARATION_BODY_MODE_INFO) && (
          <DeclarationItemInfo {...props} />
        )}
        {bodyMode === DECLARATION_BODY_MODE_CHAT && (
          <DeclarationItemChat store={props.chat} />
        )}
        {bodyMode === DECLARATION_BODY_MODE_HISTORY && (
          <DeclarationItemHistory store={props.history} />
        )}
      </FlexColumn>
    </div>
  );
};

export default observer(DeclarationItemBody);
