import { addRemoveFromArray } from "./addRemoveFromArray";
import CascadeCheckboxesItem from "./CascadeCheckboxesItem";
import { createCascade } from "./createCascade";
import {
  ICascadeCheckboxesOption,
  ICascadeCheckboxesOptionRecursive,
} from "./index.types";
import "./styles.scss";

interface IProps<V> {
  options: ICascadeCheckboxesOption<V>[];
  values: V[];
  /** Всегда показывать все варианты в вложениях */
  isShowBody?: boolean;
  onChange: (value: V, name?: string, values?: V[]) => void;
  name?: string;
}

const CascadeCheckboxes = <V,>(props: IProps<V>) => {
  const cascade = createCascade(props.options);

  const RecursiveComponent = (
    recursiveProps: ICascadeCheckboxesOptionRecursive<any>
  ) => {
    return (
      <>
        {recursiveProps.options?.map((item, idx) => (
          <CascadeCheckboxesItem
            isShowBody={props.isShowBody}
            text={item.text}
            value={props.values.includes(item.id)}
            onChange={() =>
              props.onChange(
                item.id,
                props.name,
                addRemoveFromArray(item.id, props.values)
              )
            }
            key={idx}
          >
            <div className="cascade-checkboxes__container">
              <RecursiveComponent {...item} />
            </div>
          </CascadeCheckboxesItem>
        ))}
      </>
    );
  };

  return (
    <div className="cascade-checkboxes">
      <RecursiveComponent id="" text="" options={cascade} />
    </div>
  );
};

export default CascadeCheckboxes;
