import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AdmissionTabs from "../../../components/AdmissionTabs";
import MainLayout from "../../../components/layouts/MainLayout";
import UsersPageTable from "../../../components/UsersPageTable";
import Button from "../../../kit/components/Button";
import FlexColumn from "../../../kit/components/FlexColumn";
import H1 from "../../../kit/components/H1";
import { URL_ADMISSION_USERS_CREATE } from "../../../routing/urls";
import { UsersPageStore } from "../../../stores/pages/admission/users";

const store = new UsersPageStore();

const UsersPage: React.FC = () => {
  const { push } = useHistory();
  useEffect(() => {
    store.init();
    return () => store.reset();
  }, []);

  return (
    <MainLayout isLoading={store.isLoading}>
      <H1>Пользователи системы</H1>
      <FlexColumn>
        <AdmissionTabs />
        <div>
          <Button onClick={() => push(URL_ADMISSION_USERS_CREATE)}>
            Добавить пользователя
          </Button>
        </div>
        <UsersPageTable users={store.userList.users} />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(UsersPage);
