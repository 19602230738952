import TextArea from "common/components/Inputs/TextArea";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import Button from "newApp/kit/components/Button";
import ButtonsContainer from "newApp/kit/components/ButtonsContainer";
import FlexColumn from "newApp/kit/components/FlexColumn";
import Popup from "newApp/kit/components/Popup";
import { URL_EXECUTION_ACCEPTED } from "newApp/routing/urls";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { IExecutionStepProps } from "..";

const StepInwork: React.FC<IExecutionStepProps> = (props) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const { push } = useHistory();

  const onClickReject = async () => {
    if (await props.onReject()) push(URL_EXECUTION_ACCEPTED);
  };

  console.log("props StepInwork", toJS(props));

  return (
    <div>
      {!props.isDone && (
        <ButtonsContainer>
          <Button onClick={props.onClickGetInWork}>Взять в работу</Button>
          <Button onClick={() => setIsShowPopup(true)} variant="danger">
            Отказать
          </Button>
        </ButtonsContainer>
      )}

      <Popup
        isOpen={isShowPopup}
        onClose={() => setIsShowPopup(false)}
        title="Укажите причину отказа"
      >
        <FlexColumn>
          <TextArea
            placeholder="Причина отказа"
            minRows={6}
            {...props.form.adapters.reason}
          />
          <ButtonsContainer>
            <Button onClick={onClickReject} variant="danger">
              Отказать
            </Button>
            <Button onClick={() => setIsShowPopup(false)} variant="secondary">
              Отмена
            </Button>
          </ButtonsContainer>
        </FlexColumn>
      </Popup>
    </div>
  );
};

export default observer(StepInwork);
