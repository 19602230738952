import { observer } from "mobx-react";
import Tabs from "newApp/kit/components/Tabs";
import {
  URL_SITE_DOCS_TERMS,
  URL_SITE_DOCS_AGREEMENTS,
  URL_SITE_DOCS_POLICY,
} from "newApp/routing/urls";
import React from "react";
import { useHistory, useLocation } from "react-router";

const TechSupportNavigator: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const tabs = [
    {
      title: "Условия участия",
      onClick: () => push(URL_SITE_DOCS_TERMS),
      isActive: pathname === URL_SITE_DOCS_TERMS,
    },
    {
      title: "Пользовательское соглашение",
      onClick: () => push(URL_SITE_DOCS_AGREEMENTS),
      isActive: pathname === URL_SITE_DOCS_AGREEMENTS,
    },
    {
      title: "Политика обработки персональных данных",
      onClick: () => push(URL_SITE_DOCS_POLICY),
      isActive: pathname === URL_SITE_DOCS_POLICY,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default observer(TechSupportNavigator);
